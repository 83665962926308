import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    getFilterPaginationBecados,
    getSearchBecados,
    getFiltersData,
    getAllBecados,
} from "../../Store/slices/becados";
import Spinner from "../../components/Spinner";
import MUIDataTable from "mui-datatables";
import { BecadosFilterable } from "../../components/Common/Filters/Filterable.jsx";
import { Pagination } from "../../components/Common/Index";
import { replaceHttpToHttps } from "../../utils/http";
import { Fetch } from "../../Services/Fetch";
import { toast } from "react-toastify";
import useFileDownloader from "../../utils/fileDownloaderMaker";

export default function Index() {
    // Hooks Initilizr
    const history = useHistory();
    const dispatch = useDispatch();
    const { ExcelGetter } = useFileDownloader();

    // Constants
    const name = "Becados";
    const [isLoading, setIsLoading] = useState(true);
    const [searches, setSearches] = useState({});
    const [isExporting, setIsExporting] = useState(false);
    const { filtered, status, filters } = useSelector(({ becados }) => becados);

    const columns = [
        {
            name: "id",
            options: {
                display: false,
            },
        },
        {
            label: "Nombre",
            name: "becado_nombres",
        },
        {
            label: "Apellido",
            name: "becado_apellidos",
        },
        {
            label: "Género",
            name: "genero",
        },
        {
            label: "Institución",
            name: "institucion",
        },
        {
            label: "Carrera",
            name: "carrera",
        },
        {
            label: "Nivel",
            name: "nivel_educativo",
        },
        {
            label: "Area",
            name: "area_desarrollo",
        },
        {
            label: "Convocatoria",
            name: "convocatoria",
        },
        {
            label: "Locación",
            name: "campus_pais",
        },
        {
            label: "Cobertura",
            name: "coverage",
        },
        {
            label: "Estado",
            name: "estado",
        },
        {
            label: "Modalidad",
            name: "modalidad",
        },
    ];

    const options = {
        enableNestedDataAccess: ".",
        selectableRows: false,
        onRowClick: (data) => showdetails(data[0]),
        pagination: false,
        search: false,
        toolbar: false,
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
    };

    //Functions
    const paginateAhead = (nextLink) => {
        let link = "&";
        for (let key in searches) {
            link += `${key}=${searches[key]}&`;
        }
        link = link.slice(0, -1);

        dispatch(
            getFilterPaginationBecados(
                replaceHttpToHttps(nextLink + link ?? "")
            )
        );
    };

    const handleSearch = (obj) => {
        setSearches(obj);
        dispatch(getSearchBecados(obj));
    };

    const showdetails = (id) => history.push(`/becados/detalles/${id}`);

    const handleFileDownload = (obj) => {
        setIsExporting(true);
        if (obj.convocatoria === "") {
            toast.error("Debe seleccionar una convocatoria para exportar");
            setIsExporting(false);
            return null;
        }

        let url = `becados/exportar?convocatoria=${obj.convocatoria}&`;
        let convocName =
            filters["generalSelector"].find(
                ({ id }) => id === (obj.convocatoria | 0)
            ).name ?? "";

        for (let key in obj) {
            url += `${key}=${obj[key]}&`;
        }

        url = url.slice(0, -1);

        Fetch({
            uri: url,
            method: "get",
        }).then(({ data: { data } }) => {
            ExcelGetter(data, `Becados -> ${convocName}`);
            setIsExporting(false);
        });
    };

    // effect
    useEffect(() => {
        document.title = `${name} | ${global.title}`;
        dispatch(getAllBecados()).then(() => setIsLoading(false));
        dispatch(getFiltersData());
    }, []);

    return (
        <div className="block m-5 pb-8">
            {isLoading ? (
                <Spinner />
            ) : (
                <div>
                    <BecadosFilterable
                        selects={filters ?? {}}
                        setSearch={handleSearch}
                        exportable={handleFileDownload}
                        isSpinning={isExporting}
                        hasconvocatoria={true}
                    />
                    <MUIDataTable
                        title="Becados"
                        data={filtered}
                        columns={columns}
                        options={options}
                    />

                    <Pagination
                        meta={status?.data?.meta ?? {}}
                        links={status?.data?.links ?? {}}
                        linkCaller={paginateAhead}
                    />
                </div>
            )}
        </div>
    );
}
