export default function InformacionAcademica({ data = [] }) {
    return data?.map((obj, i) => (
        <div key={i} className="py-6 my-4 border-b border-gray-200">
            <form className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                    <label className="block text-sm font-medium text-gray-700">
                        Título
                    </label>
                    <div className="mt-1">
                        <input
                            className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                            value={obj?.carrera}
                            disabled
                        />
                    </div>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">
                        Institución
                    </label>
                    <div className="mt-1">
                        <input
                            className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                            value={obj?.institucion}
                            disabled
                        />
                    </div>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">
                        Fecha de ingreso
                    </label>
                    <div className="mt-1">
                        <input
                            className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                            value={obj?.fecha_entrada}
                            disabled
                        />
                    </div>
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700">
                        Índice obtenido
                    </label>
                    <div className="mt-1">
                        <input
                            className="px-2 text-3xl font-bold bg-white"
                            value={obj?.indice}
                            disabled
                        />
                    </div>
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700">
                        Fecha de salida
                    </label>
                    <div className="mt-1">
                        <input
                            className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                            value={obj?.fecha_salida || ""}
                            disabled
                        />
                    </div>
                </div>
                <div>
                    <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Beca de estudio
                    </label>
                    <div className="flex gap-4 mt-1">
                        <div className="flex items-center gap-2">
                            <label>Si</label>
                            <input
                                type="radio"
                                checked={obj?.isBecado}
                                id="about"
                                name="about"
                                className="block border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                disabled
                            />
                        </div>
                        <div className="flex items-center gap-2">
                            <label>No</label>
                            <input
                                type="radio"
                                checked={!obj?.isBecado}
                                id="about"
                                name="about"
                                className="block border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">
                        Documento anexo
                    </label>
                    <div className="mt-1">
                        <a
                            href={obj?.certificacion_url}
                            className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:ring-indigo-500 focus:border-indigo-500"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Ver documento
                        </a>
                    </div>
                </div>
            </form>
        </div>
    ));
}
