import { useEffect, useState } from "react";
import { Archive } from "react-feather";
import { CardNotification } from "../../components/card/Notification";
import {
    deleteNotification,
    getAllNotifications,
    readNotification,
} from "../../Services/Notifications";
export default function Notifications() {
    const [notifications, setnotifications] = useState([]);

    const onRead = async (id) => {
        await readNotification(id);
        getAll();
    };

    const onDelete = async (id) => {
        await deleteNotification(id);
        getAll();
    };

    const getAll = async () => {
        const res = await getAllNotifications();
        setnotifications(res);
    };

    useEffect(() => {
        getAll();
    }, []);

    return (
        <div className="grid gap-4">
            {notifications.length > 0 ? (
                notifications.map((notification) => (
                    <CardNotification
                        notification={notification}
                        onRead={onRead}
                        onDelete={onDelete}
                    />
                ))
            ) : (
                <div className="bg-white text-gray-500 h-80 flex justify-center items-center flex-col">
                    <Archive className="h-16 w-16" />
                    <h4>No tiene notificaciones</h4>
                </div>
            )}
        </div>
    );
}
