import React from 'react'
import { Route, useRouteMatch,Switch } from 'react-router'
import UserOne from './userOne'
import UsersAll from './usersAll'

export default function Users() {
    const {path}=useRouteMatch()

    return (
        <>
           <Switch>
               <Route exact path={`${path}/:id`}>
                   <UserOne/>
               </Route>
               <Route exact path={path}>
                   <UsersAll/>
               </Route>
          </Switch> 
        </>
    )
}
