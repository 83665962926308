import { useEffect, useState } from "react";
import {
    Switch,
    Route,
    useParams,
    Redirect,
    useHistory,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-feather";
import Tabnavigator from "../../../components/navigation/tabnavigator";
import Experiencialaboral from "./experiencialaboral";
import DatosPersonales from "./datosPersonales";
import DatosSocioeconomicos from "./datosSocioeconomicos";
import InformacionAcademica from "./informacionAcademica";
import Formulario from "./formulario";
import {
    getOneSolicitud,
    setRequerimiento,
} from "../../../Store/slices/solicitudes";
import noimage from "../../../images/no_image_available.png";
import { toast } from "react-toastify";
import { Fetch } from "../../../Services/Fetch";
import SendMessages from "../../../components/messages/SendMessages";
import { Button } from "../../../components/Button";
import renderError from "../../../helpers/renderErrors";
import Authorized from "../../../helpers/authorized";
import CicularSpinner from "../../../components/CicularSpinner";
import DocumentViewer from "./documentViewer";
import { setUser } from "../../../Store/slices/user";
import SendEmail from "../../../components/messages/SendEmail";
import ListadeSolicitudes from "./ListadeSolicitudes";

export default function DetalleEvaluacion() {
    // Hooks
    const dispatch = useDispatch();
    const history = useHistory();
    const { id, solicitud_id } = useParams();

    // Statefull-Variables
    const [userRole, setUserRole] = useState("");
    const [commet, setcommet] = useState("");
    const [loading, setloading] = useState(false);
    const [loadingr, setloadingr] = useState({});
    const [modal, setModal] = useState(false);

    const [modalEmail, setModalEmail] = useState(false);

    // InComponent Variables
    let initial;

    // Selectors
    const { one } = useSelector((state) => state.solicitudes);
    const { users } = useSelector((state) => state);

    console.log({ one });

    // Functions
    const setEvaluationRequarimentsFinal = async ({ _id }) => {
        setloadingr({ id: _id });
        try {
            if (
                window.confirm(
                    "!Alerta, una vez se cierre la solicitud, no se podra volver a editar, desea continuar ? "
                )
            ) {
                const res = await Fetch({
                    data: { aplication_id: solicitud_id },
                    uri: "solicitudes/finalizar",
                });

                dispatch(
                    setRequerimiento({
                        id: res.data.data.id,
                        score: res.data.data.score,
                    })
                );
                setloadingr({});
                return { res: res };
            }
        } catch (error) {
            setloadingr({});
            renderError(error.response.data);
            return null;
        }
    };

    const setEvaluationRequariments = async ({ _id, score, notes }) => {
        setloadingr({ id: _id });
        try {
            const res = await Fetch({
                data: { aplication_detail_id: _id, score: score, notes },
                uri: "solicitudes/details/evaluate",
            });
            dispatch(
                setRequerimiento({
                    id: res.data.data.id,
                    score: res.data.data.score,
                })
            );
            setloadingr({});
            return { res: res };
        } catch (error) {
            setloadingr({});
            renderError(error.response.data);
            return null;
        }
    };

    const giveBeca = async () => {
        setloading(true);

        if (
            window.confirm(
                "Esta seguro que desea proceder? La solicitud no podrá ser edita, y el candidato será notificado."
            )
        ) {
            try {
                await Fetch({
                    data: { aplication_id: one?.id },
                    uri: "solicitudes/becar",
                });
                toast.success("Beca otorgada");
            } catch (error) {
                renderError(error.response.data);
            }
        }

        setloading(false);
    };

    const rejectBeca = async () => {
        setloading(true);
        if (
            window.confirm(
                "Esta seguro que desea proceder? La solicitud no podrá ser edita, y el candidato será notificado."
            )
        ) {
            try {
                await Fetch({
                    data: { aplication_id: one?.id },
                    uri: "solicitudes/declinar",
                });
                toast.success("Beca declinada");
            } catch (error) {
                renderError(error.response.data);
            }
        }
        setloading(false);
    };

    const finisher = () => {
        setEvaluationRequarimentsFinal({
            _id: one.evaluacion[0].id,
            score: one.evaluacion[0].score || 0,
            notes: commet,
        }).then(({ res: { status = 404 } }) => {
            if (status >= 200 && status < 400) {
                toast.success(`La solicitud fue cerrada exitosamente`);

                Array.isArray(one?.isntitution)
                    ? history.push(
                          `/evaluaciones/detalles_evaluaciones/${one?.isntitution[0]?.institution_id}/${id}`
                      )
                    : history.push(`/evaluaciones`);
            } else {
                toast.error("Algo salió mal inténtelo nuevamente");
            }
        });
    };

    const setOnRevision = () => {
        Fetch({
            uri: "solicitudes/revision",
            data: { aplication_id: solicitud_id },
        }).then(() => {
            toast.success(`Solicitud puesta en revision`);
        });
    };

    const commentSaver = () => {
        Fetch({
            uri: "solicitudes/setnotes",
            data: { aplication: solicitud_id, notes: commet },
        }).then(() => {
            toast.success(`Los comentarios fueron guardados exitosamente`);
        });
    };

    const reEnable = () => {
        // aplication_id ==== solicitud_id
        Fetch({
            uri: "solicitudes/reopen",
            data: { aplication_id: solicitud_id },
        }).then((_) => {
            toast.success(`La solicitud fue re abierta exitosamente`);
        });
    };

    // Effects
    useEffect(() => {
        dispatch(getOneSolicitud({ aplication_id: solicitud_id }));
        setcommet(one.notes === undefined ? "" : one.notes);
        setUserRole(users.user.user.role.name);
    }, []);

    useEffect(() => {
        setcommet(one?.notes);
    }, [one?.notes]);

    return (
        <>
            <SendMessages
                setModal={setModal}
                state={modal}
                destino={one.candidate?.id}
            />
            <SendEmail
                setModal={setModalEmail}
                state={modalEmail}
                destino={one.candidate?.id}
            />
            <div className="grid grid-cols-1 gap-4 p-4 pb-8 lg:grid-cols-3 xl:col-span-4">
                <div className="lg:col-span-3 xl:col-span-4">
                    <div className="grid grid-cols-12 mb-[22px] bg-white items-center justify-start">
                        <div className="col-span-12 lg:col-span-4 grid xl:flex justify-center xl:justify-start items-center gap-4 p-4 border-solid border-[#F0F0F0] border-r-[1px]  ">
                            <img
                                src={one?.candidate?.image_url || noimage}
                                alt="Perfil"
                                className="max-w-[150px] p-2 justify-self-center "
                            />

                            <div className="grid items-center justify-center ">
                                <h3 className="w-full text-2xl text-[#231F20] mb-[2px]">{`${one?.candidate?.name} ${one?.candidate?.last_name}`}</h3>
                                <div className="mt-[16px] flex justify-center  items-center ">
                                    <span className="flex items-center mr-3">
                                        <Icon.BarChart
                                            size={16}
                                            className="text-[#7BB31A]"
                                        />
                                        {/* TODO  experiencia laboral  */}
                                        &nbsp;
                                        {one?.candidate?.experiencia_laboral
                                            ?.length || 0}
                                    </span>
                                    <span className="flex items-center">
                                        <Icon.Award
                                            size={16}
                                            className="text-[#FF9800]"
                                        />
                                        {/* TODO total de becas solicitadas */}
                                        &nbsp;
                                        {one?.candidate?.formacion_academica
                                            ?.length || 0}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="grid items-center justify-between col-span-12 gap-4 p-4 lg:col-span-8 lg:grid-flow-col lg:grid-cols-2 lg:grid-rows-3 xl:grid-cols-3 xl:grid-rows-2">
                            <div className="flex items-center">
                                <div className="pr-2">
                                    <Icon.BarChart size={16} />
                                </div>
                                <div className="capitalize">
                                    {one?.convocatoria?.name}
                                </div>
                            </div>

                            <div className="flex items-center ">
                                <div className="pr-2">
                                    <Icon.Award size={16} />
                                </div>
                                <div className="capitalize">
                                    {one?.academi_offer?.academic_offer_type}
                                </div>
                            </div>

                            <div className="flex items-center ">
                                <div className="pr-2">
                                    <Icon.BookOpen size={16} />
                                </div>
                                <div className="capitalize">
                                    {one?.academi_offer?.carrera?.career}
                                </div>
                            </div>

                            <div className="flex items-center ">
                                <div className="pr-2">
                                    <Icon.Home size={16} />
                                </div>
                                <div className="capitalize">
                                    {one?.academi_offer?.institution_name}
                                </div>
                            </div>

                            {one?.solicitud?.status && (
                                <div className="flex items-center gap-1 ">
                                    <div>{one?.solicitud?.status}</div>
                                    <div>
                                        <Icon.Circle
                                            size={16}
                                            stroke={0}
                                            fill="green"
                                        />
                                    </div>
                                </div>
                            )}

                            {/* TODO: Pendiente evaluador en becados */}
                            <div className="flex items-center ">
                                <div className="pr-2">
                                    <Icon.User size={16} />
                                </div>
                                <div>
                                    {((one.evaluacion ?? []).length > 0 &&
                                        one.evaluacion.find(
                                            (x) => x.evaluator_name !== null
                                        )?.evaluator_name) ??
                                        "No Evaluado"}
                                </div>
                            </div>
                            <div className="flex items-center ">
                                <div className="pr-2">
                                    <Icon.ArrowRight size={16} />
                                </div>
                                <div>{one?.status ?? ""}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:col-span-2 xl:col-span-3">
                    <Tabnavigator
                        tab_disabled_class="w-20"
                        action={{
                            name: <Icon.MessageSquare />,
                            do: () => setModal(true),
                        }}
                        action2={{
                            name: <Icon.Mail />,
                            do: () => setModalEmail(true),
                        }}
                        routes={[
                            {
                                path: `/evaluaciones/detalles_evaluaciones/${id}/solicitud/${solicitud_id}/datospersonales`,
                                name: "Datos Personales",
                            },
                            {
                                path: `/evaluaciones/detalles_evaluaciones/${id}/solicitud/${solicitud_id}/informacionacademica`,
                                name: "Formación Académica",
                            },
                            {
                                path: `/evaluaciones/detalles_evaluaciones/${id}/solicitud/${solicitud_id}/experiencialaboral`,
                                name: "Experiencia laboral",
                            },
                            {
                                path: `/evaluaciones/detalles_evaluaciones/${id}/solicitud/${solicitud_id}/datossocioeconomicos`,
                                name: "Socio Económicos",
                            },
                            {
                                path: `/evaluaciones/detalles_evaluaciones/${id}/solicitud/${solicitud_id}/formulario`,
                                name: "Formulario",
                            },
                            {
                                path: `/evaluaciones/detalles_evaluaciones/${id}/solicitud/${solicitud_id}/documentos`,
                                name: "Documentos",
                            },
                            {
                                path: `/evaluaciones/detalles_evaluaciones/${id}/solicitud/${solicitud_id}/solicitudes`,
                                name: "Solicitudes",
                            },
                        ]}
                    />
                    <div className="w-full h-auto shadow-lg bg-white p-[30px]">
                        <div>
                            <Switch>
                                <Route
                                    exact
                                    path="/evaluaciones/detalles_evaluaciones/:id/solicitud/:solicitud_id/datospersonales"
                                >
                                    <DatosPersonales data={one.candidate} />
                                </Route>
                                <Route
                                    exact
                                    path="/evaluaciones/detalles_evaluaciones/:id/solicitud/:solicitud_id/informacionacademica"
                                >
                                    <InformacionAcademica
                                        data={
                                            one.candidate?.formacion_academica
                                        }
                                    />
                                </Route>
                                <Route
                                    exact
                                    path="/evaluaciones/detalles_evaluaciones/:id/solicitud/:solicitud_id/experiencialaboral"
                                >
                                    <Experiencialaboral
                                        data={
                                            one.candidate?.experiencia_laboral
                                        }
                                    />
                                </Route>
                                <Route
                                    exact
                                    path="/evaluaciones/detalles_evaluaciones/:id/solicitud/:solicitud_id/datossocioeconomicos"
                                >
                                    <DatosSocioeconomicos
                                        data={one.candidate?.socio_economico}
                                    />
                                </Route>
                                <Route
                                    exact
                                    path="/evaluaciones/detalles_evaluaciones/:id/solicitud/:solicitud_id/formulario"
                                >
                                    <Formulario data={one.formulario_answers} />
                                </Route>

                                <Route
                                    exact
                                    path="/evaluaciones/detalles_evaluaciones/:id/solicitud/:solicitud_id/documentos"
                                >
                                    <DocumentViewer
                                        aplication_id={solicitud_id}
                                    />
                                </Route>

                                <Route
                                    exact
                                    path="/evaluaciones/detalles_evaluaciones/:id/solicitud/:solicitud_id/solicitudes"
                                >
                                    <ListadeSolicitudes
                                        cadidate_id={one?.candidate?.id}
                                    />
                                </Route>

                                <Redirect
                                    to={`/evaluaciones/detalles_evaluaciones/${id}/solicitud/${solicitud_id}/datospersonales`}
                                />
                            </Switch>
                        </div>
                        <Authorized hasAuthorized="coordinador">
                            <div className="flex justify-end my-4">
                                <div className="mx-4">
                                    <Button
                                        onClick={rejectBeca}
                                        isLoading={loading}
                                        variant="danger"
                                    >
                                        Rechazar beca
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        onClick={giveBeca}
                                        isLoading={loading}
                                    >
                                        Otorgar beca
                                    </Button>
                                </div>
                            </div>
                        </Authorized>
                    </div>
                </div>
                {/* hjsdfvhjfdsghjgldfs */}
                {/* TODO */}
                <div className="bg-white ">
                    {/* Header */}
                    <div className="w-full p-4 flex items-center justify-between bg-[#7BB31A] text-white text-lg font-bold">
                        <div>Evaluación</div>
                        <div>
                            {one.evaluacion?.reduce(
                                (acc, curr) =>
                                    (acc = acc + (parseInt(curr.score) || 0)),
                                0
                            )}
                            /
                            {one.evaluacion?.reduce(
                                (acc, curr) =>
                                    (acc =
                                        acc +
                                        (parseInt(curr.requerimiento.value) ||
                                            0)),
                                0
                            )}
                        </div>
                    </div>

                    {/* Section 1 */}
                    {one.evaluacion?.map((o, i) => {
                        return (
                            <div key={i} className="">
                                <div className="flex items-center justify-between px-2 mt-4 py-2 font-bold border-solid border-[#F0F0F0] border-b-2">
                                    <div className="pr-4">
                                        {o.requerimiento.name}
                                    </div>
                                    <div className="flex">
                                        {(loadingr.id === o.id ? (
                                            <CicularSpinner />
                                        ) : (
                                            o.score
                                        )) || 0}
                                        /{o.requerimiento.value}
                                    </div>
                                </div>
                                <div className="flex ">
                                    <p className="px-2">0</p>
                                    <input
                                        onChange={({ target }) => {
                                            clearTimeout(initial);
                                            initial = setTimeout(
                                                () =>
                                                    setEvaluationRequariments({
                                                        _id: o.id,
                                                        score: target.value,
                                                    }),
                                                500
                                            );
                                        }}
                                        className={`rounded-lg m-auto overflow-hidden appearance-none bg-gray-300 h-3 w-full ${
                                            loadingr.id === o.id
                                                ? "cursor-wait	"
                                                : "cursor-pointer"
                                        }`}
                                        type="range"
                                        min="0"
                                        disabled={
                                            users.user.user.role.name !==
                                            "evaluador"
                                                ? true
                                                : loadingr.id === o.id
                                        }
                                        max={o.requerimiento.step_complete}
                                        step="1"
                                        defaultValue={
                                            o.score === null ? 0 : o.score
                                        }
                                    />
                                    <p className="px-2">
                                        {o.requerimiento.step_complete}
                                    </p>
                                </div>
                            </div>
                        );
                    })}

                    <div className="px-2 py-4 mx-2 mt-4 ">
                        <div className="mb-2 font-bold">Comentario</div>
                        <div>
                            <textarea
                                className="w-full p-2 bg-gray-50 rounded-xl"
                                placeholder="Escriba su comentario aquí..."
                                rows="3"
                                defaultValue={one?.notes || ""}
                                value={commet}
                                onChange={(e) => {
                                    setcommet(e.target.value);
                                }}
                            />
                        </div>
                        <div className="flex w-full py-2 mx-2">
                            <button
                                className="w-full btn btn-default outline-4"
                                onClick={() => commentSaver()}
                            >
                                Guardar Comentario
                            </button>
                        </div>

                        {/* Current Editing Page */}

                        <div className="flex w-full py-2 mx-2">
                            <button
                                className="w-full bg-yellow-600 btn btn-default"
                                onClick={() => setOnRevision()}
                                disabled={userRole !== "evaluador"}
                            >
                                Poner en revisión
                            </button>
                        </div>

                        <div className="flex w-full py-2 mx-2">
                            <button
                                className="btn btn-default w-full bg-[#29afe4]"
                                onClick={() => reEnable()}
                                disabled={userRole !== "coordinador"}
                            >
                                Re-Habilitar
                            </button>
                        </div>

                        <div className="flex w-full py-2 mx-2">
                            <button
                                className="btn btn-default w-full bg-[#7BB31A]"
                                onClick={() => finisher()}
                                disabled={userRole !== "evaluador"}
                            >
                                Finalizar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
