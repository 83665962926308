import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../components/Spinner";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import SubHeader from "../components/SubHeder/Index";
import { becadosUpdateestatus, getBecados } from "../Store/slices/becados";
import { toast } from "react-toastify";

export default function EstatusBecados() {
    const name = "Becados";
    const history = useHistory();
    const dispatch = useDispatch();
    const [changinStatus, setchanginStatus] = useState(false);
    const { all: data, status } = useSelector((state) => state.becados);

    useEffect(() => {
        document.title = `${name} | ${global.title}`;
        !data.length && dispatch(getBecados());
    }, []);

    const columns = [
        {
            name: "id",
            options: {
                display: false,
            },
        },
        {
            label: "Nombre",
            name: "becado_name",
        },
        {
            label: "Apellido",
            name: "becado_lastname",
        },
        {
            label: "Carrera",
            name: "genero",
        },
        {
            label: "Nivel",
            name: "institution.name",
        },
        {
            label: "Locación",
            name: "institution_offer.campus_name",
        },
        {
            label: "Cobertura",
            name: "coverage",
        },
        {
            label: "Índice",
        },
        {
            label: "Estatus",
            name: "estado",
            options: {
                customBodyRender: ({ value, id }, tableMeta, updateValue) => {
                    return (
                        <select
                            onChange={({ target }) => {
                                setchanginStatus(true);
                                dispatch(
                                    becadosUpdateestatus({
                                        scholarship_id: id,
                                        estado: target.value,
                                    })
                                ).then((res) => {
                                    if (res.error) {
                                        toast.error(res.error.message);
                                    }
                                    setchanginStatus(false);
                                });
                            }}
                            defaultValue={value}
                            className={`mx-1 px-1 text-xs rounded 0 ${
                                changinStatus && "cursor-wait"
                            }`}
                        >
                            <option value="egresado">Egresado</option>
                            <option value="retirado">Retirado</option>
                            <option value="expulsado">Expulsado</option>
                            <option value="activo">Activo</option>
                            <option value="suspendido">Suspendido</option>
                        </select>
                    );
                },
            },
        },
    ];
    const getMuiTheme = () =>
        createTheme({
            overrides: {
                MuiTableRow: {
                    root: {
                        cursor: "pointer",
                    },
                },
            },
        });

    const showdetails = (id) => {
        history.push(`/becados/detalles/${id}`);
    };

    const options = {
        enableNestedDataAccess: ".",
        selectableRows: false,
        onCellClick: (dat, meta) => {
            if (meta.colIndex !== 8) {
                showdetails(data[meta.dataIndex]?.id);
            }
        },
        textLabels: {
            body: {
                noMatch: "Lo sentimos, no se encontraron registros.",
                toolTip: "Clasificar",
                columnHeaderTooltip: (column) =>
                    `clasificar por ${column.label}`,
            },
            pagination: {
                next: "Siguiente página",
                previous: "Página anterior",
                rowsPerPage: "Filas por página:",
                displayRows: "de", // 1-10 of 30
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar como CSV",
                print: "Imprimir",
                viewColumns: "Columnas",
                filterTable: "Filtros",
            },
            filter: {
                title: "FILTROS",
                reset: "reiniciar",
            },
            viewColumns: {
                title: "Mostrar columnas",
            },
            selectedRows: {
                text: "Fila(s) eliminadas",
                delete: "Borrar",
            },
        },
    };

    return (
        <>
            {/* <SubHeader title="Estatus Becados" /> */}
            <div className="block m-5 pb-8">
                {status === "loading" ? (
                    <Spinner />
                ) : (
                    <MuiThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            data={data.reduce((acc, curr) => {
                                const ind = curr.detalles_becado.reduce(
                                    (acc, curr) => {
                                        acc.maximo =
                                            acc.maximo + curr?.rango_maximo;
                                        acc.calificacion =
                                            acc.calificacion +
                                            curr?.calificacion;
                                        return acc;
                                    },
                                    {
                                        maximo: 0,
                                        calificacion: 0,
                                        length: curr.detalles_becado?.length,
                                    }
                                );
                                return [
                                    ...acc,
                                    [
                                        curr.id,
                                        curr.becado_name,
                                        curr.becado_lastname,
                                        curr.genero,
                                        curr.institution.name,
                                        curr.institution_offer.campus_name,
                                        `${curr.coverage}%`,
                                        `${(
                                            ind.calificacion / ind.length
                                        ).toFixed(1)}/${(
                                            ind.maximo / ind.length
                                        ).toFixed(1)}`,
                                        { value: curr.estado, id: curr.id },
                                    ],
                                ];
                            }, [])}
                            columns={columns}
                            options={options}
                        />
                    </MuiThemeProvider>
                )}
            </div>
        </>
    );
}
