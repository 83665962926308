import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetAll } from "../../../Services/Evaluadores/GetAll";
import { Create } from "../../../Services/Evaluadores/Create";
import { GetOne } from "../../../Services/Evaluadores/GetOne";
import { Delete } from "../../../Services/Evaluadores/Delete";
import generateActions from "../generateActions";
import { Fetch } from "../../../Services/Fetch";

const name = "evaluadores";

const initialState = {
    all: [],
    one: {},
    create: [],
    status: null,
    modal1: false,
    modal2: false,
};

export const getAll = createAsyncThunk(
    `${name}/getall`,
    async (data) => await GetAll(data)
);

export const getAllFilter = createAsyncThunk(
    `${name}/getall`,
    async (data) => await Fetch({ uri: data, method: "get" })
);

export const getAllSearch = createAsyncThunk(
    `${name}/getall`,
    async ({ search }) => {
        return await Fetch({
            uri: `/evaluators/filtrar?busqueda=${search}`,
            method: "get",
        });
    }
);

export const getOne = createAsyncThunk(
    `${name}/getone`,
    async (_, { getState }) => {
        return await GetOne(getState().users?.user?.user?.profile?.id);
    }
);

export const getOneFromCoordinator = createAsyncThunk(
    `${name}/getone`,
    async (data) => await GetOne(data)
);

export const create = createAsyncThunk(
    `${name}/create`,
    async (data, { rejectWithValue }) => {
        try {
            const res = await Fetch({ data, uri: "/evaluators/create" });
            return res;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const remove = createAsyncThunk(
    `${name}/delete`,
    async (data) => await Delete(data)
);

export const asignar = createAsyncThunk(
    `${name}/asignar`,
    async (data, { rejectWithValue }) => {
        try {
            const res = await Fetch({ data, uri: "/ievaluators/add" });
            return res;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const removerAsigned = createAsyncThunk(
    `${name}/removerInstitucion`,
    async (data) => await Fetch({ data, uri: "/ievaluators/remove" })
);

export const reducers = generateActions([
    { action: getAll, stateProp: "all" },
    { action: getAllFilter, stateProp: "all" },
    { action: getAllSearch, stateProp: "all" },
    { action: getOne, stateProp: "one" },
    {
        action: create,
        stateProp: "create",
        statePropAll: "all",
        element: "modal1",
    },
    { action: remove, stateProp: "delete", statePropAll: "all" },
    { action: removerAsigned, stateProp: "removerAsigned" },
]);

const EvaluadoresSlice = createSlice({
    name,
    initialState,
    reducers: {
        setModal(state, action) {
            state[action.payload.name] = action.payload.state;
        },
    },
    extraReducers: {
        ...reducers,
        [asignar.fulfilled]: (state, { payload }) => {
            state.all = state.all.reduce((acc, curr) => {
                if (
                    parseInt(curr.id) ===
                    parseInt(payload?.data?.data?.evaluator_id)
                ) {
                    curr.institutions = [
                        ...curr.institutions,
                        payload?.data?.data,
                    ];
                }
                const a = [...acc, curr];
                return a;
            }, []);
        },
    },
});

export const { setModal } = EvaluadoresSlice.actions;

export default EvaluadoresSlice.reducer;
