import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Fetch } from "../../../Services/Fetch";
import generateActions from "../generateActions";

const name = "ofertainstitucion";

const initialState = {
    all: [],
    one: {},
    filtered: [],
    create: [],
    status: null,
};

export const getOfertas = createAsyncThunk(
    `${name}/getall`,
    async (_, { dispatch, getState }) => {
        return await Fetch({
            data: {
                institution_id: getState().users.user?.user?.institution?.id,
            },
            uri: "/instituciones/carreras/getAll",
        });
    }
);

export const crearOfertas = createAsyncThunk(
    `${name}/create`,
    async (data, { rejectWithValue }) => {
        try {
            const res = await Fetch({
                data,
                uri: "/instituciones/carreras/create",
            });
            return res;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const ActualizarOfertas = createAsyncThunk(
    `${name}/actualizar`,
    async (data, { rejectWithValue }) => {
        try {
            const res = await Fetch({
                data,
                uri: "/instituciones/carreras/update",
            });
            return res;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const removeOfertas = createAsyncThunk(
    `${name}/delete`,
    async (data, { rejectWithValue }) => {
        try {
            let res = await Fetch({
                data,
                uri: "instituciones/carreras/delete",
            });
            res = { ...res, id: data.academic_offer_id };
            return res;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const reducers = generateActions([
    { action: getOfertas, stateProp: "all" },
    { action: crearOfertas, stateProp: "create", statePropAll: "all" },
    { action: removeOfertas, stateProp: "delete", statePropAll: "all" },
]);

const OfertasSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: {
        ...reducers,
        [ActualizarOfertas.fulfilled]: (state, { payload }) => {},
    },
});

export default OfertasSlice.reducer;
