export default function DatosPersonales({ data }) {
    return (
        <form className="grid grid-cols-2 gap-3">
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Nombre
                </label>
                <div className="mt-1">
                    <input
                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                        value={data?.name}
                        disabled
                    />
                </div>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Apellido
                </label>
                <div className="mt-1">
                    <input
                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                        value={data?.last_name}
                        disabled
                    />
                </div>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Documento de identidad
                </label>
                <div className="mt-1">
                    <input
                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                        value={data?.document_id}
                        disabled
                    />
                </div>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Fecha de nacimiento
                </label>
                <div className="mt-1">
                    <input
                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                        value={data?.born_date}
                        disabled
                    />
                </div>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Nacionalidad
                </label>
                <div className="mt-1">
                    <input
                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                        value={data?.nacionality}
                        disabled
                    />
                </div>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Género
                </label>
                <div className="mt-1">
                    <input
                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                        value={data?.genero}
                        disabled
                    />
                </div>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Correo electrónico
                </label>
                <div className="mt-1">
                    <input
                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                        value={data?.contact_email}
                        disabled
                    />
                </div>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Teléfono residencial
                </label>
                <div className="mt-1">
                    <input
                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                        value={data?.home_phone || ""}
                        disabled
                    />
                </div>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Teléfono móvil
                </label>
                <div className="mt-1">
                    <input
                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                        value={data?.contact_phone}
                        disabled
                    />
                </div>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Dirección
                </label>
                <div className="mt-1">
                    <input
                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                        value={data?.address}
                        disabled
                    />
                </div>
            </div>
        </form>
    );
}
