import * as Icon from "react-feather";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

export default function NewModal({
    isVisible = false,
    action,
    title,
    children,
}) {
    return (
        <Transition appear show={isVisible} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                style={{ zIndex: 100 }}
                onClose={action}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle "
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                            <Dialog.Title
                                as="h3"
                                className="flex justify-between items-center text-lg font-medium leading-6 text-gray-900 border-solid pb-2 border-b-2 border-[#F8F8F8]"
                            >
                                {title}
                                <button type="button" onClick={action}>
                                    <Icon.X className="w-5 h-5 transform hover:scale-110 hover:text-red-600 transition-all duration-200" />
                                </button>
                            </Dialog.Title>
                            <div className="mt-4">{children}</div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
}
