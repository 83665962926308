import * as Icon from "react-feather";
export default function InformacionAcademica({ data = [] }) {
    return (
        <div className="">
            {data.map((obj, i) => {
                return (
                    <div key={i} className="border-b border-gray-200 pb-4 mb-4">
                        <form className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {/* <div>
                                    <label
                                        htmlFor="about"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Tipo de estudio
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="about"
                                            name="about"
                                            className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                                            value={data?.name}
                                            disabled
                                        />
                                    </div>
                                </div> */}
                            <div>
                                <label
                                    htmlFor="about"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Título
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="about"
                                        name="about"
                                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                                        value={obj?.carrera}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div>
                                <label
                                    htmlFor="about"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Institución
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="about"
                                        name="about"
                                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                                        value={obj?.institucion}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div>
                                <label
                                    htmlFor="about"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Fecha de ingreso
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="about"
                                        name="about"
                                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                                        value={obj?.fecha_entrada}
                                        disabled
                                    />
                                </div>
                            </div>
                            {/* <div>
                                    <label
                                        htmlFor="about"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Nacionales
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="about"
                                            name="about"
                                            className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                                            value={data?.nacionality}
                                            disabled
                                        />
                                    </div>
                                </div> */}
                            <div>
                                <label
                                    htmlFor="about"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Índice obtenido
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="about"
                                        name="about"
                                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                                        value={obj?.indice}
                                        disabled
                                    />
                                </div>
                            </div>
                            {/* <div>
                                    <label
                                        htmlFor="about"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Pais de estudio
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="about"
                                            name="about"
                                            className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                                            value={data?.contact_email}
                                            disabled
                                        />
                                    </div>
                                </div> */}
                            {/* <div>
                                    <label
                                        htmlFor="about"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Carracter del centro
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="about"
                                            name="about"
                                            className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                                            value={data?.home_phone || ""}
                                            disabled
                                        />
                                    </div>
                                </div> */}
                            <div>
                                <label
                                    htmlFor="about"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Fecha de salida
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="about"
                                        name="about"
                                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                                        value={obj?.fecha_salida || ""}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div>
                                <label
                                    htmlFor="about"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Beca de estudio
                                </label>
                                <div className="flex mt-1 gap-4">
                                    <div className="flex items-center gap-2">
                                        <label>Si</label>
                                        <input
                                            type="radio"
                                            checked={obj.isBecado}
                                            id="about"
                                            name="about"
                                            className="shadow-sm  focus:ring-indigo-500 focus:border-indigo-500  block   border border-gray-300 rounded-md"
                                            disabled
                                        />
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <label>No</label>
                                        <input
                                            type="radio"
                                            checked={!obj.isBecado}
                                            id="about"
                                            name="about"
                                            className="shadow-sm  focus:ring-indigo-500 focus:border-indigo-500  block   border border-gray-300 rounded-md"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label
                                    htmlFor="about"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Documento anexo
                                </label>
                                <div className="mt-1">
                                    <a
                                        href={obj.documento_url}
                                        className="flex"
                                    >
                                        <Icon.Download /> Descargar
                                    </a>
                                </div>
                            </div>
                        </form>
                    </div>
                );
            })}
        </div>
    );
}
