import React, { useEffect } from "react";
import * as Icon from "react-feather";
import SubHeader from "../components/SubHeder/Index";
import Filter from "../components/filter";
import SimpleCard from "../components/card/simpleCard";

export default function Desembolsos() {
    const name = "Desembolsos";

    useEffect(() => {
        document.title = `${name} | ${global.title}`;
    }, []);

    return (
        <>
            {/* <SubHeader title="Desembolsos" /> */}
            <Filter />

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 m-5 pb-8">
                <SimpleCard
                    name="Monto total desembolsado"
                    total="RD$00.00"
                    color="#003579"
                >
                    <Icon.DollarSign size={50} className="text-custom-400" />
                </SimpleCard>

                <SimpleCard
                    name="Fondos Públicos"
                    total="RD$00.00"
                    color="#FF5274"
                >
                    <Icon.DollarSign size={50} className="text-custom-400" />
                </SimpleCard>

                <SimpleCard name="Crédito" total="RD$00.00" color="#FF9800">
                    <Icon.DollarSign size={50} className="text-custom-400" />
                </SimpleCard>
            </div>
        </>
    );
}
