import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getOneFromCoordinator } from "../../Store/slices/evaluadores";
import { InstitutionsCards } from "./Componentes-Reutilizables/Cards";
import { Fetch } from "../../Services/Fetch";
import Spinner from "../../components/Spinner";
import NewModal from "../../components/modal";

export default function Evaluaciones() {
    // Statefull-Variables
    const [modalContent, setModalContent] = useState({});
    const [modalDispatcher, setModalDispatcher] = useState(false);

    // In-Component Hooks
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    // Dispatchers -> Store Getters
    const { one } = useSelector(({ evaluadores }) => evaluadores);

    // Constanst
    const { institutions = [] } = one;

    // InComponent Functions
    const cardClicker = (cov_id, inst_id) =>
        history.push(
            `/evaluaciones/detalles_evaluaciones/${inst_id}/${cov_id}`
        );

    function statsCheker(institucion, convocatoria) {
        Fetch({
            uri: `estadisticas/institucion?convocatoria=${convocatoria}&institucion=${institucion}`,
            method: "get",
        }).then(({ data: { data } }) => {
            setModalContent(data);

            displayModal();
        });
    }

    function displayModal() {
        setModalDispatcher(!modalDispatcher);
    }

    // Effects
    useEffect(() => dispatch(getOneFromCoordinator(id)), []);

    return institutions.length < 1 ? (
        <Spinner />
    ) : (
        <>
            <NewModal
                isVisible={modalDispatcher}
                action={displayModal}
                title={"Estadisticas"}
            >
                <div className="grid grid-cols-2 gap-2 border-2 border-slate-600 rounded-xl px-2">
                    <div>
                        <h3 className="text-sm font-bold sm:text-base line-clamp-3">
                            Solicitudes : {modalContent?.solicitudes?.enviadas}
                        </h3>
                    </div>

                    <div>
                        <h3 className="text-sm font-bold sm:text-base line-clamp-3">
                            Preseleccionadas :{" "}
                            {modalContent?.solicitudes?.preseleccionadas}
                        </h3>
                    </div>

                    <div>
                        <h3 className=" text-sm font-bold sm:text-base line-clamp-3">
                            Declinadas : {modalContent?.solicitudes?.declinadas}
                        </h3>
                    </div>

                    <div>
                        <h3 className="text-sm font-bold sm:text-base line-clamp-3">
                            Revision : {modalContent?.solicitudes?.en_revision}
                        </h3>
                    </div>

                    <div>
                        <h3 className="text-sm font-bold sm:text-base line-clamp-3">
                            Pendientes :{" "}
                            {(modalContent?.solicitudes?.enviadas || 0) -
                                ((modalContent?.solicitudes?.en_evaluacion ||
                                    0) +
                                    (modalContent?.solicitudes?.en_revision ||
                                        0) +
                                    (modalContent?.solicitudes?.declinadas ||
                                        0))}
                        </h3>
                    </div>
                </div>
            </NewModal>

            <InstitutionsCards
                data={institutions}
                onSolicitudes={cardClicker}
                statsCheker={statsCheker}
            />
        </>
    );
}
