import { Route, Switch } from "react-router-dom";
import Index from "./Evaluaciones/Index";
import DetallesEvaluaciones from "./Evaluaciones/DetallesEvaluaciones";
import Evaluacion from "./Evaluaciones/evaluaciones/DetallesEvaluaciones";

export default function Evaluaciones() {
    return (
        <Switch>
            <Route exact path="/evaluaciones">
                <Index />
            </Route>

            <Route path="/evaluaciones/detalles_evaluaciones/:id/solicitud/:solicitud_id/:id_convocatoria/:o?">
                <Evaluacion />
            </Route>
            <Route path="/evaluaciones/detalles_evaluaciones/:id/:convocatoria">
                <DetallesEvaluaciones />
            </Route>
        </Switch>
    );
}
