import React from "react";
import { useSelector } from "react-redux";

export default function Authorized({ children, hasAuthorized }) {
    const { user } = useSelector((state) => state.users);

    if (!hasAuthorized?.includes(user.user?.role?.name)) return <></>;

    return <>{children}</>;
}
