export default function Perfil({ data }) {
    return (
        <form className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Nombre de institución
                </label>
                <div className="mt-1">
                    <input
                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                        value={data?.name}
                        disabled
                    />
                </div>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Siglas
                </label>
                <div className="mt-1">
                    <input
                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                        value={data?.siglas}
                        disabled
                    />
                </div>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Encargado de becas en la institución
                </label>
                <div className="mt-1">
                    <input
                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                        value={data?.contacto_persona}
                        disabled
                    />
                </div>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Teléfono
                </label>
                <div className="mt-1">
                    <input
                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                        value={data?.telefono}
                        disabled
                    />
                </div>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Correo electrónico
                </label>
                <div className="mt-1">
                    <input
                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                        value={data?.email}
                        disabled
                    />
                </div>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Dirección
                </label>
                <div className="mt-1">
                    <input
                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                        value={data?.direccion}
                        disabled
                    />
                </div>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Página web
                </label>
                <div className="mt-1">
                    <input
                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                        value={data?.web}
                        disabled
                    />
                </div>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Tipo de institución
                </label>
                <div className="mt-1">
                    <input
                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                        value={data?.type?.name}
                        disabled
                    />
                </div>
            </div>
        </form>
    );
}
