import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Pagination } from "../Index";

export default function SelfPaginatedTable({
    dataToRender = [],
    paginationProps = {},
    executerFunction = () => console.log("aaaa"),
    paginate = (data) => console.log(data),
}) {
    let { meta, links } = paginationProps;

    let subData = dataToRender;

    const columns = [
        {
            name: "id",
            options: {
                display: false,
            },
        },
        {
            label: "Nombre",
            name: "nombre",
        },
        {
            label: "Apellido",
            name: "apellido",
        },
        {
            label: "Institución",
            name: "institucion",
        },
        {
            label: "Carrera",
            name: "carrera",
        },
        {
            label: "Nivel",
            name: "nivel",
        },
        {
            label: "Tipo",
            name: "tipo",
        },

        {
            label: "Puntuación",
            name: "score",
        },
        {
            label: "Estado",
            name: "status",
        },
        {
            label: "Modalidad",
            name: "modalidad",
        },
    ];

    const options = {
        selectable: false,
        onRowClick: (rowData) => executerFunction(rowData[0]),
        pagination: false,
        search: false,
        toolbar: false,
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
        selectableRows: false,
    };

    const getMuiTheme = () =>
        createTheme({
            overrides: {
                shadows: "0",
                MuiTableRow: {
                    root: {
                        cursor: "pointer",
                    },
                },
            },
        });

    return (
        <>
            <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    data={subData}
                    columns={columns}
                    options={options}
                />
            </MuiThemeProvider>

            <Pagination
                meta={meta}
                links={links}
                linkCaller={(link) => paginate(link)}
            />
        </>
    );
}
