import MUIDataTable from "mui-datatables";
import { Pagination } from "../../components/Common/Index";

export default function Becados({
    paginationData = {},
    action = (str) => console.log(str + " as default "),
}) {
    // Constants
    const columns = [
        {
            label: "Nombre",
            name: "becado_nombres",
        },
        {
            label: "Apellido",
            name: "becado_apellidos",
        },
        {
            label: "Género",
            name: "genero",
        },
        {
            label: "Carrera",
            name: "carrera",
        },
        {
            label: "Nivel",
            name: "nivel_educativo",
        },
        {
            label: "Locación",
            name: "campus_pais",
        },
        {
            label: "Cobertura",
            name: "coverage",
            options: {
                customBodyRender: (coverage) => {
                    return <div>{`${coverage} %`}</div>;
                },
            },
        },
    ];

    const options = {
        selectableRows: false,
        pagination: false,
        search: false,
        toolbar: false,
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
    };

    const { data, links, meta } = paginationData;

    // Functions

    return (
        <>
            <MUIDataTable
                data={data ?? []}
                columns={columns}
                options={options}
            />
            <Pagination links={links} meta={meta} linkCaller={action} />
        </>
    );
}
