import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetAll } from "../../../Services/Coordinadores/GetAll";
import { Create } from "../../../Services/Coordinadores/Create";
import { Delete } from "../../../Services/Coordinadores/Delete";
import generateActions from "../generateActions";
import { Fetch } from "../../../Services/Fetch";

const name = "coordinadores";

const initialState = {
    all: [],
    one: {},
    create: [],
    status: null,
    asigned: {},
    asigneRemoved: {},
};

export const getAll = createAsyncThunk(
    `${name}/getall`,
    async (data) => await GetAll(data)
);

export const getAllSelector = createAsyncThunk(
    `${name}/getall`,
    async () => await Fetch({ uri: "selects/coordinadores", method: "get" })
);

export const getAllFiltered = createAsyncThunk(
    `${name}/getall`,
    async (data) => await Fetch({ uri: data, method: "get" })
);

export const getAllSearch = createAsyncThunk(
    `${name}/getall`,
    async (data) =>
        await Fetch({
            uri: `coordinators/filtrar?busqueda=${data}`,
            method: "get",
        })
);

export const getOne = createAsyncThunk(
    `${name}/getone`,
    async (data) => await Fetch({ data, uri: "/coordinators/show" })
);

export const getAllPaginated = createAsyncThunk(
    `${name}/getone`,
    async (data) => await Fetch({ uri: data, method: "get" })
);

export const create = createAsyncThunk(
    `${name}/create`,
    async (data, { rejectWithValue }) => {
        try {
            const res = await Fetch({ data, uri: "/coordinators/create" });
            return res?.data?.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const remove = createAsyncThunk(
    `${name}/delete`,
    async (data) => await Delete(data)
);

export const reducers = generateActions([
    { action: getAll, stateProp: "all" },
    { action: getOne, stateProp: "one" },
    {
        action: create,
        stateProp: "create",
        statePropAll: "all",
        element: "modal",
    },
    { action: remove, stateProp: "delete", statePropAll: "all" },
]);

const EvaluadoresSlice = createSlice({
    name,
    initialState,
    reducers: {
        setModal(state, action) {
            state[action.payload.name] = action.payload.state;
        },
    },
    extraReducers: reducers,
});

export const { setModal } = EvaluadoresSlice.actions;

export default EvaluadoresSlice.reducer;
