import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    institution: "",
    genre: "",
    career: "",
    status: "",
    level: "",
    type: "",
    modality: "",
    search: "",
    page: "",
};

const EvaluationFiltersSlice = createSlice({
    name: "EvaluationFilters",
    initialState,
    reducers: {
        addEvaluationFilter: (state, { payload }) => {
            state[payload.name] = payload.value;
        },

        clearEvaluationFilter: (state) => {
            Object.keys(state).map((key) => {
                state[key] = "";
            });
        },
    },
});

export const { addEvaluationFilter, clearEvaluationFilter } =
    EvaluationFiltersSlice.actions;

export default EvaluationFiltersSlice.reducer;
