import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Loging } from "../../../Services/Auth/Loging";
import { Fetch } from "../../../Services/Fetch";
import generateActions from "../generateActions";

const initialState = {
    user: {},
    update: {},
    status: null,
};

export const login = createAsyncThunk(
    "user/login",
    async (data) => await Loging(data)
);

export const updateProfile = createAsyncThunk(
    `profile/update`,
    async (data) => await Fetch({ data, uri: "/profile/update" })
);

export const reducers = generateActions([
    { action: login, stateProp: "user" },
    { action: updateProfile, stateProp: "update" },
]);

const userSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        setUser(state, action) {
            state.user = action.payload;
        },
        setProfile(state, action) {
            state.user.user.profile = action.payload;
        },

        logOut(state, action) {
            state.user = {};
        },
    },
    extraReducers: reducers,
});

export const { setUser, setProfile, logOut } = userSlice.actions;

export default userSlice.reducer;
