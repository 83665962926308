import AuthAxios from '../../config/AuthAxios'

export async function Delete(setLoading, id) {
  const Axios = AuthAxios()
  try {
    return Axios.post(`/areas/coordinadores/delete?coordinador_id=${id}`)
  } catch (err) {
    console.error(err)
  }
}
