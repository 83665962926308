import { useState, useEffect } from "react";
import renderError from "../../helpers/renderErrors";
import { Fetch } from "../../Services/Fetch";

export function jsonToparam(obj) {
    if (!obj) return "";

    let params = "";

    const arrayparams = Object.entries(obj);

    arrayparams.forEach((o, i) => {
        if (i === 0) {
            params += `?${o[0]}=${o[1]}`;
        } else {
            params += `&${o[0]}=${o[1]}`;
        }
    });

    return params;
}

export default function useAction() {
    const [isLoading, setisLoading] = useState(false);
    const [data, setData] = useState([]);
    const [convocatorias, setConvocatorias] = useState([]);
    const [pagination, SetPagination] = useState([]);

    const [stados, setstados] = useState([]);

    const getAll = async (params = {}) => {
        setisLoading(true);
        try {
            const res = await Fetch({
                uri: `/solicitudes/report${jsonToparam(params)}`,
                method: "get",
            });
            setData(res.data?.data);
            SetPagination(res.data.meta);
            setisLoading(false);
            return res.data.data;
        } catch (error) {
            setisLoading(false);
            renderError(error.response?.data);
        }
    };

    const getAllstados = async (params = {}) => {
        setisLoading(true);
        try {
            const res = await Fetch({
                uri: `solicitudes/estados/getAll`,
                method: "get",
            });
            setstados(res.data?.data);

            return res.data.data;
        } catch (error) {
            setisLoading(false);
            renderError(error.response?.data);
        }
    };

    const getAllConvocatorias = async (params = {}) => {
        setisLoading(true);
        try {
            const res = await Fetch({
                uri: `convocatorias/filtrar`,
                method: "get",
            });
            setConvocatorias(res.data?.data);

            return res.data.data;
        } catch (error) {
            setisLoading(false);
            renderError(error.response?.data);
        }
    };

    useEffect(() => {
        getAll();
        getAllConvocatorias();
        getAllstados();
    }, []);

    return { isLoading, data, pagination, getAll, stados, convocatorias };
}
