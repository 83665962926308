import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import generateActions from "../generateActions";
import {
    getAllNotifications,
    readNotification,
    deleteNotification,
} from "../../../Services/Notifications";

const name = "notificaciones";

const initialState = {
    all: [],
    one: {},
    read: [],
    status: null,
};
export const getAll = createAsyncThunk(
    `${name}/getAll`,
    async () => await getAllNotifications()
);

export const togleRead = createAsyncThunk(
    `${name}/togleRead`,
    async (data) => await readNotification(data)
);

export const reducers = generateActions([
    { action: getAll, stateProp: "all" },
    {
        action: togleRead,
        stateProp: "read",
        statePropAll: "all",
    },
]);

const NotificacionesSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: reducers,
});

export default NotificacionesSlice.reducer;
