import { useState } from "react";
import { toast } from "react-toastify";
import { updatePassword } from "../../Services/Auth/ChangePassword";

import { Form, InputField } from "../../components/Form";
import { Button } from "../../components/Button";
import renderErrors from "../../helpers/renderErrors";

import * as yup from "yup";

const schema = yup
    .object({
        current_password: yup.string().required("Requerido"),
        password: yup.string().required("Requerido"),
        password_confirmation: yup.string().required("Requerido"),
    })
    .required();

export default function ChangePassword() {
    const [loading, setLoading] = useState(false);

    const onSubmit = async (data) => {
        try {
            await updatePassword(setLoading, data);
            toast.success("Contraseña actualizada correctamente");
        } catch (error) {
            renderErrors(error);
        }
    };

    return (
        <Form onSubmit={onSubmit} schema={schema}>
            {({ register, formState }) => (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <InputField
                            type="password"
                            label="Contraseña actual"
                            error={formState.errors.current_password}
                            registration={register("current_password")}
                        />
                        <InputField
                            type="password"
                            label="Nueva contraseña"
                            error={formState.errors.password}
                            registration={register("password")}
                        />
                        <InputField
                            type="password"
                            label="Confirme la nueva contraseña"
                            error={formState.errors.password_confirmation}
                            registration={register("password_confirmation")}
                        />
                    </div>
                    <div className="flex justify-end">
                        <Button
                            isLoading={loading}
                            type="submit"
                            variant="primary"
                        >
                            Cambiar contraseña
                        </Button>
                    </div>
                </>
            )}
        </Form>
    );
}
