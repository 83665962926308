import React, { useState, useEffect } from "react";
import { User } from "react-feather";
import { InputField, SelectField } from "../../components/Form";
import { Button } from "../../components/Button";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { getAll } from "../../Store/slices/instituciones";
import { getAll as getAlloferentes } from "../../Store/slices/oferentes";
import { create, getOne, newUser, update } from "../../Store/slices/usersAdmin";
import { useHistory, useParams } from "react-router";
import Spinner from "../../components/Spinner";
import renderError from "../../helpers/renderErrors";
import useAction from "./useAction";

export default function UserOne() {
    const [type, setType] = useState(1);
    const [loading, setloading] = useState({ gurdar: false, geting: false });
    const { isLoadingreset, resetPassword } = useAction();
    const {
        watch,
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const { instituciones, oferentes, usersAdmin } = useSelector(
        (state) => state
    );
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();

    useEffect(() => {
        const sub = watch((value) => {
            setType(value.type_user);
        });
    }, [watch]);

    useEffect(() => {
        if (!instituciones.all.length) {
            dispatch(getAll());
        }
        if (!oferentes.all.length) {
            dispatch(getAlloferentes());
        }
        if (id !== "new") {
            setloading({ ...loading, geting: true });
            dispatch(getOne({ user_id: id })).then((res) => {
                setloading({ ...loading, geting: false });
            });
        } else {
            dispatch(newUser());
        }
    }, []);

    const getRole = (role) => {
        switch (role) {
            case "admin":
                return 4;
            case "lectura":
                return 1;
            case "institucion":
                return 3;
            case "oferente":
                return 2;
        }
    };

    useEffect(() => {
        setValue("user_id", usersAdmin?.one?.id);
        setValue("email", usersAdmin?.one?.email);
        setValue("name", usersAdmin?.one?.profile?.name);
        setValue("type_user", getRole(usersAdmin?.one?.role?.name));
        usersAdmin?.one?.institution &&
            setValue("institucion", usersAdmin?.one?.institution.id);
        usersAdmin?.one?.offerer &&
            setValue("oferente", usersAdmin?.one?.offerer.id);
    }, [usersAdmin?.one?.id]);

    const getOptnalFields = (val) => {
        if (parseInt(val) == 3) {
            return (
                <SelectField
                    label="Institución"
                    registration={register("institucion", {
                        required: "Requerido",
                    })}
                    error={errors.institucion}
                    options={instituciones.all?.reduce(
                        (acc, curr) =>
                            (acc = [
                                ...acc,
                                { value: curr.id, label: curr.name },
                            ]),
                        []
                    )}
                />
            );
        } else if (parseInt(val) == 2) {
            return (
                <SelectField
                    label="Entidad Oferente"
                    registration={register("oferente", {
                        required: "Requerido",
                    })}
                    error={errors.oferente}
                    options={oferentes.all?.reduce(
                        (acc, curr) =>
                            (acc = [
                                ...acc,
                                { value: curr.id, label: curr.name },
                            ]),
                        []
                    )}
                />
            );
        }
    };

    const onSubmit = (data) => {
        setloading({ ...loading, gurdar: true });
        if (!data.user_id) {
            dispatch(create(data)).then((res) => {
                setloading({ ...loading, gurdar: false });
                if (res.error) {
                    renderError(res.payload);
                    return;
                }
                history.push("/usuarios");
            });
        } else {
            dispatch(update(data)).then((res) => {
                setloading({ ...loading, gurdar: false });
                if (res.error) {
                    renderError(res.payload);
                    return;
                }
                history.push("/usuarios");
            });
        }
    };

    return (
        <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
            <div className="relative py-3 sm:max-w-xl sm:mx-auto w-1/2">
                <div className="relative  px-4 py-10 bg-white mx-8 md:mx-0 shadow rounded-3xl m sm:p-10">
                    <div className="max-w-md mx-auto">
                        <div className="flex items-center space-x-5">
                            <div className="h-14 w-14 bg-gray-200 rounded-full flex flex-shrink-0 justify-center items-center text-gray-500 text-2xl font-mono">
                                <User />
                            </div>
                            <div className="block pl-2 font-semibold text-xl self-start text-gray-700">
                                <h2 className="leading-relaxed">Usuario</h2>
                                <p className="text-sm text-gray-500 font-normal leading-relaxed">
                                    {id !== "new"
                                        ? `Actualizar ${
                                              watch("name") || "usuario"
                                          } `
                                        : watch("name") || "Nuevo Usuario"}
                                </p>
                            </div>
                        </div>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="divide-y divide-gray-200"
                        >
                            {loading.geting && <Spinner />}
                            <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                <InputField
                                    label="Correo electrónico"
                                    type="mail"
                                    registration={register("email", {
                                        required: "Requerido",
                                    })}
                                    placeholder="ejemplo@email.com"
                                    error={errors.email}
                                />
                                <InputField
                                    label="Nombre"
                                    type="text"
                                    registration={register("name", {
                                        required: "Requerido",
                                    })}
                                    error={errors.name}
                                />

                                <SelectField
                                    label="Tipo de usuario"
                                    registration={register("type_user", {
                                        required: "Requerido",
                                    })}
                                    error={errors.type_user}
                                    options={[
                                        { label: "Solo lectura", value: 1 },
                                        { label: "Oferente", value: 2 },
                                        { label: "Institución", value: 3 },
                                        { label: "Administrador", value: 4 },
                                    ]}
                                />

                                {getOptnalFields(type)}

                                {id != "new" && (
                                    <Button
                                        isLoading={isLoadingreset === id}
                                        onClick={() => {
                                            resetPassword(id);
                                        }}
                                        className="w-full"
                                        variant="inverse"
                                    >
                                        Resetiar contraseña
                                    </Button>
                                    // <div className="flex items-center space-x-4">
                                    //     <div className="flex flex-col">
                                    //         <InputField
                                    //             label="Contraseña"
                                    //             type="password"
                                    //             registration={register(
                                    //                 "password",
                                    //                 { required: "Requerido" }
                                    //             )}
                                    //             error={errors.password}
                                    //         />
                                    //     </div>
                                    //     <div className="flex flex-col">
                                    //         <InputField
                                    //             label="Confirmar Contraseña"
                                    //             type="password"
                                    //             registration={register(
                                    //                 "password_confirmation",
                                    //                 { required: "Requerido" }
                                    //             )}
                                    //             error={
                                    //                 errors.password_confirmation
                                    //             }
                                    //         />
                                    //     </div>
                                    // </div>
                                )}
                            </div>
                            <div className="pt-4 flex items-center space-x-4">
                                <Button
                                    type="submit"
                                    isLoading={loading.gurdar}
                                    className="flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none"
                                >
                                    {loading.gurdar
                                        ? "Guardando..."
                                        : "Guardar"}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
