import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { login } from "../Store/slices/user";
import Spinner from "../components/Spinner";
import { Button } from "../components/Button";
import { Form, InputField } from "../components/Form";

import * as yup from "yup";

const schema = yup
    .object({
        email: yup
            .string()
            .email("El Correo electrónico no es valido")
            .required("Requerido"),
        password: yup.string().required("Requerido"),
    })
    .required();

export default function Auth() {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const onSubmit = async (data) => {
        setLoading(true);
        const res = await dispatch(login(data));
        setLoading(false);

        let message;

        if (res.payload?.errors) {
            const errorsMessage = Object.entries(res.payload?.errors);
            message = errorsMessage[0][1][0];
        } else if (res.payload?.message) {
            message = res.payload?.message;
        } else {
            message = res.message;
        }

        toast.error(message);
    };

    const name = "Login";

    useEffect(() => {
        document.title = `${name} | ${global.title}`;
    }, []);

    return (
        <div className="bg-login bg-cover bg-center">
            <div className="bg-secondary/80 h-screen flex justify-center items-center">
                <div className="mx-auto max-w-md w-full px-6 py-12 bg-white border-0 shadow-lg rounded-lg transition-all duration-200">
                    <div className="logo pb-12 flex justify-center">
                        <img
                            className="h-[100px] object-contain"
                            src="img/logo.svg"
                            alt={global.title}
                        />
                    </div>
                    <Form
                        onSubmit={onSubmit}
                        schema={schema}
                        className="grid h-49"
                    >
                        {({ register, formState }) => (
                            <>
                                {loading ? (
                                    <Spinner />
                                ) : (
                                    <>
                                        <InputField
                                            type="email"
                                            label="Correo electrónico"
                                            error={formState.errors.email}
                                            registration={register("email")}
                                        />
                                        <InputField
                                            type="password"
                                            label="Contraseña"
                                            error={formState.errors.password}
                                            registration={register("password")}
                                        />
                                        <Button
                                            isLoading={loading}
                                            type="submit"
                                            variant="primary"
                                        >
                                            Acceder
                                        </Button>
                                    </>
                                )}
                            </>
                        )}
                    </Form>
                </div>
            </div>
        </div>
    );
}
