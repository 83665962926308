import React, { useEffect, useState, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-feather";
import {
    addEvaluation,
    removeEvaluation,
    setAllEvaluations,
} from "../../../Store/slices/evaluaciones";
import { Fetch } from "../../../Services/Fetch";
import { Button } from "../../../components/Button";
import { Form, InputField } from "../../../components/Form";

import * as yup from "yup";
import { toast } from "react-toastify";

const schema = yup
    .object({
        name: yup.string().required("Requerido"),
        description: yup.string().required("Requerido"),
        value: yup
            .number()
            .typeError("Debe ser un numero")
            .positive("Debe ser un numero positivo")
            .required("Requerido"),
    })
    .required();

export default function Evaluaciones() {
    const [newForm, setnewForm] = useState([]);
    const [form, setform] = useState({ color: "#ffffff" });
    const newconvocatoria = useSelector((state) => state.newConvocatoria);
    const evaluaciones = useSelector((state) => state.evaluations.all);
    const inputname = useRef();
    const inputcolor = useRef();
    const inputmin = useRef();
    const inputmax = useRef();
    const dispatch = useDispatch();
    const { register, watch, setValue, formState } = useFormContext();

    useEffect(() => {
        dispatch(setAllEvaluations());
    }, []);

    useEffect(() => {
        newconvocatoria.evaluation?.requirements &&
            setnewForm(newconvocatoria.evaluation.requirements);
    }, [newconvocatoria]);
    const dataEvaluation = (e) => {
        setform({ ...form, [e.target.name]: e.target.value });
    };

    const onSubmit2 = async (data, reset) => {
        setnewForm([...newForm, data]);
        reset();
    };
    const SavePlantilla = async () => {
        if (!form?.name) {
            const d = document.querySelectorAll('[name="name"]');
            d[0].focus();
            return;
        }
        if (!form?.top_score) {
            const d = document.querySelectorAll('[name="top_score"]');
            d[0].focus();
            return;
        }
        if (!form?.pre_approved) {
            const d = document.querySelectorAll('[name="pre_approved"]');
            d[0].focus();
            return;
        }
        if (
            parseInt(form?.top_score) !==
            newForm?.reduce((acc, curr) => acc + parseInt(curr.value), 0)
        ) {
            const c = `valor esperado:${
                form?.top_score
            } valor actual:${newForm?.reduce(
                (acc, curr) => acc + parseInt(curr.value),
                0
            )}`;
            toast.warning(c);
            return;
        }

        toast.promise(
            Fetch({
                data: {
                    ...form,
                    requerimientos: newForm,
                    isPlanilla: true,
                    color: "#fffff",
                },
                uri: "/evaluaciones/create",
                toForm: false,
            }),
            {
                pending: "Creando Evaluación...",
                success: {
                    render({ data }) {
                        dispatch(addEvaluation(data.data.data));
                        setValue("evaluation_id", data.data.data.id);
                        return "Evaluación creada con éxito!";
                    },
                },
                error: {
                    render({ data }) {
                        const arrayErrors = Object.entries(
                            data?.response?.data?.errors || {}
                        );
                        if (arrayErrors[0]) {
                            if (arrayErrors[0][1]) {
                                return arrayErrors[0][1][0];
                            } else {
                                return data.response?.data?.message;
                            }
                        } else {
                            return data.response?.data?.message;
                        }
                    },
                },
            }
        );
    };

    const handleRemove = () => {
        toast.promise(
            Fetch({
                data: { evaluation_id: form?.id },
                uri: "/evaluaciones/delete",
                toForm: false,
            }),
            {
                pending: "Eliminando evaluación...",
                success: {
                    render({ data }) {
                        dispatch(removeEvaluation(form?.id));
                        setValue("evaluation_id", "");
                        return "Evaluación eliminada con éxito!";
                    },
                },
                error: {
                    render({ data }) {
                        const arrayErrors = Object.entries(
                            data?.response?.data?.errors || {}
                        );
                        if (arrayErrors[0]) {
                            if (arrayErrors[0][1]) {
                                return arrayErrors[0][1][0];
                            } else {
                                return data.response?.data?.message;
                            }
                        } else {
                            return data.response?.data?.message;
                        }
                    },
                },
            }
        );
    };
    const camp = watch("evaluation_id");
    useEffect(() => {
        if (evaluaciones) {
            const eva = evaluaciones.find(
                (o) => parseInt(o.id) === parseInt(camp)
            );
            setnewForm(eva?.requirements || []);
            setform(eva);
            inputname.current.value = eva?.name || "";
            inputcolor.current.value = eva?.color || "#ffffff";
            inputmax.current.value = eva?.top_score || "";
            inputmin.current.value = eva?.min_score || "";
        }
    }, [camp]);

    return (
        <>
            <div>
                <form>
                    <div className="grid grid-cols-2 gap-3">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Evaluación
                                <select
                                    className="appearance-none block w-full px-2 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 disabled:bg-gray-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm "
                                    name="convocatoria_type_id"
                                    {...register("evaluation_id")}
                                >
                                    <option value="">Nuevo</option>
                                    {evaluaciones.map((o) => {
                                        return (
                                            <option key={o.id} value={o.id}>
                                                {o.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </label>
                            <div
                                role="alert"
                                aria-label={formState.errors.evaluation_id}
                                className="text-sm font-semibold text-red-500"
                            >
                                {formState.errors?.evaluation_id?.message}
                            </div>
                        </div>

                        <label className="block text-sm font-medium text-gray-700">
                            Nombre
                            <input
                                ref={inputname}
                                className="appearance-none block w-full px-2 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 disabled:bg-gray-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm "
                                name="name"
                                type="text"
                                onChange={dataEvaluation}
                            />
                        </label>
                        <label className="hidden text-sm font-medium text-gray-700">
                            Color
                            <input
                                ref={inputcolor}
                                className="appearance-none block w-full px-1 h-[38px] border border-gray-300 rounded-md shadow-sm placeholder-gray-400 disabled:bg-gray-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm "
                                name="color"
                                type="color"
                                defaultValue={"#ffffff"}
                                onChange={dataEvaluation}
                            />
                        </label>
                        <label className="block text-sm font-medium text-gray-700">
                            Puntuación máxima
                            <input
                                ref={inputmax}
                                className="appearance-none block w-full px-2 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 disabled:bg-gray-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm "
                                name="top_score"
                                type="number"
                                onChange={dataEvaluation}
                                defaultValue={
                                    newconvocatoria.evaluation?.top_score
                                }
                            />
                        </label>
                        <label className="block text-sm font-medium text-gray-700">
                            Puntuación de aprobación
                            <input
                                ref={inputmin}
                                className="appearance-none block w-full px-2 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 disabled:bg-gray-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm "
                                name="pre_approved"
                                type="number"
                                onChange={dataEvaluation}
                                defaultValue={
                                    newconvocatoria.evaluation?.pre_approved
                                }
                            />
                        </label>
                    </div>

                    <p className="text-xl my-3 border-b-2 mb-8">
                        Requerimientos
                    </p>
                    {newForm?.map((obj, i) => (
                        <div
                            key={i}
                            className="grid grid-cols-12 gap-3 items-center form mb-4"
                        >
                            <label className="col-span-3 block text-sm font-medium text-gray-700">
                                Nombre
                                <input
                                    className="appearance-none block w-full px-2 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 disabled:bg-gray-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    type="text"
                                    name="name"
                                    defaultValue={obj?.name}
                                    disabled
                                />
                            </label>
                            <label className="col-span-7 block text-sm font-medium text-gray-700">
                                Descripcion
                                <input
                                    className="appearance-none block w-full px-2 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 disabled:bg-gray-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    type="text"
                                    defaultValue={obj?.description}
                                    name="description"
                                    disabled
                                />
                            </label>
                            <label className="block text-sm font-medium text-gray-700">
                                Valor
                                <input
                                    className="appearance-none block w-full px-2 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 disabled:bg-gray-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    type="number"
                                    defaultValue={obj?.value}
                                    name="value"
                                    disabled
                                />
                            </label>

                            <div
                                onClick={() => {
                                    const newformcopy = [...newForm];
                                    newformcopy.splice(i, 1);
                                    setnewForm(newformcopy);
                                }}
                                className="flex mt-6 items-center justify-center  px-2 py-2 rounded-md bg-primary cursor-pointer"
                            >
                                <Icon.X className="text-white "></Icon.X>
                            </div>
                        </div>
                    ))}
                </form>

                <p className="my-4 border-b-2">Nuevo Requerimiento</p>

                <Form onSubmit={onSubmit2} schema={schema}>
                    {({ register, formState }) => (
                        <>
                            <div className="grid grid-cols-12 gap-4 items-center">
                                <InputField
                                    label="Nombre"
                                    error={formState.errors.name}
                                    registration={register("name")}
                                    wrapperClassName="col-span-5"
                                />
                                <InputField
                                    label="Descripción"
                                    error={formState.errors.description}
                                    registration={register("description")}
                                    wrapperClassName="col-span-5"
                                />
                                <InputField
                                    type="number"
                                    label="Valor"
                                    error={formState.errors.value}
                                    registration={register("value")}
                                />
                                <div>
                                    <Button
                                        type="submit"
                                        size="sm"
                                        variant="primary"
                                        className="mt-6"
                                    >
                                        <Icon.Plus></Icon.Plus>
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                </Form>
            </div>

            <div className="w-full flex justify-end mt-[90px] gap-4">
                <button
                    onClick={handleRemove}
                    disabled={!form?.id}
                    className="bg-transparent mx-2 disabled:opacity-50 disabled:cursor-not-allowed hover:bg-primary text-primary font-semibold hover:text-white py-2 px-4 border border-primary rounded"
                >
                    Eliminar
                </button>
                <Button onClick={SavePlantilla} variant="inverse">
                    Guardar Formulario
                </Button>
                {/* <Button onClick={create}>Guardar convocatoria</Button> */}
            </div>
        </>
    );
}
