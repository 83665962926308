import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "../../components/Button";
import { Form, InputField, SelectField } from "../../components/Form";
import { setUser } from "../../Store/slices/user";
import renderErrors from "../../helpers/renderErrors";
import { getAll, update } from "../../Store/slices/instituciones";
import { Fetch } from "../../Services/Fetch";

import * as yup from "yup";

const schema = yup.object({
    name: yup.string().required("Requerido"),
    siglas: yup.string().required("Requerido"),
    institution_type_id: yup.string().required("Requerido"),
    contacto_telefono: yup.number().required("Requerido"),
    telefono: yup.string().required("Requerido"),
    direccion: yup.string().required("Requerido"),
    email: yup
        .string()
        .email("Correo electrónico inválido")
        .required("Requerido"),
    web: yup.string().required("Requerido"),
    contacto_persona: yup.string().required("Requerido"),
    contacto_email: yup.string().required("Requerido"),
    contacto_telefono: yup.string().required("Requerido"),
});

export default function ChangeInstitution({ data, user }) {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [types, setTypes] = useState([]);

    const getintitutionstype = async () => {
        try {
            const res = await Fetch({
                uri: "/instituciones/tipos/getAll",
                method: "get",
            });
            setTypes(res.data?.data);
        } catch (error) {}
    };

    useEffect(() => {
        dispatch(getAll());
        getintitutionstype();
    }, []);

    const onSubmit = async (payload) => {
        if (payload.image?.length > 0) {
            payload.image = payload.image && payload.image[0];
        } else {
            delete payload.image;
        }

        payload.institution_id = payload.id;

        setIsLoading(true);

        const res = await dispatch(update(payload));

        if (res.error) {
            renderErrors(res?.payload);
            setIsLoading(false);
        } else {
            await sessionStorage.setItem(
                "user",
                JSON.stringify({
                    ...user,
                    user: { ...user.user, institution: res.payload.data.data },
                })
            );

            await dispatch(
                setUser({
                    ...user,
                    user: { ...user.user, institution: res.payload.data.data },
                })
            );

            setIsLoading(false);
            toast.success("Institución actualizada satisfactoriamente");
        }
    };

    return (
        <Form
            onSubmit={onSubmit}
            options={{
                defaultValues: {
                    ...data,
                },
            }}
            schema={schema}
        >
            {({ register, formState, setValue, watch }) => (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
                        <InputField
                            label="Nombre"
                            placeholder="Johnston LLC"
                            error={formState.errors.name}
                            registration={register("name")}
                        />

                        <InputField
                            label="Siglas"
                            placeholder="JLLC"
                            error={formState.errors.siglas}
                            registration={register("siglas")}
                        />

                        <InputField
                            type="phone"
                            label="Teléfono"
                            value={data?.telefono}
                            error={formState.errors.telefono}
                            registration={register("telefono")}
                            name="telefono"
                            onChange={setValue}
                        />

                        <InputField
                            type="email"
                            label="Correo electrónico"
                            placeholder="johnston@email.com"
                            error={formState.errors.email}
                            registration={register("email")}
                        />

                        <InputField
                            label="Dirección"
                            placeholder="Av. Máximo Gómez No.31, esq. Pedro Henríquez Ureña, Santo Domingo, República Dominicana"
                            error={formState.errors.direccion}
                            registration={register("direccion")}
                        />

                        <SelectField
                            label="Tipo de institución"
                            error={formState.errors.institution_type_id}
                            registration={register("institution_type_id")}
                            options={types?.reduce(
                                (acc, curr) => [
                                    ...acc,
                                    {
                                        value: curr.id,
                                        label: curr.name,
                                    },
                                ],
                                []
                            )}
                        />

                        <InputField
                            type="file"
                            label="Imagen de perfil"
                            accept="image/*"
                            error={formState.errors.image}
                            watch={watch("image")}
                            setValue={setValue}
                            name="image"
                        />

                        <InputField
                            label="Página Web"
                            placeholder="www.Johnston.com"
                            error={formState.errors.web}
                            registration={register("web")}
                        />
                    </div>

                    <div className="border-b-2">
                        Datos de la persona de contacto
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
                        <InputField
                            label="Nombre"
                            placeholder="Juan Santos"
                            error={formState.errors.contacto_persona}
                            registration={register("contacto_persona")}
                        />

                        <InputField
                            type="text"
                            label="Email"
                            placeholder="info@ejemplo.gob.do"
                            error={formState.errors.contacto_email}
                            registration={register("contacto_email")}
                        />

                        <InputField
                            type="phone"
                            label="Teléfono"
                            placeholder="8090000000"
                            value={data?.contacto_telefono}
                            error={formState.errors.contacto_telefono}
                            name="contacto_telefono"
                            onChange={setValue}
                        />
                    </div>

                    <div className="flex justify-end mt-4">
                        <Button type="submit" isLoading={isLoading}>
                            Guardar
                        </Button>
                    </div>
                </>
            )}
        </Form>
    );
}
