import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { InputField } from "../../components/Form";
import Card from "../../components/card/imageCard";
import {
    getFilteredByName,
    getAllFromFilter,
} from "../../Store/slices/convocatorias";
import Spinner from "../../components/Spinner";
import { Button } from "../../components/Button";
import { replaceHttpToHttps } from "../../utils/http";
import { Pagination } from "../../components/Common/Index";

export default function Index() {
    // State
    const [filtrado, setFiltrado] = useState([]);
    const [paginationData, setPaginationData] = useState([]);
    const [search, setSearch] = useState("");

    // Constants
    const name = "Convocatorias";
    const dispatch = useDispatch();
    const history = useHistory();

    // Redux Functions dispatcher and setter
    const convocatorias = useSelector(({ convocatorias: { all } }) => all);
    const { status } = useSelector((state) => state.convocatorias);

    // Functions
    const filterData = () => dispatch(getFilteredByName(search));
    const getNextPageToShow = (link) =>
        dispatch(getAllFromFilter(replaceHttpToHttps(link)));

    const createConvocatoria = () => history.push(`/convocatorias/crear/new`);

    // Effects
    useEffect(() => {
        document.title = `${name} | ${global.title}`;
        dispatch(getAllFromFilter());
    }, []);

    useEffect(() => {
        setPaginationData(status?.data ?? {});
        setFiltrado(convocatorias);
    }, [convocatorias]);

    return (
        <>
            <div className="py-8 px-5 md:flex md:justify-between items-center gap-4">
                <div className="flex md:items-center w-1/2">
                    <div className="flex text-base pr-4">
                        Filtrar Convocatoria :
                    </div>
                    <div className="px-2">
                        <InputField
                            className={"w-full"}
                            onChange={({ target: { value } }) =>
                                setSearch(value)
                            }
                        />
                    </div>
                    <Button onClick={filterData}> Buscar </Button>
                </div>

                <div>
                    <Button onClick={createConvocatoria}> Agregar </Button>
                </div>
            </div>

            {status === "loading" ? (
                <Spinner />
            ) : (
                <div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 px-4">
                        {[...filtrado]
                            .sort((a, b) => b.id - a.id)
                            .map(
                                ({
                                    id,
                                    type: { name: typeName, color },
                                    image_url,
                                    status: itStatus,
                                    name,
                                    audience: { name: audName },
                                }) => (
                                    <Card
                                        key={id}
                                        id={id}
                                        category={typeName ?? ""}
                                        image={
                                            image_url ??
                                            `img/no_image_available.png`
                                        }
                                        status={itStatus}
                                        title={name}
                                        level={audName}
                                        color={color}
                                        onclick={() => {
                                            history.push(
                                                itStatus === "Pendiente"
                                                    ? `/convocatorias/crear/${id}`
                                                    : `/convocatorias/detalles_convocatoria/${id}`
                                            );
                                        }}
                                    />
                                )
                            )}
                    </div>

                    <Pagination
                        meta={paginationData.meta ?? {}}
                        links={paginationData.links ?? {}}
                        linkCaller={getNextPageToShow}
                    />
                </div>
            )}
        </>
    );
}
