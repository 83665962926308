import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Fetch } from "../Services/Fetch";
import {
    addOfferta,
    removeOfferta,
    setConvocatoria,
} from "../Store/slices/convocatorias/newConvocatoria";

export default function useCreateConvocatorias() {
    const dispatch = useDispatch();
    const h = useHistory();

    const create = async (data) => {
        // const cc = await cantCreate();
        toast.promise(
            Fetch({
                data,
                uri: "/convocatorias/create",
            }),
            {
                pending: "Cargando...",
                success: {
                    render({ data }) {
                        dispatch(setConvocatoria(data.data.data));
                        return "Creado exitosamente";
                    },
                },
                error: {
                    render({ data }) {
                        const arrayErrors = Object.entries(
                            data?.response?.data?.errors || {}
                        );
                        if (arrayErrors[0]) {
                            if (arrayErrors[0][1]) {
                                return arrayErrors[0][1][0];
                            } else {
                                return data.response?.data?.message;
                            }
                        } else {
                            return data.response?.data?.message;
                        }
                    },
                },
            }
        );
    };

    const update = async (data) => {
        // const cc = await cantCreate();
        toast.promise(
            Fetch({
                data,
                uri: "/convocatorias/update",
            }),
            {
                pending: "Actualizando...",
                success: {
                    render({ data }) {
                        dispatch(setConvocatoria(data.data.data));
                        return "Actualizado exitosamente";
                    },
                },
                error: {
                    render({ data }) {
                        const arrayErrors = Object.entries(
                            data?.response?.data?.errors || {}
                        );
                        if (arrayErrors[0]) {
                            if (arrayErrors[0][1]) {
                                return arrayErrors[0][1][0];
                            } else {
                                return data.response?.data?.message;
                            }
                        } else {
                            return data.response?.data?.message;
                        }
                    },
                },
            }
        );
    };

    const publicar = (convocatoria) => {
        toast.promise(
            Fetch({
                data: {
                    convocatoria_id: convocatoria,
                },
                uri: "convocatorias/setOpen",
            }),
            {
                pending: "Publicando...",
                success: {
                    render({ data }) {
                        h.push(`/convocatorias`);
                        return "Publicada exitosamente";
                    },
                },
                error: {
                    render({ data }) {
                        const arrayErrors = Object.entries(
                            data?.response?.data?.errors || {}
                        );
                        if (arrayErrors[0]) {
                            if (arrayErrors[0][1]) {
                                return arrayErrors[0][1][0];
                            } else {
                                return data.response?.data?.message;
                            }
                        } else {
                            return data.response?.data?.message;
                        }
                    },
                },
            }
        );
    };

    const optener = (convocatoria) => {
        toast.promise(
            Fetch({
                data: {
                    convocatoria_id: convocatoria,
                },
                uri: "/convocatorias/show",
            }),
            {
                pending: "Oteniendo datos...",
                success: {
                    render({ data }) {
                        dispatch(setConvocatoria(data?.data?.data));
                        return "Listo!";
                    },
                },
                error: {
                    render({ data }) {
                        const arrayErrors = Object.entries(
                            data?.response?.data?.errors || {}
                        );
                        if (arrayErrors[0]) {
                            if (arrayErrors[0][1]) {
                                return arrayErrors[0][1][0];
                            } else {
                                return data.response?.data?.message;
                            }
                        } else {
                            return data.response?.data?.message;
                        }
                    },
                },
            }
        );
    };

    const addOfert = async (formdata, reset) => {
        toast.promise(
            Fetch({
                data: formdata,
                uri: "/convocatorias/details/create",
            }),
            {
                pending: "Oteniendo datos...",
                success: {
                    render({ data }) {
                        dispatch(addOfferta(data?.data?.data));
                        reset();
                        return "Oferta Agregada con exito!";
                    },
                },
                error: {
                    render({ data }) {
                        const arrayErrors = Object.entries(
                            data?.response?.data?.errors || {}
                        );
                        if (arrayErrors[0]) {
                            if (arrayErrors[0][1]) {
                                return arrayErrors[0][1][0];
                            } else {
                                return data.response?.data?.message;
                            }
                        } else {
                            return data.response?.data?.message;
                        }
                    },
                },
            }
        );
    };

    const removeOfert = async (objdata) => {
        let status = false;
        await toast.promise(
            Fetch({
                data: objdata,
                uri: "/convocatorias/details/delete",
            }),
            {
                pending: "Eliminando oferta...",
                success: {
                    render({ data }) {
                        dispatch(removeOfferta(objdata.convocatoria_detail_id));
                        status = true;
                        return "Oferta eliminada con exito!";
                    },
                },
                error: {
                    render({ data }) {
                        status = false;
                        const arrayErrors = Object.entries(
                            data?.response?.data?.errors || {}
                        );
                        if (arrayErrors[0]) {
                            if (arrayErrors[0][1]) {
                                return arrayErrors[0][1][0];
                            } else {
                                return data.response?.data?.message;
                            }
                        } else {
                            return data.response?.data?.message;
                        }
                    },
                },
            }
        );
        return status;
    };

    return {
        optener,
        create,
        update,
        publicar,
        addOfert,
        removeOfert,
    };
}
