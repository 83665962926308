import { useState, useEffect } from "react";

import Chart from "react-apexcharts";

export default function ColumnsSubmitted({ title = "test", data = [] }) {

    // Constants
    const [series, setSeries] = useState([
        {
            data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
        },
    ]);

    const [categories, setCategories] = useState([
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
    ]);

    const options = {
        chart: {
            type: "bar",
            height: 350,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "55%",
                endingShape: "rounded",
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ["#00A2FF", "#FFC107", "#7BB31A", "#FF9800"],
        },
        xaxis: {
            categories: categories,
        },
        yaxis: {
            title: {
                text: "",
            },
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            y: {
                formatter: (val) => val,
            },
        },
    };

    useEffect(() => {
        let subData = Array.isArray(data)
            ? data.sort((a, b) => b.Solicitudes - a.Solicitudes).splice(0, 10)
            : [];
        setSeries([{ data: subData.map(({ Solicitudes }) => Solicitudes) }]);
        setCategories(subData.map(({ Area }) => Area));
    }, []);

    return (
        <>
            <div className="h-[340px] p-8 bg-white col-span-3 rounded-lg transition shadow-sm hover:shadow-xl">
                <h3 className="text-base font-light">{title}</h3>
                <Chart
                    options={options}
                    series={series}
                    type="bar"
                    height={270}
                />
            </div>
        </>
    );
}
