import MUIDataTable from "mui-datatables";
import { BecadosFilterable } from "../../components/Common/Filters/Filterable";
import { Pagination } from "../../components/Common/Index";

export default function Becados({
    data,
    onPageChange,
    status,
    filters,
    doBecadosSearch,
    doBecadosExportable,
    isExporting,
}) {
    // Constants
    const columns = [
        {
            label: "Nombre",
            name: "becado_nombres",
        },
        {
            label: "Apellido",
            name: "becado_apellidos",
        },
        {
            label: "Género",
            name: "genero",
        },
        {
            label: "Institución",
            name: "institucion",
        },
        {
            label: "Carrera",
            name: "carrera",
        },
        {
            label: "Nivel",
            name: "nivel_educativo",
        },
        {
            label: "Area",
            name: "area_desarrollo",
        },
        {
            label: "Locación",
            name: "campus_pais",
        },
        {
            label: "Cobertura",
            name: "coverage",
        },
        {
            label: "Estado",
            name: "estado",
        },
    ];

    const options = {
        enableNestedDataAccess: ".",
        selectableRows: false,
        pagination: false,
        search: false,
        toolbar: false,
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
    };

    return (
        <>
            <BecadosFilterable
                hide={false}
                selects={filters}
                setSearch={doBecadosSearch}
                exportable={doBecadosExportable}
                isSpinning={isExporting}
            />

            <MUIDataTable data={data} columns={columns} options={options} />

            <Pagination
                linkCaller={onPageChange}
                meta={status?.data?.meta ?? {}}
                links={status?.data?.links ?? {}}
            />
        </>
    );
}
