import React from "react";
import { Route, Switch } from "react-router-dom";
import Index from "./Becados/index";
import DetallesBecados from "./Becados/DetallesBecados";

export default function Becados() {
    return (
        <>
            <Switch>
                <Route exact path="/becados">
                    <Index />
                </Route>
                <Route path="/becados/detalles/:id">
                    <DetallesBecados />
                </Route>
            </Switch>
        </>
    );
}
