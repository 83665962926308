import clsx from "clsx";
import { forwardRef } from "react";

const variants = {
    primary: "bg-blue-900 text-white hover:bg-gray-50 hover:text-blue-900",
    inverse: "bg-white text-blue-900 hover:bg-blue-900 hover:text-white",
    inverse2: "bg-white text-blue-900 hover:bg-orange hover:text-blue-900",
    danger: "bg-red-600 text-white hover:bg-red-50 hover:text-red-600",
    dangerInversed: "bg-white text-red-600 hover:bg-red-600 hover:text-white",
};

const sizes = {
    sm: "py-2 px-4 text-sm",
    md: "py-2 px-6 text-md",
    lg: "py-3 px-8 text-lg",
};

export const Button = forwardRef(
    (
        {
            type = "button",
            className = "",
            variant = "primary",
            size = "md",
            isLoading = false,
            startIcon,
            endIcon,
            ...props
        },
        ref
    ) => (
        <button
            ref={ref}
            disabled={isLoading}
            type={type}
            className={clsx(
                "flex justify-center items-center border border-gray-300 disabled:opacity-70 disabled:cursor-not-allowed rounded-md shadow-sm font-medium focus:outline-none transition-all duration-200",
                variants[variant],
                sizes[size],
                className
            )}
            {...props}
        >
            {!isLoading && startIcon}
            <span className="mx-2">
                {isLoading ? "Cargando..." : props.children}
            </span>
            {!isLoading && endIcon}
        </button>
    )
);

Button.displayName = "Button";
