import React from "react";

export default function SimpleCard(props) {
    return (
        <div className="bg-white flex px-4 items-center h-32 rounded-lg transition shadow-sm hover:shadow-xl">
            <div className="mr-3">{props.children}</div>
            <div>
                <span
                    className="block text-3xl font-black leading-7"
                    style={{ color: props.color }}
                >
                    {props.total}
                </span>
                <span className="block text-base font-light text-black">
                    {props.name}
                </span>
            </div>
        </div>
    );
}
