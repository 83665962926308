import { UserCheck, X } from "react-feather";
import { Button } from "../Button";

export const CardNotification = ({ notification, onRead, onDelete }) => {
    return (
        <span className="relative grid grid-cols-12 items-center bg-white cursor-pointer p-4 shadow hover:shadow-lg transition-all">
            <div className="col-span-1 hidden md:flex items-center justify-center">
                <div className="flex justify-center items-center bg-blue-200 p-5 rounded-full">
                    <UserCheck className="w-8  h-8 text-blue-900" />
                </div>
            </div>
            <div className=" col-span-12 md:col-span-11 flex items-center space-x-3 p-4 pt-2">
                <div className="w-full">
                    <div className="mb-2">{notification?.nombre}</div>
                    <p className=" text-sm text-gray-400">
                        {notification?.descripcion}
                    </p>

                    <div className="flex items-center justify-between mt-4 ">
                        <div className="italic text-gray-400">
                            {notification?.creada}
                        </div>
                        <div className=" ">
                            <Button
                                onClick={() => onRead(notification.id)}
                                variant={
                                    notification?.read ? "inverse" : "primary"
                                }
                            >
                                {notification?.read
                                    ? "Leída"
                                    : "Marcar como leída"}
                            </Button>

                            <div
                                onClick={() => onDelete(notification.id)}
                                className="absolute top-0 right-0 text-red-500"
                            >
                                <X />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </span>
    );
};
