import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import generateActions from '../generateActions'
import { Fetch } from '../../../Services/Fetch'

const name = 'Horarios'

const initialState = {
  all: [],
  create: [],
}

export const setAllHorario = createAsyncThunk(
  `${name}/getall`,
  async (data) => await Fetch({ data, uri: '/horarios/getAll', method: 'get' }),
)

export const createHorario = createAsyncThunk(
  `${name}/create`,
  async (data) => await Fetch({ data, uri: '/horarios/create' }),
)

export const reducers = generateActions([
  { action: setAllHorario, stateProp: 'all' },
  { action: setAllHorario, stateProp: 'create', statePropAll: 'all' },
])

const horarioSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: reducers,
})

export default horarioSlice.reducer
