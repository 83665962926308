import React, { useEffect } from 'react'

export default function NotFount() {
  const name =
    '[Error 404] - La página que estás buscando no existe o ha ocurrido un error inesperado'

  useEffect(() => {
    document.title = `${name} | ${global.title}`
  }, [])

  return (
    <>
      <div className="bg-secondary h-screen flex justify-center items-center">
        <div className="text-white text-center">
          <h1 className="text-9xl font-black">404</h1>
          <p>
            [Error 404] - La página que estás buscando
            <br /> no existe o ha ocurrido un error inesperado
          </p>
        </div>
      </div>
    </>
  )
}
