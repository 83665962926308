import Tabnavigator from "../../components/navigation/tabnavigator";
import * as Icon from "react-feather";
import { Redirect, Route, Switch } from "react-router";
import ChangePassword from "./ChangePassword";
import ChangePerfil from "./ChangePerfil";
import Notifications from "./Notifications";
import { useSelector } from "react-redux";
import noImage from "../../images/profile-placeholder.png";
import Messages from "./Messages";
import ChangeInstitution from "./ChangeInstitution";

export default function Index() {
    const { user } = useSelector((state) => state.users);
    const { profile } = user.user;

    return (
        <div className="p-4 pb-8">
            <div className="grid grid-cols-12 mb-[22px] bg-white justify-start items-center relative">
                <div className="col-span-12 sm:col-span-4 md:col-span-5 grid md:flex justify-center md:justify-start items-center gap-4 p-1 border-solid border-[#F0F0F0] border-r-[1px]">
                    <img
                        src={profile?.image_url || noImage}
                        alt="Perfil"
                        className="max-h-[150px] justify-self-center"
                    />

                    <div className="grid items-center justify-center gap-2">
                        <h3 className="w-full text-2xl text-[#231F20] mb-[2px]">
                            {profile?.name}
                        </h3>
                        {user.user.institution?.name && (
                            <span className="w-full text-lg text-[#AAAAAA] text-center md:text-left">{`Institución: ${user.user.institution?.name}`}</span>
                        )}
                        <span className="w-full text-lg text-[#AAAAAA] text-center md:text-left">{`Rol: ${user.user.role?.name}`}</span>
                    </div>
                </div>
                <div className="col-span-12 sm:col-span-8 md:col-span-7 grid grid-cols-1 md:grid-cols-2 gap-4 items-center justify-between p-4 ">
                    <span className="flex items-center gap-2">
                        <Icon.Mail size={16} />
                        &nbsp;{profile?.contact_email}
                    </span>
                    <span className="flex items-center gap-2">
                        <Icon.Phone size={16} />
                        &nbsp;{profile?.contact_phone}
                    </span>
                </div>
            </div>

            <Tabnavigator
                routes={[
                    {
                        path: `/perfil/mensajes`,
                        name: "Mensajes",
                    },
                    {
                        path: `/perfil/notificaciones`,
                        name: "Notificaciones",
                    },
                    !user.user.institution &&  {
                        path: `/perfil`,
                        name: "Actualizar perfil",
                    },
                    {
                        path: `/perfil/cambiar_contrasena`,
                        name: "Cambiar contraseña",
                    },
                    user.user.institution && {
                        path: `/perfil/institucion`,
                        name: "Actualizar institución",
                    },
                ]}
            />

            <div className="w-full h-auto shadow-lg bg-white p-[30px]">
                <div>
                    <Switch>
                        <Route exact path="/perfil/mensajes">
                            <Messages />
                        </Route>
                        <Route exact path="/perfil/notificaciones">
                            <Notifications />
                        </Route>
                        <Route exact path="/perfil">
                            <ChangePerfil data={profile} user={user} />
                        </Route>
                        <Route exact path="/perfil/cambiar_contrasena">
                            <ChangePassword />
                        </Route>

                        {user.user.institution && (
                            <Route exact path="/perfil/institucion">
                                <ChangeInstitution
                                    data={user.user.institution}
                                    user={user}
                                />
                            </Route>
                        )}

                        <Redirect to="/perfil" />
                    </Switch>
                </div>
            </div>
        </div>
    );
}
