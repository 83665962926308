import { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Icon from "react-feather";
import { Switch, Route, useParams, Redirect, Link } from "react-router-dom";
import Tabnavigator from "../../components/navigation/tabnavigator";
import Historial from "./Historial";
import Becados from "./Becados";
import Perfil from "./Perfil";
import { getOne } from "../../Store/slices/oferentes";
import { Menu, Transition } from "@headlessui/react";
import noimage from "../../images/no_image_available.png";

export default function DetallesOferentes() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const oferente = useSelector((state) => state.oferentes.one);

    useEffect(() => {
        dispatch(getOne({ offerer_id: id }));
        dispatch(getOne({ offerer_id: id }));
    }, []);

    return (
        <div className="grid p-4 pb-8 gap-4">
            <div className="grid grid-cols-12 mb-[22px] bg-white items-center justify-start">
                <div className="col-span-12 lg:col-span-3 grid xl:flex justify-center xl:justify-start items-center gap-4 p-4 border-solid border-[#F0F0F0] border-r-[1px]  ">
                    <img
                        src={oferente?.image_url || noimage}
                        alt="Perfil"
                        className="max-w-[150px] p-2 justify-self-center "
                    />

                    <div className="grid items-center justify-center ">
                        <h3 className="w-full text-2lg text-[#231F20] mb-[2px]">{`${oferente?.name}`}</h3>
                        <div className="mt-[16px] flex justify-center lg:justify-start items-center ">
                            <span className="flex items-center text-xl text-[#AAAAAA]">
                                {oferente?.type}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="col-span-12 lg:col-span-9 grid grid-rows-none lg:grid-rows-4 xl:grid-rows-3 lg:grid-flow-col items-center justify-between p-4 gap-4">
                    <div className="flex items-center ">
                        <div className="pr-2">
                            <Icon.User size={16} />
                        </div>
                        <div className="capitalize">
                            {oferente?.contact_person}
                        </div>
                    </div>

                    <div className="flex items-center ">
                        <div className="pr-2">
                            <Icon.Phone size={16} />
                        </div>
                        <div className="capitalize">
                            {oferente?.contact_number}
                        </div>
                    </div>

                    <div className="flex items-center ">
                        <div className="pr-2">
                            <Icon.Mail size={16} />
                        </div>
                        <div className="capitalize">
                            {oferente?.contact_email}
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <Tabnavigator
                    routes={[
                        {
                            path: `/oferentes/detalles/${id}/historial`,
                            name: "Convicatorias",
                        },
                        {
                            path: `/oferentes/detalles/${id}/_becados`,
                            name: "Becados",
                        },
                        {
                            path: `/oferentes/detalles/${id}/perfil`,
                            name: "Perfil",
                        },
                    ]}
                />

                <div className="w-full h-auto shadow-lg bg-white p-[30px]">
                    <div>
                        <Switch>
                            <Route
                                exact
                                path="/oferentes/detalles/:id/historial"
                            >
                                <Historial />
                            </Route>
                            <Route
                                exact
                                path="/oferentes/detalles/:id/_becados"
                            >
                                <Becados />
                            </Route>
                            <Route exact path="/oferentes/detalles/:id/perfil">
                                <Perfil data={oferente} />
                            </Route>
                            <Redirect to="/oferentes/detalles/:id/historial" />
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    );
}
