import React from "react";
import { Route, Switch } from "react-router-dom";

import Index from "./Convocatorias/index";
import DetallesConvocatoria from "./Convocatorias/DetallesConvocatoria";
import CreateNewConvocatoria from "./Convocatorias/create/create";
import DetalleEvaluacion from "./Convocatorias/evaluaciones/DetallesEvaluaciones";

export default function Convocatorias() {
    return (
        <>
            {/* <SubHeader title="Convocatorias" /> */}
            <Switch>
                <Route exact path="/convocatorias">
                    <Index />
                </Route>
                <Route path="/convocatorias/crear/:id">
                    <CreateNewConvocatoria />
                </Route>
                <Route path="/convocatorias/detalles_convocatoria/:id">
                    <DetallesConvocatoria />
                </Route>
                <Route path="/convocatorias/detalles_evaluaciones/:id">
                    <DetalleEvaluacion />
                </Route>
            </Switch>
        </>
    );
}
