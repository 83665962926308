import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Card from "../../components/card/imageCard";
import Modal from "../../components/modal";
import {
    create,
    getSearchFilterAll,
    setModal,
    getFilterAll,
} from "../../Store/slices/oferentes";
import Spinner from "../../components/Spinner";
import { Button } from "../../components/Button";
import { Form, InputField } from "../../components/Form";
import useFilter from "../../hooks/useFilter";
import * as yup from "yup";
import { replaceHttpToHttps } from "../../utils/http";
import { Pagination } from "../../components/Common/Index.js";

// YUP Constant shcema
const schema = yup
    .object({
        name: yup.string().required("Requerido"),
        document_id: yup.string().length(11, "Inválido").required("Requerido"),
        type: yup.string().required("Requerido"),
        contact_person: yup.string().required("Requerido"),
        contact_number: yup.string().required("Requerido"),
        contact_email: yup.string().required("Requerido"),
    })
    .required();

export default function Index() {
    // Constants -> Inner Hooks
    const name = "Oferentes";
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        all: oferentes,
        modal,
        status,
    } = useSelector((state) => state.oferentes);

    // State
    const [isLoading, setIsLoading] = useState(false);
    const [param, setParam] = useState("");
    const [search, setSearch] = useState("");

    // In-State Hook
    const [filtered] = useFilter(oferentes, param);

    // Functions
    const onSubmit = (data) => {
        setIsLoading(true);
        data.image = data.image && data.image[0];
        dispatch(create(data));
        setIsLoading(false);
    };

    const getNextPageToShow = (link) =>
        link !== null && dispatch(getFilterAll(replaceHttpToHttps(link)));

    const filterData = () => dispatch(getSearchFilterAll(search));

    // Effects
    useEffect(() => {
        document.title = `${name} | ${global.title}`;
        dispatch(getFilterAll());
    }, []);

    return (
        <>
            {/* Inicio modal */}

            <Modal
                action={() =>
                    dispatch(setModal({ name: "modal", state: false }))
                }
                isVisible={modal}
                title="Crear nuevo oferente"
            >
                <Form onSubmit={onSubmit} schema={schema}>
                    {({ register, formState, setValue, watch }) => (
                        <>
                            <InputField
                                label="Nombre"
                                placeholder="Ministerio de la Juventud"
                                error={formState.errors.name}
                                registration={register("name")}
                            />

                            <InputField
                                label="Documento de identidad o RNC"
                                error={formState.errors.document_id}
                                registration={register("document_id")}
                            />

                            <InputField
                                type="email"
                                label="Correo electrónico"
                                placeholder="info@minjuventud.gob.do"
                                error={formState.errors.contact_email}
                                registration={register("contact_email")}
                            />

                            <InputField
                                label="Tipo de institución"
                                placeholder="Empresa privada"
                                error={formState.errors.type}
                                registration={register("type")}
                            />

                            <InputField
                                label="Página Web"
                                placeholder="www.example.com"
                                error={formState.errors.pagina_web}
                                registration={register("pagina_web")}
                            />

                            <InputField
                                type="file"
                                label="Imagen de perfil"
                                accept="image/*"
                                error={formState.errors.image}
                                watch={watch("image")}
                                setValue={setValue}
                                name="image"
                            />

                            <div className="border-b-2">
                                Datos de la persona de contacto
                            </div>

                            <InputField
                                label="Nombre"
                                placeholder="Juan Santos"
                                error={formState.errors.contact_person}
                                registration={register("contact_person")}
                            />

                            <InputField
                                type="phone"
                                label="Teléfono"
                                placeholder="8090000000"
                                error={formState.errors.contact_number}
                                name="contact_number"
                                onChange={setValue}
                            />

                            <div className="flex justify-end mt-4">
                                <Button type="submit" isLoading={isLoading}>
                                    Guardar
                                </Button>
                            </div>
                        </>
                    )}
                </Form>
            </Modal>

            {/* Fin modal */}

            <div className="py-8 px-5 md:flex md:justify-between items-center gap-4">
                <div className="flex md:items-center w-1/2">
                    <div className="flex text-base pr-4">
                        Filtrar Oferentes :
                    </div>
                    <div className="px-2 ">
                        <InputField
                            className={"w-full mr-10 "}
                            onChange={({ target: { value } }) =>
                                setSearch(value)
                            }
                        />
                    </div>
                    <Button onClick={filterData}> Buscar </Button>
                </div>

                <div>
                    <Button
                        onClick={() =>
                            dispatch(setModal({ name: "modal", state: true }))
                        }
                    >
                        Agregar
                    </Button>
                </div>
            </div>

            {status === "loading" ? (
                <Spinner />
            ) : (
                <div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 p-4">
                        {filtered?.map((e) => (
                            <Card
                                onclick={() =>
                                    history.push(`/oferentes/detalles/${e.id}`)
                                }
                                key={e.id}
                                image={
                                    e.image_url || `img/no_image_available.png`
                                }
                                title={e.name}
                                level={e.type}
                                object="contain"
                            ></Card>
                        ))}
                    </div>

                    <Pagination
                        meta={status?.data?.meta ?? {}}
                        links={status?.data?.links ?? {}}
                        linkCaller={getNextPageToShow}
                    />
                </div>
            )}
        </>
    );
}
