import { Fragment, useEffect, useState } from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AuthorizedRoute } from "../../hooks/Auth/Index";
import Dashboard from "../../pages/dashboard.js";
import Convocatorias from "../../pages/convocatorias.js";
import Becados from "../../pages/becados.js";
import Desembolsos from "../../pages/desembolsos";
import Oferentes from "../../pages/oferentes.js";
import Instituciones from "../../pages/instituciones";
import Coordinadores from "../../pages/coordinadores";
import Evaluadores from "../../pages/evaluadores";
import Navigation from "./Navigation";
import NotFount from "../../pages/404";
import { setInit } from "../../Store/slices/init";
import Formulario from "../../pages/formulario";
import Evaluaciones from "../../pages/Evaluaciones";
import EstatusBecados from "../../pages/estatusBecados";
import OfertaAcademicas from "../../pages/OfertaAcademica";
import Carreras from "../../pages/Carreras";
import Perfil from "../../pages/perfil";
import * as Icon from "react-feather";
import Logo from "./Logo";
import { Dialog, Menu, Transition } from "@headlessui/react";
import clsx from "clsx";
import { logOut } from "../../Store/slices/user/index";
import { Logout } from "../../Services/Auth/Logout";
import SubHeader from "./SubHeader";
import Messages from "./Messages";
import Notifications from "./Notifications";
import Users from "../../pages/users";
import DetalleEvaluacion from "../../pages/Evaluaciones/evaluaciones/DetallesEvaluaciones";
import Reports from "../../pages/reports";
import ReportsBecas from "../../pages/report_becados";

const UserNavigation = () => {
    const { user } = useSelector((state) => state.users);
    const { profile } = user.user;

    const userNavigation = [
        { name: "Mi perfil", to: "/perfil", icon: <Icon.User /> },
    ].filter(Boolean);

    const dispatch = useDispatch();
    const signOut = async () => {
        const res = await Logout();
        res?.status === 200 && sessionStorage.removeItem("user");
        dispatch(logOut());
    };

    return (
        <Menu as="div" className="relative ml-3">
            {({ open }) => (
                <>
                    <div>
                        <Menu.Button className="flex items-center max-w-xs text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <span className="sr-only">Open user menu</span>

                            {profile?.image_url ? (
                                <img
                                    src={profile?.image_url}
                                    className="rounded-full w-14 h-14"
                                    alt="Perfil"
                                />
                            ) : (
                                <span className="flex items-center justify-center rounded-full bg-secondary w-14 h-14">
                                    <div className="text-2xl font-bold text-white">
                                        {profile?.name?.slice(0, 1)}
                                    </div>
                                </span>
                            )}
                            <p className="mx-2 font-semibold text-secondary">
                                {user?.user?.profile?.name}
                            </p>
                        </Menu.Button>
                    </div>
                    <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            static
                            className="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                            {userNavigation.map((item) => (
                                <Menu.Item key={item.name}>
                                    {({ active }) => (
                                        <Link
                                            onClick={item.onClick}
                                            to={item.to}
                                            className={clsx(
                                                active ? "bg-gray-100" : "",
                                                "px-4 py-2 text-sm text-gray-700 flex items-center gap-4"
                                            )}
                                        >
                                            {item.icon}
                                            {item.name}
                                        </Link>
                                    )}
                                </Menu.Item>
                            ))}
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={signOut}
                                        className={clsx(
                                            active ? "bg-gray-100" : "",
                                            "px-4 py-2 text-sm text-gray-700 flex items-center gap-4 w-full"
                                        )}
                                    >
                                        <Icon.LogOut />
                                        Cerrar sesión
                                    </button>
                                )}
                            </Menu.Item>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    );
};

const MobileSidebar = ({ sidebarOpen, setSidebarOpen }) => (
    <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
            as="div"
            static
            className="fixed inset-0 z-40 flex md:hidden"
            open={sidebarOpen}
            onClose={setSidebarOpen}
        >
            <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
            >
                <div className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-white">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="absolute top-0 right-0 pt-2 -mr-12">
                            <button
                                type="button"
                                className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                onClick={() => setSidebarOpen(false)}
                            >
                                <span className="sr-only">Close sidebar</span>
                                <Icon.X
                                    className="w-6 h-6 text-white"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                    </Transition.Child>
                    <div className="flex items-center flex-shrink-0 px-4">
                        <Logo />
                    </div>
                    <div className="flex-1 h-0 mt-5 overflow-y-auto">
                        <nav className="px-2 space-y-1">
                            <Navigation />
                        </nav>
                    </div>
                </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true" />
        </Dialog>
    </Transition.Root>
);

const Sidebar = ({ showSidebar }) => {
    return (
        <div className="hidden md:flex md:flex-shrink-0 drop-shadow">
            <div
                className={`flex flex-col w-64 ${
                    showSidebar ? "h-full" : "h-32"
                }  transition-all duration-100`}
            >
                <div className="flex flex-col flex-1 h-0">
                    <div className="flex items-center justify-center flex-shrink-0 h-32 bg-white ">
                        <Logo />
                    </div>
                    <div className="flex flex-col flex-1 overflow-y-auto">
                        <nav className="flex-1 px-2 py-4 space-y-1 bg-white">
                            <Navigation />
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default function Layout() {
    const [showSidebar, setShowSidebar] = useState(true);
    const [showMobileSidebar, setShowMobileSidebar] = useState(false);
    const dispatch = useDispatch();
    const match = useRouteMatch("/convocatorias/detalles_evaluaciones/:id");
    const match2 = useRouteMatch(
        "/evaluaciones/detalles_evaluaciones/:id/solicitud/:solicitud_id/:o?"
    );
    const matchDetallesBecados = useRouteMatch("/becados/detalles/:id");

    useEffect(() => {
        dispatch(setInit());
    }, []);

    return (
        <div className="relative flex h-screen overflow-hidden bg-gray-100 ">
            <MobileSidebar
                sidebarOpen={showMobileSidebar}
                setSidebarOpen={setShowMobileSidebar}
            />
            <Sidebar showSidebar={showSidebar} />
            <div className="flex flex-col flex-1 w-0 overflow-hidden">
                <div className="relative z-10 flex flex-shrink-0 h-16 bg-white ">
                    <button
                        type="button"
                        className="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                        onClick={() => setShowMobileSidebar(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Icon.Menu className="w-6 h-6" aria-hidden="true" />
                    </button>
                    <div className="flex justify-end flex-1 pr-4 md:justify-between">
                        <button
                            type="button"
                            className="items-center hidden px-4 text-gray-500 border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:flex"
                            onClick={() => setShowSidebar(!showSidebar)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <Icon.Menu className="w-6 h-6" aria-hidden="true" />
                        </button>
                        <div className="flex items-center ml-4 md:ml-6">
                            <div className="flex items-center gap-4">
                                <Messages />
                                <Notifications />
                                <UserNavigation />
                            </div>
                        </div>
                    </div>
                </div>

                <SubHeader />

                <main
                    className={` ${
                        showSidebar ? "" : "absolute left-0 mt-32"
                    } w-full overflow-y-auto transition-all duration-200 `}
                >
                    <Switch>
                        <Route exact path="/">
                            <Dashboard />
                        </Route>
                        <Route path="/inicio">
                            <Dashboard />
                        </Route>
                        <Route path="/index">
                            <Dashboard />
                        </Route>
                        <Route path="/convocatorias">
                            <Convocatorias />
                        </Route>
                        <Route path="/becados">
                            <Becados />
                        </Route>
                        <Route path="/desembolsos">
                            <Desembolsos />
                        </Route>
                        <Route path="/oferentes">
                            <Oferentes />
                        </Route>
                        <Route path="/instituciones">
                            <Instituciones />
                        </Route>
                        <AuthorizedRoute
                            hasAuthorized={["admin", "coordinador", "oferente"]}
                            path="/coordinadores"
                        >
                            <Coordinadores />
                        </AuthorizedRoute>
                        <Route path="/perfil">
                            <Perfil />
                        </Route>

                        <AuthorizedRoute
                            hasAuthorized={["admin", "coordinador", "oferente"]}
                            path="/evaluadores"
                        >
                            <Evaluadores />
                        </AuthorizedRoute>

                        <AuthorizedRoute
                            hasAuthorized={["admin", "coordinador", "oferente"]}
                            path="/formularios"
                        >
                            <Formulario />
                        </AuthorizedRoute>

                        <Route
                            // hasAuthorized={[
                            //     "evaluador",
                            //     "coordinador",
                            //     "admin",
                            // ]}
                            path="/evaluaciones"
                        >
                            <Evaluaciones showSidebar={showSidebar} />
                        </Route>

                        <AuthorizedRoute
                            hasAuthorized="institucion"
                            path="/estatus_becados"
                        >
                            <EstatusBecados />
                        </AuthorizedRoute>

                        <AuthorizedRoute
                            hasAuthorized="institucion"
                            path="/ofertas_academicas"
                        >
                            <OfertaAcademicas />
                        </AuthorizedRoute>

                        <AuthorizedRoute hasAuthorized="admin" path="/usuarios">
                            <Users />
                        </AuthorizedRoute>

                        <AuthorizedRoute hasAuthorized="admin" path="/reportes">
                            <Reports />
                        </AuthorizedRoute>

                        <AuthorizedRoute
                            hasAuthorized="admin"
                            path="/reporte_becas"
                        >
                            <ReportsBecas />
                        </AuthorizedRoute>

                        <AuthorizedRoute
                            hasAuthorized="institucion"
                            path="/carreras"
                        >
                            <Carreras />
                        </AuthorizedRoute>

                        <Route path="*">
                            <NotFount />
                        </Route>
                    </Switch>
                </main>
            </div>
        </div>
    );
}
