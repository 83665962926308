import React from "react";
import * as Icon from "react-feather";
import { useHistory } from "react-router-dom";
import noImage from "../../images/profile-placeholder.png";

export default function Evaluadores({ data: evaluadores }) {
    const history = useHistory();

    return (
        <div>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
                {evaluadores?.map((evaluador) => (
                    <div
                        key={evaluador.id}
                        onClick={() => {
                            history.push(
                                `/evaluadores/detalles_evaluadores/${evaluador?.id}`
                            );
                        }}
                        className="bg-white group flex relative py-[30px] flex-col overflow-hidden cursor-pointer rounded-lg border border-gray-200 transition shadow-sm hover:shadow-xl"
                    >
                        <img
                            className="w-[100px] h-[100px] border-2 border-[#F0F0F0] border-solid rounded-full object-contain m-auto"
                            src={evaluador.image_url || noImage}
                            alt={evaluador.name}
                        />
                        <div className="my-5 px-5 flex justify-center">
                            <h2 className="block text-center text-xl line-clamp-2 text-[#231F20]">
                                {evaluador.name}
                            </h2>
                        </div>

                        <div className="flex justify-center p-5">
                            <div className="flex px-4 flex-col items-center">
                                <Icon.Home
                                    className="text-[#7BB31A]"
                                    size={20}
                                />
                                <span className="text-xs mt-1">
                                    {evaluador.estadisticas?.instituciones}
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
