import { useEffect, useState } from "react";

export default function useFilter(
    dofilter = [],
    param = "",
    keys = ["name", "nombre", "title"]
) {
    function eliminarDiacriticosEs(texto) {
        return texto
            ?.normalize("NFD")
            .replace(
                /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
                "$1"
            )
            .normalize();
    }

    const [filter, setfilter] = useState([]);
    useEffect(() => {
        let result = [];
        keys.forEach((element) => {
            const res = dofilter.filter((o) =>
                eliminarDiacriticosEs(o[element]?.toLowerCase())?.includes(
                    eliminarDiacriticosEs(param.toLowerCase())
                )
            );
            result = [...res, ...result];
            setfilter(result);
        });
        return () => {};
    }, [param, dofilter]);

    return [filter];
}
