import clsx from "clsx";
import * as Icon from "react-feather";
import CicularSpinner from "../../components/CicularSpinner";
import { FieldWrapper } from "./FieldWrapper";

export const SelectField = (props) => {
    const {
        label,
        options,
        error,
        className,
        defaultValue,
        onChange,
        registration,
        placeholder,
        loading,
        addAction,
        disabled,
        wrapperClassName,
        isloading
    } = props;
    return (
        <FieldWrapper label={label} error={error} className={wrapperClassName}>
            <div className="flex items-center gap-2">
                <select
                    placeholder={placeholder}
                    name="location"
                    disabled={disabled}
                    className={clsx(
                        " block w-full pl-3 pr-10 py-2 text-base border-gray-300 shadow-sm disabled:bg-gray-100 disabled:text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md",
                        className
                    )}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    {...registration}
                >
                    {!isloading ? (
                        <option value="">Seleccione...</option>
                    ) : (
                        <option value="">Cargando...</option>
                    )}

                    {options?.map((option, i) => (
                        <option
                            key={`${i}${option?.label?.toString()}`}
                            value={option?.value}
                        >
                            {option?.label}
                        </option>
                    ))}
                </select>
                <span className="">
                    {loading && <CicularSpinner />}
                    {addAction && (
                        <Icon.PlusCircle
                            onClick={addAction}
                            size={30}
                            color="#012E6C"
                            className="cursor-pointer"
                        />
                    )}
                </span>
            </div>
        </FieldWrapper>
    );
};
