import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Loging } from "../../../Services/Auth/Loging";
import { Fetch } from "../../../Services/Fetch";

const initialState = {
    all: [],
    one: null,
};

const uriCreate = {
    1: "/users/createSoloLectura",
    2: "/users/createOferente",
    3: "/users/createInstitucion",
    4: "/users/createAdmin",
};

export const getAll = createAsyncThunk(
    "useradmin/getAll",
    async (_, { rejectWithValue }) => {
        try {
            const res = await Fetch({ uri: "/users/getAll" });
            return res.data.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getOne = createAsyncThunk(
    "useradmin/getOne",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Fetch({ data, uri: "/users/show" });
            return res.data.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const Delete = createAsyncThunk(
    "useradmin/delete",
    async (_, { rejectWithValue }) => {
        try {
            const res = await Fetch({ uri: "" });
            return res.data.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const create = createAsyncThunk(
    "useradmin/create",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Fetch({ data, uri: uriCreate[data.type_user] });
            return res.data.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const update = createAsyncThunk(
    "useradmin/update",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Fetch({ data, uri: "/users/update" });
            return res.data.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const userSlice = createSlice({
    name: "userAdmin",
    initialState,
    reducers: {
        newUser: (state, _) => {
            state.one = { id: null, email: null, name: null };
        },
    },
    extraReducers: {
        [getAll.fulfilled]: (state, { payload }) => {
            state.all = payload;
        },
        [create.fulfilled]: (state, { payload }) => {
            state.all = [...state.all, payload];
        },
        [Delete.fulfilled]: (state, { payload }) => {
            state.all = state.all.filter(
                (o) => parseInt(o.id) !== parseInt(payload.id)
            );
        },
        [getOne.fulfilled]: (state, { payload }) => {
            state.one = payload;
        },
        [update.fulfilled]: (state, { payload }) => {
            state.all = [
                ...state.all.filter(
                    (o) => parseInt(o.id) !== parseInt(payload.id)
                ),
                payload,
            ];
        },
    },
});

export const { newUser } = userSlice.actions;

export default userSlice.reducer;
