import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "../../../components/Button";
import { Form, InputField, SelectField } from "../../../components/Form";
import useCreateConvocatorias from "../../../hooks/useCreateConvocatoria";
import * as yup from "yup";
import MUIDataTable from "mui-datatables";
import { Fetch } from "../../../Services/Fetch";
import alertify from "alertifyjs";
import { setLoading } from "../../../Store/slices/solicitudes";
import { replaceHttpToHttps } from "../../../utils/http.js";
import { Pagination } from "../../../components/Common/Index";

const schema = yup
    .object({
        offerer_id: yup.string().required("Requerido"),
        institution_offer_id: yup.string().required("Requerido"),
        formulario_id: yup.string().required("Requerido"),
        coverage: yup
            .number()
            .typeError("Debe ser un número")
            .positive("Debe ser un número positivo")
            .max(100, "Valor máximo 100%")
            .required("Requerido"),
    })
    .required();

function Filterable({
    selects = {},
    setSearch = (p) => console.log(p),
    exportable = () => console.log("aaa"),
}) {
    let [internalSearch, setInternalSearch] = useState("");
    let [modalidades, setModalidades] = useState("");
    let [formularios, setFormularios] = useState("");
    let [sede, setSede] = useState("");
    let [carreras, setCarreras] = useState("");
    let [instituciones, setInstituciones] = useState("");
    let [oferente, setOferente] = useState("");

    return (
        <>
            <div className="w-full p-5 bg-white rounded-lg shadow">
                <div className="flex items-center justify-between mt-4">
                    <p className="font-medium">Filtros</p>

                    <Button
                        className="px-4 py-2 text-sm font-medium text-gray-800 bg-gray-100 rounded-md hover:bg-gray-200"
                        onClick={exportable}
                    >
                        Exportar CSV
                    </Button>
                </div>

                <div>
                    <div className="grid grid-cols-2 gap-4 mt-4 md:grid-cols-3 xl:grid-cols-4">
                        <select
                            className="w-full px-4 py-3 text-sm bg-gray-100 border-transparent rounded-md focus:border-gray-500 focus:bg-white focus:ring-0"
                            value={instituciones}
                            onChange={({ target: { value } }) =>
                                setInstituciones(value)
                            }
                        >
                            <option value="">Instituciones...</option>
                            {(selects["instituciones"] ?? []).map(
                                ({ name, id }) => (
                                    <option value={id}>{name}</option>
                                )
                            )}
                        </select>

                        <select
                            className="w-full px-4 py-3 text-sm bg-gray-100 border-transparent rounded-md focus:border-gray-500 focus:bg-white focus:ring-0"
                            value={carreras}
                            onChange={({ target: { value } }) =>
                                setCarreras(value)
                            }
                        >
                            <option value="">Carreras...</option>
                            {(selects["carreras"] ?? []).map(({ career }) => (
                                <option value={career}>{career}</option>
                            ))}
                        </select>
                        <select
                            className="w-full px-4 py-3 text-sm bg-gray-100 border-transparent rounded-md focus:border-gray-500 focus:bg-white focus:ring-0"
                            value={modalidades}
                            onChange={({ target: { value } }) =>
                                setModalidades(value)
                            }
                        >
                            <option value="">Modalidades...</option>
                            {(selects["modalidades"] ?? []).map(
                                ({ modality }) => {
                                    return (
                                        <option value={modality}>
                                            {modality}
                                        </option>
                                    );
                                }
                            )}
                        </select>

                        <select
                            className="w-full px-4 py-3 text-sm bg-gray-100 border-transparent rounded-md focus:border-gray-500 focus:bg-white focus:ring-0"
                            value={formularios}
                            onChange={({ target: { value } }) =>
                                setFormularios(value)
                            }
                        >
                            <option value="">Formularios...</option>
                            {(selects["formularios"] ?? []).map(
                                ({ name, id }) => {
                                    return <option value={id}>{name}</option>;
                                }
                            )}
                        </select>

                        <select
                            className="w-full px-4 py-3 text-sm bg-gray-100 border-transparent rounded-md focus:border-gray-500 focus:bg-white focus:ring-0"
                            value={sede}
                            onChange={({ target: { value } }) => setSede(value)}
                        >
                            <option value="">Sede...</option>
                            {(selects["sedes"] ?? []).map(({ name }) => {
                                return <option value={name}>{name}</option>;
                            })}
                        </select>

                        <select
                            className="w-full px-4 py-3 text-sm bg-gray-100 border-transparent rounded-md focus:border-gray-500 focus:bg-white focus:ring-0"
                            value={oferente}
                            onChange={({ target: { value } }) =>
                                setOferente(value)
                            }
                        >
                            <option value="">Oferentes...</option>
                            {(selects["oferentes"] ?? []).map(
                                ({ name, id }) => (
                                    <option value={id}>{name}</option>
                                )
                            )}
                        </select>

                        <input
                            className="w-full px-4 py-3 text-sm bg-gray-100 border-transparent rounded-md focus:border-gray-500 focus:bg-white focus:ring-0"
                            placeholder="Buscar..."
                            value={internalSearch}
                            onChange={({ target: { value } }) =>
                                setInternalSearch(value)
                            }
                        />
                        <div className="flex">
                            <Button
                                className="w-3/6"
                                onClick={() =>
                                    setSearch({
                                        internalSearch: internalSearch,
                                        modalidades: modalidades,
                                        formularios: formularios,
                                        sede: sede,
                                        carreras: carreras,
                                        instituciones: instituciones,
                                        oferente: oferente,
                                    })
                                }
                            >
                                Buscar
                            </Button>
                            <Button
                                className="w-3/6"
                                onClick={() => {
                                    setInternalSearch("");
                                    setModalidades("");
                                    setFormularios("");
                                    setSede("");
                                    setCarreras("");
                                    setInstituciones("");
                                    setOferente("");

                                    setSearch({
                                        internalSearch: "",
                                        modalidades: "",
                                        formularios: "",
                                        sede: "",
                                        carreras: "",
                                        instituciones: "",
                                        oferente: "",
                                    });
                                }}
                            >
                                Limpiar Filtros
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default function OfertaAcademica({ data }) {
    const { addOfert, removeOfert } = useCreateConvocatorias();
    const [carreras, setcarreras] = useState([]);
    const [Ofertas, setOfertas] = useState([]);
    const [selectData, setSelectData] = useState([]);
    const [paginationData, setPaginationData] = useState([]);
    const [loading, setloading] = useState(false);
    const [loadinginst, setloadinginst] = useState(false);
    const { instituciones, oferentes, formularios } = useSelector(
        (state) => state
    );
    const columns = [
        {
            name: "oferente",
            label: "Oferente",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "institucion",
            label: "Institución",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            label: "Carrera",
            name: "carrera",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            label: "Sede",
            name: "cede",
            // selector: "oferta.campus_name",
            sortable: true,
        },
        {
            name: "formulario",
            label: "Formulario",
            sortable: true,
        },
        {
            name: "cobertura",
            label: "Cobertura",
            sortable: true,
        },
        {
            name: "dias",
            label: "Dias",
            sortable: true,
        },
        {
            name: "horario",
            label: "Horario",
            sortable: true,
        },
        {
            name: "modalidad",
            lebel: "Modalidad",
            sortable: true,
        },
        {
            label: "Acción",
            name: "eliminar",
            sortable: true,
            right: true,
            options: {
                customBodyRender: (value) =>
                    value && (
                        <button
                            onClick={async () => {
                                alertify
                                    .confirm(
                                        "Confirmar",
                                        "Esta seguro que desea eliminar esta oferta?",
                                        async () => {
                                            await removeOfert({
                                                convocatoria_detail_id: value,
                                            });

                                            setOfertas(
                                                Ofertas.filter(
                                                    (o) => o.id !== value
                                                )
                                            );
                                        },
                                        () =>
                                            toast.error(
                                                "No se elimino la oferta"
                                            )
                                    )
                                    .setting({ label: "Confirmar" });
                            }}
                            className="btn border-[1px] border-primary"
                        >
                            Eliminar
                        </button>
                    ),
            },
        },
    ];

    const onSubmit = async (formdata, reset) => {
        if (!data?.id) {
            toast.error("Convocatoria invalida");
            return;
        }

        await addOfert(
            {
                ...formdata,
                convocatoria_id: data.id,
            },
            reset
        );

        setloading(true);
        Promise.all([
            Fetch({
                uri: `selects/ofertas/modalidades?convocatoria=${data.id}`,
                method: "get",
            }),
            Fetch({
                uri: `selects/ofertas/formularios?convocatoria=${data.id}`,
                method: "get",
            }),
            Fetch({
                uri: `selects/ofertas/sedes?convocatoria=${data.id}`,
                method: "get",
            }),
            Fetch({
                uri: `selects/ofertas/carreras?convocatoria=${data.id}`,
                method: "get",
            }),
            Fetch({
                uri: `selects/ofertas/instituciones?convocatoria=${data.id}`,
                method: "get",
            }),
            Fetch({
                uri: `selects/ofertas/oferentes?convocatoria=${data.id}`,
                method: "get",
            }),
        ])
            .then(
                ([
                    modalidades,
                    formularios,
                    sedes,
                    carreras,
                    instituciones,
                    oferentes,
                ]) =>
                    setSelectData({
                        modalidades: modalidades.data.data ?? [],
                        formularios: formularios.data.data ?? [],
                        sedes: sedes.data.data ?? [],
                        carreras: carreras.data.data ?? [],
                        instituciones: instituciones.data.data ?? [],
                        oferentes: oferentes.data.data ?? [],
                    })
            )
            .then((x) => setLoading(false));

        getAllAfertasacademicas();
    };

    Ofertas.map((obj) => ({
        id: obj.id || "",
        oferente: obj.offerer?.name || "",
        institucion: obj.institucion?.name || "",
        carrera: obj.oferta?.academic_offer_name || "",
        cede: obj.oferta?.campus_name || "",
        formulario: obj.formulario?.name || "",
        cobertura: obj.coverage || "",
        horario: obj.oferta?.schedule?.days || "",
        dias: obj.oferta?.schedule?.time || "",
        modalidad: obj.modalidad || "",
        eliminar: obj.id || "",
    }));

    const getAllAfertasacademicas = async () => {
        if (!data.id) return;
        setloading(true);
        const res = await Fetch({
            uri: "/convocatorias/details/filter",
            data: { convocatoria: data.id, estado: "pendientes" },
        });

        setPaginationData(res.data ?? {});
        setOfertas(res.data.data);

        setloading(false);
    };

    const getAllCarrerasInstituciones = async (institucion_id) => {
        if (!data.id) return;
        setloadinginst(true);
        const res = await Fetch({
            uri: "/instituciones/ofertas/institucion",
            data: { institution_id: institucion_id },
        });
        setcarreras(res.data.data);
        setloadinginst(false);
    };

    function convertArrayOfObjectsToCSV(data) {
        let ctr;

        if (data === null || !data.length) return null;

        let keys = Object.keys(data[0]);

        let result = "";
        result += keys.join(",");
        result += "\n";

        data.forEach((item) => {
            ctr = 0;
            keys.forEach((key) => {
                if (ctr > 0) result += ",";

                result += item[key];
                ctr++;
            });
            result += "\n";
        });

        return result;
    }

    function downloadCSV(rawData, fileName) {
        let csv = convertArrayOfObjectsToCSV(rawData);
        if (csv == null) return;

        if (!csv.match(/^data:text\/csv/i)) {
            csv = "data:text/csv;charset=utf-8," + csv;
        }
        let data = encodeURI(csv);

        let link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", fileName + ".csv");
        link.click();
    }

    function getExportableData() {
        Fetch({
            uri: `convocatorias/details/exportar`,
            data: {
                convocatoria: data.id,
                estado: "todas",
            },
        }).then(({ data: { data } }) =>
            downloadCSV(data, "Ofertas Academicas")
        );
    }

    async function fetchNextData(link) {
        if (link === null) return;
        setloading(true);
        const res = await Fetch({
            uri: replaceHttpToHttps(link),
            data: { convocatoria: data.id, estado: "pendientes" },
        });

        setPaginationData(res.data ?? {});
        setOfertas(res.data.data);

        setloading(false);
    }

    async function searchby({
        internalSearch,
        modalidades,
        formularios,
        sede,
        carreras,
        instituciones,
        oferente,
    }) {
        let firstCall = await Fetch({
            data: {
                convocatoria: data.id,
                estado: "pendientes",
                modalidad: modalidades,
                busqueda: internalSearch,
                institucion: instituciones,
                sede: sede,
                formulario: formularios,
                carrera: carreras,
                oferente: oferente,
            },
            uri: replaceHttpToHttps("/convocatorias/details/filter"),
        });

        setOfertas(firstCall?.data?.data ?? []);
        setPaginationData(firstCall?.data ?? {});
    }

    useEffect(() => {
        getAllAfertasacademicas();

        setloading(true);
        Promise.all([
            Fetch({
                uri: `selects/ofertas/modalidades?convocatoria=${data.id}`,
                method: "get",
            }),
            Fetch({
                uri: `selects/ofertas/formularios?convocatoria=${data.id}`,
                method: "get",
            }),
            Fetch({
                uri: `selects/ofertas/sedes?convocatoria=${data.id}`,
                method: "get",
            }),
            Fetch({
                uri: `selects/ofertas/carreras?convocatoria=${data.id}`,
                method: "get",
            }),
            Fetch({
                uri: `selects/ofertas/instituciones?convocatoria=${data.id}`,
                method: "get",
            }),
            Fetch({
                uri: `selects/ofertas/oferentes?convocatoria=${data.id}`,
                method: "get",
            }),
        ])
            .then(
                ([
                    modalidades,
                    formularios,
                    sedes,
                    carreras,
                    instituciones,
                    oferentes,
                ]) =>
                    setSelectData({
                        modalidades: modalidades.data.data ?? [],
                        formularios: formularios.data.data ?? [],
                        sedes: sedes.data.data ?? [],
                        carreras: carreras.data.data ?? [],
                        instituciones: instituciones.data.data ?? [],
                        oferentes: oferentes.data.data ?? [],
                    })
            )
            .then((x) => setLoading(false));
    }, [data.id]);

    async function paginate(link) {
        let firstCall = await Fetch({
            data: { convocatoria: data.id, estado: "pendientes" },
            uri: replaceHttpToHttps(link),
        });

        setcarreras(firstCall.data.data);
    }

    const options = {
        selectable: false,
        pagination: false,
        search: false,
        toolbar: false,
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
    };

    return (
        <>
            <Form onSubmit={onSubmit} schema={schema}>
                {({ register, formState }) => (
                    <>
                        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                            <label>
                                Convocatoria
                                <input
                                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    disabled
                                    defaultValue={data?.name}
                                />
                            </label>
                            <SelectField
                                loading={oferentes.status === "loading"}
                                label="Oferente"
                                error={formState.errors.offerer_id}
                                registration={register("offerer_id")}
                                options={oferentes?.all?.map((obj) => ({
                                    label: obj.name,
                                    value: obj.id,
                                }))}
                            />
                            <SelectField
                                loading={instituciones.status === "loading"}
                                label="Institución"
                                error={formState.errors.institution_id}
                                options={instituciones?.all?.map((obj) => ({
                                    label: obj.name,
                                    value: obj.id,
                                }))}
                                onChange={(e) => {
                                    getAllCarrerasInstituciones(e.target.value);
                                }}
                            />
                            <SelectField
                                label="Carrera"
                                loading={loadinginst}
                                error={formState.errors.institution_offer_id}
                                registration={register("institution_offer_id")}
                                options={carreras?.map((obj) => ({
                                    label: obj?.academic_offer_name,
                                    value: obj?.id,
                                }))}
                            />

                            <SelectField
                                loading={formularios.status === "loading"}
                                label="Formulario"
                                error={formState.errors.formulario_id}
                                registration={register("formulario_id")}
                                options={formularios?.all?.map((obj) => ({
                                    label: obj.name,
                                    value: obj.id,
                                }))}
                            />
                            <InputField
                                type="number"
                                label="Cobertura"
                                max="100"
                                error={formState.errors.coverage}
                                registration={register("coverage")}
                            />
                        </div>
                        <div className="flex justify-end">
                            <Button type="submit">Agregar</Button>
                        </div>
                    </>
                )}
            </Form>
            <div className="block pb-8 m-5">
                <Filterable
                    selects={selectData ?? {}}
                    exportable={getExportableData}
                    setSearch={searchby}
                />

                <MUIDataTable
                    title="Ofertas académicas"
                    columns={columns}
                    data={Ofertas.map((obj) => {
                        return {
                            id: obj.id || "",
                            oferente: obj.oferente || "",
                            institucion: obj.institucion || "",
                            carrera: obj.oferta?.academic_offer_name || "",
                            cede: obj.oferta?.campus_name || "",
                            formulario: obj.formulario || "",
                            cobertura: obj.coverage || "",
                            horario: obj.horario || "",
                            dias: obj.dias || "",
                            modalidad: obj.modalidad || "",
                            eliminar: obj.id || "",
                        };
                    })}
                    pagination
                    paginationPerPage={12}
                    options={options}
                />
            </div>
            <Pagination
                meta={paginationData.meta ?? {}}
                links={paginationData.links ?? {}}
                linkCaller={fetchNextData}
            />
        </>
    );
}
