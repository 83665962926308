import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Card from "../../components/card/imageCard";
import {
    create,
    setModal,
    getAll,
    getAllFiltered,
    getAllSearch,
} from "../../Store/slices/coordinadores";
import Modal from "../../components/modal";
import Spinner from "../../components/Spinner";
import { Button } from "../../components/Button";
import { Form, InputField } from "../../components/Form";
import * as yup from "yup";
import useFilter from "../../hooks/useFilter";
import { toast } from "react-toastify";
import renderErrors from "../../helpers/renderErrors";
import { replaceHttpToHttps } from "../../utils/http";
import { Pagination } from "../../components/Common/Index.js";

const schema = yup
    .object({
        name: yup.string().required("Requerido"),
        email: yup.string().email("Email invalido").required("Requerido"),
        phone: yup.string().min(10, "Teléfono invalido").required("Requerido"),
        password: yup.string().required("Requerido"),
        password_confirmation: yup.string().required("Requerido"),
    })
    .required();

export default function Index() {
    const dispatch = useDispatch();
    const name = "Coordinadores";
    const [isLoading, setIsLoading] = useState(false);
    const [param, setParam] = useState("");
    const [search, setSearch] = useState("");

    const {
        all: coordinadores,
        status,
        modal,
    } = useSelector(({ coordinadores }) => coordinadores);

    const onSubmit = async (data) => {
        data.image = data.image && data.image[0];
        setIsLoading(false);

        const res = dispatch(create(data));
        setIsLoading(false);

        if (res.payload.errors) {
            renderErrors(res.payload);
        } else {
            toast.success("Coordinador creado exitosamente");
        }
    };

    const filterData = () => {
        dispatch(getAllSearch(search));
    };

    const whereTo = (link) => {
        if (search !== "") {
            link !== null &&
                dispatch(
                    getAllFiltered(
                        replaceHttpToHttps(link + `&busqueda=${search}`)
                    )
                );
        } else {
            link !== null && dispatch(getAllFiltered(replaceHttpToHttps(link)));
        }
    };

    useEffect(() => {
        document.title = `${name} | ${global.title}`;
        dispatch(getAll());
    }, []);

    const history = useHistory();
    const [filtered] = useFilter(coordinadores, param);

    return (
        <>
            <Modal
                action={() =>
                    dispatch(setModal({ name: "modal", state: false }))
                }
                isVisible={modal}
                title="Crear nuevo coordinador"
            >
                <Form onSubmit={onSubmit} schema={schema}>
                    {({ register, formState, setValue, watch }) => (
                        <>
                            <InputField
                                label="Nombre"
                                placeholder="Juan santos"
                                error={formState.errors.name}
                                registration={register("name")}
                            />

                            <InputField
                                type="email"
                                label="Correo electrónico"
                                placeholder="info@ejemplo.gob.do"
                                error={formState.errors.email}
                                registration={register("email")}
                            />

                            <InputField
                                type="phone"
                                label="Teléfono"
                                error={formState.errors.phone}
                                name="phone"
                                onChange={setValue}
                            />

                            <InputField
                                type="file"
                                label="Imagen de perfil"
                                accept="image/*"
                                error={formState.errors.image}
                                watch={watch("image")}
                                className="py-0"
                                setValue={setValue}
                                name="image"
                            />

                            <InputField
                                type="password"
                                label="Contraseña"
                                error={formState.errors.password}
                                registration={register("password")}
                            />

                            <InputField
                                type="password"
                                label="Confirmar contraseña"
                                error={formState.errors.password_confirmation}
                                registration={register("password_confirmation")}
                            />

                            <div className="flex justify-end mt-4">
                                <Button type="submit" isLoading={isLoading}>
                                    Guardar
                                </Button>
                            </div>
                        </>
                    )}
                </Form>
            </Modal>

            <div className="py-8 px-5 md:flex md:justify-between items-center gap-4">
                <div className="flex md:items-center w-1/2">
                    <div className="flex text-base pr-4">Filtrar :</div>
                    <div className="px-2 ">
                        <InputField
                            className={"w-full mr-10 "}
                            onChange={({ target: { value } }) =>
                                setSearch(value)
                            }
                        />
                    </div>
                    <Button onClick={filterData}> Buscar </Button>
                </div>

                <div>
                    <Button
                        onClick={() =>
                            dispatch(setModal({ name: "modal", state: true }))
                        }
                    >
                        Agregar
                    </Button>
                </div>
            </div>

            {status === "loading" ? (
                <Spinner />
            ) : (
                <div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 p-4">
                        {filtered?.map((e) => (
                            <Card
                                key={e.id}
                                image={
                                    e.image_url || `img/no_image_available.png`
                                }
                                title={e.name}
                                level={e.type}
                                object="contain"
                                onclick={() => {
                                    history.push(
                                        `/coordinadores/detalles/${e.id}`
                                    );
                                }}
                            >
                                <div className="border-[#DDDFE1] border-solid border-t-[1px] grid grid-cols-2 p-5">
                                    <div className="flex flex-col items-center">
                                        <Icon.User
                                            className="text-[#7BB31A]"
                                            size={20}
                                        />
                                        <span className="text-xs mt-1">
                                            {e.evaluators?.length}
                                        </span>
                                    </div>
                                    <div className="flex flex-col items-center">
                                        <Icon.BookOpen
                                            className="text-[#00A2FF]"
                                            size={20}
                                        />
                                        <span className="text-xs mt-1">
                                            {e.convocatorias?.length}
                                        </span>
                                    </div>
                                </div>
                            </Card>
                        ))}
                    </div>

                    <Pagination
                        meta={status?.data?.meta ?? {}}
                        links={status?.data?.links ?? {}}
                        linkCaller={whereTo}
                    />
                </div>
            )}
        </>
    );
}
