import axios from "axios";
import store from "../Store";
import { BACKOFFICE_URI } from "./path_base";

export default function AuthAxios() {
    const { users } = store.getState();
    const { user } = users;

    return axios.create({
        baseURL: BACKOFFICE_URI,
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user?.access_token}`,
        },
    });
}
