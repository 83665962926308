import AuthAxios from '../../config/AuthAxios'

export async function Create(form) {
  const Axios = AuthAxios()
  try {
    const res = await Axios.post(`/formularios/create`, form)
    return res
  } catch (err) {
    console.error(err)
  }
}
