import React from "react";
import { Route, useRouteMatch, Switch } from "react-router";
import One from "./One";
import All from "./All";

export default function Reports() {
    const { path } = useRouteMatch();

    return (
        <>
            <Switch>
                <Route exact path={`${path}/:id`}>
                    <One />
                </Route>
                <Route exact path={path}>
                    <All />
                </Route>
            </Switch>
        </>
    );
}
