export default function DatosSocioeconomicos({ data = {} }) {
    const field = (label, name) => (
        <div>
            <label className="block text-sm font-medium text-gray-700">
                {label}
            </label>
            <div className="mt-1">
                <input
                    className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    value={name}
                    disabled
                />
            </div>
        </div>
    );

    const optionField = (label, name) => (
        <div>
            <label
                htmlFor="about"
                className="block text-sm font-medium text-gray-700"
            >
                {label}
            </label>
            <div className="flex gap-4 mt-1">
                <div className="flex items-center gap-2">
                    <label htmlFor={label}>Si</label>
                    <input
                        type="radio"
                        checked={
                            name === 1 ||
                            name === true ||
                            name === "true" ||
                            name === "si" ||
                            name === "Si"
                        }
                        id={label}
                        name={label}
                        className="block border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                        disabled
                    />
                </div>
                <div className="flex items-center gap-2">
                    <label htmlFor={label}>No</label>
                    <input
                        type="radio"
                        checked={
                            name === 0 ||
                            name === false ||
                            name === "false" ||
                            name === "no" ||
                            name === "No"
                        }
                        id={label}
                        name={label}
                        className="block border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                        disabled
                    />
                </div>
            </div>
        </div>
    );

    console.log(data);

    return (
        <div className="py-6 my-4 border-b border-gray-200">
            <form className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                {field("Nombre de la madre", data?.madre_nombre)}
                {field("Nombre del padre", data?.padre_nombre)}

                {field(
                    "Nivel educativo de la madre",
                    data?.madre_nivel_educativo
                )}
                {field(
                    "Nivel educativo del padre",
                    data?.padre_nivel_educativo
                )}

                {optionField("¿La madre trabaja?", data?.madre_trabaja)}
                {optionField("¿El padre trabaja?", data?.padre_trabaja)}
                {field(
                    "Función en el trabajo de la madre",
                    data?.madre_funcion_trabajo
                )}
                {field(
                    "Función en el trabajo del padre",
                    data?.padre_funcion_trabajo
                )}
                {field(
                    "Lugar de trabajo de la madre",
                    data?.madre_lugar_trabajo
                )}
                {field("Lugar de trabajo del padre", data?.padre_lugar_trabajo)}

                {field(
                    "Rango salarial de la madre",
                    data?.madre_rango_salarial
                )}

                {field("Rango salarial del padre", data?.padre_rango_salarial)}

                {optionField("La madre aún está viva", data?.madre_viva)}

                {optionField("El padre aún está vivo", data?.padre_vivo)}

                {optionField("¿Pago de alquiler?", data?.pago_alquiler)}

                {field("Monto del alquiler", data?.monto_alquiler)}

                {optionField("¿Vehículo propio?", data?.vehiculo_propio)}

                {optionField("Están casados", data?.casados)}

                {field("Última actualización", data?.actualizado)}
            </form>
        </div>
    );
}
