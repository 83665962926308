import Chart from "react-apexcharts";

export default function Gender({ title, data, alter = false }) {
    const options = {
        labels: !alter ? ["Mujeres", "Hombres"] : ["Hombres", "Mujeres"],
        legend: {
            position: "bottom",
        },
        colors: !alter ? ["#FF5274", "#00A2FF"] : ["#ec880f", "#0fc1ec"],
    };

    return (
        <div className="h-[340px] p-8 bg-white rounded-lg transition shadow-sm hover:shadow-xl">
            <h3 className="text-base font-light">{title}</h3>
            <Chart
                type="donut"
                series={[data?.hombres || 0, data?.mujeres || 0]}
                height={300}
                options={options}
            />
        </div>
    );
}
