import { useState, useEffect, useRef } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "alertifyjs/build/css/alertify.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    createMensajes,
    getAllMensajes,
    removeMensajes,
    updateMensajes,
} from "../../../Store/slices/convocatorias";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import renderError from "../../../helpers/renderErrors";
import alertify from "alertifyjs";
import { useFormContext } from "react-hook-form";

export default function Mesanje() {
    const dispatch = useDispatch();
    const ref = useRef();
    const [tab, settab] = useState("aprobada");
    const [editorState, seteditorState] = useState(EditorState.createEmpty());
    const [loading, setloading] = useState(false);
    const { register, getValues, formState, setValue, watch } =
        useFormContext();
    const [selectedMessage, setselectedMessage] = useState({
        name: "",
        id: null,
    });
    const mensajes = useSelector(({ convocatorias }) => convocatorias.mensajes);

    const htmlToDraftBlocks = (html) => {
        const blocksFromHtml = htmlToDraft(html);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
        );
        return EditorState.createWithContent(contentState);
    };
    useEffect(() => {
        dispatch(getAllMensajes());
    }, []);
    useEffect(() => {
        tab === "aprobada" &&
            seteditorState(
                htmlToDraftBlocks(selectedMessage?.aprobada || "<p></p>")
            );
        tab === "rechazada" &&
            seteditorState(
                htmlToDraftBlocks(selectedMessage?.rechazada || "<p></p>")
            );
        tab === "credito" &&
            seteditorState(
                htmlToDraftBlocks(selectedMessage?.credito || "<p></p>")
            );
        tab === "evaluacion" &&
            seteditorState(
                htmlToDraftBlocks(selectedMessage?.evaluacion || "<p></p>")
            );
        tab === "iniciada" &&
            seteditorState(
                htmlToDraftBlocks(selectedMessage?.iniciada || "<p></p>")
            );
    }, [tab, selectedMessage?.id]);
    const handlerSave = () => {
        setloading(true);
        if (selectedMessage.id) {
            dispatch(updateMensajes(selectedMessage)).then((res) => {
                setloading(false);
                if (res.error) {
                    renderError(res.payload);
                    return;
                }
                toast.success("Actualizado exitosamente");
                setselectedMessage(res.payload);
            });
        } else {
            dispatch(createMensajes(selectedMessage)).then((res) => {
                setloading(false);
                if (res.error) {
                    renderError(res.payload);
                    return;
                }
                toast.success("Creado exitosamente");
                setValue("mensajes_id", res.payload.id);
                setselectedMessage(res.payload);
            });
        }
    };
    const handleRemove = () => {
        alertify.confirm(
            "Eliminar mensajes",
            `Esta seguro que desea eliminar: ${selectedMessage.name}`,
            function () {
                setloading(true);
                dispatch(
                    removeMensajes({ mensaje_id: selectedMessage.id })
                ).then((res) => {
                    setloading(false);
                    if (res.error) {
                        renderError(res.payload);
                        return;
                    }
                    toast.success("Eliminado exitosamente");
                    setselectedMessage({ name: "", id: null });
                    setValue("mensajes_id", "");
                    ref.current.value = "";
                });
            },
            function () {}
        );
    };
    useEffect(() => {
        if (getValues().mensaje_id && mensajes) {
            let value = mensajes.find(
                (o) =>
                    parseInt(getValues().mensaje_id, 10) === parseInt(o.id, 10)
            );
            setselectedMessage(value);
            ref.current.value = value?.name;
        }
    }, [mensajes]);

    let men = watch("mensajes_id");

    useEffect(() => {
        let value = { name: "", id: null };
        if (men !== "") {
            value = mensajes.find(
                (o) => parseInt(men, 10) === parseInt(o.id, 10)
            );
        }
        setselectedMessage(value);
        ref.current.value = value?.name;
    }, [men]);

    return (
        <div>
            <div className="grid grid-cols-3 gap-4">
                <label className="col-span-2 block text-sm font-medium text-gray-700">
                    Nombre <br />
                    <input
                        ref={ref}
                        className="appearance-none block w-full px-2 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 disabled:bg-gray-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm "
                        type="text"
                        name="name"
                        onChange={({ target }) =>
                            setselectedMessage({
                                ...selectedMessage,
                                name: target.value,
                            })
                        }
                    />
                </label>
                <label className="block mb-3 text-sm font-medium text-gray-700">
                    Mensaje
                    <br />
                    <select
                        className="appearance-none block w-full px-2 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 disabled:bg-gray-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm "
                        onChange={"handleChange"}
                        name="convocatoria_type_id"
                        {...register("mensajes_id")}
                    >
                        <option value="">Nuevo</option>
                        {mensajes.map((o) => {
                            return (
                                <option key={o.id} value={o.id}>
                                    {o.name}
                                </option>
                            );
                        })}
                    </select>
                    <div
                        role="alert"
                        aria-label={formState.errors.mensajes_id}
                        className="text-sm font-semibold text-red-500"
                    >
                        {formState.errors?.mensajes_id?.message}
                    </div>
                </label>
            </div>
            <div>
                <div>
                    <ul className="flex cursor-pointer">
                        <li
                            onClick={() => settab("aprobada")}
                            className={`py-2 px-6 rounded-t-lg ${
                                tab === "aprobada" && "text-white bg-secondary"
                            } `}
                        >
                            Aprobado
                        </li>
                        <li
                            onClick={() => settab("rechazada")}
                            className={`py-2 px-6 rounded-t-lg ${
                                tab === "rechazada" && "text-white bg-secondary"
                            } `}
                        >
                            Rechazado
                        </li>
                        <li
                            onClick={() => settab("credito")}
                            className={`py-2 px-6 rounded-t-lg ${
                                tab === "credito" && "text-white bg-secondary"
                            } `}
                        >
                            Crédito
                        </li>
                        <li
                            onClick={() => settab("evaluacion")}
                            className={`py-2 px-6 rounded-t-lg ${
                                tab === "evaluacion" &&
                                "text-white bg-secondary"
                            } `}
                        >
                            En evaluación
                        </li>
                        <li
                            onClick={() => settab("iniciada")}
                            className={`py-2 px-6  rounded-t-lg ${
                                tab === "iniciada" && "text-white bg-secondary"
                            } `}
                        >
                            Iniciada
                        </li>
                    </ul>
                </div>
                <div className="border">
                    <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={(state) => {
                            seteditorState(state);
                            setselectedMessage({
                                ...selectedMessage,
                                [tab]: draftToHtml(
                                    convertToRaw(state.getCurrentContent())
                                ),
                            });
                        }}
                    />
                </div>
            </div>
            <div className="py-2 flex justify-end w-full ">
                <button
                    onClick={handleRemove}
                    disabled={selectedMessage?.id === null || loading}
                    className="bg-transparent mx-2 disabled:opacity-50 disabled:cursor-not-allowed hover:bg-primary text-primary font-semibold hover:text-white py-2 px-4 border border-primary rounded"
                >
                    Eliminar
                </button>
                <button
                    onClick={handlerSave}
                    disabled={loading}
                    className="bg-transparent disabled:opacity-50 disabled:cursor-not-allowed hover:bg-secondary text-secondary font-semibold hover:text-white py-2 px-4 border border-secondary hover:border-transparent rounded"
                >
                    Guardar
                </button>
            </div>
            <div></div>
        </div>
    );
}
