import AuthAxios from "../../config/AuthAxios";

export const readNotification = async (id) => {
    const Axios = AuthAxios();
    const res = await Axios.post(`/notificaciones/update?notificacion=${id}`);
    return res;
};

export const deleteNotification = async (id) => {
    const Axios = AuthAxios();

    const res = await Axios.post(`/notificaciones/delete?notificacion=${id}`);
    return res;
};

export const getAllNotifications = async () => {
    const Axios = AuthAxios();
    const { data } = await Axios.get(`/notificaciones/getAll`);
    return data.data;
};

export async function GetAll() {
    const Axios = AuthAxios();
    try {
        return Axios.get(`/notificaciones/getAll`);
    } catch (err) {
        console.error(err);
    }
}
