import Chart from "react-apexcharts";
import { useState, useEffect } from "react";

export default function CountryCard({ title = "", data = [] }) {

    // Constants
    const [series, setSeries] = useState([
        {
            data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
        },
    ]);

    const colors = [
        "#00A2FF",
        "#FFC107",
        "#7BB31A",
        "#FF9800",
        "#9400ca",
        "#8acc72",
        "#c89900",
        "#e2639c",
    ];

    const options = {
        chart: {
            height: 350,
            type: "bar",
        },
        colors: colors,
        plotOptions: {
            bar: {
                columnWidth: "45%",
                distributed: true,
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        xaxis: {
            categories: data.map((x) => x.Area),
            labels: {
                style: {
                    fontSize: "12px",
                },
            },
        },
    };

    // Effects
    useEffect(() => setSeries([{ data: data.map((x) => x.Solicitudes) }]), []);

    return (
        <div className="h-[340px] p-8 bg-white col-span-3 rounded-lg transition shadow-sm hover:shadow-xl">
            <h3 className="text-base font-light">{title}</h3>
            <Chart options={options} series={series} type="bar" height={270} />
        </div>
    );
}
