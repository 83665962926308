import React from 'react'
import Average from '../../components/card/average'

export default function Resultados() {
  return (
    <div className="grid grid-cols-4 gap-8 m-5 pb-8">
      <Average title="Promedio de calificaciones" />
    </div>
  )
}
