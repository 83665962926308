import React from "react";
import { Route, Switch } from "react-router-dom";
import Index from "./Instituciones/Index";
import DetallesInstitucion from "./Instituciones/DetallesInstitucion";

export default function Oferentes() {
    return (
        <Switch>
            <Route exact path="/instituciones">
                <Index />
            </Route>
            <Route path="/instituciones/detalles/:id">
                <DetallesInstitucion />
            </Route>
        </Switch>
    );
}
