import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import renderError from "../../helpers/renderErrors";
import { Fetch } from "../../Services/Fetch";
import {
    setPaises,
    setMunicipio,
    setPrivincias,
} from "../../Store/slices/paises";
import { addCampus, setCampus } from "../../Store/slices/campus";
import { addHorario, setHorario } from "../../Store/slices/horarios";
import { getOfertas } from "../../Store/slices/ofertaInstitucion";

export default function useAction({ id = null }) {
    const [modal, setmodal] = useState({ campus: false, horario: false });
    const [loading, setloading] = useState({});
    const [ofert, setofert] = useState({});
    const [ofertas, setofertas] = useState([]);
    const dispatch = useDispatch();
    useState;
    const {
        user: { institution },
    } = useSelector((state) => state.users.user);
    const {
        paises,
        campus,
        horarios,
        ofertasAcademicas: carreras,
    } = useSelector((state) => state);
    const methods = useForm();

    const history = useHistory();

    const onSubmit = (data) => {
        if (data.institution_offer_id) {
            updateOferta(data);
        } else {
            createOferta(data);
        }
    };

    const getOferta = async () => {
        setloading({ ...loading, geting: true });
        try {
            const res = await Fetch({
                data: { institution_offer_id: id },
                uri: "/instituciones/ofertas/show",
            });
            setofert(res.data.data);
            methods.setValue("institution_id", institution.id);
            methods.setValue("institution_offer_id", res.data?.data?.id);
            methods.setValue(
                "academic_offer_id",
                res.data?.data?.academic_offer_id
            );
            methods.setValue("campus_id", res.data?.data?.campus_id);
            methods.setValue("schedule_id", res.data?.data?.schedule.id);
            methods.setValue("justificacion", res.data?.data?.justificacion);
        } catch (error) {
            renderError(error.response?.data);
        }
        setloading({ ...loading, geting: false });
    };

    const getAllOferta = async () => {
        setloading({ ...loading, getinggall: true });
        try {
            const res = await Fetch({
                data: { institution_id: institution.id },
                uri: "/instituciones/ofertas/institucion",
            });
            setofertas(res.data.data);
        } catch (error) {
            renderError(error.response?.data);
        }
        setloading({ ...loading, getinggall: false });
    };

    const deleteOferta = (id) => {
        toast.promise(
            Fetch({
                data: { institution_offer_id: id },
                uri: "/instituciones/ofertas/delete",
            }),
            {
                pending: "Eliminando recurso...",
                success: {
                    render({ data }) {
                        setofertas(
                            ofertas.filter(
                                (o) => parseInt(id) !== parseInt(o.id)
                            )
                        );
                        return "Recurso eliminado!";
                    },
                },
                error: {
                    render({ data }) {
                        const arrayErrors = Object.entries(
                            data?.response?.data?.errors || {}
                        );
                        if (arrayErrors[0]) {
                            if (arrayErrors[0][1]) {
                                return arrayErrors[0][1][0];
                            } else {
                                return data.response?.data?.message;
                            }
                        } else {
                            return data.response?.data?.message;
                        }
                    },
                },
            }
        );
    };

    const createOferta = async (data) => {
        setloading({ ...loading, guardar: true });
        if (!institution.id) {
            toast.error("Este usuario no tiene instituciòn asignada");
            return;
        }
        try {
            await Fetch({
                data: { ...data, institution_id: institution.id },
                uri: "/instituciones/ofertas/create",
            });
            history.push("/ofertas_academicas");
        } catch (error) {
            renderError(error.response?.data);
        }
        setloading({ ...loading, guardar: false });
    };
    const updateOferta = async (data) => {
        setloading({ ...loading, guardar: true });
        if (!institution.id) {
            toast.error("Este usuario no tiene instituciòn asignada");
            return;
        }
        try {
            await Fetch({
                data: { ...data, institution_id: institution.id },
                uri: "/instituciones/ofertas/update",
            });
            history.push("/ofertas_academicas");
        } catch (error) {
            renderError(error.response?.data);
        }
        setloading({ ...loading, guardar: false });
    };

    useEffect(() => {
        id !== "new" && id && getOferta();
        return () => {};
    }, [ofert?.id]);

    const createCampus = async (data) => {
        setloading({ ...loading, guardar: true });
        if (!institution.id) {
            toast.error("Este usuario no tiene instituciòn asignada");
            return;
        }
        try {
            const res = await Fetch({
                data: { ...data, institution_id: institution.id },
                uri: "/campus/create",
            });
            methods.reset();
            dispatch(addCampus(res.data.data));
            setTimeout(() => {
                setmodal({ ...modal, campus: false });
            }, 500);
        } catch (error) {
            renderError(error.response?.data);
        }
        setloading({ ...loading, guardar: false });
    };

    const getCampus = async () => {
        setloading({ ...loading, guardar: true });
        if (!institution.id) {
            toast.error("Este usuario no tiene instituciòn asignada");
            return;
        }
        try {
            const res = await Fetch({
                data: { institution_id: institution.id },
                uri: "/campus/institucion",
            });
            dispatch(setCampus(res.data.data));
        } catch (error) {
            renderError(error.response?.data);
        }
        setloading({ ...loading, guardar: false });
    };

    const getHorarios = async () => {
        setloading({ ...loading, guardar: true });
        if (!institution.id) {
            toast.error("Este usuario no tiene instituciòn asignada");
            return;
        }
        try {
            const res = await Fetch({
                uri: `/horarios/getAll?institution_id=${institution.id}`,
                method: "get",
            });
            dispatch(setHorario(res.data.data));
        } catch (error) {
            renderError(error.response?.data);
        }
        setloading({ ...loading, guardar: false });
    };

    const createHorario = async (data) => {
        setloading({ ...loading, guardar: true });
        if (!institution.id) {
            toast.error("Este usuario no tiene instituciòn asignada");
            return;
        }
        try {
            const res = await Fetch({
                data: { ...data, institution_id: institution.id },
                uri: "/horarios/create",
            });
            methods.reset();
            dispatch(addHorario(res.data.data));
            setTimeout(() => {
                setmodal({ ...modal, horario: false });
            }, 500);
        } catch (error) {
            renderError(error.response?.data);
        }
        setloading({ ...loading, guardar: false });
    };

    const getPaises = async () => {
        setloading({ ...loading, getpaises: true });
        try {
            const res = await Fetch({
                uri: "/paises/getAll",
                method: "get",
            });
            dispatch(setPaises(res.data.data));
        } catch (error) {
            renderError(error.response?.data);
        }
        setloading({ ...loading, getpaises: false });
    };

    const getmunicipio = async (data) => {
        setloading({ ...loading, getmunicipios: true });
        try {
            const res = await Fetch({
                uri: `/municipios/byProvince?province_code=${data}`,
                method: "get",
            });
            dispatch(setMunicipio(res.data.data));
        } catch (error) {
            renderError(error.response?.data);
        }
        setloading({ ...loading, getmunicipios: false });
    };

    const getProvincias = async (data) => {
        setloading({ ...loading, getprovincias: true });
        try {
            const res = await Fetch({
                uri: `/provincias/byCountry?country_id=${data}`,
                method: "get",
            });
            dispatch(setPrivincias(res.data.data));
        } catch (error) {
            renderError(error.response?.data);
        }
        setloading({ ...loading, getprovincias: false });
    };

    useEffect(() => {
        !campus.length && getCampus();
        !paises.length && getPaises();
        !horarios.data.length && getHorarios();
        !carreras.length && dispatch(getOfertas());
    }, []);

    const listening = (data, name, type) => {
        if (name === "country_id" && data[name] === "62") {
            getProvincias(data[name]);
        } else if (name === "province_id") {
            getmunicipio(data[name]);
        }
    };

    return {
        modal,
        setmodal,
        listening,
        ...methods,
        onSubmit,
        loading,
        ofertas,
        getAllOferta,
        createOferta,
        createCampus,
        getPaises,
        getmunicipio,
        getProvincias,
        paises,
        createHorario,
        getCampus,
        campus,
        updateOferta,
        horarios,
        carreras,
        deleteOferta,
    };
}
