import { useParams } from "react-router";
import { Button } from "../../components/Button";
import { InputField, SelectField, Form } from "../../components/Form";
import Modal from "../../components/modal";
import Spinner from "../../components/Spinner";
import useAction from "./useAction";
import Select from "react-select";
import { FieldWrapper } from "../../components/Form/FieldWrapper";

export default function OfertaAcademicaOne() {
    const { id } = useParams();
    const {
        modal,
        setmodal,
        register,
        watch,
        onSubmit,
        formState: { errors },
        loading,
        handleSubmit,
        createCampus,
        createHorario,
        horarios,
        campus,
        paises,
        carreras,
        listening,
    } = useAction({ id: id });
    return (
        <>
            <Modal
                action={() => setmodal({ ...modal, horario: false })}
                isVisible={modal.horario}
                title="Crear Nuevo Horario"
            >
                <Form onSubmit={createHorario}>
                    {({ register, formState, setValue }) => (
                        <>
                            <SelectField
                                label="Modalidad"
                                type="text"
                                registration={register("modality", {
                                    required: "Requerido",
                                })}
                                error={formState?.errors.modality}
                                options={[
                                    {
                                        label: "Presencial",
                                        value: "Presencial",
                                    },
                                    {
                                        label: "Virtual",
                                        value: "Virtual",
                                    },
                                    {
                                        label: "Semipresencial",
                                        value: "Semipresencial",
                                    },
                                    {
                                        label: "Por determinar",
                                        value: "Por determinar",
                                    },
                                ]}
                            />
                            <FieldWrapper
                                label="Días"
                                error={formState?.errors.time}
                            >
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    onChange={(data) => {
                                        let t = "";
                                        data.forEach((o) => {
                                            !t
                                                ? (t = `${t}${o.label}`)
                                                : (t = `${t},${o.label}`);
                                        });
                                        setValue("days", t);
                                    }}
                                    options={[
                                        { value: "lunes", label: "Lunes" },
                                        {
                                            value: "martes",
                                            label: "Martes",
                                        },
                                        {
                                            value: "miercoles",
                                            label: "Miercoles",
                                        },
                                        { value: "jueves", label: "Jueves" },
                                        {
                                            value: "viernes",
                                            label: "Viernes",
                                        },
                                        { value: "sabado", label: "Sabado" },
                                        { value: "domingo", label: "Domingo" },
                                        {
                                            value: "Por determinar",
                                            label: "Por determinar",
                                        },
                                    ]}
                                />
                            </FieldWrapper>
                            <FieldWrapper
                                label="Tanda"
                                error={formState?.errors.shift}
                            >
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    onChange={(data) => {
                                        let t = "";
                                        data.forEach((o) => {
                                            !t
                                                ? (t = `${t}${o.label}`)
                                                : (t = `${t},${o.label}`);
                                        });
                                        setValue("shift", t);
                                    }}
                                    options={[
                                        { value: "mañana", label: "Mañana" },
                                        {
                                            value: "tarde",
                                            label: "Tarde",
                                        },
                                        {
                                            value: "noche",
                                            label: "Noche",
                                        },
                                        {
                                            value: "Por determinar",
                                            label: "Por determinar",
                                        },
                                    ]}
                                />
                            </FieldWrapper>

                            <InputField
                                label="Horas a la semana"
                                placeholder=""
                                type="number"
                                error={formState.errors.horas}
                                registration={register("time")}
                                min={0}
                                max={168}
                                steps={0.5}
                            />
                            <br />
                            <br />
                            <br />
                            <br />

                            <div className="flex justify-end mt-4">
                                <Button
                                    type="submit"
                                    isLoading={loading.guardar}
                                >
                                    Guardar
                                </Button>
                            </div>
                        </>
                    )}
                </Form>
            </Modal>

            <Modal
                action={() => setmodal({ ...modal, campus: false })}
                isVisible={modal.campus}
                title="Crear Sede"
            >
                <Form
                    onSubmit={createCampus}
                    listening={listening}
                    options={{
                        defaultValues: {
                            province_id: 33,
                            municipality_id: 159,
                        },
                    }}
                >
                    {({ register, formState, setValue, watch }) => (
                        <>
                            <InputField
                                label="Nombre"
                                placeholder="Ministerio de Educación Superior, Ciencia y Tecnología"
                                error={formState.errors.name}
                                registration={register("name", {
                                    required: "Requerido",
                                })}
                            />

                            <InputField
                                type="phone"
                                label="Contacto"
                                placeholder="8095000000"
                                error={formState.errors.phone_number}
                                onChange={setValue}
                                name="phone_number"
                            />

                            <SelectField
                                label="País"
                                type="text"
                                registration={register("country_id", {
                                    required: "Requerido",
                                })}
                                error={formState.errors.country_id}
                                options={paises.paises.reduce(
                                    (acc, curr) => [
                                        ...acc,
                                        { value: curr.id, label: curr.name },
                                    ],
                                    []
                                )}
                            />

                            <SelectField
                                label="Provincia"
                                type="text"
                                registration={register("province_id", {
                                    required: "Requerido",
                                })}
                                error={formState.errors.province_id}
                                options={paises.provincias.reduce(
                                    (acc, curr) => [
                                        ...acc,
                                        {
                                            value: curr.code,
                                            label: curr.name,
                                        },
                                    ],
                                    []
                                )}
                                wrapperClassName={`${
                                    watch("country_id") !== "62" && "hidden"
                                } `}
                            />

                            <SelectField
                                label="Municipio"
                                type="text"
                                registration={register("municipality_id", {
                                    required: "Requerido",
                                })}
                                error={formState.errors.municipality_id}
                                options={paises.municipios.reduce(
                                    (acc, curr) => [
                                        ...acc,
                                        {
                                            value: curr.code,
                                            label: curr.name,
                                        },
                                    ],
                                    []
                                )}
                                wrapperClassName={`${
                                    watch("country_id") !== "62" && "hidden"
                                } `}
                            />

                            <InputField
                                label="Dirección"
                                placeholder="Dirección"
                                error={formState.errors.address}
                                registration={register("address", {
                                    required: "Requerido",
                                })}
                            />

                            <div className="flex justify-end mt-4">
                                <Button
                                    type="submit"
                                    isLoading={loading.guardar}
                                >
                                    Guardar
                                </Button>
                            </div>
                        </>
                    )}
                </Form>
            </Modal>
            <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
                <div className="relative py-3 sm:max-w-xl sm:mx-auto w-1/2">
                    <div className="relative  px-4 py-10 bg-white mx-8 md:mx-0 shadow rounded-3xl m sm:p-10">
                        <div className="max-w-md mx-auto">
                            <div className="flex items-center space-x-5">
                                <div className="block font-semibold text-xl self-start text-gray-700">
                                    <h2 className="leading-relaxed">
                                        Oferta académica
                                    </h2>
                                    <p className="text-sm text-gray-500 font-normal leading-relaxed">
                                        {id !== "new"
                                            ? `Actualizar ${
                                                  watch("name") ||
                                                  "Oferta Academíca"
                                              } `
                                            : watch("name") ||
                                              "Crear Oferta Academíca"}
                                    </p>
                                </div>
                            </div>
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                                className="divide-y divide-gray-200"
                            >
                                {loading.geting && <Spinner />}
                                <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                    <SelectField
                                        label="Carrera"
                                        type="text"
                                        registration={register(
                                            "academic_offer_id",
                                            {
                                                required: "Requerido",
                                            }
                                        )}
                                        error={errors.academic_offer_id}
                                        options={carreras.all?.reduce(
                                            (acc, curr) => [
                                                ...acc,
                                                {
                                                    value: curr.id,
                                                    label: curr.career,
                                                },
                                            ],
                                            []
                                        )}
                                    />
                                    <SelectField
                                        addAction={() =>
                                            setmodal({ ...modal, campus: true })
                                        }
                                        label="Sede"
                                        type="text"
                                        registration={register("campus_id", {
                                            required: "Requerido",
                                        })}
                                        error={errors.campus_id}
                                        options={campus.data?.reduce(
                                            (acc, curr) => [
                                                ...acc,
                                                {
                                                    value: curr.id,
                                                    label: curr.name,
                                                },
                                            ],
                                            []
                                        )}
                                    />

                                    <SelectField
                                        addAction={() =>
                                            setmodal({
                                                ...modal,
                                                horario: true,
                                            })
                                        }
                                        label="Horario"
                                        type="text"
                                        registration={register("schedule_id", {
                                            required: "Requerido",
                                        })}
                                        error={errors.schedule_id}
                                        options={horarios.data?.reduce(
                                            (acc, curr) => [
                                                ...acc,
                                                {
                                                    value: curr.id,
                                                    label: `${curr.modality} | ${curr.time} horas a la semana | ${curr.days}`,
                                                },
                                            ],
                                            []
                                        )}
                                    />
                                    <InputField
                                        label="Justificación"
                                        placeholder="Justifique esta oferta..."
                                        error={errors.justificacion}
                                        registration={register("justificacion")}
                                    />
                                </div>

                                <div className="pt-4 flex items-center space-x-4">
                                    <Button
                                        type="submit"
                                        isLoading={loading.guardar}
                                        className="flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none"
                                    >
                                        {loading.guardar
                                            ? "Guardando..."
                                            : "Guardar"}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
