import React, { useEffect, useState } from "react";
import { Route, Redirect, useLocation, useHistory } from "react-router-dom";
import { isExpired } from "react-jwt";
import { useSelector, useDispatch } from "react-redux";
import { Loging } from "../../Services/Auth/Loging";
import { Logout } from "../../Services/Auth/Logout";
import { ACTION } from "../../Store/Actions";
import { setUser } from "../../Store/slices/user";

const ServiceAuth = {
    async signin(setLoading, data) {
        const form = new FormData();
        Object.entries(data).forEach((obj) => {
            form.append(obj[0], obj[1]);
        });
        const res = await Loging(setLoading, form);
        return res;
    },
    async signout(setLoading) {
        const res = await Logout(setLoading);
        return res;
    },
};

export function useProvideAuth() {
    const [loading, setloading] = useState(false);
    const [res, setres] = useState({});

    const dispatch = useDispatch();

    const signing = async (data) => {
        setres({});
        await ServiceAuth.signin(setloading, data).then((res) => {
            if (res?.access_token) {
                dispatch({ type: ACTION.USER.LOGING, value: res });
            }
            setres(res);
        });
    };

    const signout = () => {
        ServiceAuth.signout().then((res) => {});
    };
    return {
        signing,
        signout,
        loading,
        res,
    };
}

export const isAuth = (token) => {
    if (token) {
        if (!isExpired(token)) {
            return token;
        }
    }
    return false;
};

export function PrivateRoute({ children, ...rest }) {
    const user = useSelector((state) => state.users.user);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!user?.keys) {
            const user = JSON.parse(sessionStorage.getItem("user"));
            dispatch(setUser(user));
        }
    }, []);

    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuth(user.access_token) ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/auth",
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

export function PublicRoute({ children, ...rest }) {
    const user = useSelector((state) => state.users.user);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!user?.keys) {
            const user = JSON.parse(sessionStorage.getItem("user"));
            dispatch(setUser(user));
        }
    }, []);
    return (
        <Route
            {...rest}
            render={({ location }) =>
                !isAuth(user?.access_token) ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname:
                                location.state?.from !== "/auth"
                                    ? location.state?.from?.pathname
                                    : "/",
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

export function AuthorizedRoute({ children, ...rest }) {
    const { user } = useSelector((state) => state.users);

    const isAuthorized = () => {
        if (
            rest.hasAuthorized?.includes(user.user?.role.name) ||
            rest.hasAuthorized?.includes("*")
        ) {
            return true;
        }
        return false;
    };

    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuthorized() ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}
