export default function generateActions(action) {
    const obj = {};

    action.forEach((element) => {
        obj[element.action.fulfilled] = (state, action) => {
            const { payload } = action;
            if (element.stateProp === "create") {
                state[element.statePropAll] = [
                    ...state[element.statePropAll],
                    payload?.data?.data || payload?.data || payload,
                ];
            } else if (element.stateProp === "delete") {
                state[element.statePropAll] = state[
                    element.statePropAll
                ].filter((obj) => {
                    return parseInt(obj.id) !== parseInt(payload?.id);
                });
            } else {
                payload &&
                    (state[element.stateProp] = payload?.data?.data || payload);
            }
            state.status = payload;
            element.element && (state[element.element] = false);
        };

        obj[element.action.pending] = (state, _) => {
            state.status = "loading";
        };

        obj[element.action.rejected] = (state, _) => {
            state.status = "error";
        };
    });

    return obj;
}
