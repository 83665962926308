import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NotFount from "./pages/404.js";
import { PrivateRoute, PublicRoute } from "./hooks/Auth/Index.js";
import Layout from "./components/Layout/Layout";
import Auth from "./pages/auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
    global.title = "Becas Dominicanas";


    return (
        <main className="bg-custom-200 h-screen">
            <ToastContainer />
            <Router>
                <Switch>
                    <PublicRoute exact path="/auth">
                        <Auth />
                    </PublicRoute>
                    <PrivateRoute path="/">
                        <Layout />
                    </PrivateRoute>
                    <Route path="*">
                        <NotFount />
                    </Route>
                </Switch>
            </Router>
        </main>
    );
}


export default App;
