import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetAll } from "../../../Services/Oferentes/GetAll";
import { Create } from "../../../Services/Oferentes/Create";
import { Delete } from "../../../Services/Oferentes/Delete";
import generateActions from "../generateActions";
import { Fetch } from "../../../Services/Fetch";

const name = "oferentes";

const initialState = {
    all: [],
    one: {},
    create: [],
    status: null,
};

export const getAll = createAsyncThunk(
    `${name}/getall`,
    async (data) => await GetAll(data)
);

export const getFilterAll = createAsyncThunk(
    `${name}/getall`,
    async (data = "oferentes/filtrar") =>
        await Fetch({ uri: data, method: "get" })
);

export const getSearchFilterAll = createAsyncThunk(
    `${name}/getall`,
    async (data) =>
        await Fetch({
            uri: `oferentes/filtrar?busqueda=${data}`,
            method: "get",
        })
);

export const getOne = createAsyncThunk(
    `${name}/getone`,
    async (data) => await Fetch({ data, uri: "/oferentes/show" })
);

export const create = createAsyncThunk(
    `${name}/create`,
    async (data) => await Create(data)
);

export const remove = createAsyncThunk(
    `${name}/delete`,
    async (data) => await Delete(data)
);

export const reducers = generateActions([
    { action: getAll, stateProp: "all" },
    { action: getOne, stateProp: "one" },
    {
        action: create,
        stateProp: "create",
        statePropAll: "all",
        element: "modal",
    },
    { action: remove, stateProp: "delete", statePropAll: "all" },
]);

const OferentesSlice = createSlice({
    name,
    initialState,
    reducers: {
        setModal(state, action) {
            state[action.payload.name] = action.payload.state;
        },
    },
    extraReducers: reducers,
});

export const { setModal } = OferentesSlice.actions;

export default OferentesSlice.reducer;
