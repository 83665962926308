import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import generateActions from "../generateActions";
import { Fetch } from "../../../Services/Fetch";
import { Create } from "../../../Services/Formularios/Create";

const name = "formularios";

const initialState = {
    all: [],
    create: [],
};

export const setAllForms = createAsyncThunk(
    `${name}/getall`,
    async (data) => await Fetch({ data, uri: "/formularios/getAll" })
);

export const createForm = createAsyncThunk(
    `${name}/create`,
    async (data) => await Create(data)
);

export const reducers = generateActions([
    { action: setAllForms, stateProp: "all" },
    { action: createForm, stateProp: "create", statePropAll: "all" },
]);

const formsSlice = createSlice({
    name,
    initialState,
    reducers: {
        deleteForm: (state, { payload }) => {
            state.all = state.all.filter(
                (o) => parseInt(o.id) !== parseInt(payload)
            );
        },
    },
    extraReducers: reducers,
});

export const { deleteForm } = formsSlice.actions;

export default formsSlice.reducer;
