import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { Fetch } from "../../../Services/Fetch";
import generateActions from "../generateActions";

const name = "becados";

const initialState = {
    all: [],
    one: {},
    filtered: [],
    create: [],
    oneContracts: {},
    paginate: {},
    filters: {},
    status: null,
};

export const getBecados = createAsyncThunk(
    `${name}/getall`,
    async (data) => await Fetch({ data, uri: "/becados/getAll", method: "get" })
);

export const getFilterBecados = createAsyncThunk(
    `${name}/getall`,
    async (data) =>
        await Fetch({ data, uri: "/becados/filtrar", method: "get" })
);

export const getFilterPaginationBecados = createAsyncThunk(
    `${name}/getfilltered`,
    async (next) => await Fetch({ uri: next, method: "get" })
);

export const getOneBecado = createAsyncThunk(
    `${name}/getone`,
    async (data) => await Fetch({ data, uri: "/becados/show" })
);

export const getFilteredBecados = createAsyncThunk(
    `${name}/getfilltered`,
    async ({ id = "", link = "" }) =>
        await Fetch({
            uri: `/becados/filtrar?convocatoria=${id}&${link}`,
            method: "get",
        })
);

export const getFilteredBecadosByLink = createAsyncThunk(
    `${name}/getfilltered`,
    async ({ link = "" }) => {
        return await Fetch({
            uri: link,
            method: "get",
        });
    }
);

export const getAllBecados = createAsyncThunk(
    `${name}/getfilltered`,
    async () =>
        await Fetch({
            uri: `/becados/filtrar`,
            method: "get",
        })
);

export const getFiltersData = createAsyncThunk(
    `${name}/getfilters`,
    (data = "") =>
        Promise.all([
            Fetch({
                //Estados
                uri: `selects/becados/estados?convocatoria=${data}`,
                method: "get",
            }),
            Fetch({
                //Areas
                uri: `selects/becados/areas?convocatoria=${data}`,
                method: "get",
            }),
            Fetch({
                // Sedes
                uri: `selects/becados/sedes?convocatoria=${data}`,
                method: "get",
            }),
            Fetch({
                // Niveles
                uri: `selects/becados/niveles?convocatoria=${data}`,
                method: "get",
            }),
            Fetch({
                // Carreras
                uri: `selects/becados/carreras?convocatoria=${data}`,
                method: "get",
            }),
            Fetch({
                // instituciones
                uri: `selects/becados/instituciones?convocatoria=${data}`,
                method: "get",
            }),
            Fetch({
                // Genero
                uri: `selects/generos`,
                method: "get",
            }),
            Fetch({
                // Convocatorias Generales
                uri: `selects/convocatorias`,
                method: "get",
            }),
            Fetch({
                // Modalidades
                uri: `selects/modalidades`,
                method: "get",
            }),
        ]).then(
            ([
                estados,
                areas,
                sedes,
                niveles,
                carreras,
                instituciones,
                generos,
                generalSelector,
                modalidades,
            ]) => ({
                estados: estados.data.data ?? [],
                areas: areas.data.data ?? [],
                sedes: sedes.data.data ?? [],
                niveles: niveles.data.data ?? [],
                carreras: carreras.data.data ?? [],
                instituciones: instituciones.data.data ?? [],
                generos: generos.data.data ?? [],
                generalSelector: generalSelector.data.data ?? [],
                modalidades: modalidades.data.data ?? [],
            })
        )
);

export const getSearchBecados = createAsyncThunk(
    `${name}/getfilltered`,
    async (data) => {
        let serachStr = "";
        for (let key in data) serachStr += `${key}=${data[key]}&`;
        serachStr = serachStr.slice(0, -1);
        return await Fetch({
            uri: `/becados/filtrar?${serachStr}`,
            method: "get",
        });
    }
);

export const becadosUpdateestatus = createAsyncThunk(
    `${name}/becadosupdateestatus`,
    async (data) => await Fetch({ data, uri: "/becados/updateEstado" })
);

export const getContratosForOne = createAsyncThunk(
    `${name}/getcontratosforone`,
    async ({ candidatoId = 12, solicitudId = 17, pageNum = 0 }) =>
        await Fetch({
            uri: `/contratos/filtrar?candidato=${candidatoId}&solicitud=${solicitudId}&page=${pageNum}`,
            method: "get",
        })
);

export const reducers = generateActions([
    { action: getBecados, stateProp: "all" },
    { action: getOneBecado, stateProp: "one" },
    { action: getFilteredBecados, stateProp: "filtered" },
    { action: getFilteredBecadosByLink, stateProp: "filtered" },
    { action: getFilterPaginationBecados, stateProp: "filtered" },
    { action: getFiltersData, stateProp: "filters" },
    { action: getContratosForOne, stateProp: "oneContracts" },
]);

const BecadosSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: {
        ...reducers,
        // reducers adicionales
        [becadosUpdateestatus.fulfilled]: (state, { payload }) => {},
        [becadosUpdateestatus.pending]: (state, { payload }) => {},
        [becadosUpdateestatus.rejected]: (state, action) => {},
    },
});

export default BecadosSlice.reducer;
