import { useEffect, useState } from "react";
import { Archive } from "react-feather";
import { MessagesCard } from "../../components/card/Messages";
import { Fetch } from "../../Services/Fetch";

export default function Messages() {
    const [messages, setMessages] = useState([]);

    const onRead = async (id) => {};

    const onDelete = async (id) => {};

    const getAll = async () => {
        const res = await Fetch({
            uri: "/mensajes/internos/getAll",
            method: "get",
        });
        setMessages(res.data.data);
    };

    useEffect(() => {
        getAll();
    }, []);

    return (
        <div className="grid gap-4">
            {messages.length > 0 ? (
                messages.map((message) => (
                    <MessagesCard
                        key={message.id}
                        message={message}
                        onRead={onRead}
                        onDelete={onDelete}
                    />
                ))
            ) : (
                <div className="bg-white text-gray-500 h-80 flex justify-center items-center flex-col">
                    <Archive className="h-16 w-16" />
                    <h4>No tiene mensajes</h4>
                </div>
            )}
        </div>
    );
}
