import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Route, useParams, Redirect } from "react-router-dom";
import Tabnavigator from "../../components/navigation/tabnavigator";
import DatosPersonales from "./datosPersonales";
import InformacionAcademica from "./informacionAcademica";
import Experiencialaboral from "./experiencialaboral";
import DatosSocioeconomicos from "./datosSocioeconomicos";
import Resultados from "./resultados";
import { getOneBecado } from "../../Store/slices/becados";
import DetallesBody from "../../components/DetallesBody";
import BecadosDetallesSidebar from "../../components/BecadosDetallesSidebar";
import Contrato from "./Contratos";
import Spinner from "../../components/Spinner";

export default function DetallesBecados() {
    const { id } = useParams();

    const { one } = useSelector((state) => state.becados);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOneBecado({ scholarship_id: id }));
    }, []);

    return (
        <div className="grid grid-cols-1 lg:grid-cols-3 xl:col-span-4 p-4 pb-8 gap-4">
            <div className="lg:col-span-3 xl:col-span-4">
                <DetallesBody data={one} />
            </div>

            <div className="lg:col-span-2 xl:col-span-3">
                <Tabnavigator
                    routes={[
                        {
                            path: `/becados/detalles/${id}/resultados`,
                            name: "Resultados",
                        },
                        {
                            path: `/becados/detalles/${id}/datos_personales`,
                            name: "Datos Personales",
                        },
                        {
                            path: `/becados/detalles/${id}/informacion_academica`,
                            name: "Formación Académica",
                        },
                        {
                            path: `/becados/detalles/${id}/experiencia_laboral`,
                            name: "Experiencia Laboral",
                        },
                        {
                            path: `/becados/detalles/${id}/contrato`,
                            name: "Contrato",
                        },
                    ]}
                />

                <div className="w-full h-auto shadow-lg bg-white p-[30px]">
                    <div>
                        <Switch>
                            <Route
                                exact
                                path="/becados/detalles/:id/resultados"
                            >
                                <Resultados />
                            </Route>
                            <Route
                                exact
                                path="/becados/detalles/:id/datos_personales"
                            >
                                <DatosPersonales data={one.candidate} />
                            </Route>
                            <Route
                                exact
                                path="/becados/detalles/:id/informacion_academica"
                            >
                                <InformacionAcademica
                                    data={one.candidate?.formacion_academica}
                                />
                            </Route>
                            <Route
                                exact
                                path="/becados/detalles/:id/experiencia_laboral"
                            >
                                <Experiencialaboral
                                    data={one.candidate?.experiencia_laboral}
                                />
                            </Route>
                            <Route
                                exact
                                path="/becados/detalles/:id/datos_socioeconomicos"
                            >
                                <DatosSocioeconomicos />
                            </Route>
                            <Route exact path="/becados/detalles/:id/contrato">
                                {one.candidate?.id !== undefined &&
                                one.solicitud?.id !== undefined ? (
                                    <Contrato
                                        candidatoId={one.candidate?.id ?? 0}
                                        solicitudId={one.solicitud?.id ?? 0}
                                        becaId={
                                            one.institution_offer
                                                ?.institution_id ?? 0
                                        }
                                    />
                                ) : (
                                    <Spinner />
                                )}
                            </Route>
                            <Redirect
                                to={`/becados/detalles/${id}/resultados`}
                            />
                        </Switch>
                    </div>
                </div>
            </div>

            <div className="bg-white">
                <BecadosDetallesSidebar
                    scholarship_id={id}
                    status={one.estado}
                    data={one.detalles_becado}
                />
            </div>
        </div>
    );
}
