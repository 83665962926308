import { useImperativeHandle } from "react";
import { useFieldArray } from "react-hook-form";
import { InputField } from ".";
import { Button } from "../Button";
import NestedArray from "./NestedFieldArray";

export default function Fields({
    control,
    register,
    formState,
    watch,
    sectionsRef,
    currentSection,
}) {
    const { fields, append, remove } = useFieldArray({
        control,
        name: "sections",
    });

    useImperativeHandle(sectionsRef, () => ({
        addSection() {
            append({
                name: "Nombre de la sección",
                detalles: [
                    {
                        name: "Nombre de la pregunta",
                        seccion: "Nombre de la sección",
                        type: "text",
                        required: false,
                        description: "Descripción o detalle de la pregunta",
                    },
                ],
            });
        },
    }));

    return (
        <div>
            <ul className="">
                {fields.map((item, index) => (
                    <li key={item.id}>
                        {watch(`sections[${index}]`) === currentSection && (
                            <div>
                                <div className="pb-4 border-b-2 ">
                                    <h3 className="text-base font-bold uppercase text-secondary">
                                        Nombre de la sección
                                    </h3>
                                    <div className="flex items-start gap-4 ">
                                        <div className="flex-grow">
                                            <InputField
                                                error={
                                                    formState?.errors
                                                        ?.sections &&
                                                    formState?.errors?.sections[
                                                        index
                                                    ]?.name
                                                }
                                                registration={register(
                                                    `sections[${index}].name`
                                                )}
                                            />
                                        </div>
                                        <div>
                                            <Button
                                                onClick={() => remove(index)}
                                                variant="danger"
                                            >
                                                Eliminar sección
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                                {watch(`sections[${index}].name`) && (
                                    <NestedArray
                                        parentIndex={index}
                                        {...{
                                            control,
                                            register,
                                            formState,
                                            watch,
                                            currentSection,
                                        }}
                                    />
                                )}
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
}
