import { toast } from "react-toastify";

function renderError(props) {
    const { errors, message, status } = props || {};

    if (errors) {
        const arrayErrors = Object.entries(errors);
        arrayErrors[0][1][0]
            ? toast.error(arrayErrors[0][1][0])
            : toast.error(message);
    } else {
        toast.error(message);
    }
}

export default renderError;
