export default function Card({
    status,
    category,
    image,
    title,
    level,
    color,
    object,
    children,
    onclick,
}) {
    return (
        <div
            onClick={onclick}
            className="bg-white flex relative flex-col overflow-hidden cursor-pointer rounded-lg transition-all shadow-sm hover:shadow-xl"
        >
            {category ? (
                <>
                    <div
                        className="block absolute right-0 px-3 py-2 m-5 rounded-full"
                        style={{ backgroundColor: color }}
                    >
                        <h3 className="text-sm text-white text-center">
                            {category}
                        </h3>
                    </div>
                </>
            ) : null}
            <img
                className={
                    object === "contain"
                        ? "w-40 h-32 object-contain m-auto"
                        : "h-40 object-cover w-full"
                }
                src={image}
                alt={title}
            />

            <div className="px-4">
                <span className="block text-sm uppercase font-bold  text-secondary pt-4">
                    {level}
                </span>
                <h2 className="block text-xl font-bold h-14 line-clamp-2 text-[#707070] mt-2 mb-4">
                    {title}
                </h2>
            </div>
            <div className="text-sm text-center border-t-2 py-2 font-bold text-secondary uppercase">
                {status}
            </div>

            <div>{children}</div>
        </div>
    );
}
