import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import OfertaAcademica from './OfertaAcademicas'
import OfertaAcademicaOne from './OfertaAcademicaOne'

export default function index() {
    const {path}=useRouteMatch()
    return (
        <>
            <Switch>
                <Route exact path={path} >
                    <OfertaAcademica/>
                </Route>
                <Route exact path={`${path}/:id`} >
                    <OfertaAcademicaOne/>
                </Route>
            </Switch>
        </>
    )
}
