export default function Formulario({ data }) {
    return (
        <div>
            <ul>
                {data?.map((o) => {
                    return (
                        <li className="my-2 list-outside rounded shadow p-2 py-2 ">
                            <p className="text-md font-bold">
                                <b>{o.formulario_detail_name}</b>
                            </p>
                            <p className="text-md font-italic">
                                {o.formulario_detail_description}
                            </p>
                            {o.formulario_detail_type === "file" &&
                            o.canditate_answer ? (
                                <div>
                                    {o.canditate_answer.split("/")[5] + " > "}
                                    <a
                                        className="mx-2 underline text-blue-400"
                                        href={o.canditate_answer}
                                        target="_blank"
                                    >
                                        Visualizar
                                    </a>
                                </div>
                            ) : (
                                <p className="mx-2">{o.canditate_answer}</p>
                            )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
