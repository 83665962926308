import React, { useEffect } from "react";
import Spinner from "../../components/Spinner";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Filter from "../../components/filter";
import { Edit, XCircle } from "react-feather";
import { useHistory, useRouteMatch } from "react-router";
import useAction from "./useAction";

export default function OfertaAcademica() {
    const { url } = useRouteMatch();
    const history = useHistory();
    const { getAllOferta, ofertas, deleteOferta, loading } = useAction({});

    const columns = [
        {
            label: "Institución",
            name: "institution_id",
            options: {
                display: false,
            },
        },
        {
            label: "Carrera",
            name: "academic_offer_id",
        },
        {
            label: "Sede",
            name: "campus_id",
        },
        {
            label: "Horario",
            name: "schedule_id",
        },
        {
            name: "Acción",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className="flex">
                            <XCircle
                                className="mx-3 text-red-600"
                                onClick={() => {
                                    deleteOferta(value);
                                }}
                            />
                            <Edit
                                className="mx-3 text-yellow-400"
                                onClick={() => {
                                    history.push(`${url}/${value}`);
                                }}
                            />
                        </div>
                    );
                },
            },
        },
    ];

    const getMuiTheme = () =>
        createTheme({
            overrides: {
                MuiTableRow: {
                    root: {
                        cursor: "pointer",
                    },
                },
            },
        });

    const options = {
        enableNestedDataAccess: ".",
        selectableRows: false,
        textLabels: {
            body: {
                noMatch: "Lo sentimos, no se encontraron registros.",
                toolTip: "Clasificar",
                columnHeaderTooltip: (column) =>
                    `clasificar por ${column.label}`,
            },
            pagination: {
                next: "Siguiente página",
                previous: "Página anterior",
                rowsPerPage: "Filas por página:",
                displayRows: "de", // 1-10 of 30
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar como CSV",
                print: "Imprimir",
                viewColumns: "Columnas",
                filterTable: "Filtros",
            },
            filter: {
                title: "FILTROS",
                reset: "reiniciar",
            },
            viewColumns: {
                title: "Mostrar columnas",
            },
            selectedRows: {
                text: "Fila(s) eliminadas",
                delete: "Borrar",
            },
        },
    };

    useEffect(() => {
        getAllOferta();
        return () => {};
    }, []);

    return (
        <>
            <Filter
                type="add"
                hasAuthorized={["institucion"]}
                action={() => history.push(`${url}/new`)}
            />

            <div className="block m-5 pb-8">
                {loading.getinggall ? (
                    <Spinner />
                ) : (
                    <MuiThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            data={ofertas.reduce((acc, curr) => {
                                return [
                                    ...acc,
                                    [
                                        curr?.id,
                                        curr?.academic_offer_name,
                                        curr?.campus_name,
                                        `${curr.schedule.modality} | ${curr.schedule.time} horas a la semana | ${curr.schedule.days}`,
                                        curr?.id,
                                    ],
                                ];
                            }, [])}
                            columns={columns}
                            options={options}
                        />
                    </MuiThemeProvider>
                )}
            </div>
        </>
    );
}
