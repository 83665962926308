import AuthAxios from "../../config/AuthAxios";

export async function updatePassword(setLoading, form) {
    setLoading(true);
    const Axios = AuthAxios();
    try {
        const res = await Axios.post(`/profile/changepassword`, form);
        setLoading(false);
        return res;
    } catch (error) {
        setLoading(false);
        if (error.response) {
            throw error.response.data;
        }

        throw error;
    }
}
