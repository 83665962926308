import { useEffect, useState } from "react";
import { Button } from "../../../components/Button";
import Modal from "../../../components/modal";
import { toast } from "react-toastify";
import { setPaises, setPrivincias } from "../../../Store/slices/paises";
import { Fetch } from "../../../Services/Fetch";
import renderError from "../../../helpers/renderErrors";
import { useDispatch, useSelector } from "react-redux";
import AuthAxios from "../../../config/AuthAxios";

export default function DatosPersonales({ data }) {
    const [loading, setloading] = useState(false);
    const [modalBody, setmodalBody] = useState({});
    const [modal, setmodal] = useState(false);
    const dispatch = useDispatch();
    const { paises } = useSelector((state) => state);

    const idValidator = async (id) => {
        setloading(true);
        try {
            const res = await AuthAxios().get(`/external-api/jce?dni=${id}`);
            if (res.status >= 300) {
                toast.error("No existen datos para esta cédula");
                setloading(false);
                return;
            }

            setmodalBody(res?.data);
            setmodal(true);
        } catch (error) {
            console.log(error);
            renderError(error?.response?.data);
        }
        setloading(false);
    };

    const getProvincia = async (data) => {
        try {
            const res = await Fetch({
                uri: `/provincias/getOne?code=${data}`,
                method: "get",
            });

            dispatch(setPrivincias(res.data.data));
        } catch (error) {}
    };

    const getPais = async (data) => {
        try {
            const res = await Fetch({
                uri: `/paises/getOne?country_id=${data}`,
                method: "get",
            });

            dispatch(setPaises(res.data.data));
        } catch (error) {}
    };

    useEffect(() => {
        data?.country_id === 62
            ? getProvincia(data?.province_id)
            : getPais(data?.country_id);
    }, [data]);

    return (
        <div>
            <Modal
                isVisible={modal}
                action={() => setmodal(false)}
                title={`${modalBody.first_name} ${modalBody.first_last_name} ${modalBody.second_last_name}`}
            >
                <div className="flex">
                    <div className="mx-4">
                        <img src={modalBody.foto} />
                    </div>
                    <div>
                        <div>
                            <p>{modalBody.citizen_id}</p>
                        </div>
                        <div className="my-1 ">
                            <p className="text-xs font-semibold ">
                                LUGAR DE NACIMIENTO
                            </p>
                            <p>{modalBody.birthday_place}</p>
                        </div>
                        <div className="my-1 ">
                            <p className="text-xs font-semibold ">
                                FECHA DE NACIMIENTO
                            </p>
                            <p>{modalBody.birthday}</p>
                        </div>
                        <div className="my-1">
                            <p className="text-xs font-semibold ">
                                NACIONALIDAD
                            </p>
                            <p>
                                {modalBody.Nacionalidad ||
                                    "República Dominicana"}
                            </p>
                        </div>
                        <div className="flex my-1">
                            <p className="text-xs font-semibold ">SEXO:</p>
                            <p className="mx-2 text-xs">
                                {modalBody.gender || ""}
                            </p>
                        </div>
                        <div className="flex my-1">
                            <p className="text-xs font-semibold ">
                                ESTADO CIVIL:
                            </p>
                            <p className="mx-2 text-xs">
                                {modalBody.marital_status || ""}
                            </p>
                        </div>
                    </div>
                </div>
            </Modal>
            <div>
                <form className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <div>
                        <label
                            htmlFor="about"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Nombre
                        </label>
                        <div className="mt-1">
                            <input
                                id="about"
                                name="about"
                                className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                value={data?.name}
                                disabled
                            />
                        </div>
                    </div>
                    <div>
                        <label
                            htmlFor="about"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Apellido
                        </label>
                        <div className="mt-1">
                            <input
                                id="about"
                                name="about"
                                className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                value={data?.last_name}
                                disabled
                            />
                        </div>
                    </div>
                    <div>
                        <label
                            htmlFor="about"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Documento de identidad
                        </label>
                        <div className="flex mt-1">
                            <input
                                id="about"
                                name="about"
                                className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                value={data?.document_id}
                                disabled
                            />
                            &nbsp;
                            <Button
                                className="h-12 mt-1"
                                isLoading={loading}
                                variant="inverse"
                                onClick={() => idValidator(data?.document_id)}
                            >
                                Validar
                            </Button>
                        </div>
                    </div>
                    <div>
                        <label
                            htmlFor="about"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Fecha de nacimiento
                        </label>
                        <div className="mt-1">
                            <input
                                id="about"
                                name="about"
                                className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                value={data?.born_date}
                                disabled
                            />
                        </div>
                    </div>

                    <div>
                        <label
                            htmlFor="about"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Género
                        </label>
                        <div className="mt-1">
                            <input
                                id="about"
                                name="about"
                                className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                value={data?.genero}
                                disabled
                            />
                        </div>
                    </div>
                    <div>
                        <label
                            htmlFor="about"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Correo electrónico Contacto
                        </label>
                        <div className="mt-1">
                            <input
                                id="about"
                                name="about"
                                className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                value={data?.contact_email || ""}
                                disabled
                            />
                        </div>
                    </div>

                    {data?.country_id === 62 ? (
                        <div>
                            <label
                                htmlFor="about"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Provincia
                            </label>
                            <div className="mt-1">
                                <input
                                    id="about"
                                    name="about"
                                    className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                    value={paises?.provincias?.name || ""}
                                    disabled
                                />
                            </div>
                        </div>
                    ) : (
                        <div>
                            <label
                                htmlFor="about"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Pais
                            </label>
                            <div className="mt-1">
                                <input
                                    id="about"
                                    name="about"
                                    className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                    value={paises?.paises?.name || ""}
                                    disabled
                                />
                            </div>
                        </div>
                    )}

                    <div>
                        <label
                            htmlFor="about"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Correo electrónico Registro
                        </label>
                        <div className="mt-1">
                            <input
                                id="about"
                                name="about"
                                className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                value={data?.email_registro || ""}
                                disabled
                            />
                        </div>
                    </div>
                    <div>
                        <label
                            htmlFor="about"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Teléfono móvil
                        </label>
                        <div className="mt-1">
                            <input
                                id="about"
                                name="about"
                                className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                value={data?.contact_phone}
                                disabled
                            />
                        </div>
                    </div>
                    <div>
                        <label
                            htmlFor="about"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Dirección
                        </label>
                        <div className="mt-1">
                            <input
                                id="about"
                                name="about"
                                className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                value={data?.address}
                                disabled
                            />
                        </div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">
                            Cantidad de veces que ha solicitado becas
                            anteriormente
                        </label>
                        <div className="mt-1">
                            <input
                                className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                value={data?.total_aplications}
                                disabled
                            />
                        </div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">
                            Anteriormente becado
                        </label>
                        <div className="mt-1">
                            <input
                                className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                value={
                                    data?.total_aplications_aproved > 0
                                        ? "Si"
                                        : "No"
                                }
                                disabled
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
