import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    paises: [],
    provincias: [],
    municipios:[]
  
};

const paisSlice = createSlice({
    name: "paises",
    initialState,
    reducers: {
        setPaises: (state, { payload }) => {
            state.paises = payload;
        },
        setMunicipio: (state, { payload }) => {
            state.municipios = payload;
        },
        setPrivincias: (state, { payload }) => {
            state.provincias = payload;
        },
    },
});

export const { setPaises,setMunicipio,setPrivincias } = paisSlice.actions;

export default paisSlice.reducer;
