import { Button } from "../../../components/Button";

const InstitutionsCards = ({
    data = [],
    onSolicitudes = () => {},
    statsCheker = () => {},
}) => (
    <>
        <div className="grid grid-cols-2 xl:grid-cols-2 gap-8 p-4">
            {data.map(
                (
                    {
                        name: baseName,
                        institution: { image_url, name, id: inst_id },
                        convocatoria: { name: convoc_name, id: conv_id },
                    },
                    k
                ) => (
                    <div key={k}>
                        <div
                            key={baseName}
                            className="bg-white  max-w-3xl sm:w-full h-auto sm:h-72 rounded-md shadow-lg flex flex-col sm:flex-row gap-5 select-none cursor-pointer"
                        >
                            <img
                                src={image_url}
                                className="h-52 sm:h-full sm:w-2/5 rounded-l-md object-center object-cover"
                            />

                            <div className="flex sm:flex-1 flex-col gap-2 p-1 py-2">
                                <div>
                                    <h3 className="text-gray-400 text-sm font-bold sm:text-base line-clamp-3">
                                        Institución
                                    </h3>
                                    <p className=" text-sm sm:text-base line-clamp-3">
                                        {name ?? ""}
                                    </p>
                                </div>

                                <div>
                                    <h3 className="text-gray-400 text-sm font-bold sm:text-base line-clamp-3">
                                        Convocatoria
                                    </h3>
                                    <p className=" text-sm sm:text-base line-clamp-3">
                                        {convoc_name ?? ""}
                                    </p>
                                </div>

                                <div className="flex pt-2 px-2 flex-col xl:flex-row">
                                    <Button
                                        onClick={() =>
                                            onSolicitudes(conv_id, inst_id)
                                        }
                                    >
                                        Ver Solicitudes
                                    </Button>

                                    <Button
                                        variant="inverse2"
                                        onClick={() =>
                                            statsCheker(
                                                inst_id,
                                                conv_id
                                            )
                                        }
                                    >
                                        Ver Estadisticas
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            )}
        </div>
    </>
);

export { InstitutionsCards };
