import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../components/Spinner";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Filter from "../components/filter";
import {
    crearOfertas,
    getOfertas,
    removeOfertas,
    ActualizarOfertas,
} from "../Store/slices/ofertaInstitucion";
import { File } from "react-feather";
import Modal from "../components/modal";
import { languages } from "../helpers/languages.json";
import { toast } from "react-toastify";
import {
    Form,
    InputField,
    SelectField,
    TextAreaField,
} from "../components/Form";
import { Fetch } from "../Services/Fetch";
import renderErrors from "../helpers/renderErrors";
import { Button } from "../components/Button";

import * as yup from "yup";
import alertify from "alertifyjs";

const schema = yup.object({
    academic_offer_type_id: yup.string().required("Requerido"),
    education_level_id: yup.string().required("Requerido"),
    career: yup.string().required("Requerido"),
    language: yup.string().required("Requerido"),
});

export default function Carreras() {
    const name = "Carrera Academica";
    const dispatch = useDispatch();

    const [modal, setmodal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(true);

    const [academicOffersType, setAcademicOffersType] = useState([]);
    const [educationalLevels, setEducationalLevels] = useState([]);

    const [academicOfferToEdit, setAcademicOfferToEdit] = useState({});

    const { all: data, status } = useSelector(
        (state) => state.ofertasAcademicas
    );

    const getAllAcademicOffers = async () => {
        dispatch(getOfertas());
    };

    const getAllAcademicOffersType = async () => {
        try {
            const res = await Fetch({
                uri: "/ofertas/academicas/tipos/getAll",
                method: "get",
            });
            setAcademicOffersType(res.data?.data);
        } catch (error) {
            renderErrors(error.response?.data);
        }
    };

    const getAllEducationalLevels = async () => {
        try {
            const res = await Fetch({
                uri: "/niveles/educativos/getAll",
                method: "get",
            });
            setEducationalLevels(res.data?.data);
        } catch (error) {
            renderErrors(error.response?.data);
        }
    };

    useEffect(() => {
        document.title = `${name} | ${global.title}`;
        getAllAcademicOffers();
        getAllAcademicOffersType();
        getAllEducationalLevels();
    }, []);

    useEffect(() => {
        modal === false && setAcademicOfferToEdit({});
        modal === true && !academicOfferToEdit.id && setIsEditing(true);
    }, [modal]);

    const onSubmit = async (data) => {
        data.pensum = data.pensum && data.pensum[0];
        let res = {};

        setIsLoading(true);

        if (!academicOfferToEdit.id) {
            res = await dispatch(crearOfertas(data));
        } else {
            let { pensum, ...rest } = data;
            if (pensum) {
                rest = data;
            }
            res = await dispatch(ActualizarOfertas(rest));
        }

        setIsLoading(false);

        if (res.error) {
            renderErrors(res?.payload);
        } else {
            toast.success(
                `Carrera ${
                    !academicOfferToEdit.id ? "creada" : "actualizada"
                } exitosamente`
            );

            setmodal(false);
        }

        getAllAcademicOffers();
    };

    const handleDelete = async (id) => {
        alertify.confirm(
            "Eliminar Carrera",
            `Esta seguro que desea eliminar esta carrera`,
            async function () {
                setIsLoading(true);

                const res = await dispatch(
                    removeOfertas({ academic_offer_id: id })
                );
                setIsLoading(false);

                if (res.error) {
                    renderErrors(res?.payload);
                } else {
                    toast.success("Carrera eliminada exitosamente");
                }
            },
            function () {}
        );
    };

    const handleEdit = (id) => {
        setIsEditing(false);

        const academicOffer = data.find((o) => parseInt(id) === parseInt(o.id));

        setAcademicOfferToEdit({
            ...academicOffer,
            academic_offer_id: academicOffer.id,
            academic_offer_type_id: academicOffer.type.id,
            education_level_id: academicOffer.education_level?.id,
            creditos: academicOffer.creditos || 0,
            esfuerzo: academicOffer.esfuerzo || 0,
            costo: academicOffer.costo || 0,
        });

        setmodal(true);
    };

    const columns = [
        {
            name: "id",
            options: {
                display: false,
            },
        },
        {
            label: "Carrera",
            name: "career",
        },

        {
            label: "Nivel",
            name: "type.name",
        },
        {
            label: "Área",
            name: "education_level.development_area.name",
        },
        {
            label: "Idioma",
            name: "language",
        },
        {
            label: "Duración",
            name: "duration",
        },
        {
            label: "Créditos",
            name: "creditos",
        },
        {
            label: "Nivel de esfuerzo",
            name: "esfuerzo",
        },
        {
            label: "Pénsum",
            name: "pensum_url",
            options: {
                customBodyRender: (value) =>
                    value && (
                        <a href={value} target="_blank" className="flex">
                            <File />
                            Descargar
                        </a>
                    ),
            },
        },
        {
            label: "Costo",
            name: "costo",
        },
    ];
    const getMuiTheme = () =>
        createTheme({
            overrides: {
                MuiTableRow: {
                    root: {
                        cursor: "pointer",
                    },
                },
            },
        });
    const options = {
        enableNestedDataAccess: ".",
        selectableRows: false,
        onRowClick: (rowData) => {
            handleEdit(rowData[0]);
        },
        textLabels: {
            body: {
                noMatch: "Lo sentimos, no se encontraron registros.",
                toolTip: "Clasificar",
                columnHeaderTooltip: (column) =>
                    `clasificar por ${column.label}`,
            },
            pagination: {
                next: "Siguiente página",
                previous: "Página anterior",
                rowsPerPage: "Filas por página:",
                displayRows: "de", // 1-10 of 30
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar como CSV",
                print: "Imprimir",
                viewColumns: "Columnas",
                filterTable: "Filtros",
            },
            filter: {
                title: "FILTROS",
                reset: "reiniciar",
            },
            viewColumns: {
                title: "Mostrar columnas",
            },
            selectedRows: {
                text: "Fila(s) eliminadas",
                delete: "Borrar",
            },
        },
    };

    return (
        <>
            <Modal
                action={() => setmodal(false)}
                isVisible={modal}
                title={`${
                    !academicOfferToEdit.id ? "Agregar una nueva" : "Actualizar"
                } carrera`}
            >
                <Form
                    onSubmit={onSubmit}
                    options={{
                        defaultValues: {
                            ...academicOfferToEdit,
                        },
                    }}
                    schema={schema}
                >
                    {({ register, formState, setValue }) => (
                        <>
                            <div className="grid gap-4">
                                <SelectField
                                    label="Nivel"
                                    disabled={!isEditing}
                                    registration={register(
                                        "academic_offer_type_id"
                                    )}
                                    error={
                                        formState.errors?.academic_offer_type_id
                                    }
                                    options={academicOffersType?.map(
                                        (offerType) => ({
                                            label: offerType.name,
                                            value: offerType.id,
                                        })
                                    )}
                                />

                                <SelectField
                                    label="Área de desarrollo"
                                    disabled={!isEditing}
                                    registration={register(
                                        "education_level_id"
                                    )}
                                    error={formState.errors?.education_level_id}
                                    options={educationalLevels.map(
                                        (educationalLevel) => ({
                                            label: educationalLevel.name,
                                            value: educationalLevel.id,
                                        })
                                    )}
                                />

                                <InputField
                                    label="Nombre de la carrera"
                                    disabled={!isEditing}
                                    placeholder="Abogado"
                                    error={formState.errors?.career}
                                    registration={register("career")}
                                />

                                <div className="grid grid-cols-5">
                                    <div className="col-span-3">
                                        <InputField
                                            label="Duración"
                                            type="number"
                                            disabled={!isEditing}
                                            placeholder=""
                                            error={formState.errors?.duration}
                                            registration={register("duration")}
                                        />
                                    </div>
                                    <div className="col-span-2 flex justify-end	flex-col">
                                        <InputField
                                            disabled={true}
                                            disabled={!isEditing}
                                            placeholder=""
                                            value="Meses"
                                        />
                                        {/* <SelectField
                                            className="col-span-1"
                                            disabled={!isEditing}
                                            options={[
                                                { label: "Cuatrimestre", value: "Cuatrimestre" },
                                                { label: "Trimestre", value: "Trimestre" },
                                                { label: "Semestre", value: "Semestre" },
                                                { label: "Años", value: "Año" },
                                                { label: "Mes", value: "Mes" },
                                            ]}
                                        /> */}
                                    </div>
                                </div>
                                <SelectField
                                    label="Idioma"
                                    disabled={!isEditing}
                                    error={formState.errors?.language}
                                    registration={register("language")}
                                    options={languages.map((lang) => ({
                                        label: lang.name,
                                        value: lang.name,
                                    }))}
                                />

                                <InputField
                                    type="file"
                                    disabled={!isEditing}
                                    label="Pénsum"
                                    error={formState.errors?.pensum}
                                    setValue={setValue}
                                    name="pensum"
                                />

                                <InputField
                                    type="number"
                                    disabled={!isEditing}
                                    label="Créditos"
                                    placeholder="20"
                                    error={formState.errors?.creditos}
                                    registration={register("creditos")}
                                />

                                <div className="grid grid-cols-5">
                                    <div className="col-span-2 flex justify-end	flex-col">
                                        <InputField
                                            disabled={true}
                                            disabled={!isEditing}
                                            placeholder=""
                                            value="RD$"
                                        />
                                        {/* <SelectField
                                            className="col-span-1"
                                            disabled={!isEditing}
                                            options={[
                                                { label: "Cuatrimestre", value: "Cuatrimestre" },
                                                { label: "Trimestre", value: "Trimestre" },
                                                { label: "Semestre", value: "Semestre" },
                                                { label: "Años", value: "Año" },
                                                { label: "Mes", value: "Mes" },
                                            ]}
                                        /> */}
                                    </div>
                                    <div className="col-span-3">
                                        <InputField
                                            label="Costo"
                                            type="number"
                                            disabled={!isEditing}
                                            placeholder=""
                                            error={formState.errors?.duration}
                                            registration={register("costo")}
                                        />
                                    </div>
                                </div>

                                <div className="grid grid-cols-5">
                                    <div className="col-span-3">
                                        <InputField
                                            label="Esfuerzo"
                                            type="number"
                                            disabled={!isEditing}
                                            placeholder=""
                                            error={formState.errors?.duration}
                                            registration={register("esfuerzo")}
                                        />
                                    </div>
                                    <div className="col-span-2 flex justify-end	flex-col">
                                        <InputField
                                            disabled={true}
                                            disabled={!isEditing}
                                            placeholder=""
                                            value="h/sem"
                                        />
                                        {/* <SelectField
                                            className="col-span-1"
                                            disabled={!isEditing}
                                            options={[
                                                { label: "Cuatrimestre", value: "Cuatrimestre" },
                                                { label: "Trimestre", value: "Trimestre" },
                                                { label: "Semestre", value: "Semestre" },
                                                { label: "Años", value: "Año" },
                                                { label: "Mes", value: "Mes" },
                                            ]}
                                        /> */}
                                    </div>
                                </div>

                                <TextAreaField
                                    label="Detalles"
                                    disabled={!isEditing}
                                    rows="3"
                                    error={formState.errors?.detalles}
                                    registration={register(`detalles`)}
                                />
                            </div>
                            <div className="flex justify-between">
                                {academicOfferToEdit.id ? (
                                    <Button
                                        isLoading={isLoading}
                                        type="button"
                                        onClick={() =>
                                            handleDelete(academicOfferToEdit.id)
                                        }
                                        variant="dangerInversed"
                                    >
                                        Eliminar
                                    </Button>
                                ) : (
                                    <div />
                                )}

                                {!academicOfferToEdit.id && (
                                    <Button isLoading={isLoading} type="submit">
                                        Crear
                                    </Button>
                                )}

                                {academicOfferToEdit.id && !isEditing && (
                                    <Button
                                        type="button"
                                        onClick={() => setIsEditing(true)}
                                        variant="inverse"
                                    >
                                        Actualizar
                                    </Button>
                                )}

                                {academicOfferToEdit.id && isEditing && (
                                    <Button isLoading={isLoading} type="submit">
                                        Guardar
                                    </Button>
                                )}
                            </div>
                        </>
                    )}
                </Form>
            </Modal>

            {/* <SubHeader title="Ofertas Academicas" /> */}
            <Filter
                type="add"
                hasAuthorized={["institucion"]}
                action={() => setmodal(true)}
            />

            <div className="block m-5 pb-8">
                {status === "isLoading" ? (
                    <Spinner />
                ) : (
                    <MuiThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            data={data}
                            columns={columns}
                            options={options}
                        />
                    </MuiThemeProvider>
                )}
            </div>
        </>
    );
}
