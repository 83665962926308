export default function Perfil({ data }) {
    return (
        <form className="grid grid-cols-2 gap-3">
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Nombre
                </label>
                <div className="mt-1">
                    <input
                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                        value={data?.name}
                        disabled
                    />
                </div>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Tipo
                </label>
                <div className="mt-1">
                    <input
                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                        value={data?.type}
                        disabled
                    />
                </div>
            </div>

            <div className="col-span-2 my-4 border-b-2">
                Datos de la persona de contacto
            </div>

            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Nombre
                </label>
                <div className="mt-1">
                    <input
                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                        value={data?.contact_person}
                        disabled
                    />
                </div>
            </div>

            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Correo electrónico
                </label>
                <div className="mt-1">
                    <input
                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                        value={data?.contact_email}
                        disabled
                    />
                </div>
            </div>

            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Teléfono
                </label>
                <div className="mt-1">
                    <input
                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                        value={data?.contact_number}
                        disabled
                    />
                </div>
            </div>

            <div>
                <label className="block text-sm font-medium text-gray-700">
                    Cédula
                </label>
                <div className="mt-1">
                    <input
                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                        value={data?.document_id}
                        disabled
                    />
                </div>
            </div>
        </form>
    );
}
