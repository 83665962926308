import AuthAxios from "../config/AuthAxios";

export async function Fetch({
    data = {},
    uri = "",
    method = "post",
    toForm = true,
}) {
    if (!uri) {
        console.error("No se espesifico la direccion para el request");
        return;
    }

    let form = data;

    if (toForm) {
        form = new FormData();
        Object.entries(data).forEach((obj) => {
            form.append(obj[0], obj[1]);
        });
    }

    const Axios = AuthAxios();
    try {
        if (Object.keys(data)) return Axios[method](uri, form);
        let cachedRes = Axios[method](uri);

        return cachedRes;
    } catch (err) {
        return err;
    }
}
