import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "../../components/Button";
import { Form, InputField } from "../../components/Form";
import { setUser, updateProfile } from "../../Store/slices/user";
import renderErrors from "../../helpers/renderErrors";

import * as yup from "yup";

const schema = yup
    .object({
        name: yup.string().required("Requerido"),
        contact_phone: yup.string().required("Requerido"),
        contact_email: yup
            .string()
            .email("Email invalido")
            .required("Requerido"),
    })
    .required();

export default function ChangePerfil({ data, user }) {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const onSubmit = async (data) => {
        try {
            if (data.image?.length > 0) {
                data.image = data.image && data.image[0];
            } else {
                delete data.image;
            }

            setIsLoading(true);
            const res = await dispatch(updateProfile(data));

            await sessionStorage.setItem(
                "user",
                JSON.stringify({ ...user, user: res.payload.data.data })
            );

            await dispatch(setUser({ ...user, user: res.payload.data.data }));
            setIsLoading(false);
            toast.success("Perfil actualizado correctamente");
        } catch (error) {
            setIsLoading(false);
            renderErrors(error);
        }
    };

    return (
        <Form
            onSubmit={onSubmit}
            options={{
                defaultValues: {
                    ...data,
                },
            }}
            schema={schema}
        >
            {({ register, formState, setValue, watch }) => (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <InputField
                            label="Nombre completo"
                            error={formState.errors.name}
                            registration={register("name")}
                        />
                        <InputField
                            type="phone"
                            label="Teléfono"
                            value={data.contact_phone}
                            error={formState.errors.contact_phone}
                            name="contact_phone"
                            onChange={setValue}
                        />
                        <InputField
                            type="email"
                            label="Correo electrónico de contacto"
                            error={formState.errors.contact_email}
                            registration={register("contact_email")}
                        />

                        <InputField
                            type="file"
                            label="Imagen de perfil"
                            accept="image/*"
                            error={formState.errors.image}
                            watch={watch("image")}
                            setValue={setValue}
                            name="image"
                        />
                    </div>
                    <div className="flex justify-end">
                        <Button
                            isLoading={isLoading}
                            type="submit"
                            variant="primary"
                        >
                            Actualizar perfil
                        </Button>
                    </div>
                </>
            )}
        </Form>
    );
}
