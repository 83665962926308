import React from "react";
import Spinner from "../../components/Spinner";
import MUIDataTable from "mui-datatables";
import useAction from "./useAction";
import Filter from "../../components/filter";
import { useHistory, useRouteMatch } from "react-router";
import { Button } from "../../components/Button";

export default function UsersAll() {
    const { users, isLoading, resetPassword, isLoadingreset } = useAction();
    const history = useHistory();
    const { url } = useRouteMatch();

    const columns = [
        {
            name: "id",
            options: {
                display: false,
            },
        },
        {
            label: "Correo electrónico",
            name: "email",
        },
        {
            label: "Nombre",
            name: "name",
        },
        {
            label: "Rol",
            name: "role.name",
        },
        // {
        //     label: "Recetiar Contraseña",
        //     name: "id",
        //     sortable: true,
        //     right: true,
        //     options: {
        //         customBodyRender: (value) =>
        //             value && (
        //                 <Button
        //                     isLoading={isLoadingreset === value}
        //                     onClick={() => {
        //                         resetPassword(value);
        //                     }}
        //                 >
        //                     Resetiar
        //                 </Button>
        //             ),
        //     },
        // },
    ];

    const options = {
        enableNestedDataAccess: ".",
        selectableRows: false,
        onRowClick: (rowData) => {
            history.push(`${url}/${rowData[0]}`);
        },
        textLabels: {
            body: {
                noMatch: "Lo sentimos, no se encontraron registros.",
                toolTip: "Clasificar",
                columnHeaderTooltip: (column) =>
                    `clasificar por ${column.label}`,
            },
            pagination: {
                next: "Siguiente página",
                previous: "Página anterior",
                rowsPerPage: "Filas por página:",
                displayRows: "de", // 1-10 of 30
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar como CSV",
                print: "Imprimir",
                viewColumns: "Columnas",
                filterTable: "Filtros",
            },
            filter: {
                title: "FILTROS",
                reset: "reiniciar",
            },
            viewColumns: {
                title: "Mostrar columnas",
            },
            selectedRows: {
                text: "Fila(s) eliminadas",
                delete: "Borrar",
            },
        },
    };

    return (
        <>
            <div className="flex-cols justify-center pb-8 w-full mt-5">
                <Filter
                    type="add"
                    action={() => history.push(`${url}/new`)}
                    hasAuthorized={["admin"]}
                />
                {isLoading && <Spinner />}

                <div className="px-8">
                    <MUIDataTable
                        title="Usuarios"
                        data={users}
                        columns={columns}
                        options={options}
                    />
                </div>
            </div>
        </>
    );
}
