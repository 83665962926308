import clsx from "clsx";
import { FieldWrapper } from "./FieldWrapper";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import es from "react-phone-number-input/locale/es.json";

export const InputField = ({
    type = "text",
    label,
    accept,
    placeholder,
    wrapperClassName,
    className,
    registration,
    name,
    error,
    onChange,
    min,
    max,
    steps,
    value,
    disabled,
    setValue,
    watch,
    onBlur,
}) => {
    const handleAction = (event) => {
        if (name === "image" || type === "file") {
            setValue(name, event.target.files);
        } else {
            onChange && onChange(event);
        }
    };

    return (
        <FieldWrapper
            label={label}
            error={error}
            className={wrapperClassName}
            type={type}
            accept={accept}
            watch={watch}
            onChange={onChange}
            onBlur={onBlur}
            setValue={setValue}
            name={name}
        >
            {type === "phone" ? (
                <PhoneInput
                    placeholder="Introduzca número de teléfono"
                    labels={es}
                    value={value}
                    onChange={(event) => onChange && onChange(name, event)}
                    numberInputProps={{
                        className:
                            "appearance-none block w-full px-2 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 disabled:bg-gray-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm",
                    }}
                />
            ) : (
                <input
                    type={type}
                    value={value}
                    accept={accept}
                    disabled={disabled}
                    placeholder={placeholder}
                    className={clsx(
                        "appearance-none block w-full px-2 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 disabled:bg-gray-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm",
                        `${
                            type === "file" &&
                            accept?.includes("image") &&
                            "hidden"
                        }`,
                        `${type === "file" && "py-0"}`,
                        className
                    )}
                    onChange={handleAction}
                    max={max}
                    min={min}
                    step={steps}
                    {...registration}
                />
            )}
        </FieldWrapper>
    );
};
