import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import generateActions from '../generateActions'
import { Fetch } from '../../../Services/Fetch'

const name = 'init'

const initialState = {
  all: {},
}

export const setInit = createAsyncThunk(
  `${name}/getall`,
  async (data) =>
    await Fetch({ data, uri: '/parametros/getAll', method: 'get' }),
)

export const reducers = generateActions([{ action: setInit, stateProp: 'all' }])

const initSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: reducers,
})

export default initSlice.reducer
