import { useEffect, useState } from "react";
import NewModal from "../../../components/modal";
import { Fetch } from "../../../Services/Fetch";
import { Button } from "../../../components/Button";
import { toast, ToastContainer } from "react-toastify";

export default function DocumentViewer({ aplication_id = "0000" }) {
    // State
    const [actualData, setActualData] = useState([]);
    const [uploadingFile, setUploadingFile] = useState(null);
    const [modalUp, setModalUp] = useState(false);
    const [uploadingComment, setUploadingComment] = useState("");

    // Functions
    async function callDocumentsFromAPI(id) {
        Fetch({
            uri: `/documentos/byAplication?aplication_id=${id}`,
            data: { aplication_id },
        }).then(({ data: { data } }) => setActualData(data ?? []));
    }

    async function addDocument() {
        if (uploadingComment.length < 3 || uploadingFile === null) {
            alert("Por favor, justifique la subida del siguien documento!");
        } else {
            Fetch({
                uri: "solicitudes/add_document",
                data: {
                    solicitud: aplication_id,
                    documento: uploadingFile,
                    notas: uploadingComment,
                },
            })
                .catch(
                    ({
                        response: {
                            data: { message },
                        },
                    }) => alert(message)
                )
                .then((_) => {
                    callDocumentsFromAPI(aplication_id);
                    killModal();
                });
        }
    }

    function killModal() {
        setModalUp(!modalUp);
    }

    // Effects
    useEffect(() => callDocumentsFromAPI(aplication_id), []);

    return (
        <div>
            <NewModal
                title="Subir Archivo"
                isVisible={modalUp}
                action={killModal}
            >
                <div>
                    <div className="mb-3 w-96">
                        <input
                            className="form-control block w-full px-3 text-base
                            font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300
                            rounded transition ease-in-out m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            type="file"
                            accept="application/pdf"
                            onChange={({ target: { files } }) =>
                                setUploadingFile(files[0])
                            }
                        />
                        <div className="pt-2">
                            <label className="form-label inline-block mb-2 text-gray-700">
                                Comentatio :
                            </label>
                            <textarea
                                className=" form-control block w-full px-3 py-1.5 text-base
                                font-normal text-gray-700 bg-white bg-clip-padding
                                border border-solid border-gray-300 rounded
                                transition ease-in-out m-0
                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                onBlur={({ target: { value } }) =>
                                    setUploadingComment(value)
                                }
                                rows="5"
                                placeholder="Comentario....."
                            />

                            <div className="py-2 flex justify-end">
                                <Button onClick={addDocument}>Cargar</Button>
                                <Button variant="danger" onClick={killModal}>
                                    Cancelar
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </NewModal>

            <div className="overflow-x-auto">
                <table className="table-auto w-full">
                    <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                        <tr>
                            {[
                                "Nombre",
                                "Extension",
                                "Peso",
                                "Comentarios",
                                "Acciones",
                            ].map((x) => (
                                <th className="p-2 whitespace-nowrap">
                                    <div className="font-semibold text-left">
                                        {x}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>

                    <tbody className="text-sm divide-y divide-gray-100">
                        {actualData.map(
                            ({ name, size, notes, extension, url }) =>
                                name !== null && (
                                    <tr>
                                        <td className="p-2 whitespace-nowrap">
                                            <div className="flex items-center">
                                                <div className="font-medium text-gray-800">
                                                    {name.split(".")[0]}
                                                </div>
                                            </div>
                                        </td>

                                        <td className="p-2 whitespace-nowrap">
                                            <div className="text-lg text-center">
                                                {extension.toUpperCase()}
                                            </div>
                                        </td>
                                        <td className="p-2 whitespace-nowrap">
                                            <div className="text-center">{`${
                                                (size / 1000) | 0
                                            }KB`}</div>
                                        </td>
                                        <td className="p-2 whitespace-nowrap">
                                            <div className="text-left font-medium text-green-500">
                                                {notes ?? "N/A"}
                                            </div>
                                        </td>

                                        <td className="p-2 whitespace-nowrap">
                                            <div className="text-lg text-center">
                                                <button
                                                    onClick={() =>
                                                        window.open(url)
                                                    }
                                                    className="bg-sky-600 hover:bg-sky-700"
                                                >
                                                    Ver
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                        )}
                    </tbody>
                </table>
            </div>
            <div className="font-medium text-gray-800 text-center">
                <Button onClick={killModal}>Añadir Documento</Button>
            </div>
        </div>
    );
}
