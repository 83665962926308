import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import { getAllNotifications } from "../../Services/Notifications";

export default function Notifications() {
    const [notifications, setnotifications] = useState([]);

    // async function getAllUnReads() {
    //     const res = await getAllNotifications();

    //     if (res.length > 0) {
    //         const unReads = res?.filter((noti) => noti?.read === false);
    //         setnotifications(unReads);
    //     } else {
    //         setTimeout(() => {
    //             getAllUnReads();
    //         }, 4000);
    //     }
    // }

    const getAllUnReads = async () => {
        const res = await getAllNotifications();
        const unReads = res.filter((noti) => noti.read === false);

        setnotifications(unReads);
    };

    useEffect(() => {
        getAllUnReads();
    }, []);

    return (
        <>
            <Menu as="div" className="relative inline-block text-left ">
                <Menu.Button className="relative cursor-pointer group">
                    <Icon.Bell className="text-sm" />
                    <div className="badge">
                        <span className="block w-full text-xs">
                            {notifications.length}
                        </span>
                    </div>
                </Menu.Button>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="origin-top-right md:absolute fixed md:right-0 right-4 mt-2 w-80 max-h-96 md:w-96 overflow-y-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {notifications?.slice(0, 3).map((noti) => (
                            <Link key={noti.id} to="/perfil/notificaciones">
                                <Menu.Item>
                                    {({ active }) => (
                                        <div
                                            className={`${
                                                active && "bg-gray-200"
                                            }
                                      border-b-2 text-sm p-4 cursor-pointer
                                       `}
                                        >
                                            <div className="">
                                                <div className="flex items-center justify-between pb-2">
                                                    <div className="font-semibold ">
                                                        {noti.nombre}
                                                    </div>
                                                </div>

                                                <div className="text-gray-500">
                                                    {noti.descripcion}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Menu.Item>
                            </Link>
                        ))}
                        <Link to="/perfil/notificaciones">
                            <Menu.Item>
                                {({ active }) => (
                                    <div
                                        className={`${active && "bg-gray-200"}
                                      border-b-2 text-sm p-4 cursor-pointer 
                                       `}
                                    >
                                        <div className="flex items-center justify-center font-semibold">
                                            Ver más
                                        </div>
                                    </div>
                                )}
                            </Menu.Item>
                        </Link>
                    </Menu.Items>
                </Transition>
            </Menu>
        </>
    );
}
