import Spinner from "../../components/Spinner";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

export default function Convocatorias({ data = [], name = "niguno" }) {
    data = data.reduce((acc, ob) => {
        const obj = JSON.parse(JSON.stringify(ob));
        ob && (obj.type = ob.type.name);
        ob && (obj.evaluation = ob.evaluation.name);
        ob && (obj.audience = ob.audience.name);
        ob && (obj.coordinator = name);
        return acc.concat(obj);
    }, []);

    const columns = [
        {
            label: "Imagen",
            name: "image_url",
            options: {
                customBodyRender: (url) => (
                    <img
                        src={url}
                        alt="img perfil de convocatoria"
                        className="w-16"
                    />
                ),
            },
        },
        {
            label: "Nombre",
            name: "name",
        },
        {
            label: "Tipo",
            name: "type",
        },
        {
            label: "Audiencia",
            name: "audience",
        },
        {
            label: "Coordinador",
            name: "coordinator",
        },
        {
            label: "Estado",
            name: "status",
        },
    ];

    const getMuiTheme = () =>
        createTheme({
            overrides: {
                MuiTableRow: {
                    root: {
                        cursor: "pointer",
                    },
                },
            },
        });

    const options = {
        enableNestedDataAccess: ".",
        selectableRows: false,
        textLabels: {
            body: {
                noMatch: "Lo sentimos, no se encontraron registros.",
                toolTip: "Clasificar",
                columnHeaderTooltip: (column) =>
                    `clasificar por ${column.label}`,
            },
            pagination: {
                next: "Siguiente página",
                previous: "Página anterior",
                rowsPerPage: "Filas por página:",
                displayRows: "de", // 1-10 of 30
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar como CSV",
                print: "Imprimir",
                viewColumns: "Columnas",
                filterTable: "Filtros",
            },
            filter: {
                title: "FILTROS",
                reset: "reiniciar",
            },
            viewColumns: {
                title: "Mostrar columnas",
            },
            selectedRows: {
                text: "Fila(s) eliminadas",
                delete: "Borrar",
            },
        },
    };

    return (
        <>
            <div className="block m-5 pb-8">
                {!data.length > 0 ? (
                    <Spinner />
                ) : (
                    <MuiThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            title="Convocatorias"
                            data={data}
                            columns={columns}
                            options={options}
                        />
                    </MuiThemeProvider>
                )}
            </div>
        </>
    );
}
