import Loader from "react-loader-spinner";

export default function Spinner() {
    return (
        <div className="flex justify-center items-center h-1/2">
            <Loader
                type="MutatingDots"
                color="#ee2a24"
                secondaryColor="#003876"
                size={90}
            />
        </div>
    );
}
