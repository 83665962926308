import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Icon from "react-feather";
import { Switch, Route, Redirect, useParams } from "react-router-dom";
import Tabnavigator from "../../components/navigation/tabnavigator";
import Becados from "./Becados";
import Perfil from "./Perfil";
import OfertaCademica from "./OfertaCademica";
import Convocatorias from "./Convocatorias";
import { getOne } from "../../Store/slices/instituciones";
import Spinner from "../../components/Spinner";
import {
    getFilteredBecados,
    getFilteredBecadosByLink,
} from "../../Store/slices/becados";
import noimage from "../../images/no_image_available.png";
import { replaceHttpToHttps } from "../../utils/http";

export default function DetallesInstitucion() {
    // Hooks
    const dispatch = useDispatch();
    const { id } = useParams();

    // Global State callers
    const institucion = useSelector((state) => state.instituciones.one);
    const { status } = useSelector((state) => state.instituciones);
    const becados = useSelector((state) => state.becados);

    // Functions
    function paginateBecados(str) {
        dispatch(
            getFilteredBecadosByLink({
                link: replaceHttpToHttps(str + `&convocatoria=${id}`),
            })
        );
    }

    // Effects-Calling
    useEffect(() => {
        dispatch(getOne({ institution_id: id }));
        dispatch(getFilteredBecados({ id }));
    }, []);

    return (
        <div className="p-4 pb-8">
            <div className="grid grid-cols-12 mb-[22px] bg-white items-center relative">
                <div className="col-span-12 sm:col-span-4 md:col-span-5 grid md:flex justify-center items-center gap-4 p-1 border-solid border-[#F0F0F0] border-r-[1px] ">
                    <div className="object-cover justify-self-center">
                        <img
                            src={institucion?.image_url || noimage}
                            alt="Perfil"
                            className="max-h-[150px] "
                        />
                    </div>

                    <div className="grid items-center justify-center ">
                        <h3 className="w-full text-2xl text-[#231F20] mb-[2px]">{`${institucion?.name} `}</h3>
                        <h4 className="w-full text-xl text-[#AAAAAA] text-center md:text-left">
                            {institucion?.type?.name}
                        </h4>
                        <div className="mt-[16px] flex justify-around ">
                            <div className="flex flex-col items-center">
                                <Icon.TrendingUp
                                    className="text-[#ED232A]"
                                    size={20}
                                />
                                <div className="text-xs mt-1">
                                    {institucion?.estadisticas?.convocatorias}
                                </div>
                            </div>
                            <div className="flex flex-col items-center">
                                <Icon.User
                                    className="text-[#7BB31A]"
                                    size={20}
                                />
                                <div className="text-xs mt-1">
                                    {institucion?.estadisticas?.becados}
                                </div>
                            </div>
                            <div className="flex flex-col items-center">
                                <Icon.Book
                                    className="text-[#00A2FF]"
                                    size={20}
                                />
                                <span className="text-xs mt-1">
                                    {institucion?.estadisticas?.ofertas}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-span-12 sm:col-span-8 md:col-span-7 grid grid-cols-1 md:grid-cols-2 gap-4 items-center justify-between p-4 ">
                    <span className="flex items-center gap-2">
                        <Icon.User size={16} />
                        {institucion?.contacto_persona}
                    </span>
                    <span className="flex items-center gap-2">
                        <Icon.Phone size={16} />
                        {institucion?.contacto_telefono}
                    </span>
                    <span className="flex items-center gap-2">
                        <Icon.Mail size={16} />
                        {institucion?.contacto_email}
                    </span>

                    <span className="flex items-center gap-2">
                        <Icon.Globe size={16} />
                        {institucion?.web}
                    </span>
                    <span className="flex items-center gap-2">
                        <Icon.Map size={16} />
                        {institucion?.direccion}
                    </span>
                </div>
            </div>

            <Tabnavigator
                routes={[
                    {
                        path: `/instituciones/detalles/${id}/_convocatoria`,
                        name: "Convocatorias",
                    },
                    {
                        path: `/instituciones/detalles/${id}/_becados`,
                        name: "Becados",
                    },
                    {
                        path: `/instituciones/detalles/${id}/ofertas_academicas`,
                        name: "Ofertas Académicas",
                    },
                    {
                        path: `/instituciones/detalles/${id}/perfil`,
                        name: "Perfil",
                    },
                ]}
            />
            <div className="w-full h-auto shadow-lg bg-white p-[30px]">
                <div>
                    <Switch>
                        <Route
                            exact
                            path="/instituciones/detalles/:id/_convocatoria"
                        >
                            {status === "loading" ? (
                                <Spinner />
                            ) : (
                                <Convocatorias
                                    data={institucion?.convocatorias}
                                />
                            )}
                        </Route>
                        <Route
                            exact
                            path="/instituciones/detalles/:id/_becados"
                        >
                            {becados.status === "loading" ? (
                                <Spinner />
                            ) : (
                                <Becados
                                    paginationData={becados.status?.data ?? {}}
                                    action={paginateBecados}
                                />
                            )}
                        </Route>
                        <Route
                            exact
                            path="/instituciones/detalles/:id/ofertas_academicas"
                        >
                            {status === "loading" ? (
                                <Spinner />
                            ) : (
                                <OfertaCademica data={institucion?.ofertas} />
                            )}
                        </Route>
                        <Route exact path="/instituciones/detalles/:id/perfil">
                            <Perfil data={institucion} />
                        </Route>
                        <Redirect
                            to={`/instituciones/detalles/${id}/_convocatoria`}
                        />
                    </Switch>
                </div>
            </div>
        </div>
    );
}
