import React from "react";
import Authorized from "../helpers/authorized";
import * as Icon from "react-feather";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "./Button";
import { InputField, SelectField } from "./Form";

export default function Filter(props) {
    const init = useSelector((state) => state.init.all);

    const years = [];
    for (let year = new Date().getFullYear() + 1; year > 1970; year--) {
        years.push(year);
    }

    return (
        <>
            <div className="py-8 px-5 md:flex md:justify-between items-center gap-4">
                {props.textfilter && (
                    <div className="flex md:items-center w-1/2">
                        <div className="flex text-base pr-4">Filtrar:</div>

                        <InputField
                            className={"w-full"}
                            onChange={({ target }) =>
                                props.textfilter(target.value)
                            }
                        />
                    </div>
                )}

                {props.filter ? (
                    <div className="md:flex md:items-center">
                        <div className="flex text-base pr-4">Filtrar:</div>

                        {props.textfilter && (
                            <InputField
                                onChange={({ target }) =>
                                    props.textfilter(target.value)
                                }
                            />
                        )}

                        <div className="filter grid grid-cols-1 md:grid-cols-3 items-center gap-4">
                            <select
                                onChange={(e) => props.onFiltering(e, "year")}
                                defaultValue={0}
                            >
                                <option value={0}>Año</option>
                                {years?.map((obj, i) => (
                                    <option key={i} value={obj}>
                                        {obj}
                                    </option>
                                ))}
                            </select>

                            {/* <SelectField
                                onChange={(e) => props.onFiltering(e, "type")}
                                options={init.tipos_convocatorias?.map(
                                    (obj, i) => ({
                                        label: obj.name,
                                        value: obj.name,
                                    })
                                )}
                            /> */}

                            <select
                                onChange={(e) => props.onFiltering(e, "type")}
                            >
                                <option value={0}>Tipo</option>
                                {init.tipos_convocatorias?.map((obj, i) => (
                                    <option key={i} value={obj.name}>
                                        {obj.name}
                                    </option>
                                ))}
                            </select>

                            <select
                                onChange={(e) => props.onFiltering(e, "level")}
                            >
                                <option value={0}>Nivel</option>
                                {init.audiencias?.map((obj, i) => (
                                    <option key={i} value={obj.name}>
                                        {obj.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                ) : (
                    <div></div>
                )}

                <div className="pt-4 md:pt-0">
                    {props?.type === "general" && (
                        <div className="align-self-end flex">
                            <button
                                onClick={props.getStatistics}
                                className="h-[50px] w-[50px] text-[#AAAAAA] hover:text-gray-800 flex justify-center items-center focus:outline-none  border-solid border-[#DDDFE1] border-[1px] hover:bg-white text-base rounded-lg"
                            >
                                <Icon.RefreshCw size={18} />
                            </button>
                            <button
                                onClick={props.generatePDF}
                                className="h-[50px] w-[50px] mx-2 text-[#AAAAAA] hover:text-gray-800 flex justify-center items-center focus:outline-none  border-solid border-[#DDDFE1] border-[1px] hover:bg-white text-base rounded-lg"
                            >
                                <Icon.Printer size={18} />
                            </button>
                            {/* <button className="h-[50px] w-[50px] text-[#AAAAAA] hover:text-gray-900 flex justify-center items-center focus:outline-none  border-solid border-[#DDDFE1] border-[1px] hover:bg-white text-base rounded-lg">
                                <Icon.Download size={18} />
                            </button> */}
                        </div>
                    )}

                    {props?.type === "download" && (
                        <div className="align-self-end">
                            <Button className="w-full">Descargar</Button>
                        </div>
                    )}

                    {props?.type === "create" && (
                        <Authorized hasAuthorized={props.hasAuthorized || []}>
                            <div className="align-self-end">
                                <Link to={props.path}>
                                    <Button className="w-full">Crear</Button>
                                </Link>
                            </div>
                        </Authorized>
                    )}

                    {props?.type === "add" && (
                        <Authorized hasAuthorized={props.hasAuthorized || []}>
                            <div
                                onClick={props.action}
                                className="align-self-end"
                            >
                                <Button className="w-full">Agregar</Button>
                            </div>
                        </Authorized>
                    )}
                </div>
            </div>
        </>
    );
}
