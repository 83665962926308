import { Avatar } from "@material-ui/core";

import MUIDataTable from "mui-datatables";
export default function Convocatorias({ data }) {
    const columns = [
        {
            label: "Image",
            name: "image_url",
            options: {
                customBodyRender: (img) => {
                    return <Avatar variant="rounded" src={img}></Avatar>;
                },
            },
        },
        {
            label: "Nombre",
            name: "name",
        },
        {
            label: "Tipo",
            name: "type.name",
        },
        {
            label: "Audiencia",
            name: "audience.name",
        },
        {
            label: "Estado",
            name: "status",
        },
    ];

    const options = {
        selectableRows: false,
        search: false,
        toolbar: false,
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
        enableNestedDataAccess: ".",
        selectableRows: false,
        textLabels: {
            body: {
                noMatch: "Lo sentimos, no se encontraron registros.",
                toolTip: "Clasificar",
                columnHeaderTooltip: (column) =>
                    `clasificar por ${column.label}`,
            },
            pagination: {
                next: "Siguiente página",
                previous: "Página anterior",
                rowsPerPage: "Filas por página:",
                displayRows: "de", // 1-10 of 30
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar como CSV",
                print: "Imprimir",
                viewColumns: "Columnas",
                filterTable: "Filtros",
            },
            filter: {
                title: "FILTROS",
                reset: "reiniciar",
            },
            viewColumns: {
                title: "Mostrar columnas",
            },
            selectedRows: {
                text: "Fila(s) eliminadas",
                delete: "Borrar",
            },
        },
    };

    return (
        <div>
            <MUIDataTable data={data} columns={columns} options={options} />
        </div>
    );
}
