import React, { useRef, useState } from "react";
import { Button } from "../../components/Button";
import { Form, InputField, TextAreaField } from "../../components/Form";
import FieldArray from "../../components/Form/FieldArray";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { createForm } from "../../Store/slices/formularios";
import { useHistory } from "react-router-dom";

const schema = yup.object().shape({
    name: yup.string().required("Requerido"),
    sections: yup.array().of(
        yup.object().shape({
            name: yup.string().required("Requerido"),
            detalles: yup.array().of(
                yup.object().shape({
                    name: yup.string().required("Requerido"),
                    type: yup.string().required("Requerido"),
                    data: yup.array().of(
                        yup.object().shape({
                            name: yup.string().required("Requerido"),
                        })
                    ),
                })
            ),
        })
    ),
});

const defaultValues = {
    name: "Prueba Formuario",
    description: "Descripción o detalle del Formulario",
    isPlantilla: false,
    sections: [
        {
            name: "Nombre de la sección",
            detalles: [
                {
                    name: "Nombre de la pregunta",
                    seccion: "Nombre de la sección",
                    type: "select",
                    required: "true",
                    description: "Descripción o detalle de la pregunta",
                    data: "['opcion1','opcion2','opcion3']",
                },
            ],
        },
    ],
};

const newDefaultValues = {
    ...defaultValues,
    sections: {
        ...defaultValues?.sections?.map((section) =>
            section?.detalles?.map((detalle) => {
                const dataArr = detalle?.data?.split(",").map((data) => data);
                const detallesData = dataArr.reduce((acc, curr) => {
                    return [...acc, { name: curr }];
                }, []);

                return { ...detalle, data: detallesData };
            })
        ),
    },
};

export default function Plantillas() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [currentSection, setCurrentSection] = useState(undefined);
    const history = useHistory();
    const sectionsRef = useRef();
    const onSubmit = (data) => {
        const mergeDetails = [];
        data.sections?.map((o) =>
            o.detalles?.map((b) => {
                mergeDetails.push({
                    seccion: o.name,
                    type: b.type,
                    required: b.required,
                    name: b.name,
                    description: b.description,
                    data: JSON.stringify(b?.data?.map((c) => c.name)),
                });
            })
        );
        const payload = {
            name: data.name,
            description: data.description,
            isPlantilla: data.isPlantilla,
            detalles: mergeDetails,
        };
        setIsLoading(true);
        dispatch(createForm(payload)).then((res) => {
            setIsLoading(false);
            console.log({ res });
            if (!res.payload) {
                toast.error("Se ha producido un error");
                return;
            }
            history.push("/formularios");
            toast.success("Formulario creado exitosamente");
        });
    };

    return (
        <div className="p-4 bg-white">
            <Form
                onSubmit={(data) => {
                    onSubmit(data);
                }}
                options={{
                    mode: "onChange",
                }}
                schema={schema}
            >
                {({
                    register,
                    formState,
                    control,
                    getValues,
                    setValue,
                    watch,
                }) => (
                    <>
                        <div className="grid grid-cols-12 ">
                            <div className="col-span-3 pr-2 border-r-2">
                                <div className="mb-4">
                                    <h3 className="text-base font-bold uppercase text-secondary">
                                        Nombre
                                    </h3>
                                    <InputField
                                        error={formState.errors.name}
                                        registration={register("name", {
                                            required: {
                                                value: true,
                                                message: "Requerido",
                                            },
                                        })}
                                    />
                                </div>

                                <div className="mb-2">
                                    <h3 className="text-base font-bold uppercase text-secondary">
                                        Descripción
                                    </h3>
                                    <TextAreaField
                                        error={formState.errors.description}
                                        registration={register("description")}
                                    />
                                </div>

                                <div className="flex gap-4 mb-4">
                                    <span>Guardar como plantilla: </span>
                                    <InputField
                                        type="checkbox"
                                        error={formState.errors.isPlantilla}
                                        registration={register("isPlantilla")}
                                    />
                                </div>

                                <div className="">
                                    <h3 className="pb-4 text-base font-bold uppercase text-secondary">
                                        Secciones
                                    </h3>
                                    {watch("sections")?.map(
                                        (section, index) => (
                                            <div className="pr-2">
                                                <button
                                                    type="button"
                                                    onClick={() =>
                                                        setCurrentSection(
                                                            section
                                                        )
                                                    }
                                                    className={`
                                                ${
                                                    formState?.errors
                                                        ?.sections &&
                                                    formState.errors.sections[
                                                        index
                                                    ] &&
                                                    "bg-red-300"
                                                }
                                                ${
                                                    section === currentSection
                                                        ? "bg-gray-200"
                                                        : "hover:bg-gray-200"
                                                }  text-left w-full px-2 py-4 border-b-2 transition-all duration-200`}
                                                >
                                                    <div className="w-full overflow-x-hidden truncate">
                                                        {section.name}
                                                    </div>
                                                </button>
                                            </div>
                                        )
                                    )}
                                </div>

                                <Button
                                    onClick={() => {
                                        sectionsRef?.current?.addSection();
                                    }}
                                    className="w-full mt-2"
                                >
                                    Agregar sección
                                </Button>
                            </div>

                            <div className="col-span-9 pl-2">
                                {currentSection === undefined && (
                                    <div>Seleccione una sección</div>
                                )}

                                <FieldArray
                                    {...{
                                        control,
                                        register,
                                        defaultValues,
                                        formState,
                                        getValues,
                                        setValue,
                                        watch,
                                        sectionsRef,
                                        currentSection,
                                        setCurrentSection,
                                    }}
                                />
                            </div>
                        </div>

                        <div className="flex justify-end mt-4">
                            <Button type="submit" isLoading={isLoading}>
                                Guardar formulario
                            </Button>
                        </div>
                    </>
                )}
            </Form>
        </div>
    );
}
