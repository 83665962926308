import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import noImage from "../../images/no_image_available.png";
import {
    getInstitucionStadistics,
    getInstitucionSolicitudesData,
    getInstitucionSolicitudesDataPaginated,
    getInstitucionSolicitudesDataPaginatedSearch,
    getSelectsDataDestructured,
} from "../../Store/slices/solicitudes";
import { SolicitudesFilterable } from "../../components/Common/Filters/Filterable";
import { Pagination } from "../../components/Common/Index";
import { replaceHttpToHttps } from "../../utils/http";
import { Fetch } from "../../Services/Fetch";
import { useHistory } from "react-router-dom";
import useFileDownloader from "../../utils/fileDownloaderMaker";

export default function DetallesEvaluaciones() {
    // In-Component Hooks
    const dispatch = useDispatch();
    const p = useParams();
    const history = useHistory();
    const { ExcelGetter } = useFileDownloader();

    // In-Component State
    let [isSpinning, setIsSpinning] = useState(false);
    let [searchableData, setSearchableData] = useState({ institucion: p.id });

    // Constants
    const {
        all: data,
        status,
        stadistics,
        selectsData,
    } = useSelector((state) => state.solicitudes);

    const { users } = useSelector((state) => state);

    const { institutions } = useSelector((state) => state.evaluadores.one);

    const institucion = institutions?.find(
        (e) => parseInt(e.institution.id) == parseInt(p.id)
    );

    const columns = [
        {
            label: "Nombre",
            name: "nombre",
        },
        {
            label: "Apellido",
            name: "apellido",
        },
        {
            label: "Carrera",
            name: "carrera",
        },
        {
            label: "Nivel",
            name: "nivel",
        },
        {
            label: "Locación",
            name: "locacion",
        },
        {
            label: "Comentarios",
            name: "comentarios",
        },
        {
            label: "Puntuación",
            name: "puntacion",
        },
        {
            name: "Estado",
            options: {
                customBodyRender: (value) => {
                    if (
                        value.status === "Solicitud Enviada" ||
                        value.status === "En proceso de evaluacion"
                    ) {
                        return (
                            <Link
                                to={`/evaluaciones/detalles_evaluaciones/${p.id}/solicitud/${value.id}/:o?/${p.convocatoria}`}
                            >
                                <button className="btn btn-default">
                                    {(users.user.user.role.name ?? "") !==
                                    "coordinador"
                                        ? "Evaluar"
                                        : "ver"}
                                </button>
                            </Link>
                        );
                    }

                    return value.status;
                },
            },
        },
        {
            label: "Id",
            name: "id",
            options: {
                display: false,
            },
        },
    ];

    const options = {
        selectableRows: false,
        pagination: false,
        search: false,
        toolbar: false,
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
        onRowClick: (rowData, {}) => {
            history.push(
                `/evaluaciones/detalles_evaluaciones/${
                    p.convocatoria
                }/solicitud/${rowData[rowData.length - 1]}/datospersonales`
            );
        },
    };

    // Functions
    function moveMonitoring(link) {
        link = replaceHttpToHttps(link);
        link += `&institucion=${p.id}&`;
        for (let k in searchableData) {
            link += `${(() => {
                if (k === "internalSearch") {
                    return "busqueda";
                } else if (k === "instituciones") {
                    return "institucion";
                } else {
                    return k;
                }
            })()}=${searchableData[k]}&`;
        }

        console.log("hola pase");

        dispatch(getInstitucionSolicitudesDataPaginated(`${link}`));
    }

    function searchFromTable(obj) {
        setSearchableData(obj);
        let link = ``;

        for (let k in obj) {
            link += `${(() => {
                if (k === "internalSearch") {
                    return "busqueda";
                } else if (k === "instituciones") {
                    return "institucion";
                } else {
                    return k;
                }
            })()}=${obj[k]}&`;
        }
        dispatch(
            getInstitucionSolicitudesDataPaginatedSearch(
                `${link}convocatoria=${p.convocatoria}`
            )
        );
    }

    function exportData() {
        setIsSpinning(true);

        let link = `solicitudes/exportar?`;
        for (let k in searchableData) {
            link += `${(() => {
                if (k === "internalSearch") {
                    return "busqueda";
                } else if (k === "instituciones") {
                    return "institucion";
                } else if (k === "carreras") {
                    return "carrera";
                } else {
                    return k;
                }
            })()}=${searchableData[k]}&`;
        }

        Fetch({
            uri: `${link}convocatoria=${p.convocatoria}`,
            method: "get",
        }).then((x) => {
            ExcelGetter(x?.data?.data ?? {}, `Solicitudes`);
            setIsSpinning(false);
        });
    }

    // Effects
    useEffect(() => {
        dispatch(
            getInstitucionSolicitudesData({ id: p.id, convoc: p.convocatoria })
        );
        dispatch(
            getSelectsDataDestructured({ convoc: p.convocatoria, insti: p.id })
        );
        dispatch(
            getInstitucionStadistics({ id: p.id, convoc: p.convocatoria })
        );
    }, []);

    return (
        <div>
            <div className="pb-8 m-5">
                {/* Menu up start */}
                <div className="grid grid-cols-[164px,300px,1fr] mb-[22px] bg-white items-center">
                    <img
                        src={institucion?.institution?.image_url || noImage}
                        alt="Perfil"
                        className="h-32 p-4"
                    />

                    <div className="px-8 border-solid border-[#F0F0F0] border-r-[1px]">
                        <h3 className="text-2xl text-[#231F20] mb-[2px]">
                            {institucion?.institution?.name}
                        </h3>
                        <div></div>
                    </div>

                    <div className="flex mx-3 px-11 ">
                        <div className="flex flex-col items-baseline justify-start mx-3">
                            <span className="mt-0 text-xs text-gray-500">
                                Convocatoria
                            </span>
                            <h1 className="mb-0 font-sans text-sm font-normal text-gray-800">
                                {institucion?.institution?.name}
                            </h1>
                        </div>
                        <div className="flex flex-col items-baseline justify-start mx-3">
                            <span className="mt-0 text-xs text-gray-500">
                                Solicitudes
                            </span>
                            <h1 className="mb-0 font-sans text-sm font-normal text-gray-800">
                                {stadistics?.solicitudes?.enviadas || 0}
                            </h1>
                        </div>

                        <div className="flex flex-col items-baseline justify-start mx-3">
                            <span className="mt-0 text-xs text-gray-500">
                                Preseleccionados
                            </span>
                            <h1 className="mb-0 font-sans text-sm font-normal text-gray-800">
                                {stadistics?.solicitudes?.preseleccionadas || 0}
                            </h1>
                        </div>

                        <div className="flex flex-col items-baseline justify-start mx-3">
                            <span className="mt-0 text-xs text-gray-500">
                                Declinados
                            </span>
                            <h1 className="mb-0 font-sans text-sm font-normal text-gray-800">
                                {stadistics?.solicitudes?.declinadas || 0}
                            </h1>
                        </div>

                        <div className="flex flex-col items-baseline justify-start mx-3">
                            <span className="mt-0 text-xs text-gray-500">
                                Revision
                            </span>
                            <h1 className="mb-0 font-sans text-sm font-normal text-gray-800">
                                {stadistics?.solicitudes?.en_revision || 0}
                            </h1>
                        </div>

                        <div className="flex flex-col items-baseline justify-start mx-3">
                            <span className="mt-0 text-xs text-gray-500">
                                Pendientes
                            </span>
                            <h1 className="mb-0 font-sans text-sm font-normal text-gray-800">
                                {(stadistics?.solicitudes?.enviadas || 0) -
                                    ((stadistics?.solicitudes?.en_evaluacion ||
                                        0) +
                                        (stadistics?.solicitudes?.en_revision ||
                                            0) +
                                        (stadistics?.solicitudes?.declinadas ||
                                            0))}
                            </h1>
                        </div>
                    </div>
                </div>

                {/* Menu up end */}

                <div>
                    <SolicitudesFilterable
                        exportable={exportData}
                        selects={selectsData ?? {}}
                        setSearch={searchFromTable}
                        isSpinning={isSpinning}
                        notInst={true}
                        instit={p.id}
                    />
                    <MUIDataTable
                        title={"Solicitudes"}
                        data={data.reduce((acc, curr) => {
                            return [
                                ...acc,
                                [
                                    curr.nombre,
                                    curr.apellido,
                                    curr.carrera,
                                    curr.nivel,
                                    curr.sede,
                                    curr.notes,
                                    curr.score,
                                    { status: curr.status, id: curr.id },
                                    curr.id,
                                ],
                            ];
                        }, [])}
                        columns={columns}
                        options={options}
                    />

                    <Pagination
                        meta={status?.data?.meta ?? {}}
                        links={status?.data?.links ?? {}}
                        linkCaller={moveMonitoring}
                    />
                </div>
            </div>
        </div>
    );
}
