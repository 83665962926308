import clsx from "clsx";
import * as React from "react";
import * as Icon from "react-feather";

export const FieldWrapper = ({
    label,
    className,
    error,
    watch,
    type,
    accept,
    children,
    setValue,
    name,
}) => {
    const dropZone = (
        <div
            onDrop={(ev) => {
                ev.preventDefault();
                if (setValue && name) {
                    setValue(name, ev.dataTransfer.files);
                } else {
                    console.error(
                        "Debe indicar el valor a setear y el name del input"
                    );
                }
            }}
            onDragOver={(ev) => {
                ev.preventDefault();
            }}
            className="group rounded-lg border-4 border-dashed "
        >
            <div className="flex flex-col items-center justify-center text-center py-4 gap-4">
                <div className="flex max-h-52 items-center justify-center">
                    {watch?.length > 0 ? (
                        <img
                            className="h-52"
                            src={URL.createObjectURL(watch[0])}
                            alt="Preview"
                        />
                    ) : (
                        <Icon.Image size={40} className="text-gray-400" />
                    )}
                </div>

                <p className="pointer-none text-gray-500 ">
                    {(!watch || watch?.length === 0) && (
                        <div>
                            <span className="text-sm">Arrastrar la imagen</span>
                            <br /> O
                            <br />
                        </div>
                    )}

                    <a
                        href
                        id
                        className="py-2 px-6 text-md flex justify-center items-center border border-gray-300 disabled:opacity-70 disabled:cursor-not-allowed rounded-md shadow-sm font-medium focus:outline-none transition-all duration-200 bg-blue-900 text-white hover:bg-gray-50 hover:text-blue-900 cursor-pointer"
                    >
                        {!watch?.length > 0 ? "Seleccionar archivo" : "Cambiar"}
                    </a>
                </p>
            </div>
        </div>
    );

    return (
        <div className={className}>
            <label
                className={clsx(
                    "block text-sm font-medium text-gray-700",
                    className
                )}
            >
                {label}
                <div className="mt-1">
                    {children}
                    {type === "file" && accept?.includes("image") && dropZone}
                </div>
            </label>
            {error?.message && (
                <div
                    role="alert"
                    aria-label={error.message}
                    className="text-sm font-semibold text-red-500"
                >
                    {error.message}
                </div>
            )}
        </div>
    );
};
