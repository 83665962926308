import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Icon from "react-feather";
import { Switch, Route, useParams, Redirect } from "react-router-dom";
import Tabnavigator from "../../components/navigation/tabnavigator";
import Convocatorias from "./Convocatorias";
import Evaluadores from "./Evaluadores";
import { getOne } from "../../Store/slices/coordinadores";
import noImage from "../../images/profile-placeholder.png";

export default function DetallesCoordinador() {
    const dispatch = useDispatch();

    const { id } = useParams();
    const coordinador = useSelector((state) => state.coordinadores.one);

    useEffect(() => {
        dispatch(getOne({ coordinator_id: id }));
    }, []);

    return (
        <div className="p-4">
            <div className="grid grid-cols-[164px,300px,1fr] mb-[22px] bg-white items-center">
                <img
                    src={coordinador.image_url || noImage}
                    alt="Perfil"
                    className="max-h-[150px] p-2"
                />

                <div className="px-8 border-solid border-[#F0F0F0] border-r-[1px]">
                    <h3 className="text-2xl text-[#231F20] mb-[2px]">
                        {coordinador.name}
                    </h3>
                    <span className="text-xl text-[#AAAAAA]">
                        Becas Nacionales
                    </span>

                    <div className="border-[#DDDFE1] border-solid border-t-[1px] pt-2 flex">
                        <div className="flex flex-col items-center mx-3">
                            <Icon.User className="text-[#7BB31A]" size={20} />
                            <span className="text-xs mt-1">
                                {coordinador.evaluators?.length}
                            </span>
                        </div>
                        <div className="flex flex-col items-center">
                            <Icon.BookOpen
                                className="text-[#00A2FF]"
                                size={20}
                            />
                            <span className="text-xs mt-1">
                                {coordinador.convocatorias?.length}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="block px-11">
                    <span className="flex items-center mb-[14px]">
                        <Icon.Phone size={16} />
                        &nbsp;{coordinador.contact_phone}
                    </span>
                    <span className="flex items-center">
                        <Icon.Mail size={16} />
                        &nbsp;{coordinador.contact_email}
                    </span>
                </div>
            </div>

            <Tabnavigator
                routes={[
                    {
                        path: `/coordinadores/detalles/${id}/_evaluadores`,
                        name: "Evaluadores",
                    },
                    {
                        path: `/coordinadores/detalles/${id}/_convocatorias`,
                        name: "Convocatorias",
                    },
                ]}
            />
            <div className="w-full h-auto shadow-lg bg-white p-4">
                <div>
                    <Switch>
                        <Route
                            exact
                            path="/coordinadores/detalles/:id/_evaluadores"
                        >
                            <Evaluadores data={coordinador.evaluators} />
                        </Route>
                        <Route
                            exact
                            path="/coordinadores/detalles/:id/_convocatorias"
                        >
                            <Convocatorias
                                data={coordinador.convocatorias}
                                name={coordinador.name}
                            />
                        </Route>
                        <Redirect
                            to={`/coordinadores/detalles/${id}/_evaluadores`}
                        />
                    </Switch>
                </div>
            </div>
        </div>
    );
}
