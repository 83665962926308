import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import { getContratosForOne } from "../../Store/slices/becados/index.js";
import { Button } from "../../components/Button.js";
import NewModal from "../../components/modal.js";
import { toast } from "react-toastify";
import { Fetch } from "../../Services/Fetch.js";

export default function Contrato({
    candidatoId = 0,
    solicitudId = 0,
    becaId = 0,
}) {
    // StateUses
    let [currFile, setCurrFile] = useState(null);
    let [modal, setModal] = useState(false);
    let [fileComment, setFileComment] = useState("");

    // Hooks
    const dispatcher = useDispatch();

    // Store Global Constants
    const { oneContracts } = useSelector((state) => state.becados);

    //Constants
    const columns = [
        {
            label: "Id Documento",
            name: "id",
            options: {
                display: false,
            },
        },
        {
            label: "Comentario",
            name: "comentarios",
        },
        {
            label: "Documento",
            name: "url",
            options: {
                customBodyRender: (value) => {
                    return (
                        <a href={value} target="_blank">
                            <u>
                                <b>{value.split("/").pop()}</b>
                            </u>
                        </a>
                    );
                },
            },
        },
        {
            label: "Estado Actual",
            name: "actual",
            options: {
                customBodyRender: (value) => {
                    return value ? <p>Activo</p> : <p>Inactivo</p>;
                },
            },
        },
        {
            label: "Version",
            name: "count",
            options: {
                customBodyRender: (value) => value,
            },
        },
    ];

    const options = {
        selectableRows: false,
        viewColumns: false,
        pagination: false,
        search: false,
        toolbar: false,
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
    };

    // Functions
    function manageAndSet(arr = []) {
        if (!Array.isArray(arr)) return [];
        arr = [...arr];
        arr = arr.map((x, i) => ({
            ...x,
            count: `V${i + 1}`,
        }));
        arr = arr.sort((a, b) => a - b);
        return arr;
    }

    function callAndClear() {
        setModal(!modal);
        setCurrFile(null);
        setFileComment("");
    }

    function getFile({ target: { files } }) {
        const MAX_FILE_SIZE = 30000000;

        if ((files[0].size || MAX_FILE_SIZE) >= MAX_FILE_SIZE) {
            toast.error("le bum bum");
            return null;
        }
        setCurrFile(files[0]);
    }

    async function uploadFile() {
        if (
            fileComment.length < 5 ||
            currFile === null ||
            currFile.name.split(".").pop().toUpperCase() !== "PDF"
        ) {
            toast.error("Revise el archivo que intenta subir!");
            return null;
        }

        try {
            let response = await Fetch({
                uri: "contratos/create",
                data: {
                    documento: currFile,
                    candidato: candidatoId,
                    solicitud: solicitudId,
                    comentarios: fileComment,
                    beca: becaId,
                },
            });

            toast.success(response);
            callAndClear();
            dispatcher(getContratosForOne({ candidatoId, solicitudId }));
        } catch (e) {
            toast.error(e);
        }
    }

    //Effects

    useEffect(
        async () =>
            dispatcher(getContratosForOne({ candidatoId, solicitudId })),
        []
    );

    return (
        <>
            <NewModal
                action={() => callAndClear()}
                isVisible={modal}
                title="Subir un nuevo contrato"
            >
                <div>
                    <div className="mb-3 w-96">
                        <input
                            className="form-control block w-full px-3 text-base
                            font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300
                            rounded transition ease-in-out m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            type="file"
                            accept="application/pdf"
                            onChange={getFile}
                        />
                        <div className="pt-2">
                            <label className="form-label inline-block mb-2 text-gray-700">
                                Comentatio :
                            </label>
                            <textarea
                                className=" form-control block w-full px-3 py-1.5 text-base
                                font-normal text-gray-700 bg-white bg-clip-padding
                                border border-solid border-gray-300 rounded
                                transition ease-in-out m-0
                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                onBlur={({ target: { value } }) =>
                                    setFileComment(value)
                                }
                                rows="5"
                                placeholder="Comentario....."
                            />

                            <div className="py-2 flex justify-end">
                                <Button onClick={() => uploadFile()}>
                                    Cargar
                                </Button>
                                <Button
                                    variant="inverse"
                                    onClick={callAndClear}
                                >
                                    Cancelar
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </NewModal>
            <div className="pb-4">
                <Button className="px-2" onClick={() => callAndClear()}>
                    Añadir contrato
                </Button>
            </div>

            <MUIDataTable
                columns={columns}
                options={options}
                data={manageAndSet(oneContracts)}
            />
        </>
    );
}
