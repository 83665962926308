import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import renderError from "../../helpers/renderErrors";
import { Fetch } from "../../Services/Fetch";

export default function useAction() {
    const [isLoading, setisLoading] = useState(false);
    const [isLoadingreset, setisLoadingreset] = useState(false);
    const [users, setusers] = useState([]);

    const callAllUsers = async () => {
        setisLoading(true);
        try {
            const res = await Fetch({ uri: "/users/getAll" });
            setusers(res.data?.data);
            setisLoading(false);
            return res.data.data;
        } catch (error) {
            setisLoading(false);
            renderError(error.response?.data);
        }
    };

    const resetPassword = async (id) => {
        setisLoadingreset(id);
        try {
            const res = await Fetch({
                uri: "/users/resetpassword",
                data: { user_id: id },
            });
            setisLoadingreset(false);
            toast.success("Contraseña reseteada satisfactoriamente");
            return res.data.data;
        } catch (error) {
            setisLoadingreset(false);
            renderError(error.response.data);
        }
    };

    useEffect(() => {
        callAllUsers();
    }, []);
    return { isLoading, callAllUsers, resetPassword, isLoadingreset, users };
}
