import { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "alertifyjs/build/css/alertify.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useFormContext } from "react-hook-form";
import { Button } from "../../../components/Button";
import { InputField } from "../../../components/Form";
import { toast } from "react-toastify";
import { Fetch } from "../../../Services/Fetch";
import alertify from "alertifyjs";

export default function Requisitos({ convocatoriaid }) {
    const [editorState, seteditorState] = useState(EditorState.createEmpty());
    const [mounted, setmounted] = useState(false);
    const [formAdjun, setformAdjun] = useState({});
    const [files, setfiles] = useState([]);
    const { setValue, watch } = useFormContext();

    const htmlToDraftBlocks = (html) => {
        const blocksFromHtml = htmlToDraft(html);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
        );
        return EditorState.createWithContent(contentState);
    };

    const subirArchivo = () => {
        if (!convocatoriaid) {
            alertify.alert(
                "Error",
                "No Pude subir archivos hasta guarda la convocatoria"
            );
            return;
        }
        toast.promise(
            Fetch({
                data: {
                    ...formAdjun,
                    convocatoria_id: convocatoriaid,
                },
                uri: "/convocatorias/documentos/create",
            }),
            {
                pending: "Cargando...",
                success: {
                    render({ data }) {
                        setfiles([...files, data?.data?.data]);
                        return "Creado exitosamente";
                    },
                },
                error: {
                    render({ data }) {
                        const arrayErrors = Object.entries(
                            data?.response?.data?.errors || {}
                        );
                        if (arrayErrors[0]) {
                            if (arrayErrors[0][1]) {
                                return arrayErrors[0][1][0];
                            } else {
                                return data.response?.data?.message;
                            }
                        } else {
                            return data.response?.data?.message;
                        }
                    },
                },
            }
        );
    };

    const getFiles = async () => {
        if (!convocatoriaid) return;
        const res = await Fetch({
            uri: `/convocatorias/documentos/getAll?convocatoria_id=${convocatoriaid}`,
            method: "get",
        });
        setfiles(res.data?.data);
    };

    useEffect(() => {
        !files.length && getFiles();
    }, [convocatoriaid]);

    const EliminarArchivo = (id) => {
        toast.promise(
            Fetch({
                data: {
                    documento_id: id,
                },
                uri: "/convocatorias/documentos/delete",
            }),
            {
                pending: "Cargando...",
                success: {
                    render({ data }) {
                        setfiles(
                            files.filter(
                                (o) => parseInt(o.id, 10) !== parseInt(id, 10)
                            )
                        );
                        return "Eliminado exitosamente";
                    },
                },
                error: {
                    render({ data }) {
                        const arrayErrors = Object.entries(
                            data?.response?.data?.errors || {}
                        );
                        if (arrayErrors[0]) {
                            if (arrayErrors[0][1]) {
                                return arrayErrors[0][1][0];
                            } else {
                                return data.response?.data?.message;
                            }
                        } else {
                            return data.response?.data?.message;
                        }
                    },
                },
            }
        );
    };

    let req = watch("requisitos");

    useEffect(() => {
        !mounted && seteditorState(htmlToDraftBlocks(req || "<p></p>"));
        if (req) {
            setmounted(true);
        }
    }, [req]);

    return (
        <div>
            <div>
                <div className="border">
                    <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={(state) => {
                            seteditorState(state);
                            setValue(
                                "requisitos",
                                draftToHtml(
                                    convertToRaw(state.getCurrentContent())
                                )
                            );
                        }}
                    />
                </div>
            </div>
            <div>
                {!!files.length && (
                    <div className=" mx-auto">
                        <div className="bg-white shadow-md rounded my-6">
                            <table className="text-left w-full border-collapse">
                                <thead>
                                    <tr>
                                        <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                                            Nombre
                                        </th>

                                        <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light">
                                            Descripciòn
                                        </th>

                                        <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b border-grey-light"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {files.map((res) => {
                                        return (
                                            <tr className="hover:bg-grey-lighter">
                                                <td className="py-4 px-6 border-b border-grey-light">
                                                    {res.nombre}
                                                </td>
                                                <td className="py-4 px-6 border-b border-grey-light">
                                                    {res.descripcion}
                                                </td>
                                                <td className="py-4 px-6 border-b border-grey-light">
                                                    <a
                                                        href={res.url}
                                                        target="_blank"
                                                        className="text-grey-lighter font-bold py-1 px-3 rounded text-xs bg-blue hover:bg-blue-300"
                                                    >
                                                        Ver
                                                    </a>
                                                    <button
                                                        onClick={() =>
                                                            EliminarArchivo(
                                                                res.id
                                                            )
                                                        }
                                                        className="text-grey-lighter font-bold py-1 px-3 rounded text-xs bg-green hover:bg-red-300"
                                                    >
                                                        Eliminar
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}

                <div className="grid grid-cols-5 gap-4 mt-4">
                    <div className="col-span-3">
                        <InputField
                            type="text"
                            value={formAdjun.descripcion}
                            onChange={({ target }) => {
                                setformAdjun({
                                    ...formAdjun,
                                    descripcion: target.value,
                                });
                            }}
                        />
                    </div>
                    <div className="col-span-1">
                        <label
                            htmlFor="fileidSelecterid"
                            className="flex md bg-white text-blue-900 hover:bg-blue-900 hover:text-white py-2 justify-center items-center border border-gray-300 disabled:opacity-70 disabled:cursor-not-allowed rounded-md shadow-sm font-medium focus:outline-none transition-all duration-200"
                        >
                            Adjuntar archivo
                        </label>
                        <input
                            id="fileidSelecterid"
                            type="file"
                            className="w-0"
                            onChange={({ target }) => {
                                setformAdjun({
                                    descripcion:
                                        target.files[0]?.name?.substring(
                                            0,
                                            target.files[0]?.name?.indexOf(".")
                                        ),
                                    documento: target.files[0],
                                });
                            }}
                        />
                    </div>
                    <div className="col-span-1">
                        <Button onClick={subirArchivo}>Subir archivo</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
