import MUIDataTable from "mui-datatables";
import { Pagination } from "../../components/Common/Index";

export default function OfertaCademica({ data = [] }) {
    const columns = [
        {
            label: "Carrera",
            name: "development_area_name",
        },
        {
            label: "Locación",
            name: "campus_province",
        },
        {
            label: "Nivel",
            name: "education_level_name",
        },
        {
            label: "Área",
            name: "academic_offer_name",
        },
        {
            label: "Idioma",
            name: "language",
        },
        {
            label: "Dirigido a",
            name: "education_level_name",
        },
        {
            label: "Créditos",
            name: "academic_offer_pensum_size",
        },
        {
            label: "Pensun",
            name: "academic_offer_pensum_url",
            options: {
                customBodyRender: (pdf) => {
                    return (
                        <a
                            href={pdf}
                            target="_blank"
                            rel="noreferrer"
                            className="hover:underline text-blue-400"
                        >
                            Visualizar
                        </a>
                    );
                },
            },
        },
    ];

    const options = {
        selectableRows: false,
        search: false,
        toolbar: false,
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
    };

    return <MUIDataTable data={data} columns={columns} options={options} />;
}
