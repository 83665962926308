import React from "react";
import { Switch, Route } from "react-router-dom";
import DetallesOferentes from "./Oferentes/DetalleOferentes";
import Index from "./Oferentes/Index";

export default function Oferentes() {
    return (
        <>
            {/* <SubHeader title="Oferente" /> */}
            <Switch>
                <Route exact path="/oferentes">
                    <Index />
                </Route>
                <Route path="/oferentes/detalles/:id">
                    <DetallesOferentes />
                </Route>
            </Switch>
        </>
    );
}
