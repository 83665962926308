import { useState } from "react";
import { useFieldArray } from "react-hook-form";
import { InputField, SelectField, TextAreaField } from ".";
import { Button } from "../Button";

export default function NestedOptions({
    index1,
    index2,
    control,
    register,
    formState,
}) {
    const { fields, remove, append } = useFieldArray({
        control,
        name: `sections[${index1}].detalles[${index2}].data`,
    });

    const checkErrors = (fieldName, index) => {
        return (
            formState?.errors?.sections &&
            formState?.errors?.sections[index1] &&
            formState?.errors?.sections[index1]?.detalles &&
            formState?.errors?.sections[index1]?.detalles[index2] &&
            formState?.errors?.sections[index1]?.detalles[index2] &&
            formState?.errors?.sections[index1]?.detalles[index2]?.data &&
            formState?.errors?.sections[index1]?.detalles[index2]?.data[
                index
            ] &&
            formState?.errors?.sections[index1]?.detalles[index2]?.data[index][
                fieldName
            ]
        );
    };

    return (
        <div className="grid px-4 gap-4">
            {fields.map((item, index) => (
                <div key={item.id} className="flex items-start gap-4 ">
                    <div className="flex-grow">
                        <InputField
                            label="Nombre de la opcción"
                            error={checkErrors("name", index)}
                            registration={register(
                                `sections[${index1}].detalles[${index2}].data[${index}].name`
                            )}
                        />
                    </div>
                    <div>
                        <Button
                            onClick={() => remove(index)}
                            variant="danger"
                            className="mt-5"
                        >
                            Eliminar opcción
                        </Button>
                    </div>
                </div>
            ))}

            <Button
                onClick={() =>
                    append({
                        name: "Nombre de la opcción",
                    })
                }
                className="w-full mt-4"
            >
                Agregar opcción
            </Button>
        </div>
    );
}
