import { useEffect, useState } from "react";
import * as Icon from "react-feather";
import SimpleCard from "../../components/card/simpleCard";
import MostSubmitted from "../../components/card/mostSubmitted";
import Gender from "../../components/card/gender";
import ColumnsSubmitted from "../../components/card/ColumnsSubmitted";
import CountryCard from "../../components/card/countryCard";
import { Fetch } from "../../Services/Fetch";
import Spinner from "../../components/Spinner";

export default function Resultados({ id = 0 }) {
    // Statefull-Variables
    const [estadisticas, setEstadisticas] = useState({});
    const [areas, setAreas] = useState([]);
    const [totalStadisticas, setTotalStadisticas] = useState({});
    const [totalState, setTotalState] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // Functions
    async function GetData() {
        return Promise.all([
            Fetch({
                uri: `/estadisticas/convocatoria?convocatoria_id=${id}`,
                method: "get",
            }),
            Fetch({
                uri: `/estadisticas/areas/solicitadas/convocatoria?convocatoria=${id}`,
                method: "get",
            }),
            Fetch({
                uri: `/estadisticas/provincias/solicitadas/convocatoria?convocatoria=${id}`,
                method: "get",
            }),
        ]).then(([estadisticas, areas, provincia]) => {
            const {
                data: {
                    data: { solicitudes, becados },
                },
            } = estadisticas;
            const {
                data: { data: areasData },
            } = areas;
            const {
                data: { data: provinciaData },
            } = provincia;

            setEstadisticas({ ...(solicitudes ?? {}), ...(becados ?? {}) });
            setAreas(areasData ?? []);
            setTotalStadisticas(solicitudes ?? {});
            setTotalState(provinciaData ?? []);
        });
    }

    // Effects
    useEffect(() => {
        GetData().then(() => setIsLoading(false));
        return null;
    }, []);

    return isLoading ? (
        <Spinner />
    ) : (
        <>
            {/* Simple Card Data */}
            <div className="grid grid-cols-4 gap-8 pb-8 m-4">
                <SimpleCard
                    name="Pre-Selecciones"
                    total={
                        estadisticas?.enviadas + estadisticas?.no_terminadas ||
                        0
                    }
                    color="#FF9800"
                >
                    <Icon.BarChart2 size={50} className="text-custom-400" />
                </SimpleCard>

                <SimpleCard
                    name="Solicitudes"
                    total={estadisticas?.enviadas || 0}
                    color="#00A2FF"
                >
                    <Icon.TrendingUp size={50} className="text-custom-400" />
                </SimpleCard>

                <SimpleCard
                    name="Pre-aprobadas"
                    total={estadisticas?.aprobadas || 0}
                    color="#008000"
                >
                    <Icon.ArrowUp size={50} className="text-custom-400" />
                </SimpleCard>

                <SimpleCard
                    name="Becados"
                    total={estadisticas?.total || 0}
                    color="#7BB31A"
                >
                    <Icon.User size={50} className="text-custom-400" />
                </SimpleCard>
            </div>

            {/* Divided Row 1 */}
            <div className="grid grid-cols-3 gap-4 mt-4 sm:grid-cols-1 md:grid-cols-2">
                <div>
                    <ColumnsSubmitted
                        title="Areas mas solicitadas"
                        data={areas}
                    />
                </div>
                <div>
                    <Gender
                        title="Género Becados"
                        data={{
                            hombres: estadisticas.hombres,
                            mujeres: estadisticas.mujeres,
                        }}
                    />
                </div>
                <div>
                    <MostSubmitted
                        title="Estados Solicitudes"
                        data={totalStadisticas}
                    />
                </div>
                <div>
                    <Gender
                        title="Género Solicitudes"
                        data={{
                            hombres: totalStadisticas.hombres,
                            mujeres: totalStadisticas.mujeres,
                        }}
                        alter={true}
                    />
                </div>
            </div>

            {/* Divided Row 2 */}
            <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-1 md:grid-cols-2">
                <CountryCard
                    title="Solicitudes por provincia"
                    data={totalState}
                />
            </div>
        </>
    );
}
