import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
};

const campusSlice = createSlice({
    name: "campus",
    initialState,
    reducers: {
        setCampus: (state, { payload }) => {
            state.data = payload;
        },
        addCampus: (state, { payload }) => {
            state.data = [...state.data,payload];
        },
        editCampus: (state, { payload }) => {
            state.data = state.data.filter((o)=>parseInt(o.id)!==parseInt(payload.id))
            state.data = [...state.data,payload];
        },
        removeCampus: (state, { payload }) => {
            state.data = state.data.filter((o)=>parseInt(o.id)!==parseInt(payload.id))
        },
    },
});

export const { setCampus,addCampus,editCampus,removeCampus } = campusSlice.actions;

export default campusSlice.reducer;
