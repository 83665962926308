import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { setAllForms } from "../Store/slices/formularios";
import Tabnavigator from "../components/navigation/tabnavigator";
import Formularios from "./Formularios/formularios";
import Plantillas from "./Formularios/plantillas";

export default function Formulario() {
    const dispatch = useDispatch();
    const formularios = useSelector((state) => state.formularios.all);
    const fetchForms = async () => {
        await dispatch(setAllForms());
    };

    useEffect(() => {
        fetchForms();
    }, []);

    const plantillas = formularios?.filter((obj) => obj?.plantilla === true);
    // const forms = formularios?.filter((obj) => obj?.plantilla === false);

    return (
        <>
            {/* <SubHeader title="Formularios" /> */}
            <div className="w-full h-auto shadow-lg p-[30px]">
                <Tabnavigator
                    routes={[
                        { path: "/formularios", name: "Formularios" },
                        {
                            path: "/formularios/nuevo_formulario",
                            name: "Crear Formulario",
                        },
                    ]}
                />
                <div>
                    <Switch>
                        <Route exact path="/formularios">
                            <Formularios data={formularios} fetchForms={fetchForms} />
                        </Route>
                        <Route exact path="/formularios/nuevo_formulario">
                            <Plantillas data={plantillas} />
                        </Route>
                    </Switch>
                </div>
            </div>
        </>
    );
}
