import Spinner from "../../components/Spinner";
import MUIDataTable from "mui-datatables";
import { Fetch } from "../../Services/Fetch";
import { useEffect, useState } from "react";
import { Modal, Box } from "@material-ui/core";
import { Button } from "../../components/Button.js";
import { SelectField, InputField } from "../../components/Form";
import { toast } from "react-toastify";
import { replaceHttpToHttps } from "../../utils/http.js";
import { Pagination } from "../../components/Common/Index.js";
import { default as SubSpinner } from "../../components/Common/Spinner";
import useFileDownloader from "../../utils/fileDownloaderMaker";

function Filterable({
    selects = {},
    setSearch = (p) => console.log(p),
    exportable = () => console.log("aaa"),
    modalOpener = () => console.log("bbbb"),
    isSpinning = false,
}) {
    const [internalSearch, setInternalSearch] = useState("");
    const [modalidades, setModalidades] = useState("");
    const [formularios, setFormularios] = useState("");
    const [sede, setSede] = useState("");
    const [carreras, setCarreras] = useState("");
    const [instituciones, setInstituciones] = useState("");
    const [oferente, setOferente] = useState("");

    return (
        <>
            <div className="w-full shadow p-5 rounded-lg bg-white">
                <div className="flex items-center justify-between mt-4">
                    <p className="font-medium">Filtros</p>

                    <button
                        className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 text-sm font-medium rounded-md"
                        onClick={() =>
                            exportable({
                                modalidades: modalidades,
                                formularios: formularios,
                                sede: sede,
                                carreras: carreras,
                                instituciones: instituciones,
                                oferente: oferente,
                            })
                        }
                        disabled={isSpinning}
                    >
                        {isSpinning ? <SubSpinner /> : "Exportar Archivo"}
                    </button>
                </div>

                <div>
                    <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 mt-4">
                        <select
                            className="px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm"
                            value={instituciones}
                            onChange={({ target: { value } }) =>
                                setInstituciones(value)
                            }
                        >
                            <option value="">Instituciones...</option>
                            {(selects["instituciones"] ?? []).map(
                                ({ name, id }) => (
                                    <option value={id}>{name}</option>
                                )
                            )}
                        </select>

                        <select
                            className="px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm"
                            value={carreras}
                            onChange={({ target: { value } }) =>
                                setCarreras(value)
                            }
                        >
                            <option value="">Carreras...</option>
                            {(selects["carreras"] ?? []).map(({ career }) => (
                                <option value={career}>{career}</option>
                            ))}
                        </select>
                        <select
                            className="px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm"
                            value={modalidades}
                            onChange={({ target: { value } }) =>
                                setModalidades(value)
                            }
                        >
                            <option value="">Modalidades...</option>
                            {(selects["modalidades"] ?? []).map(
                                ({ modality }) => {
                                    return (
                                        <option value={modality}>
                                            {modality}
                                        </option>
                                    );
                                }
                            )}
                        </select>

                        <select
                            className="px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm"
                            value={formularios}
                            onChange={({ target: { value } }) =>
                                setFormularios(value)
                            }
                        >
                            <option value="">Formularios...</option>
                            {(selects["formularios"] ?? []).map(
                                ({ name, id }) => {
                                    return <option value={id}>{name}</option>;
                                }
                            )}
                        </select>

                        <select
                            className="px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm"
                            value={sede}
                            onChange={({ target: { value } }) => setSede(value)}
                        >
                            <option value="">Sede...</option>
                            {(selects["sedes"] ?? []).map(({ name }) => {
                                return <option value={name}>{name}</option>;
                            })}
                        </select>

                        <select
                            className="px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm"
                            value={oferente}
                            onChange={({ target: { value } }) =>
                                setOferente(value)
                            }
                        >
                            <option value="">Oferentes...</option>
                            {(selects["oferentes"] ?? []).map(
                                ({ name, id }) => (
                                    <option value={id}>{name}</option>
                                )
                            )}
                        </select>

                        <input
                            className="px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm"
                            placeholder="Buscar..."
                            value={internalSearch}
                            onChange={({ target: { value } }) =>
                                setInternalSearch(value)
                            }
                        />
                        <div className="flex">
                            <Button
                                className="w-3/6"
                                onClick={() =>
                                    setSearch({
                                        internalSearch: internalSearch,
                                        modalidades: modalidades,
                                        formularios: formularios,
                                        sede: sede,
                                        carreras: carreras,
                                        instituciones: instituciones,
                                        oferente: oferente,
                                    })
                                }
                            >
                                Buscar
                            </Button>
                            <Button
                                className="w-3/6"
                                onClick={() => {
                                    setInternalSearch("");
                                    setModalidades("");
                                    setFormularios("");
                                    setSede("");
                                    setCarreras("");
                                    setInstituciones("");
                                    setOferente("");

                                    setSearch({
                                        internalSearch: "",
                                        modalidades: "",
                                        formularios: "",
                                        sede: "",
                                        carreras: "",
                                        instituciones: "",
                                        oferente: "",
                                    });
                                }}
                            >
                                Limpiar Filtros
                            </Button>
                            <Button className="w-3/6" onClick={modalOpener}>
                                Agregar
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default function OfertaAcademica({ id = 0, convocatoria = "" }) {
    // Upper Hooks
    const { ExcelGetter } = useFileDownloader();

    // State
    const [pagination, setPagination] = useState([]);
    const [currOfertas, setCurrOfertas] = useState([]);
    const [selectData, setSelectData] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [instituciones, setInstituciones] = useState([]);
    const [selectedInstituciones, setSelectedInstituciones] = useState("");

    const [oferentes, setOferentes] = useState([]);
    const [selectedOferentes, setSelectedOferentes] = useState("");

    const [formularios, setFormularios] = useState([]);
    const [selectedFormularios, setSelectedFormularios] = useState("");

    const [carreras, setCarreras] = useState([]);
    const [selectedCarrera, setSelectedCarrera] = useState("");

    const [coverage, setCoverage] = useState(0);

    const [pastMultiplesFound, setPastMultiplesFound] = useState([]);

    const [isSpinning, setIsSpinning] = useState(false);

    // Const Variables ( Mostly options )

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 660,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    const columns = [
        {
            label: "Portada",
            name: "image_url",
            options: {
                customBodyRender: (url) => (
                    <img src={url} alt="portada" className="w-16" />
                ),
            },
        },
        {
            label: "Oferente",
            name: "oferente",
        },
        {
            label: "Institución",
            name: "institucion",
        },
        {
            label: "Carrera",
            name: "carrera",
        },
        {
            label: "Sede",
            name: "sede",
        },
        {
            label: "Formulario",
            name: "formulario",
        },
        {
            label: "Cobertura",
            name: "coverage",
        },
        {
            label: "Días",
            name: "dias",
        },
        {
            label: "Horario",
            name: "horario",
        },
        {
            label: "Modalidad",
            name: "modalidad",
        },
    ];

    const options = {
        selectableRows: false,
        pagination: false,
        search: false,
        toolbar: false,
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
        onChangePage: (currPage) => paginateData(currPage),
        onRowsDelete: (row) => rowDeletion(row),
    };

    // Functions
    function paginateData(currPn) {
        currPn = currPn + 1;
        if (!pastMultiplesFound.includes(currPn) && currPn % 3 === 0) {
            let next = pagination?.links?.next;
            if (next !== null) {
                getComponentFetchedData(next.replace("http", "https"));
            }
            setPastMultiplesFound([...pastMultiplesFound, currPn]);
        }
    }

    function rowDeletion({ data = [] }) {
        data.forEach(async ({ index }) => {
            await Fetch({
                data: { convocatoria_detail_id: currOfertas[index]?.id ?? 0 },
                uri: "/convocatorias/details/delete",
            });
        });
    }

    async function getComponentFetchedData(link) {
        let firstCall = await Fetch({
            data: { convocatoria: id, estado: "todas" },
            uri: link,
        });

        setPagination(firstCall?.data || {});
        setCurrOfertas(currOfertas.concat(firstCall?.data?.data ?? []));
    }

    async function getAndSetAllFormData() {
        let {
            data: { data: responseInstituciones },
        } = await Fetch({
            uri: "instituciones/lista",
            method: "get",
        });

        setInstituciones(responseInstituciones);

        let {
            data: { data: responseOferente },
        } = await Fetch({
            uri: "oferentes/getAll",
            method: "get",
        });

        setOferentes(responseOferente);

        let {
            data: { data: responseFormularios },
        } = await Fetch({
            uri: "formularios/lista",
            method: "get",
        });

        setFormularios(responseFormularios);
    }

    async function getCarrerasFromInstitucion(value) {
        let {
            data: { data: responseCarreras },
        } = await Fetch({
            uri: `instituciones/ofertas/lista?institucion=${value}`,
            method: "get",
        });

        setCarreras(responseCarreras);
    }

    function cleanState() {
        setSelectedInstituciones("");
        setSelectedOferentes("");
        setSelectedFormularios("");
        setSelectedCarrera("");
        setCoverage("");
        setPastMultiplesFound("");

        setIsOpen(!isOpen);
        initialCall();
    }

    async function makePost() {
        try {
            await Fetch({
                data: {
                    convocatoria_id: id,
                    formulario_id: selectedFormularios,
                    institution_offer_id: selectedCarrera,
                    offerer_id: selectedOferentes,
                    coverage: coverage,
                },
                uri: "convocatorias/details/create",
                method: "post",
            });

            toast("Oferta Agregada");
            cleanState();
        } catch (e) {
            toast.error(`${e.response.status}, no pudo ser procesado`);
        }
    }

    async function paginate(link) {
        let firstCall = await Fetch({
            data: { convocatoria: id, estado: "todas" },
            uri: replaceHttpToHttps(link),
        });

        setPagination(firstCall?.data || {});
        setCurrOfertas(firstCall?.data?.data ?? []);
        getAndSetAllFormData();
    }

    async function initialCall() {
        let firstCall = await Fetch({
            data: { convocatoria: id, estado: "todas" },
            uri: "/convocatorias/details/filter",
        });

        Promise.all([
            Fetch({
                uri: `selects/ofertas/modalidades?convocatoria=${id}`,
                method: "get",
            }),
            Fetch({
                uri: `selects/ofertas/formularios?convocatoria=${id}`,
                method: "get",
            }),
            Fetch({
                uri: `selects/ofertas/sedes?convocatoria=${id}`,
                method: "get",
            }),
            Fetch({
                uri: `selects/ofertas/carreras?convocatoria=${id}`,
                method: "get",
            }),
            Fetch({
                uri: `selects/ofertas/instituciones?convocatoria=${id}`,
                method: "get",
            }),
            Fetch({
                uri: `selects/ofertas/oferentes?convocatoria=${id}`,
                method: "get",
            }),
        ]).then(
            ([
                modalidades,
                formularios,
                sedes,
                carreras,
                instituciones,
                oferentes,
            ]) =>
                setSelectData({
                    modalidades: modalidades.data.data ?? [],
                    formularios: formularios.data.data ?? [],
                    sedes: sedes.data.data ?? [],
                    carreras: carreras.data.data ?? [],
                    instituciones: instituciones.data.data ?? [],
                    oferentes: oferentes.data.data ?? [],
                })
        );

        setPagination(firstCall?.data || {});
        setCurrOfertas(firstCall?.data?.data ?? []);
        getAndSetAllFormData();
    }

    function getExportableData(args) {
        setIsSpinning(true);

        let {
            modalidades,
            formularios,
            sede,
            carreras,
            instituciones,
            oferente,
        } = args;

        Fetch({
            uri: `convocatorias/details/exportar`,
            data: {
                convocatoria: id,
                estado: "todas",
                modalidad: modalidades,
                formulario: formularios,
                sede: sede,
                carrera: carreras,
                institucion: instituciones,
                oferente: oferente,
            },
        }).then(({ data: { data } }) => {
            ExcelGetter(data, `Ofertas Academicas -> ${convocatoria}`);
            setIsSpinning(false);
        });
    }

    async function searchby({
        internalSearch,
        modalidades,
        formularios,
        sede,
        carreras,
        instituciones,
        oferente,
    }) {
        let firstCall = await Fetch({
            data: {
                convocatoria: id,
                estado: "todas",
                modalidad: modalidades,
                busqueda: internalSearch,
                institucion: instituciones,
                sede: sede,
                formulario: formularios,
                carrera: carreras,
                oferente: oferente,
            },
            uri: replaceHttpToHttps("/convocatorias/details/filter"),
        });

        setPagination(firstCall?.data || {});
        setCurrOfertas(firstCall?.data?.data ?? []);
        getAndSetAllFormData();
    }

    // Use Effects

    useEffect(() => initialCall(), []);

    return (
        <>
            {!currOfertas.length > 0 && selectData === null ? (
                <Spinner />
            ) : (
                <>
                    <Modal
                        open={isOpen}
                        onClose={() => setIsOpen(!isOpen)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <label>
                                    Convocatoria
                                    <input
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        disabled
                                        defaultValue={convocatoria}
                                    />
                                </label>

                                <SelectField
                                    loading={false}
                                    label="Oferente"
                                    options={oferentes.map(({ id, name }) => ({
                                        label: name,
                                        value: id,
                                    }))}
                                    onChange={({ target: { value } }) =>
                                        setSelectedOferentes(value)
                                    }
                                />

                                <SelectField
                                    loading={false}
                                    label="Institución"
                                    options={instituciones.map(
                                        ({ id, name }) => ({
                                            label: name,
                                            value: id,
                                        })
                                    )}
                                    onChange={({ target: { value } }) => {
                                        setSelectedInstituciones(value);
                                        getCarrerasFromInstitucion(value);
                                    }}
                                />
                                <SelectField
                                    label="Carrera"
                                    loading={carreras.length < 1}
                                    options={carreras.map(
                                        ({ carrera, id }) => ({
                                            label: carrera,
                                            value: id,
                                        })
                                    )}
                                    onChange={({ target: { value } }) =>
                                        setSelectedCarrera(value)
                                    }
                                />

                                <SelectField
                                    loading={false}
                                    label="Formulario"
                                    options={formularios.map(
                                        ({ name, id }) => ({
                                            label: name,
                                            value: id,
                                        })
                                    )}
                                    onChange={({ target: { value } }) =>
                                        setSelectedFormularios(value)
                                    }
                                />
                                <InputField
                                    type="number"
                                    label="Cobertura"
                                    max="100"
                                    onChange={({ target: { value } }) =>
                                        setCoverage(value)
                                    }
                                />

                                <Button onClick={makePost}> Agregar </Button>
                                <Button
                                    onClick={() => setIsOpen(!isOpen)}
                                    variant="light"
                                >
                                    Cancelar
                                </Button>
                            </div>
                        </Box>
                    </Modal>

                    <Filterable
                        selects={selectData ?? {}}
                        modalOpener={() => setIsOpen(!isOpen)}
                        exportable={(obj) => getExportableData(obj)}
                        setSearch={searchby}
                        isSpinning={isSpinning}
                    />

                    <MUIDataTable
                        data={currOfertas}
                        columns={columns}
                        options={options}
                    />

                    <Pagination
                        meta={pagination.meta ?? {}}
                        links={pagination.links ?? []}
                        linkCaller={(link) => paginate(link)}
                    />
                </>
            )}
        </>
    );
}
