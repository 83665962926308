export default function Experiencialaboral({ data }) {
    return data.map((exp) => (
        <div className="border-b-2 pb-8 mb-8">
            <form className="grid grid-cols-2 gap-3">
                <div>
                    <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Empresa
                    </label>
                    <div className="mt-1">
                        <input
                            id="about"
                            name="about"
                            className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                            value={exp.empresa}
                            disabled
                        />
                    </div>
                </div>
                <div>
                    <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Puesto
                    </label>
                    <div className="mt-1">
                        <input
                            id="about"
                            name="about"
                            className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                            value={exp.posicion}
                            disabled
                        />
                    </div>
                </div>
                <div>
                    <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Tipo de contrato
                    </label>
                    <div className="mt-1">
                        <input
                            id="about"
                            name="about"
                            className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                            value={exp.tipo_contrato}
                            disabled
                        />
                    </div>
                </div>
                <div>
                    <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Documento anexo
                    </label>
                    <div className="mt-1">
                        <a
                            href={exp.documento_url}
                            className="bg-gray-50 shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Ver documento
                        </a>
                    </div>
                </div>
                <div>
                    <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Fecha de entrada
                    </label>
                    <div className="mt-1">
                        <input
                            id="about"
                            name="about"
                            className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                            value={exp.fecha_entrada}
                            disabled
                        />
                    </div>
                </div>
                <div>
                    <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Fecha de salida
                    </label>
                    <div className="mt-1">
                        <input
                            id="about"
                            name="about"
                            className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                            value={exp.fecha_salida}
                            disabled
                        />
                    </div>
                </div>
            </form>
        </div>
    ));
}
