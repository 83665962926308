import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { FieldWrapper } from "../../../components/Form/FieldWrapper";
import { InputField, SelectField } from "../../../components/Form";

import * as yup from "yup";

const schema = yup
    .object({
        name: yup.string().required("Requerido"),
        convocatoria_type_id: yup.string().required("Requerido"),
        start_date: yup.string().required("Requerido"),
        end_date: yup.string().required("Requerido"),
        audience_id: yup.string().required("Requerido"),
        coordinator_id: yup.string().required("Requerido"),
    })
    .required();

export default function DatosGenerales() {
    const coordinadores = useSelector((state) => state.coordinadores.all);
    const init = useSelector((state) => state.init.all);
    const { register, formState, watch, setValue } = useFormContext();
    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <InputField
                    label="Nombre de la convocatoria"
                    error={formState.errors.name}
                    registration={register("name")}
                />
                <SelectField
                    label="Tipo de beca"
                    error={formState.errors.convocatoria_type_id}
                    registration={register("convocatoria_type_id")}
                    options={init?.tipos_convocatorias?.map((obj) => ({
                        label: obj.name,
                        value: obj.id,
                    }))}
                />
                <InputField
                    type="date"
                    label="Inicio de convocatoria"
                    error={formState.errors.start_date}
                    registration={register("start_date")}
                />
                <InputField
                    type="date"
                    label="Fin de convocatoria"
                    error={formState.errors.end_date}
                    registration={register("end_date")}
                />
                <SelectField
                    label="¿A quién va dirigido?"
                    error={formState.errors.audience_id}
                    registration={register("audience_id")}
                    options={init?.audiencias?.map((obj) => ({
                        label: obj.name,
                        value: obj.id,
                    }))}
                />
                <SelectField
                    label="Coordinador"
                    error={formState.errors.coordinator_id}
                    registration={register("coordinator_id")}
                    options={coordinadores?.map((obj) => ({
                        label: obj.name,
                        value: obj.id,
                    }))}
                />

                <div>
                    <FieldWrapper
                        label={"Imagen de portada"}
                        error={formState.errors.image}
                        type="file"
                        accept="image/*"
                        watch={watch("image")}
                        setValue={setValue}
                        name='image'
                    >
                        <div className="mt-1">
                            <input
                                type="file"
                                id="image"
                                accept="image/*"
                                name="image"
                                onChange={({target})=>
                                    setValue(target.name,target.files)
                                }
                                className="hidden appearance-none block w-full px-3  border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"  
                            />
                        </div>
                    </FieldWrapper>
                </div>
            </div>
        </>
    );
}
