import AuthAxios from "../../config/AuthAxios";

export async function GetAll() {
    const Axios = AuthAxios();
    try {
        return Axios.get(`/instituciones/getAll`);
    } catch (err) {
        console.error(err);
    }
}
