import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getOne } from "../../Store/slices/evaluadores";
import { Fetch } from "../../Services/Fetch";
import Spinner from "../../components/Spinner";
import Filter from "../../components/filter";
import { Button } from "../../components/Button";
import NewModal from "../../components/modal";

export default function Index() {
    // Constants
    const name = "Evaluadores";

    // Hooks
    const dispatch = useDispatch();
    const history = useHistory();

    // Statefull Variables
    const [param, setParam] = useState("");
    const [modalDispatcher, setModalDispatcher] = useState(false);
    const [modalContent, setModalContent] = useState({});

    // Storage Variables
    const { institutions } = useSelector((state) => state.evaluadores.one);
    const { status } = useSelector((state) => state.evaluadores);

    // Functions
    function filter(data = []) {
        return data.filter(
            ({ institution, convocatoria }) =>
                institution?.name
                    ?.toLowerCase()
                    .includes(param.toLowerCase()) ||
                convocatoria?.name?.toLowerCase().includes(param.toLowerCase())
        );
    }

    function cardClicker(inst_i = 0, convoc_id = 0) {
        history.push(
            `/evaluaciones/detalles_evaluaciones/${inst_i}/${convoc_id}`
        );
    }

    function displayModal() {
        setModalDispatcher(!modalDispatcher);
    }

    function statsCheker(institucion, convocatoria) {
        Fetch({
            uri: `estadisticas/institucion?convocatoria=${convocatoria}&institucion=${institucion}`,
            method: "get",
        }).then(({ data: { data } }) => {
            setModalContent(data);

            displayModal();
        });
    }

    // Effects
    useEffect(() => {
        document.title = `${name} | ${global.title}`;
        dispatch(getOne());
    }, []);

    return (
        <>
            <Filter textfilter={setParam} />
            <NewModal
                isVisible={modalDispatcher}
                action={displayModal}
                title={"Estadisticas"}
            >
                <div className="grid grid-cols-2 gap-2 border-2 border-slate-600 rounded-xl px-2">
                    <div>
                        <h3 className="text-sm font-bold sm:text-base line-clamp-3">
                            Solicitudes : {modalContent?.solicitudes?.enviadas}
                        </h3>
                    </div>

                    <div>
                        <h3 className="text-sm font-bold sm:text-base line-clamp-3">
                            Preseleccionadas :{" "}
                            {modalContent?.solicitudes?.preseleccionadas}
                        </h3>
                    </div>

                    <div>
                        <h3 className=" text-sm font-bold sm:text-base line-clamp-3">
                            Declinadas : {modalContent?.solicitudes?.declinadas}
                        </h3>
                    </div>

                    <div>
                        <h3 className="text-sm font-bold sm:text-base line-clamp-3">
                            Revision : {modalContent?.solicitudes?.en_revision}
                        </h3>
                    </div>

                    <div>
                        <h3 className="text-sm font-bold sm:text-base line-clamp-3">
                            Pendientes :{" "}
                            {(modalContent?.solicitudes?.enviadas || 0) -
                                ((modalContent?.solicitudes?.en_evaluacion ||
                                    0) +
                                    (modalContent?.solicitudes?.en_revision ||
                                        0) +
                                    (modalContent?.solicitudes?.declinadas ||
                                        0))}
                        </h3>
                    </div>
                </div>
            </NewModal>

            {status === "loading" ? (
                <Spinner />
            ) : (
                <div className="grid grid-cols-2 xl:grid-cols-2 gap-8 p-4">
                    {filter(institutions).map((evaluation, key) => {
                        return (
                            <div key={key}>
                                <div
                                    key={evaluation.name}
                                    className="bg-white max-w-3xl sm:w-full h-auto sm:h-74 rounded-md shadow-lg flex flex-col sm:flex-row gap-5 select-none"
                                >
                                    <img
                                        src={evaluation.institution.image_url}
                                        className="h-52 sm:h-full sm:w-2/5 rounded-l-md object-center object-cover"
                                    />

                                    <div className="flex sm:flex-1 flex-col gap-2 p-1 py-2">
                                        <div>
                                            <h3 className="text-gray-400 text-sm font-bold sm:text-base line-clamp-3">
                                                Institución
                                            </h3>
                                            <p className=" text-sm sm:text-base line-clamp-3">
                                                {evaluation.institution?.name}
                                            </p>
                                        </div>
                                        <div>
                                            <h3 className="text-gray-400 text-sm font-bold sm:text-base line-clamp-3">
                                                Convocatoria
                                            </h3>
                                            <p className=" text-sm sm:text-base line-clamp-3">
                                                {evaluation.convocatoria?.name}
                                            </p>
                                            <div className="flex pt-2 px-2 flex-col xl:flex-row">
                                                <Button
                                                    onClick={() =>
                                                        cardClicker(
                                                            evaluation
                                                                .institution.id,
                                                            evaluation
                                                                .convocatoria.id
                                                        )
                                                    }
                                                >
                                                    Ver Solicitudes
                                                </Button>

                                                <Button
                                                    variant="inverse2"
                                                    onClick={() =>
                                                        statsCheker(
                                                            evaluation
                                                                .institution.id,
                                                            evaluation
                                                                .convocatoria.id
                                                        )
                                                    }
                                                >
                                                    Ver Estadisticas
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
}
