import * as Icon from "react-feather";
import { useLocation, useHistory } from "react-router-dom";

export default function SubHeader() {
    const history = useHistory();

    const location = useLocation();
    let title = location.pathname.split("/")[1];
    title = title.replace("_", " ");

    return (
        <div className="bg-custom-200 drop-shadow flex items-center p-[18px] justify-between">
            <button
                onClick={() => history.goBack()}
                className="flex items-center group gap-1"
            >
                <Icon.ArrowLeft className="transform group-hover:-translate-x-2 transition-all duration-200" />
                Volver
            </button>

            <div className="cursor-pointer">
                <span className=" text-xl capitalize">
                    {title === "inicio" ? "Dashboard" : title}
                </span>
            </div>
        </div>
    );
}
