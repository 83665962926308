import { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

export default function MostSubmitted({ title = '', data = {} }) {
  // Functions
  function formatKeys(x) {
    switch (x) {
      case 'aprobadas':
        return 'Aprobadas';
      case 'declinadas':
        return 'Declinadas';
      case 'en_evaluacion':
        return 'En Evaluacion';
      case 'en_revision':
        return 'En Revision';
      case 'enviadas':
        return 'Enviadas';
      case 'incompletas':
        return 'Incompletas';
      case 'no_terminadas':
        return 'Iniciadas';
      case 'preseleccionadas':
        return 'Preseleccionadas';
      default:
        return '';
    }
  }

  // Constants
  const [series, setSeries] = useState([
    {
      data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
    },
  ]);

  console.log(series)

  const categories = [
    'Iniciadas',
    'Enviadas',
    'En Evaluación',
    'En Revisión',
    'Preseleccionadas',
    'Incompletas',
    'Declinadas',
    'Aprobadas',
  ];

  const availableOptions = {
    no_terminadas: 0,
    enviadas: 0,
    en_evaluacion: 0,
    en_revision: 0,
    preseleccionadas: 0,
    incompletas: 0,
    declinadas: 0,
    aprobadas: 0,
  };

  const colors = ['#00A2FF', '#FFC107', '#7BB31A', '#FF9800', '#9400ca', '#8acc72', '#c89900', '#e2639c'];

  const options = {
    chart: {
      height: 350,
      type: 'bar',
    },
    colors: colors,
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    },
  };

  // Effects
  useEffect(() => {
    let assignableData = {};

    for (let keys in availableOptions) {
      assignableData = {
        ...assignableData,
        [formatKeys(keys)]: data[keys],
      };
    }

    setSeries([{ data: Object.values(assignableData) }]);
  }, []);

  return (
    <div className="h-[340px] p-8 bg-white col-span-3 rounded-lg transition shadow-sm hover:shadow-xl">
      <h3 className="text-base font-light">{title}</h3>
      <Chart options={options} series={series} type="bar" height={270} />
    </div>
  );
}
