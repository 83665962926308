import { useState } from "react";
import { toast } from "react-toastify";
import { Button } from "../../components/Button";
import renderErrors from "../../helpers/renderErrors";
import { Fetch } from "../../Services/Fetch";
import { deleteForm } from "../../Store/slices/formularios";
import alertify from "alertifyjs";
import { InputField, SelectField, TextAreaField } from "../../components/Form";
export default function Formularios({ data, fetchForms }) {
    const [selectedForm, setSelectedForm] = useState({});
    const [loading, setloading] = useState(false);

    const cleanDataString = (string) => {
        return string && string.replace(/[\[\]']+/g, "");
    };

    const getIndexedArray = (arr) => {
        let formIndex = arr?.reduce(
            (acc, curr) => ({
                ...acc,
                [curr.seccion]: arr.filter((ob) => ob.seccion === curr.seccion),
            }),
            {}
        );
        formIndex = Object.entries(formIndex || "");

        return formIndex || [];
    };
    const getElement = (obj) => {
        if (obj.type === "checkbox") {
            return (
                <div>
                    <div>
                        {" "}
                        <span className="w-full font-semibold text-gray-700">
                            {obj.name}
                        </span>
                    </div>
                    <span className="text-gray-700">{obj.description}</span>

                    {cleanDataString(obj.data)
                        ?.split(",")
                        .map((ob) => (
                            <div className="flex gap-2 mb-2">
                                <InputField type="checkbox" />
                                <span>{ob}</span>
                            </div>
                        ))}
                </div>
            );
        }
        if (obj.type === "radio") {
            return (
                <div>
                    <div>
                        {" "}
                        <span className="w-full font-semibold text-gray-700">
                            {obj.name}
                        </span>
                    </div>
                    <span className="text-gray-700">{obj.description}</span>

                    {cleanDataString(obj.data)
                        ?.split(",")
                        .map((ob) => (
                            <div className="flex gap-2 mb-2">
                                <InputField type="radio" />
                                <span>{ob}</span>
                            </div>
                        ))}
                </div>
            );
        }

        if (obj.type === "select") {
            return (
                <div>
                    <div>
                        {" "}
                        <span className="w-full font-semibold text-gray-700">
                            {obj.name}
                        </span>
                    </div>
                    <SelectField
                        label={obj.description}
                        options={cleanDataString(obj.data)
                            ?.split(",")
                            .map((ob) => ({
                                label: ob,
                                value: ob,
                            }))}
                    />
                </div>
            );
        }

        if (obj.type === "textarea") {
            return (
                <div>
                    <span className="font-semibold text-gray-700">
                        {obj.name}
                    </span>
                    <TextAreaField label={obj.description} />
                </div>
            );
        }

        return (
            <div>
                <span className="font-semibold text-gray-700">{obj.name}</span>
                <InputField
                    type={obj.type}
                    label={obj.description}
                    className={`${obj.type === "file" && "py-0"}`}
                />
            </div>
        );
    };

    const DeleteElement = async () => {
        alertify.confirm(
            "Eliminar formulario",
            `Esta seguro que desea eliminar este formulario`,
            async function () {
                setloading(true);
                try {
                    await Fetch({
                        data: { formulario_id: selectedForm.id },
                        uri: "/formularios/delete",
                    });
                    toast.success("Eliminado exitosamente");
                    dispatch(deleteForm(selectedForm.id));
                } catch (error) {
                    renderErrors(error.response?.data);
                }
                setSelectedForm({});
                fetchForms();
                setloading(false);
            },
            function () {}
        );
    };

    return (
        <>
            <div className="gap-8 p-4 bg-white ">
                <div>
                    <div className="gap-2">
                        <label>
                            {/* <h3 className="my-5 text-base font-bold uppercase text-secondary">
                                Formularios
                            </h3> */}
                            <div>
                                <select
                                    name="formulario_id"
                                    className="flex placeholder-[#ABAFB3] rounded h-[52px] px-4 w-full border-solid border-[1px] border-[#DDDFE1] items-center"
                                    defaultValue={data?.formulario_id}
                                    onChange={(event) => {
                                        const form = data?.find(
                                            (obj) =>
                                                obj.id ===
                                                parseInt(event.target.value)
                                        );
                                        setSelectedForm(form);
                                    }}
                                >
                                    <option value="0" selected>
                                        Seleccione un formulario...
                                    </option>
                                    {data?.map((obj) => (
                                        <option key={obj?.id} value={obj?.id}>
                                            {obj?.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </label>
                    </div>
                </div>
                <div className="grid grid-cols-4 gap-4 py-4">
                    {selectedForm?.details?.length ? (
                        getIndexedArray(selectedForm?.details).map((obj) => (
                            <div className="col-span-3">
                                <h1 className="mb-2 text-lg font-semibold">
                                    {obj[0]}
                                </h1>
                                <div className="grid gap-4">
                                    {obj[1]?.map((ob) => getElement(ob))}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="col-span-4 ">
                            <h1 className="w-full text-center">
                                Este Formulario no contiene detalles
                            </h1>
                        </div>
                    )}{" "}
                </div>

                <div className="w-full flex justify-end mt-[90px]">
                    <Button
                        onClick={DeleteElement}
                        isLoading={loading}
                        variant="danger"
                        disabled={!selectedForm?.id}
                    >
                        Eliminar
                    </Button>
                </div>
            </div>
        </>
    );
}
