import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Fetch } from "../Services/Fetch";
import NewModal from "./modal";
import * as Icon from "react-feather";
import { useDispatch } from "react-redux";
import { getOneBecado } from "../Store/slices/becados";
import { Button } from "./Button";

import { Form, InputField } from "../components/Form";

import * as yup from "yup";

const schema = yup
    .object({
        rango_maximo: yup
            .number()
            .typeError("Número invalido")
            .positive("Debe ser un número positivo")
            .required("Requerido"),
        calificacion: yup
            .number()
            .typeError("Número invalido")
            .positive("Debe ser un número  positivo")
            .required("Requerido"),
        periodo: yup.string().required("Requerido"),
    })
    .required();

export default function BecadosDetallesSidebar({
    status,
    scholarship_id,
    data,
}) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [becadoToDelete, setBecadoToDelete] = useState(undefined);
    const [deleteModal, setDeleteModal] = useState(false);
    const ind = data?.reduce(
        (acc, curr) => {
            acc.maximo = acc.maximo + curr?.rango_maximo;
            acc.calificacion = acc.calificacion + curr?.calificacion;
            return acc;
        },
        {
            maximo: 0,
            calificacion: 0,
            length: data.length,
        }
    );

    useEffect(() => {
        dispatch(getOneBecado({ scholarship_id }));
    }, [loading]);

    const onSubmit = async (data) => {
        try {
            setLoading(true);
            await Fetch({
                data: { ...data, scholarship_id, rango_minimo: 1 },
                uri: "/becados/detalles/create",
            });
            setLoading(false);
            setModal(false);
        } catch (error) {
            let message;

            if (error.response?.data?.errors) {
                const errorsMessage = Object.entries(
                    error.response?.data?.errors
                );
                message = errorsMessage[0][1][0];
            } else if (error.response?.data?.message) {
                message = error.response?.data?.message;
            } else {
                message = error.message;
            }
            setLoading(false);
            toast.error(message);
        }
    };

    const handleDelete = async () => {
        try {
            setLoading(true);
            await Fetch({
                data: { scholarship_detail_id: becadoToDelete },
                uri: "/becados/detalles/delete",
            });
            setLoading(false);
            setDeleteModal(false);
        } catch (error) {
            setLoading(false);
            let message;

            if (error.response?.data?.errors) {
                const errorsMessage = Object.entries(
                    error.response?.data?.errors
                );
                message = errorsMessage[0][1][0];
            } else if (error.response?.data?.message) {
                message = error.response?.data?.message;
            } else {
                message = error.message;
            }

            toast.error(message);
        }
    };

    return (
        <div>
            <NewModal
                action={() => setModal(false)}
                isVisible={modal}
                title="Agregar Calificación"
            >
                <Form
                    onSubmit={onSubmit}
                    options={{ defaultValues: { rango_maximo: 100 } }}
                    schema={schema}
                >
                    {({ register, formState }) => (
                        <>
                            <InputField
                                type="number"
                                label="Rango máximo"
                                error={formState.errors.rango_maximo}
                                registration={register("rango_maximo")}
                            />

                            <InputField
                                type="number"
                                label="Calificación"
                                error={formState.errors.calificacion}
                                registration={register("calificacion")}
                            />

                            <InputField
                                label="Periodo"
                                error={formState.errors.periodo}
                                registration={register("periodo")}
                            />

                            <div className="flex justify-end mt-4">
                                <Button type="submit" isLoading={loading}>
                                    Guardar
                                </Button>
                            </div>
                        </>
                    )}
                </Form>
            </NewModal>

            <NewModal
                action={() => setDeleteModal(false)}
                isVisible={deleteModal}
                title="Borrar calificación"
            >
                <div>¿Seguro que quiere borrar esta calificación?</div>
                <div className="flex justify-end mt-4">
                    <Button
                        onClick={() => handleDelete()}
                        isLoading={loading}
                        variant="danger"
                    >
                        Borrar
                    </Button>
                </div>
            </NewModal>

            <div className="w-full p-4 flex items-center justify-between bg-secondary text-white text-lg font-bold">
                <div>{`Historial académico`}</div>
                <div>{`${(ind?.calificacion / ind?.length).toFixed(1)}/${(
                    ind?.maximo / ind?.length
                ).toFixed(1)}`}</div>
            </div>

            {data?.map((o, i) => {
                return (
                    <>
                        <div key={i} className="pb-3 mt-4 mx-4 border-b-2 z-0">
                            <div className="flex items-center justify-between pb-2  ">
                                <div className="text-lg font-bold">
                                    {o.periodo}
                                </div>

                                <div className="font-bold flex justify-center items-center gap-4">
                                    {o.calificacion}/{o.rango_maximo}
                                    {status === "activo" && (
                                        <button
                                            onClick={() => {
                                                setDeleteModal(true);
                                                setBecadoToDelete(o.id);
                                            }}
                                        >
                                            <Icon.Trash className="w-4 h-4  transform hover:scale-110 hover:text-red-600 transition-all duration-200" />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                );
            })}
            {status === "activo" && (
                <div className="flex my-5 justify-center">
                    <Button onClick={() => setModal(true)}>CALIFICAR</Button>
                </div>
            )}
        </div>
    );
}
