import React, { useEffect, useRef, useState } from "react";
import * as Icon from "react-feather";
import Filter from "../components/filter";
import SimpleCard from "../components/card/simpleCard";
import MostSubmitted from "../components/card/mostSubmitted";
import Gender from "../components/card/gender";
import Spinner from "../components/Spinner";
import ColumnsSubmitted from "../components/card/ColumnsSubmitted";
import CountryCard from "../components/card/countryCard";
import { Fetch } from "../Services/Fetch";
import html2canvas from "html2canvas";
import JsPDF from "jspdf";

// comment

export default function Dashboard() {
    const [loading, setloading] = useState(true);
    const [init, setinit] = useState({});
    const [solGraph, setSolGraph] = useState({});
    const [solicitedGraphValues, setSolicitedGraphValues] = useState({});
    const [totalPerState, setTotalPerState] = useState([]);

    // Functions
    async function getStatistics() {
        Promise.all([
            Fetch({
                uri: "/estadisticas/generales",
                method: "get",
            }),
            Fetch({
                uri: "/estadisticas/areas/solicitadas",
                method: "get",
            }),
            Fetch({
                uri: "/estadisticas/provincias/solicitadas",
                method: "get",
            }),
        ])
            .then(([generales, areas, provincias]) => {
                const {
                    data: { data: a1 },
                } = generales;
                const {
                    data: { data: b1 },
                } = areas;
                const {
                    data: { data: c1 },
                } = provincias;

                setinit(a1 ?? {});
                setSolGraph(a1.solicitudes ?? []);
                setSolicitedGraphValues(b1 ?? []);
                setTotalPerState(c1 ?? []);
            })
            .then(() => setloading(false));
    }

    function generatePDF() {
        html2canvas(document.querySelector("#dashboard")).then((canvas) => {
            const pdf = new JsPDF("l", "mm");
            pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0);
            pdf.save(`dashboard - beca tu futuro`);
        });
    }

    useEffect(() => {
        document.title = `${"Dashboard"} | ${global.title}`;
        getStatistics();
    }, []);

    return (
        <>
            <Filter
                type="general"
                generatePDF={generatePDF}
                getStatistics={getStatistics}
            />
            <div id="dashboard" className="px-4">
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                            <SimpleCard
                                name="Registrados"
                                total={init.candidatos?.total || 0}
                                color="#012E6C"
                            >
                                <Icon.User
                                    size={50}
                                    className="text-custom-400"
                                />
                            </SimpleCard>
                            <SimpleCard
                                name="Convocatorias"
                                total={init.convocatorias?.totales || "0"}
                                color="#FF9800"
                            >
                                <Icon.BarChart2
                                    size={50}
                                    className="text-custom-400"
                                />
                            </SimpleCard>

                            <SimpleCard
                                name="Solicitudes "
                                total={init.solicitudes?.enviadas || 0}
                                color="#00A2FF"
                            >
                                <Icon.TrendingUp
                                    size={50}
                                    className="text-custom-400"
                                />
                            </SimpleCard>

                            <SimpleCard
                                name="Becados"
                                total={init.becados?.total || 0}
                                color="#7BB31A"
                            >
                                <Icon.UserCheck
                                    size={50}
                                    className="text-custom-400"
                                />
                            </SimpleCard>
                        </div>

                        <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-1 md:grid-cols-2">
                            <ColumnsSubmitted
                                title="Areas mas solicitadas"
                                data={solicitedGraphValues}
                            />
                        </div>
                        <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-1 md:grid-cols-2">
                            <div>
                                <MostSubmitted
                                    title="Estados Solicitudes"
                                    data={solGraph}
                                />
                            </div>
                            <div>
                                <Gender
                                    data={init.candidatos}
                                    title="Usuraios Registrados"
                                />
                            </div>
                            <CountryCard
                                data={totalPerState
                                    ?.sort((a, b) => {
                                        return a.Solicitudes - b.Solicitudes;
                                    })
                                    .reverse()}
                                title="Solicitudes por Provincia"
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
