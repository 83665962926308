import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Modal from "../../components/modal";
import Card from "../../components/card/imageCard";
import {
    setModal,
    create,
    getAllFiltered,
} from "../../Store/slices/instituciones";
import Spinner from "../../components/Spinner";
import { Button } from "../../components/Button";
import { Form, InputField, SelectField } from "../../components/Form";
import renderErrors from "../../helpers/renderErrors";
import * as yup from "yup";
import useFilter from "../../hooks/useFilter";
import { Fetch } from "../../Services/Fetch";
import { toast } from "react-toastify";
import { Tooltip } from "@material-ui/core";
import { replaceHttpToHttps } from "../../utils/http";
import { Pagination } from "../../components/Common/Index.js";

const schema = yup.object({
    name: yup.string().required("Requerido"),
    siglas: yup.string().required("Requerido"),
    institution_type_id: yup.string().required("Requerido"),
    contacto_telefono: yup.number().required("Requerido"),
    telefono: yup.string().required("Requerido"),
    direccion: yup.string().required("Requerido"),
    email: yup
        .string()
        .email("Correo electrónico inválido")
        .required("Requerido"),
    web: yup.string().required("Requerido"),
    contacto_persona: yup.string().required("Requerido"),
    contacto_email: yup.string().required("Requerido"),
    contacto_telefono: yup.string().required("Requerido"),
});

export default function Index() {
    const name = "Instituciones";
    const [isLoading, setIsLoading] = useState(false);
    const [param, setparam] = useState("");
    const [search, setSearch] = useState("");
    const [types, setTypes] = useState([]);
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        all: instituciones,
        allTypes: tiposInstituciones,
        modal,
        status,
    } = useSelector(({ instituciones }) => instituciones);

    const onSubmit = (data) => {
        data.image = data.image && data.image[0];
        setIsLoading(true);
        dispatch(create(data)).then((res) => {
            if (res.error) {
                renderErrors(res.payload);
                setIsLoading(false);
                return;
            }
            toast.success("Institución agregada satisfactoriamente");
        });
    };

    useEffect(() => {
        document.title = `${name} | ${global.title}`;
        dispatch(getAllFiltered());
        getintitutionstype();
    }, []);

    const getintitutionstype = async () => {
        try {
            const res = await Fetch({
                uri: "/instituciones/tipos/getAll",
                method: "get",
            });
            setTypes(res.data?.data);
        } catch (error) {}
    };

    const [filter] = useFilter(instituciones, param);

    const whereTo = (link) => {
        if (search !== "") {
            link !== null &&
                dispatch(
                    getAllFiltered(
                        replaceHttpToHttps(link + `&busqueda=${search}`)
                    )
                );
        } else {
            link !== null && dispatch(getAllFiltered(replaceHttpToHttps(link)));
        }
    };

    const filterData = () =>
        dispatch(getAllFiltered(`instituciones/filtrar?busqueda=${search}`));

    return (
        <>
            <Modal
                action={() =>
                    dispatch(setModal({ name: "modal", state: false }))
                }
                isVisible={modal}
                title="Crear nueva institución"
            >
                <Form onSubmit={onSubmit} schema={schema}>
                    {({ register, formState, watch, setValue }) => (
                        <>
                            <InputField
                                label="Nombre"
                                placeholder="Johnston LLC"
                                error={formState.errors.name}
                                registration={register("name")}
                            />

                            <InputField
                                label="Siglas"
                                placeholder="JLLC"
                                error={formState.errors.siglas}
                                registration={register("siglas")}
                            />

                            <InputField
                                type="phone"
                                label="Teléfono"
                                error={formState.errors.telefono}
                                registration={register("telefono")}
                                name="telefono"
                                onChange={setValue}
                            />

                            <InputField
                                type="email"
                                label="Correo electrónico"
                                placeholder="johnston@email.com"
                                error={formState.errors.email}
                                registration={register("email")}
                            />
                            <InputField
                                label="Dirección"
                                placeholder="Av. Máximo Gómez No.31, esq. Pedro Henríquez Ureña, Santo Domingo, República Dominicana"
                                error={formState.errors.direccion}
                                registration={register("direccion")}
                            />

                            <SelectField
                                label="Tipo de institución"
                                placeholder=""
                                error={formState.errors.institution_type_id}
                                registration={register("institution_type_id")}
                                options={types?.reduce(
                                    (acc, curr) => [
                                        ...acc,
                                        {
                                            value: curr.id,
                                            label: curr.name,
                                        },
                                    ],
                                    []
                                )}
                            />

                            <InputField
                                type="file"
                                label="Imagen de perfil"
                                accept="image/*"
                                error={formState.errors.image}
                                watch={watch("image")}
                                setValue={setValue}
                                name="image"
                            />

                            <InputField
                                label="Página Web"
                                placeholder="www.Johnston.com"
                                error={formState.errors.web}
                                registration={register("web")}
                            />

                            <div className="border-b-2">
                                Datos de la persona de contacto
                            </div>

                            <InputField
                                label="Nombre"
                                placeholder="Juan Santos"
                                error={formState.errors.contacto_persona}
                                registration={register("contacto_persona")}
                            />

                            <InputField
                                type="text"
                                label="Email"
                                placeholder="info@ejemplo.gob.do"
                                error={formState.errors.contacto_email}
                                registration={register("contacto_email")}
                            />

                            <InputField
                                type="phone"
                                label="Teléfono"
                                placeholder="8090000000"
                                error={formState.errors.contacto_telefono}
                                name="contacto_telefono"
                                onChange={setValue}
                            />

                            <div className="flex justify-end mt-4">
                                <Button type="submit" isLoading={isLoading}>
                                    Guardar
                                </Button>
                            </div>
                        </>
                    )}
                </Form>
            </Modal>

            <div className="py-8 px-5 md:flex md:justify-between items-center gap-4">
                <div className="flex md:items-center w-1/2">
                    <div className="flex text-base pr-4">
                        Filtrar Instituciones :
                    </div>
                    <div className="px-2 ">
                        <InputField
                            className={"w-full mr-10 "}
                            onChange={({ target: { value } }) =>
                                setSearch(value)
                            }
                        />
                    </div>
                    <Button onClick={filterData}> Buscar </Button>
                </div>

                <div>
                    <Button
                        onClick={() =>
                            dispatch(setModal({ name: "modal", state: true }))
                        }
                    >
                        Agregar
                    </Button>
                </div>
            </div>

            {status === "loading" ? (
                <Spinner />
            ) : (
                <div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 p-4">
                        {filter?.map((e) => (
                            <Card
                                onclick={() => {
                                    history.push(
                                        `/instituciones/detalles/${e.id}`
                                    );
                                }}
                                key={e.id}
                                image={
                                    e.image_url || `img/no_image_available.png`
                                }
                                title={e.name}
                                level={e.type.name}
                                object="contain"
                            >
                                <div className="flex justify-around  pb-2 ">
                                    <Tooltip title="Convocatorias">
                                        <div className="flex flex-col items-center">
                                            <Icon.TrendingUp
                                                className="text-[#ED232A]"
                                                size={20}
                                            />
                                            <span className="text-xs mt-1">
                                                {e.estadisticas.convocatorias}
                                            </span>
                                        </div>
                                    </Tooltip>
                                    <Tooltip title="Becados">
                                        <div className="flex flex-col items-center">
                                            <Icon.User
                                                className="text-[#7BB31A]"
                                                size={20}
                                            />
                                            <span className="text-xs mt-1">
                                                {e.estadisticas.becados}
                                            </span>
                                        </div>
                                    </Tooltip>
                                    <Tooltip title="Ofertas">
                                        <div className="flex flex-col items-center">
                                            <Icon.Book
                                                className="text-[#00A2FF]"
                                                size={20}
                                            />
                                            <span className="text-xs mt-1">
                                                {e.estadisticas.ofertas}
                                            </span>
                                        </div>
                                    </Tooltip>
                                </div>
                            </Card>
                        ))}
                    </div>

                    <Pagination
                        meta={status?.data?.meta ?? {}}
                        links={status?.data?.links ?? {}}
                        linkCaller={whereTo}
                    />
                </div>
            )}
        </>
    );
}
