import React from "react";
import { Link } from "react-router-dom";
import logosvg from "../../images/logo.svg";

export default function Logo() {
    return (
        <Link to="/">
            <img className="h-24 " src={logosvg} alt={global.title} />
        </Link>
    );
}
