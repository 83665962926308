import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

export const Form = ({
    onSubmit,
    children,
    className,
    options,
    id,
    schema,
    listening,
}) => {
    const methods = useForm({
        ...options,
        resolver: schema && yupResolver(schema),
    });

    const action = () => {
        onSubmit(methods.getValues(), methods.reset);
    };

    useEffect(() => {
        methods.watch((data, { name, type }) => {
            listening && listening(data, name, type);
        });
    }, [methods.watch]);

    return (
        <form
            className={clsx("space-y-6", className)}
            onSubmit={methods.handleSubmit(action)}
            id={id}
        >
            {children(methods)}
        </form>
    );
};
