import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import generateActions from "../generateActions";
import { Fetch } from "../../../Services/Fetch";

const name = "evaluations";

const initialState = {
    all: [],
    create: [],
    modal1: false,
    modal2: false,
};

export const setAllEvaluations = createAsyncThunk(
    `${name}/getall`,
    async (data) => await Fetch({ data, uri: "/evaluaciones/getAll" })
);

export const createEvaluation = createAsyncThunk(
    `${name}/create`,
    async (data, { rejectWithValue }) => {
        try {
            const res = await Fetch({ data, uri: "/evaluaciones/create" });
            return res;
        } catch (error) {
            throw error.response.data;
        }
    }
);

export const reducers = generateActions([
    { action: setAllEvaluations, stateProp: "all" },
    {
        action: createEvaluation,
        stateProp: "create",
        statePropAll: "all",
    },
]);

const formsSlice = createSlice({
    name,
    initialState,
    reducers: {
        addEvaluation: (state, { payload }) => {
            state.all = [...state.all, payload];
        },
        removeEvaluation: (state, { payload }) => {
            state.all = state.all.filter(
                (o) => parseInt(o.id) !== parseInt(payload)
            );
        },
    },
    extraReducers: reducers,
});

export const { addEvaluation ,removeEvaluation} = formsSlice.actions;

export default formsSlice.reducer;
