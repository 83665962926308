import AuthAxios from '../../config/AuthAxios'

export async function Create(data) {
  const form = new FormData()
  Object.entries(data).forEach((obj) => {
    form.append(obj[0], obj[1])
  })
  const Axios = AuthAxios()
  try {
    return Axios.post(`/convocatorias/create`, form)
  } catch (err) {
    console.error(err)
  }
}
