import SubHeader from "../components/SubHeder/Index";
import { Route, Switch } from "react-router";
import Index from "./Perfil/Index";

export default function Perfil() {
    return (
        <>
            {/* <SubHeader title="Perfil" /> */}
            <Switch>
                <Route path="/perfil">
                    <Index />
                </Route>
            </Switch>
        </>
    );
}
