import clsx from "clsx";

import { FieldWrapper } from "./FieldWrapper";

export const TextAreaField = ({
    label,
    className,
    registration,
    error,
    rows,
    placeholder,
    disabled,
}) => {
    return (
        <FieldWrapper label={label} error={error}>
            <textarea
                rows={rows}
                placeholder={placeholder}
                disabled={disabled}
                className={clsx(
                    "mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 shadow-sm disabled:bg-gray-100 disabled:text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md",
                    className
                )}
                {...registration}
            />
        </FieldWrapper>
    );
};
