import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Fetch } from "../../../../Services/Fetch";

const initialState = { data: {}, ofertas: [] };

export const createConvocatoria = createAsyncThunk(
    `/convocatoria/create`,
    async (data, { rejectWithValue }) => {
        try {
            const res = await Fetch({ data, uri: "/convocatorias/create" });
            return res?.data?.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const newConvocatoriaSlice = createSlice({
    name: "newConvocatoria",
    initialState,
    reducers: {
        createnewConvocatoria: (state, _) => {
            state.data = {
                id: null,
                name: "",
                mensaje: "",
            };
        },
        setConvocatoria: (state, { payload }) => {
            state.data = { ...payload, ofertas: payload.ofertas || [] };
        },
        addOfferta(state, action) {
            state.data.ofertas = [...state.data.ofertas, action.payload];
        },
        removeOfferta(state, action) {
            state.data.ofertas = state.data.ofertas?.filter((obj) => {
                return parseInt(obj.id, 10) !== parseInt(action.payload, 10);
            });
        },
    },
});

export const {
    setConvocatoria,
    removeOfferta,
    createnewConvocatoria,
    addOfferta,
} = newConvocatoriaSlice.actions;
export default newConvocatoriaSlice.reducer;
