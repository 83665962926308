import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "../Button";
export default function Tabnavigator({
    routes,
    tab_disabled_class,
    action = null,
    action2 = null,
}) {
    const location = useLocation();
    return (
        <div className="flex flex-col items-center justify-between sm:flex-row ">
            <div className="flex flex-wrap w-full ">
                {routes.map((obj, i) => {
                    if (obj?.disabled) {
                        return (
                            <Link
                                key={i}
                                to="#"
                                className="inline-block px-4 py-2 text-base text-gray-500 transition-colors duration-200 bg-gray-300 cursor-not-allowed "
                            >
                                <div className="flex items-center h-full ">
                                    {obj.name}
                                </div>
                            </Link>
                        );
                    }
                    return (
                        <Link
                            key={i}
                            to={obj?.path}
                            className={`
                            ${
                                location.pathname === obj?.path
                                    ? "text-secondary bg-white"
                                    : `${
                                          obj?.error
                                              ? "bg-red-200"
                                              : "bg-[#EFEFEF]"
                                      }`
                            }
                            inline-block text-base py-2 px-4 cursor-pointer hover:opacity-80 transition-colors duration-200
                            `}
                        >
                            <div
                                className={`flex items-center h-full ${tab_disabled_class}`}
                            >
                                {obj?.name}
                            </div>
                        </Link>
                    );
                })}
            </div>
            {action && (
                <Button
                    onClick={action.do}
                    disabled={false}
                    className={action.className || ""}
                >
                    {action.name}
                </Button>
            )}

            {action2 && (
                <Button
                    onClick={action2.do}
                    title="Enviar Correo electronico al solicitante"
                    disabled={false}
                    className={action2.className || ""}
                >
                    {action2.name}
                </Button>
            )}
        </div>
    );
}
