import Spinner from "../../components/Spinner";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

export default function Historial({ data }) {
    const columns = [
        {
            label: "Nombre",
            name: "firstName",
        },
        {
            label: "Apellido",
            name: "lastName",
        },
        {
            label: "Género",
            name: "gender",
        },
        {
            label: "Institución",
            name: "institution",
        },
        {
            label: "Carrera",
            name: "profession",
        },
        {
            label: "Nivel",
            name: "level",
        },
        {
            label: "Tipo",
            name: "type",
        },
        {
            label: "Locación",
            name: "location",
        },
        {
            label: "Cobertura",
            name: "coverage",
        },
        {
            label: "Índice",
            name: "index",
        },
    ];

    const getMuiTheme = () =>
        createTheme({
            overrides: {
                MuiTableRow: {
                    root: {
                        cursor: "pointer",
                    },
                },
            },
        });

    const options = {
        enableNestedDataAccess: ".",
        selectableRows: false,
        onRowClick: (rowData) => {
            showdetails(rowData[0]);
        },
        textLabels: {
            body: {
                noMatch: "Lo sentimos, no se encontraron registros.",
                toolTip: "Clasificar",
                columnHeaderTooltip: (column) =>
                    `clasificar por ${column.label}`,
            },
            pagination: {
                next: "Siguiente página",
                previous: "Página anterior",
                rowsPerPage: "Filas por página:",
                displayRows: "de", // 1-10 of 30
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar como CSV",
                print: "Imprimir",
                viewColumns: "Columnas",
                filterTable: "Filtros",
            },
            filter: {
                title: "FILTROS",
                reset: "reiniciar",
            },
            viewColumns: {
                title: "Mostrar columnas",
            },
            selectedRows: {
                text: "Fila(s) eliminadas",
                delete: "Borrar",
            },
        },
    };

    return (
        <div className="">
            {data?.length > 0 ? (
                <Spinner />
            ) : (
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title="Convocatorias"
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider>
            )}
        </div>
    );
}
