import { Route, Switch } from "react-router-dom";
import Index from "./Evaluadores/Index";
import DetallesEvaluadores from "./Evaluadores/DetallesEvaluadores";

export default function Oferentes() {
    return (
        <>
            {/* <SubHeader title="Evaluadores" /> */}
            <Switch>
                <Route exact path="/evaluadores">
                    <Index />
                </Route>
                <Route path="/evaluadores/detalles_evaluadores/:id">
                    <DetallesEvaluadores />
                </Route>
            </Switch>
        </>
    );
}
