export default function Experiencialaboral({ data = [] }) {
    return (
        <div>
            {data.map((obj, i) => {
                return (
                    <div
                        key={i}
                        className="my-4 py-6  border-b border-gray-200"
                    >
                        <form className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div>
                                <label
                                    htmlFor="about"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Empresa
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="about"
                                        name="about"
                                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                                        value={obj?.empresa}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div>
                                <label
                                    htmlFor="about"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Puesto
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="about"
                                        name="about"
                                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                                        value={obj?.posicion}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div>
                                <label
                                    htmlFor="about"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Tipo de contrato
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="about"
                                        name="about"
                                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                                        value={obj?.tipo_contrato}
                                        disabled
                                    />
                                </div>
                            </div>
                            {/* <div>
                                <label
                                    htmlFor="about"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Documento anexo
                                </label>
                                <div className="mt-1">
                                    <a
                                        href={obj.documento_url}
                                        className="flex"
                                    >
                                        <Icon.Download /> Descargar
                                    </a>
                                </div>
                            </div> */}
                            <div>
                                <label
                                    htmlFor="about"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Fecha de entrada
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="about"
                                        name="about"
                                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                                        value={obj?.fecha_entrada}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div>
                                <label
                                    htmlFor="about"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Fecha de salida
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="about"
                                        name="about"
                                        className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                                        value={obj?.fecha_salida || ""}
                                        disabled
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                );
            })}
        </div>
    );
}
