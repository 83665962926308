import { useEffect, useState } from "react";
import { Switch, Route, useParams, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as Icon from "react-feather";
import Tabnavigator from "../../components/navigation/tabnavigator";
import OfertaAcademica from "./ofertaacademica";
import Evaluacion from "./evaluaciones";
import Resultados from "./resultados";
import Becados from "./becados";
import { stadisticas, getOne } from "../../Store/slices/convocatorias";
import {
    getFilteredBecados,
    getFilterPaginationBecados,
    getFiltersData,
} from "../../Store/slices/becados";
import {
    getFilteredSolicitudes,
    getPaginationSolicitudesByDirectLink,
    getSelectsData,
    getCustomSearch,
} from "../../Store/slices/solicitudes";
import Spinner from "../../components/Spinner";
import noimage from "../../images/no_image_available.png";
import clsx from "clsx";
import { replaceHttpToHttps } from "../../utils/http";
import { Fetch } from "../../Services/Fetch";
import useFileDownloader from "../../utils/fileDownloaderMaker";
import { Button } from "../../components/Button";
import alertify from "alertifyjs";
import { toast } from "react-toastify";

export default function ConvocatoriaDetails() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { ExcelGetter } = useFileDownloader();

    const [oneTimeEvaluationLoading, setOneTimeEvaluationLoading] =
        useState(true);
    const [becadosFilters, setBecadosFilters] = useState({});
    const [evaluacionesFilters, setEvaluacionesFilters] = useState({});
    const [isExportingBecados, setIsExportingBecados] = useState(false);

    const { one } = useSelector(({ convocatorias }) => convocatorias);

    const {
        filtered: becadosconvocatoria,
        status: bcStatus,
        filters,
        user: {
            user: { role },
        },
    } = useSelector(({ becados, users }) => ({ ...becados, ...users } ?? []));

    const solicitudes = useSelector(({ solicitudes }) => solicitudes);

    const filterData = (search) =>
        dispatch(getFilteredSolicitudes({ id: id, search: search }));

    const changeSolicitudesData = (link) => {
        let adddedLink = "&";
        for (let key in evaluacionesFilters) {
            adddedLink += `${key}=${evaluacionesFilters[key]}&`;
        }
        adddedLink = adddedLink.slice(0, -1);

        dispatch(
            getPaginationSolicitudesByDirectLink({
                link: replaceHttpToHttps(link) + adddedLink,
                convocatoria_id: id,
            })
        );
    };

    const paginationBecados = (link) => {
        let adddedLink = "&";
        for (let key in becadosFilters) {
            adddedLink += `${key}=${becadosFilters[key]}&`;
        }
        adddedLink = adddedLink.slice(0, -1);
        dispatch(
            getFilterPaginationBecados(
                replaceHttpToHttps(
                    link + `&convocatoria=${id}` + adddedLink ?? ""
                )
            )
        );
    };

    function goSearch({
        internalSearch,
        instituciones,
        genero,
        carreras,
        estado,
        nivel,
        tipo,
        modalidad,
    }) {
        setEvaluacionesFilters({
            internalSearch: internalSearch,
            instituciones: instituciones,
            genero: genero,
            carreras: carreras,
            estado: estado,
            nivel: nivel,
            tipo: tipo,
            modalidad: modalidad,
            id: id,
        });

        dispatch(
            getCustomSearch({
                internalSearch: internalSearch,
                instituciones: instituciones,
                genero: genero,
                carreras: carreras,
                estado: estado,
                nivel: nivel,
                tipo: tipo,
                modalidad: modalidad,
                id: id,
            })
        );
    }

    function handleConvClose() {
        alertify.confirm(
            "Alert",
            "Desea Cerrar la Convocatoria ?",
            () => {
                Fetch({
                    uri: "/convocatorias/setClose",
                    data: { convocatoria_id: id },
                })
                    .then((_) => {
                        toast.success(
                            "Convocatoria Cerrada de manera correcta!"
                        );
                        getInitial();
                    })
                    .catch(
                        ({
                            response: {
                                data: { message },
                            },
                        }) => toast.error(message)
                    );
            },
            () => toast.error("No se cerrara la convocatoria")
        );
    }

    function handleConvOpen() {
        alertify.confirm(
            "Alert",
            "Desea re abrir la Convocatoria ?",
            () => {
                Fetch({
                    uri: "/convocatorias/reOpen",
                    data: { convocatoria_id: id },
                })
                    .then((_) => {
                        toast.success(
                            "Convocatoria re abierta de manera correcta!"
                        );
                        getInitial();
                    })
                    .catch(
                        ({
                            response: {
                                data: { message },
                            },
                        }) => toast.error(message)
                    );
            },
            () => toast.error("No se abrira la convocatoria")
        );
    }

    function doBecadosSearch(obj) {
        setBecadosFilters(obj);
        let link = "&";
        for (let key in obj) {
            link += `${key}=${obj[key]}&`;
        }
        link = link.slice(0, -1);
        dispatch(getFilteredBecados({ id, link }));
    }

    function doBecadosExportable(obj) {
        let link = "";
        setIsExportingBecados(true);

        for (let key in obj) {
            link += `${key}=${obj[key]}&`;
        }
        link = link.slice(0, -1);

        Fetch({
            uri: `becados/exportar?convocatoria=${id}&${link}`,
            method: "get",
        }).then((x) => {
            ExcelGetter(x?.data?.data, "Becados");
            setIsExportingBecados(false);
        });
    }

    function getInitial() {
        // Initial Convocatoria Caller (Upper Card)
        dispatch(getOne({ convocatoria_id: id }));

        // Resultados Caller
        dispatch(stadisticas(id));

        // Becados Caller
        dispatch(getFilteredBecados({ id }));
        dispatch(getFiltersData(id));

        // Evaluaciones Caller
        dispatch(getSelectsData(id));
        dispatch(getFilteredSolicitudes({ id: id })).then(() =>
            setOneTimeEvaluationLoading(false)
        );
    }

    useEffect(() => {
        getInitial();
    }, []);

    return (
        <div className="grid p-4 pb-8">
            {/* Upper Card/ Card inicial -> Detalles convocatoria */}

            <div className="grid grid-cols-12 mb-[22px] bg-white items-center justify-start">
                <div className="col-span-12 lg:col-span-7 grid xl:flex justify-center xl:justify-start items-center gap-4 p-4 border-solid border-[#F0F0F0] border-r-[1px]  ">
                    <img
                        src={one?.image_url || noimage}
                        alt="Perfil"
                        className="max-w-[150px] p-2 justify-self-center "
                    />

                    <div className="grid items-center justify-center ">
                        <h3 className="text-2xl text-[#231F20] mb-[2px]">
                            {one?.name}
                        </h3>
                        <span className="text-xl text-[#AAAAAA]">
                            {one?.type?.name}
                        </span>

                        <div className="mt-[16px] flex justify-center lg:justify-start items-center ">
                            <span
                                className={clsx(
                                    "py-2 px-4 rounded-md text-white",
                                    one?.status === "Abierta" && "bg-green-600",
                                    one?.status === "Cerrada" && "bg-red-600",
                                    one?.status === "Pendiente" &&
                                        "bg-yellow-600"
                                )}
                            >
                                {one?.status}
                            </span>

                            {one?.status === "Abierta" &&
                                role?.name === "coordinador" && (
                                    <div>
                                        <Button
                                            variant="danger"
                                            onClick={handleConvClose}
                                        >
                                            Cerrar Convocatoria
                                        </Button>
                                    </div>
                                )}

                            {one?.status === "Cerrada" &&
                                role?.name === "coordinador" && (
                                    <div>
                                        <Button
                                            variant="primary"
                                            onClick={handleConvOpen}
                                        >
                                            Re Abrir
                                        </Button>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>

                <div className="col-span-12 lg:col-span-5 grid grid-rows-none lg:grid-rows-4 xl:grid-rows-3 lg:grid-flow-col items-center justify-between p-4 gap-4">
                    <div className="flex items-center ">
                        <div className="pr-2">
                            <Icon.User size={16} />
                        </div>
                        <div className="capitalize">
                            &nbsp;Coordinador: {one?.coordinator?.name}
                        </div>
                    </div>

                    <div className="flex items-center ">
                        <div className="pr-2">
                            <Icon.Phone size={16} />
                        </div>
                        <div className="capitalize">
                            &nbsp;Fecha de inicio: {one?.start_date}
                        </div>
                    </div>

                    <div className="flex items-center ">
                        <div className="pr-2">
                            <Icon.Mail size={16} />
                        </div>
                        <div className="capitalize">
                            &nbsp;Fecha de finalización: {one?.end_date}
                        </div>
                    </div>
                </div>
            </div>

            {/* Tabs => Only redirectioning */}

            <Tabnavigator
                routes={[
                    {
                        path: `/convocatorias/detalles_convocatoria/${id}/resultados`,
                        name: "Resultados",
                    },
                    {
                        path: `/convocatorias/detalles_convocatoria/${id}/oferta_academica`,
                        name: "Oferta Académica",
                    },
                    {
                        path: `/convocatorias/detalles_convocatoria/${id}/_becados`,
                        name: "Becados",
                    },
                    {
                        path: `/convocatorias/detalles_convocatoria/${id}/evaluaciones`,
                        name: "Evaluaciones",
                    },
                ]}
            />

            {/* Tabs Content */}
            <div className="w-full h-auto shadow-lg bg-white p-[30px]">
                <div>
                    <Switch>
                        <Route
                            exact
                            path="/convocatorias/detalles_convocatoria/:id/resultados"
                        >
                            <Resultados id={id} />
                        </Route>
                        <Route
                            exact
                            path="/convocatorias/detalles_convocatoria/:id/oferta_academica"
                        >
                            <OfertaAcademica id={id} convocatoria={one?.name} />
                        </Route>
                        <Route
                            exact
                            path="/convocatorias/detalles_convocatoria/:id/_becados"
                        >
                            <Becados
                                onPageChange={paginationBecados}
                                doBecadosSearch={doBecadosSearch}
                                doBecadosExportable={doBecadosExportable}
                                data={becadosconvocatoria || []}
                                isExporting={isExportingBecados}
                                status={bcStatus || {}}
                                filters={filters || {}}
                            />
                        </Route>
                        <Route
                            exact
                            path="/convocatorias/detalles_convocatoria/:id/evaluaciones"
                        >
                            {oneTimeEvaluationLoading ? (
                                <Spinner />
                            ) : (
                                <Evaluacion
                                    data={solicitudes.all}
                                    onPageChange={changeSolicitudesData}
                                    filterData={filterData}
                                    pagination={solicitudes.status.data ?? {}}
                                    selectsData={solicitudes.selectsData ?? {}}
                                    goSearch={goSearch}
                                />
                            )}
                        </Route>

                        <Redirect to="/convocatorias/detalles_convocatoria/:id/resultados" />
                    </Switch>
                </div>
            </div>
        </div>
    );
}
