import { useEffect } from "react";
import { Switch, Route, useParams, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Tabnavigator from "../../../components/navigation/tabnavigator";
import Experiencialaboral from "./experiencialaboral";
import DatosPersonales from "./datosPersonales";
import DatosSocioeconomicos from "./datosSocioeconomicos";
import InformacionAcademica from "./informacionAcademica";
import { getOneSolicitud } from "../../../Store/slices/solicitudes";
import EvaluacionesDetallesSidebar from "../../../components/EvaluacionesDetallesSidebar";
import DetallesBody from "../../../components/DetallesBody";
import Spinner from "../../../components/Spinner";

export default function DetalleEvaluacion() {
    const { id } = useParams();

    const { one, status } = useSelector((state) => state.solicitudes);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOneSolicitud({ aplication_id: id }));
    }, []);

    return (
        <div>
            {status === "loading" ? (
                <Spinner />
            ) : (
                <div className="grid grid-cols-4 gap-4 pb-8 m-5">
                    <div className="col-span-3">
                        <DetallesBody data={one} />

                        <Tabnavigator
                            routes={[
                                {
                                    path: `/convocatorias/detalles_evaluaciones/${id}/datos_personales`,
                                    name: "Datos Personales",
                                },
                                {
                                    path: `/convocatorias/detalles_evaluaciones/${id}/informacion_academica`,
                                    name: "Formación Académica",
                                },
                                {
                                    path: `/convocatorias/detalles_evaluaciones/${id}/experiencia_laboral`,
                                    name: "Experiencia Laboral",
                                },
                                {
                                    path: `/convocatorias/detalles_evaluaciones/${id}/datos_socioeconomicos`,
                                    name: "Datos Socioeconómicos",
                                },
                            ]}
                        />
                        <div className="w-full h-auto shadow-lg bg-white p-[30px]">
                            <div>
                                <Switch>
                                    <Route
                                        exact
                                        path="/convocatorias/detalles_evaluaciones/:id/datos_personales"
                                    >
                                        <DatosPersonales data={one.candidate} />
                                    </Route>
                                    <Route
                                        exact
                                        path="/convocatorias/detalles_evaluaciones/:id/informacion_academica"
                                    >
                                        <InformacionAcademica
                                            data={
                                                one.candidate
                                                    ?.formacion_academica
                                            }
                                        />
                                    </Route>
                                    <Route
                                        exact
                                        path="/convocatorias/detalles_evaluaciones/:id/experiencia_laboral"
                                    >
                                        <Experiencialaboral
                                            data={
                                                one.candidate
                                                    ?.experiencia_laboral
                                            }
                                        />
                                    </Route>
                                    <Route
                                        exact
                                        path="/convocatorias/detalles_evaluaciones/:id/datos_socioeconomicos"
                                    >
                                        <DatosSocioeconomicos />
                                    </Route>
                                    <Redirect to="/convocatorias/detalles_evaluaciones/:id/datos_personales" />
                                </Switch>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1 bg-white">
                        {one.evaluacion && (
                            <EvaluacionesDetallesSidebar
                                data={one.evaluacion}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
