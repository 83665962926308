import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetAll } from "../../../Services/Instituciones/GetAll";
import { Create } from "../../../Services/Instituciones/Create";
import { Delete } from "../../../Services/Instituciones/Delete";
import generateActions from "../generateActions";
import { Fetch } from "../../../Services/Fetch";

const name = "instituciones";

const initialState = {
    all: [],
    allTypes: [],
    one: {},
    create: [],
    status: null,
};

export const getAll = createAsyncThunk(
    `${name}/getall`,
    async (data) => await GetAll(data)
);

export const getAllFiltered = createAsyncThunk(
    `${name}/getall`,
    async (data = "instituciones/filtrar") =>
        await Fetch({ uri: data, method: "get" })
);

export const getAllTypes = createAsyncThunk(
    `${name}/getalltypes`,
    async (data) =>
        await Fetch({ data, uri: "/instituciones/tipos/getAll", method: "get" })
);

export const getOne = createAsyncThunk(
    `${name}/getone`,
    async (data) => await Fetch({ data, uri: "/instituciones/show" })
);

export const create = createAsyncThunk(
    `${name}/create`,
    async (data, { rejectWithValue }) => {
        try {
            const res = await Create(data);
            return res;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const update = createAsyncThunk(
    `${name}/update`,
    async (data, { rejectWithValue }) => {
        try {
            // const res = await Update(data);
            const res = await Fetch({
                data,
                uri: "/instituciones/update",
            });
            return res;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const remove = createAsyncThunk(
    `${name}/delete`,
    async (data) => await Delete(data)
);

export const reducers = generateActions([
    { action: getAll, stateProp: "all" },
    { action: getAllTypes, stateProp: "allTypes" },
    { action: getOne, stateProp: "one" },
    {
        action: create,
        stateProp: "create",
        statePropAll: "all",
        element: "modal",
    },
    { action: update, stateProp: "update", statePropAll: "all" },
    { action: remove, stateProp: "delete", statePropAll: "all" },
]);

const InstitucionesSlice = createSlice({
    name,
    initialState,
    reducers: {
        setModal(state, action) {
            state[action.payload.name] = action.payload.state;
        },
    },
    extraReducers: reducers,
});

export const { setModal } = InstitucionesSlice.actions;

export default InstitucionesSlice.reducer;
