import React, { useState } from "react";

import Chart from "react-apexcharts";

export default function Average(props) {
    const [series, setSeries] = useState([
        {
            data: [10, 41, 35, 51, 10, 41, 35, 51],
        },
    ]);
    const [options, setOptions] = useState({
        chart: {
            type: "area",
            stacked: false,
            zoom: {
                type: "x",
                enabled: true,
                autoScaleYaxis: true,
            },
            toolbar: {
                autoSelected: "zoom",
            },
        },
        dataLabels: {
            enabled: false,
        },
        markers: {
            size: 0,
        },
        xaxis: {
            categories: ["10", "20", "30", "40", "50", "60r", "70", "80"],
        },
    });

    return (
        <>
            <div className="h-[340px] p-8 bg-white col-span-3 rounded-lg transition shadow-sm hover:shadow-xl">
                <h3 className="text-base font-light">{props.title}</h3>
                <Chart
                    options={options}
                    series={series}
                    type="area"
                    height={270}
                />
            </div>
        </>
    );
}
