export default function EvaluacionesDetallesSidebar({ data }) {
    let total = 0;
    data?.map((e) => (total += e.requerimiento.step_complete));

    let actualScore = 0;
    data?.map((e) => (actualScore += e.score));

    return (
        <div>
            <div className="w-full p-4 flex items-center justify-between bg-[#7BB31A] text-white text-lg font-bold">
                <div>{`Evaluación`}</div>
                <div>{`${actualScore}/${total}`}</div>
            </div>

            <div>
                {data?.map((e) => (
                    <div key={e.id}>
                        <div className="flex items-center justify-between mx-2 px-2 py-6">
                            <div>{e.requerimiento.name}</div>
                            <div className="px-4 py-1 border-2 rounded-md">{`${
                                e.score || 0
                            }/${e.requerimiento.step_complete}`}</div>
                        </div>
                        <div className="flex gap-4 items-center justify-between px-4">
                            <div
                                className={`py-2 px-8 rounded-md ${
                                    e.score === e.requerimiento.step_basic
                                        ? "bg-[#AAAAAA] text-white"
                                        : "bg-[#F0F0F0]"
                                } `}
                            >
                                {e.requerimiento.step_basic}
                            </div>
                            <div
                                className={`py-2 px-8 rounded-md ${
                                    e.score === e.requerimiento.step_medium
                                        ? "bg-[#AAAAAA] text-white"
                                        : "bg-[#F0F0F0]"
                                }`}
                            >
                                {e.requerimiento.step_medium}
                            </div>
                            <div
                                className={`py-2 px-8 rounded-md ${
                                    e.score === e.requerimiento.step_complete
                                        ? "bg-[#AAAAAA] text-white"
                                        : "bg-[#F0F0F0]"
                                }`}
                            >
                                {e.requerimiento.step_complete}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="mx-2 px-2 py-4 mt-4">
                <div className="font-bold mb-2">Comentarios</div>
                <div className="border-2 rounded-md p-2">
                    {data[0]?.notes ? data[0].notes : "No hay comentarios"}
                </div>
            </div>
        </div>
    );
}
