export default function Pagination({
    meta: { path, current_page, to, total } = {},
    links: { last, next, prev } = {},
    linkCaller,
}) {
    let last_page = (last ?? "").split("=")[1];

    let notSelected =
        "py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white";
    let selected =
        "py-2 px-3 leading-tight text-blue-600 bg-blue-50 border border-gray-300 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white";

    let numbs = [];

    for (let i = current_page; i < last_page; i++) numbs = [...numbs, i | 0];

    if (current_page > 1) numbs.unshift(current_page - 1);

    numbs.splice(5, numbs.length - 1);
    if (!(numbs.length < 5)) numbs = [...numbs, "..."];
    numbs = [...numbs, last_page];

    return current_page === undefined ? (
        <></>
    ) : (
        <div className="container mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pt-6 gap-8 py-6">
            <div className="rounded border-gray-300">
                {total <= to
                    ? `Se muestran ${to === null ? 0 : to} de ${to === null ? 0 : to}`
                    : `Se muestran ${to === null ? 0 : to} de ${total === null ? 0 : total}`}
            </div>
            <div className="rounded border-gray-300">
                <div>
                    <ul className="inline-flex -space-x-px">
                        <li>
                            <button
                                disabled={prev === null}
                                onClick={() => linkCaller(prev)}
                                className={notSelected + `disabled:dark`}
                            >
                                Anterior
                            </button>
                        </li>

                        {(numbs.length < 1 ? [1] : numbs).map((x) => {
                            return (
                                <li>
                                    <button
                                        disabled={x === "..."}
                                        href="#"
                                        className={
                                            current_page == x
                                                ? selected
                                                : notSelected
                                        }
                                        onClick={() =>
                                            current_page !== x &&
                                            linkCaller(path + `?page=${x}`)
                                        }
                                    >
                                        {x}
                                    </button>
                                </li>
                            );
                        })}

                        <li>
                            <button
                                disabled={next === null}
                                onClick={() => linkCaller(next)}
                                className={notSelected}
                            >
                                Siguiente
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
