import React, { useEffect } from "react";
import { useState } from "react";
import DataTable from "react-data-table-component";
import Spinner from "../../../components/Spinner";
import { Fetch } from "../../../Services/Fetch";

const columns = [
    {
        name: "Convocatoria",
        selector: "convocatoria_name",
        sortable: true,
    },
    {
        name: "Oferta",
        selector: "carrera",
        sortable: true,
    },
    {
        name: "Institución",
        selector: "institucion",
        sortable: true,
    },

    {
        name: "Tipo",
        selector: "tipo",
        sortable: true,
    },
    {
        name: "Estatus",
        selector: "status",
    },
];

export default function ListadeSolicitudes({ cadidate_id }) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const getSolicitudesByCandidato = () => {
        if (!cadidate_id) return;
        setLoading(true);
        Fetch({
            method: "get",
            uri: `/solicitudes/filtros?candidato=${cadidate_id}`,
        })
            .then((res) => {
                setData(res?.data?.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getSolicitudesByCandidato();
    }, [cadidate_id]);

    console.log(data);

    return (
        <div>
            <DataTable
                title="Historial de Solicitudes"
                columns={columns}
                data={data}
                progressPending={loading}
                progressComponent={<Spinner />}
                pointerOnHover
            />
        </div>
    );
}
