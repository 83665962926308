import { useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { SelfPaginatedTable } from "../../components/Common/Index.js";
import { Fetch } from "../../Services/Fetch.js";
import { toast } from "react-toastify";
import { SolicitudesFilterable } from "../../components/Common/Filters/Filterable.jsx";
import useFileDownloader from "../../utils/fileDownloaderMaker.js";

export default function Evaluaciones({
    data,
    onPageChange,
    pagination,
    selectsData,
    goSearch,
}) {
    // Initial Hooks
    const history = useHistory();
    const { id: convoc_id } = useParams();
    const { ExcelGetter } = useFileDownloader();

    // Statefull-Variables
    const [inComponentData, setInComponentData] = useState({});
    const [isSpinning, setIsSpinning] = useState(false);

    // Functions
    const showdetails = (id) =>
        history.push(
            `/evaluaciones/detalles_evaluaciones/${convoc_id}/solicitud/${id}/datospersonales`
        );

    function getExportableData() {
        setIsSpinning(true);
        if (
            inComponentData["instituciones"] === "" ||
            inComponentData["instituciones"] === undefined
        ) {
            toast.error(
                "Debe selecionar una institucion para poder exportar las solicitudes relacionadas a esta misma "
            );
            setIsSpinning(false);
            return null;
        }

        let adddedLink = "&";
        for (let key in inComponentData) {
            adddedLink += `${
                key === "internalSearch"
                    ? "busqueda"
                    : key === "instituciones"
                    ? "institucion"
                    : key
            }=${inComponentData[key]}&`;
        }
        adddedLink = adddedLink.slice(0, -1);

        Fetch({
            uri: `solicitudes/exportar?convocatoria=${convoc_id + adddedLink}`,
            method: "get",
        }).then(({ data: { data } }) => {
            ExcelGetter(
                data,
                `Evaluaciones -> ${
                    (inComponentData["instituciones"],
                    selectsData["instituciones"].find(
                        (x) => x.id === (inComponentData["instituciones"] | 0)
                    ).name ?? "")
                }`
            );
            setIsSpinning(false);
        });
    }

    function setSearchableAndGo(obj) {
        goSearch(obj);
        setInComponentData(obj);
    }

    function setChange(link) {
        let adddedLink = "&";

        for (let key in inComponentData) {
            adddedLink += `${
                key === "internalSearch"
                    ? "busqueda"
                    : key === "instituciones"
                    ? "institucion"
                    : key
            }=${inComponentData[key]}&`;
        }
        adddedLink = adddedLink.slice(0, -1);
        onPageChange(link + `&convocatoria=${convoc_id + adddedLink}`);
    }

    return (
        <>
            <SolicitudesFilterable
                exportable={getExportableData}
                selects={selectsData}
                setSearch={setSearchableAndGo}
                isSpinning={isSpinning}
            />
            <SelfPaginatedTable
                fields={[
                    "Nombre",
                    "Apellido",
                    "Institucion",
                    "Carrera",
                    "Nivel",
                    "Tipo",
                    "Puntuacion",
                    "Estado",
                ]}
                dataToRender={data}
                paginationProps={pagination}
                executerFunction={showdetails}
                paginate={(link) => setChange(link)}
            />
        </>
    );
}
