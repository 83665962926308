export default function InformacionAcademica({ data }) {
    return data.map((inf) => (
        <div className="border-b-2 pb-8 mb-8">
            <form className="grid grid-cols-2 gap-3">
                <div>
                    <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Tipo de estudio
                    </label>
                    <div className="mt-1">
                        <input
                            id="about"
                            name="about"
                            className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                            value={inf.carrera}
                            disabled
                        />
                    </div>
                </div>
                <div>
                    <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Título obtenido
                    </label>
                    <div className="mt-1">
                        <input
                            id="about"
                            name="about"
                            className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                            value={inf?.last_name}
                            disabled
                        />
                    </div>
                </div>
                <div>
                    <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Institución
                    </label>
                    <div className="mt-1">
                        <input
                            id="about"
                            name="about"
                            className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                            value={inf.institucion}
                            disabled
                        />
                    </div>
                </div>
                <div>
                    <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                    >
                        fecha de ingreso
                    </label>
                    <div className="mt-1">
                        <input
                            id="about"
                            name="about"
                            className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                            value={inf.fecha_entrada}
                            disabled
                        />
                    </div>
                </div>
                <div>
                    <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Nacionales
                    </label>
                    <div className="mt-1">
                        <input
                            id="about"
                            name="about"
                            className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                            value={inf?.nacionality}
                            disabled
                        />
                    </div>
                </div>
                <div>
                    <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Índice obtenido
                    </label>
                    <div className="mt-1">
                        <input
                            id="about"
                            name="about"
                            className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                            value={inf.indice}
                            disabled
                        />
                    </div>
                </div>
                <div>
                    <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Pais de estudio
                    </label>
                    <div className="mt-1">
                        <input
                            id="about"
                            name="about"
                            className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                            value={inf?.contact_email}
                            disabled
                        />
                    </div>
                </div>
                <div>
                    <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Carracter del centro
                    </label>
                    <div className="mt-1">
                        <input
                            id="about"
                            name="about"
                            className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                            value={inf?.home_phone || ""}
                            disabled
                        />
                    </div>
                </div>
                <div>
                    <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Fecha de salida
                    </label>
                    <div className="mt-1">
                        <input
                            id="about"
                            name="about"
                            className="shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                            value={inf.fecha_salida}
                            disabled
                        />
                    </div>
                </div>
                <div>
                    <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Beca de estudio
                    </label>
                    <div className="flex mt-1 gap-4">
                        <div className="flex items-center gap-2">
                            <label>Si</label>
                            <input
                                type="radio"
                                checked={inf.isBecado}
                                id="about"
                                name="about"
                                className="shadow-sm  focus:ring-indigo-500 focus:border-indigo-500  block   border border-gray-300 rounded-md"
                                disabled
                            />
                        </div>
                        <div className="flex items-center gap-2">
                            <label>No</label>
                            <input
                                type="radio"
                                checked={!inf.isBecado}
                                id="about"
                                name="about"
                                className="shadow-sm  focus:ring-indigo-500 focus:border-indigo-500  block   border border-gray-300 rounded-md"
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Documento anexo
                    </label>
                    <div className="mt-1">
                        <a
                            href={inf.certificacion_url}
                            className="bg-gray-50 shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full  border border-gray-300 rounded-md"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Ver documento
                        </a>
                    </div>
                </div>
            </form>
        </div>
    ));
}
