import { useEffect, useState } from "react";
import { Switch, Route, useParams, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Tabnavigator from "../../../components/navigation/tabnavigator";
import DatosGenerales from "./datosgenerales";
import Mensaje from "./mesanje";
import OfertaAcademica from "./ofertaacademica";
import Requisitos from "./requisitos";
import Evaluacion from "./evaluaciones";
import { getAllSelector as allCoordinadores } from "../../../Store/slices/coordinadores";
import { getAll as allIntituciones } from "../../../Store/slices/instituciones";
import { getAll as allOferentes } from "../../../Store/slices/oferentes";
import { setAllForms } from "../../../Store/slices/formularios";
import { setAllHorario } from "../../../Store/slices/horario";
import useCreateConvocatorias from "../../../hooks/useCreateConvocatoria";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import alertify from "alertifyjs";
import { createnewConvocatoria } from "../../../Store/slices/convocatorias/newConvocatoria";

export default function CreateNewConvocatoria() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [actualizar, setactualizar] = useState(false);
    const { create, publicar, update, optener } = useCreateConvocatorias();
    const convocatoria = useSelector(
        ({ newConvocatoria }) => newConvocatoria.data
    );

    useEffect(() => {
        dispatch(allCoordinadores());
        dispatch(allIntituciones());
        dispatch(allOferentes());
        dispatch(setAllForms());
        //dispatch(setAllHorario());
        id === "new" && dispatch(createnewConvocatoria());
        id > 0 && optener(id);
    }, []);

    const schema = yup
        .object({
            name: yup.string().required("El campo nombre es requerido"),
            convocatoria_type_id: yup
                .string()
                .required("El campo tipo de convocatoria es requerido"),
            start_date: yup
                .string()
                .required("El campo fecha de inicio es requerido"),
            end_date: yup
                .string()
                .required("El campo fecha de finalizacion es requerido"),
            audience_id: yup
                .string()
                .required("El campo aquien va dirigido es requerido"),
            mensajes_id: yup
                .string()
                .required("El campo mesanjes es requerido"),
            evaluation_id: yup
                .string()
                .required("El campo evaluacion es requerido"),
            coordinator_id: yup
                .string()
                .required("El campo coordinador es requerido"),
            requisitos: yup
                .string()
                .required("El campo requisitos es requerido"),
        })
        .required();

    const methods = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        methods.setValue("convocatoria_id", convocatoria.id || "");
        methods.setValue("name", convocatoria.name || "");
        methods.setValue("start_date", convocatoria.start_date || "");
        methods.setValue("coordinator_id", convocatoria.coordinator?.id || "");
        methods.setValue("evaluation_id", convocatoria.evaluation?.id || "");
        methods.setValue("end_date", convocatoria.end_date);
        methods.setValue("convocatoria_type_id", convocatoria.type?.id || "");
        methods.setValue("audience_id", convocatoria.audience?.id || "");
        methods.setValue("mensajes_id", convocatoria.mensajes?.id || "");
        methods.setValue("requisitos", convocatoria.requisitos || "");
    }, [convocatoria.id]);

    useEffect(() => {
        const subscription = methods.watch((value) => {
            if (
                value?.name === convocatoria?.name &&
                value?.start_date === convocatoria?.start_date &&
                parseInt(value?.coordinator_id) ===
                    parseInt(convocatoria?.coordinator?.id) &&
                parseInt(value?.evaluation_id) ===
                    parseInt(convocatoria?.evaluation?.id) &&
                value?.end_date === convocatoria?.end_date &&
                parseInt(value?.convocatoria_type_id) ===
                    parseInt(convocatoria?.type?.id) &&
                parseInt(value?.audience_id) ===
                    parseInt(convocatoria?.audience?.id) &&
                parseInt(value?.mensajes_id) ===
                    parseInt(convocatoria?.mensajes?.id) &&
                value?.image &&
                !value?.image[0]
            ) {
                setactualizar(false);
            } else {
                setactualizar(true);
            }
        });
        return () => subscription.unsubscribe();
    }, [methods.watch, convocatoria.id]);

    const { isValid, errors } = methods.formState;

    const submit = () => {
        methods.trigger().then((res) => {
            if (res) {
                methods.handleSubmit(
                    create({
                        ...methods.getValues(),
                        image:
                            methods.getValues().image &&
                            methods.getValues().image[0],
                    })
                );
                setactualizar(false);
            } else {
                const arr = Object.entries(errors);
                arr[0] && toast.error(`${arr[0][1]?.message} `);
            }
        });
    };

    const submitUpdate = async () => {
        await methods.trigger();
        if (isValid) {
            const { image, ...rest } = methods.getValues();
            if (image) {
                if (image[0]) rest.image = image[0];
            }
            methods.handleSubmit(update(rest));
            setactualizar(false);
        } else {
            setTimeout(() => {
                const arr = Object.entries(errors);
                arr[0] && toast.error(`${arr[0][1]?.message} `);
            }, 500);
        }
    };

    const publish = () => {
        if (!convocatoria.publicada) {
            // if (convocatoria?.ofertas?.length) {
            alertify.confirm(
                "Confirmar",
                "Esta seguro que desea publicar la convocatoria?",
                () => publicar(convocatoria.id),
                () => toast.error("Se cancelo la publicacion")
            );
            // } else {
            //     toast.error(
            //         "Añade al menos una oferta académica para publicar"
            //     );
            // }
        } else {
            toast.error("Esta convocatoria ya fue plublicada");
        }
    };

    const getAction = () => {
        if (convocatoria?.id && actualizar) {
            return {
                name: "Actualizar",
                do: submitUpdate,
            };
        } else if (convocatoria?.id) {
            return { name: "Publicar", do: publish };
        } else {
            return { name: "Guardar", do: submit };
        }
    };

    return (
        <FormProvider {...methods}>
            <div className="m-5 pb-8">
                <Tabnavigator
                    routes={[
                        {
                            path: `/convocatorias/crear/${id}/datos_generales`,
                            name: "Datos Generales",
                            error:
                                methods.formState?.errors?.name ||
                                methods.formState?.errors
                                    ?.convocatoria_type_id ||
                                methods.formState?.errors?.end_date ||
                                methods.formState?.errors?.start_date ||
                                methods.formState?.errors?.audience_id ||
                                methods.formState?.errors?.coordinator_id,
                        },
                        {
                            path: `/convocatorias/crear/${id}/evaluacion`,
                            name: "Evaluación",
                            error: !!methods.formState?.errors?.evaluation_id,
                        },
                        {
                            path: `/convocatorias/crear/${id}/mensaje`,
                            name: "Mensaje",
                            error: !!methods.formState?.errors?.mensajes_id,
                        },
                        {
                            path: `/convocatorias/crear/${id}/requisitos`,
                            name: "Requisitos",
                            error: !!methods.formState?.errors?.requisitos,
                        },
                        {
                            path: `/convocatorias/crear/${id}/oferta_academica`,
                            name: "Oferta Académica",
                            disabled: convocatoria.id ? false : true,
                        },
                    ]}
                    action={getAction()}
                />
                <div className="w-full h-auto shadow-lg bg-white p-[30px]">
                    <div>
                        <Switch>
                            <Route
                                exact
                                path="/convocatorias/crear/:id/datos_generales"
                            >
                                <DatosGenerales />
                            </Route>
                            <Route
                                exact
                                path="/convocatorias/crear/:id/mensaje"
                            >
                                <Mensaje />
                            </Route>
                            <Route
                                exact
                                path="/convocatorias/crear/:id/oferta_academica"
                            >
                                <OfertaAcademica data={convocatoria} />
                            </Route>
                            <Route
                                exact
                                path="/convocatorias/crear/:id/requisitos"
                            >
                                <Requisitos convocatoriaid={convocatoria.id} />
                            </Route>
                            <Route
                                exact
                                path="/convocatorias/crear/:id/evaluacion"
                            >
                                <Evaluacion />
                            </Route>
                            <Route
                                exact
                                path="/convocatorias/crear/:id/requisitos"
                            >
                                <Requisitos />
                            </Route>

                            <Redirect
                                to={`/convocatorias/crear/${id}/datos_generales`}
                            />
                        </Switch>
                    </div>
                </div>
            </div>
        </FormProvider>
    );
}
