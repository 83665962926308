import React, { useState } from "react";
import Spinner from "../../components/Spinner";
import MUIDataTable from "mui-datatables";
import useAction from "./useAction";
import { Button } from "../../components/Button";
import { Form, InputField, SelectField } from "../../components/Form";

const AcademicDegree = ["grado", "bachiller", "postgrado"];

export default function All() {
    const { isLoading, data, getAll, pagination, stados, convocatorias } =
        useAction();

    const columns = [
        {
            name: "id",
            options: {
                display: false,
            },
        },

        {
            label: "NOMBRES DEL BECARIO ",
            name: "becado_name",
        },
        {
            label: "APELLIDOS  DEL BECARIO",
            name: "becado_lastname",
        },
        {
            label: "Cédula",
            name: "candidate.document_id",
        },

        {
            label: "FECHA DE NACIMIENTO",
            name: "candidate.born_date",
        },

        {
            label: "EDAD",
            name: "candidate.age",
        },

        {
            label: "SEXO",
            name: "candidate.genero",
        },

        {
            label: "MUNICIPIO DONDE RESIDE",
            name: "municipality.name",
        },

        {
            label: "PAIS DE ORIGEN O NACIMIENTO",
            name: "country.name",
        },

        {
            label: "CENTRO EDUCATIVO DEL QUE EGRESÓ EL BECARIO (BACHILLERATO)",
            name: "formacion_academica_centro_egresado_bachiller",
        },

        {
            label: "INDICE ACADÉMICO DEL BECARIO (BACHILLERATO)",
            name: "formacion_academica_indice_bachiller",
        },

        {
            label: "IES EN LA QUE APLICÓ",
            name: "institution_offer.campus_country",
        },

        {
            label: "PAIS DE  UBICACIÓN DE LA IES EN QUE APLICÓ",
            name: "institution_offer.institution_name",
        },

        {
            label: "CARRERA  O PROGRAMA PARA LA CUAL SOLICITA LA BECA",
            name: "institution_offer.carrera.career",
        },

        {
            label: "AREA DE CONOCIMIENTO A LA QUE CORRESPONDE LA CARRERA PARA LA CUAL SOLICITA LA BECA",
            name: "institution_offer.development_area_name",
        },

        // {
        //     label: "TIPO DE BECA",
        //     name: "",
        // },
        {
            label: "UNIVERSIDAD DE LA QUE ES EGRESADO (GRADO)",
            name: "formacion_academica_centro_egresado_grado",
        },
        {
            label: "ÍNDICE ACADÉMICO DE GRADUACIÓN VERIFICADO (GRADO)",
            name: "formacion_academica_indice_grado",
        },
        {
            label: "CARRERA DE LA QUE ES EGRESADO (GRADO)",
            name: "formacion_academica_carrera_grado",
        },
        {
            label: "UNIVERSIDAD DE LA QUE ES EGRESADO (POSTGRADO)",
            name: "formacion_academica_centro_egresado_posgrado",
        },
        {
            label: "ÍNDICE ACADÉMICO DE GRADUACIÓN VERIFICADO (POSTGRADO)",
            name: "formacion_academica_indice_posgrado",
        },

        {
            label: "CARRERA DE LA QUE ES EGRESADO (POSTGRADO)",
            name: "formacion_academica_carrera_posgrado",
        },
        {
            label: "MODALIDAD DE LA CARRERA PARA LA CUAL SOLICITA LA BECA",
            name: "institution_offer.schedule.modality",
        },
        {
            label: "COSTO TOTAL DE LA CARRERA  PARA LA CUAL SOLICITA LA BECA",
            name: "institution_offer.carrera.costo",
        },
        {
            label: "MONTO TOTAL EN PESOS DOMINICANOS DE LA BECA",
            name: "institution_offer.carrera.costo",
        },
        // {
        //     label: "OCUPACIÓN DEL BECARIO",
        //     name: "created",
        // },
        // {
        //     label: "¿EL BECARIO ES DOCENTE  UNIVERSITARIO?",
        //     name: "",
        // },
        // {
        //     label: "¿EL BECARIO ES DOCENTE  UNIVERSITARIO VINCULADO A LA FUNCIÓN DE INVESTIGACIÓN (CYT)?",
        //     name: "",
        // },
        {
            label: "CONVOCATORIA",
            name: "convocatoria.name",
        },
        {
            label: "FECHA",
            name: "convocatoria.start_date",
        },
    ];

    const options = {
        enableNestedDataAccess: ".",
        selectableRows: false,
        // onRowClick: (rowData) => {
        //     history.push(`${url}/${rowData[0]}`);
        // },
        pagination: false,
        textLabels: {
            body: {
                noMatch: "Lo sentimos, no se encontraron registros.",
                toolTip: "Clasificar",
                columnHeaderTooltip: (column) =>
                    `clasificar por ${column.label}`,
            },
            pagination: {
                next: "Siguiente página",
                previous: "Página anterior",
                rowsPerPage: "Filas por página:",
                displayRows: "de", // 1-10 of 30
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar como CSV",
                print: "Imprimir",
                viewColumns: "Columnas",
                filterTable: "Filtros",
            },
            filter: {
                title: "FILTROS",
                reset: "reiniciar",
            },
            viewColumns: {
                title: "Mostrar columnas",
            },
            selectedRows: {
                text: "Fila(s) eliminadas",
                delete: "Borrar",
            },
        },
    };

    return (
        <>
            <div className="justify-center w-full pb-8 mt-5 flex-cols">
                {isLoading && <Spinner />}
                <div className="px-8">
                    <Form
                        onSubmit={(data) => {
                            getAll({ page: pagination.current_page, ...data });
                        }}
                    >
                        {({ register }) => {
                            return (
                                <div className="p-4">
                                    <div className="grid grid-cols-4 gap-4">
                                        {/* <InputField
                                        label="Buscar"
                                        control={control}
                                        name="buscar"
                                    /> */}
                                        <SelectField
                                            label="Convocatoria"
                                            registration={register(
                                                "convocatoria_id"
                                            )}
                                            options={convocatorias?.map(
                                                (state) => ({
                                                    label: state.name,
                                                    value: state.id,
                                                })
                                            )}
                                        />

                                        <InputField
                                            label="Desde"
                                            type="date"
                                            registration={register("date_from")}
                                        />
                                        <InputField
                                            label="Hasta"
                                            type="date"
                                            registration={register("date_to")}
                                        />
                                        <div className="flex">
                                            <Button type="submit">
                                                Buscar
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            );
                        }}
                    </Form>

                    <MUIDataTable
                        pagination={false}
                        title="Reporte de becados"
                        data={data?.map((d) => ({
                            ...d,
                            institucion: Array.isArray(d.isntitution)
                                ? d.isntitution[0]
                                : "",
                            formacion_academica_centro_egresado_bachiller:
                                d?.candidate?.formacion_academica?.find(
                                    (o) => o?.study_grade === AcademicDegree[1]
                                )?.institucion || "",
                            formacion_academica_indice_bachiller:
                                d?.candidate?.formacion_academica?.find(
                                    (o) => o?.study_grade === AcademicDegree[1]
                                )?.indice || "",
                            formacion_academica_centro_egresado_grado:
                                d?.candidate?.formacion_academica?.find(
                                    (o) => o?.study_grade === AcademicDegree[0]
                                )?.institucion || "",
                            formacion_academica_indice_grado:
                                d?.candidate?.formacion_academica?.find(
                                    (o) => o?.study_grade === AcademicDegree[0]
                                )?.indice || "",
                            formacion_academica_carrera_grado:
                                d?.candidate?.formacion_academica?.find(
                                    (o) => o?.study_grade === AcademicDegree[0]
                                )?.carrera || "",
                            formacion_academica_centro_egresado_posgrado:
                                d?.candidate?.formacion_academica?.find(
                                    (o) => o?.study_grade === AcademicDegree[2]
                                )?.institucion || "",
                            formacion_academica_indice_posgrado:
                                d?.candidate?.formacion_academica?.find(
                                    (o) => o?.study_grade === AcademicDegree[2]
                                )?.indice || "",
                            formacion_academica_carrera_posgrado:
                                d?.candidate?.formacion_academica?.find(
                                    (o) => o?.study_grade === AcademicDegree[2]
                                )?.carrera || "",
                        }))}
                        columns={columns}
                        options={options}
                    />
                </div>

                <div className="flex items-center justify-end gap-2 py-5 pr-10 font-semibold">
                    Resultados {data.length} de {pagination.total || 0}{" "}
                    <Button
                        disabled={pagination.current_page <= 1}
                        onClick={() =>
                            getAll({ page: pagination.current_page - 1 })
                        }
                    >
                        Atras
                    </Button>{" "}
                    {pagination.current_page || 0}
                    <Button
                        onClick={() =>
                            getAll({ page: pagination.current_page + 1 })
                        }
                        disabled={
                            pagination.current_page >= pagination.last_page
                        }
                    >
                        Siguiente
                    </Button>
                </div>
            </div>
        </>
    );
}
