import { utils, writeFile } from "xlsx";

// Csv
function buildCsvObject(data = []) {
    let ctr;

    if (data === null || data.length < 1) return null;

    let keys = Object.keys(data[0]);

    let result = "";
    result += keys.join(",");
    result += "\n";

    data.forEach((item) => {
        ctr = 0;
        keys.forEach((key) => {
            if (ctr > 0) result += ",";

            result += item[key];
            ctr++;
        });
        result += "\n";
        result = result
            .split("\n")
            .map((x) =>
                x
                    .split(",")
                    .map((_) => _.trim().replace(/[#]+/gim, "No."))
                    .join(",")
            )
            .join("\n");
    });
    return result;
}

function downloadCsvAndContinue(rawData = [], fileName = "CsvTest") {
    let csv = buildCsvObject(rawData);
    if (csv === null) return;

    if (!csv.match(/^data:text\/csv/i)) {
        csv = "data:text/csv;charset=utf-8," + csv;
    }
    let data = encodeURI(csv);

    let link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", fileName + ".csv");
    link.click();
    link.remove();
}

// Excel
function downloadExcelAndContinue(data = [], fileName = "TestXlsx") {
    if (data === null || data.length < 1) {
        return null;
    }
    let wb = utils.book_new();
    let ws = utils.json_to_sheet(data);
    utils.book_append_sheet(wb, ws, "Pagina #1");
    writeFile(wb, `${fileName}.xlsx`);
}

export default function useFileDownloader() {
    return {
        CsvGetter: (value, name) => downloadCsvAndContinue(value, name),
        ExcelGetter: (value, name) => downloadExcelAndContinue(value, name),
    };
}
