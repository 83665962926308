import useAuthAxios from '../../config/AuthAxios'

export async function Logout() {
  const AuthAxios = useAuthAxios()

  try {
    const res = await AuthAxios.post(`/profile/logout`)

    return res
  } catch (err) {
    console.error(err)
  }
}
