import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAll as getAllConvocatorias } from "../../Store/slices/convocatorias";
import { getAll as gellAllintituciones } from "../../Store/slices/instituciones";
import Modal from "../../components/modal";
import Spinner from "../../components/Spinner";
import renderErrors from "../../helpers/renderErrors";
import useFilter from "../../hooks/useFilter";
import alertify from "alertifyjs";
import {
    asignar,
    create,
    getAll,
    setModal,
    removerAsigned,
    getAllFilter,
    getAllSearch,
} from "../../Store/slices/evaluadores";
import { Button } from "../../components/Button";
import { Form, InputField, SelectField } from "../../components/Form";
import * as yup from "yup";
import { getAllSelector as getAllcoordinadores } from "../../Store/slices/coordinadores";
import noImage from "../../images/profile-placeholder.png";
import { Fetch } from "../../Services/Fetch";
import { toast } from "react-toastify";
import { replaceHttpToHttps } from "../../utils/http";
import { Pagination } from "../../components/Common/Index.js";

const schema = yup
    .object({
        name: yup.string().required("Requerido"),
        coordinator_id: yup.string().required("Requerido"),
        email: yup
            .string()
            .email("Correo electrónico inválido")
            .required("Requerido"),
        phone: yup.string().min(10, "Teléfono invalido").required("Requerido"),
        password: yup.string().required("Requerido"),
        password_confirmation: yup.string().required("Requerido"),
    })
    .required();

export default function Index() {
    const [Selected, setSelected] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [loadinModal, setloadinModal] = useState(false);
    const [param, setParam] = useState("");
    const [search, setSearch] = useState("");

    const dispatch = useDispatch();
    const name = "Evaluadores";
    const history = useHistory();

    const { all, status, modal1, modal2 } = useSelector(
        ({ evaluadores }) => evaluadores
    );
    const instituciones = useSelector((state) => state.instituciones.all);
    const convocatorias = useSelector((state) => state.convocatorias.all);
    const coordinadores = useSelector((state) => state.coordinadores.all);

    useEffect(() => {
        document.title = `${name} | ${global.title}`;
        dispatch(getAll());
        !instituciones.length && dispatch(gellAllintituciones());
        !convocatorias.length && dispatch(getAllConvocatorias());
        !coordinadores.length && dispatch(getAllcoordinadores());
    }, []);

    const {
        register: resgisterAsignar,
        handleSubmit: handleAsignar,
        formState: { errors: errosasignar },
        setValue: setValueAsigmen,
    } = useForm();

    const onSubmit = (data) => {
        data.image = data.image && data.image[0];
        setloadinModal(true);
        dispatch(create(data)).then((res) => {
            if (res.error) {
                renderErrors(res.payload || {});
            }
            setloadinModal(false);
        });
    };

    const onSubmitAsignar = (data) => {
        setIsLoading(true);
        dispatch(asignar(data)).then((res) => {
            if (res.error) {
                renderErrors(res.payload);
            } else {
                setSelected({
                    ...Selected,
                    institutions: [
                        ...Selected.institutions,
                        res.payload.data.data,
                    ],
                });
            }
            setIsLoading(false);
        });
    };

    const filterData = () => dispatch(getAllSearch({ search: search }));

    const whereTo = (link) => {
        link !== null &&
            dispatch(getAllFilter(replaceHttpToHttps(link + `&busqueda=`)));
    };

    const [filtered] = useFilter(all, param);

    return (
        <>
            <Modal
                action={() =>
                    dispatch(setModal({ name: "modal1", state: false }))
                }
                isVisible={modal1}
                title="Crear nuevo evaluador"
            >
                <Form onSubmit={onSubmit} schema={schema}>
                    {({ register, formState, setValue, watch, getValues }) => (
                        <>
                            <InputField
                                label="Nombre"
                                placeholder="Juan Santos"
                                error={formState.errors.name}
                                registration={register("name")}
                            />

                            <SelectField
                                label="Coordinador"
                                error={formState.errors.coordinator_id}
                                registration={register("coordinator_id")}
                                options={coordinadores.reduce((acc, cur) => {
                                    return [
                                        ...acc,
                                        { value: cur.id, label: cur.name },
                                    ];
                                }, [])}
                            />

                            <InputField
                                type="phone"
                                label="Teléfono"
                                placeholder="8095460000"
                                error={formState.errors.phone}
                                name="phone"
                                onChange={setValue}
                            />

                            <InputField
                                type="email"
                                label="Correo electrónico"
                                placeholder="info@ejemplo.gob.do"
                                error={formState.errors.email}
                                registration={register("email")}
                            />

                            <InputField
                                type="file"
                                label="Imagen de perfil"
                                accept="image/*"
                                error={formState.errors.image}
                                watch={watch("image")}
                                setValue={setValue}
                                name="image"
                                className="py-0"
                            />

                            <InputField
                                type="password"
                                label="Contraseña"
                                error={formState.errors.password}
                                registration={register("password")}
                            />

                            <InputField
                                type="password"
                                label="Confirmar contraseña"
                                error={formState.errors.password_confirmation}
                                registration={register("password_confirmation")}
                            />

                            <div className="flex justify-end mt-4">
                                <Button type="submit" isLoading={loadinModal}>
                                    Guardar
                                </Button>
                            </div>
                        </>
                    )}
                </Form>
            </Modal>
            <Modal
                action={() =>
                    dispatch(setModal({ name: "modal2", state: false }))
                }
                isVisible={modal2}
                title={`Asignar institución a ${Selected.name}`}
            >
                <form
                    className="modal"
                    onSubmit={handleAsignar(onSubmitAsignar)}
                >
                    <img
                        className="w-[100px] h-[100px] border-2 border-[#F0F0F0] border-solid rounded-full object-contain m-auto"
                        src={
                            Selected?.image_url || `img/no_image_available.png`
                        }
                        alt="img perfil"
                    />

                    <div className="my-8">
                        {Selected.institutions?.map((obj) => (
                            <div
                                key={obj.id}
                                className="flex space-between w-full mx-3 "
                            >
                                <h1>{obj.institution?.name}</h1>
                                <Icon.XCircle
                                    onClick={() => {
                                        setIsLoading(true);
                                        dispatch(
                                            removerAsigned({
                                                institution_evaluator_id:
                                                    obj.id,
                                            })
                                        ).then((res) => {
                                            if (res.error) {
                                                renderErrors(res.payload);
                                            } else {
                                                setSelected({
                                                    ...Selected,
                                                    institutions:
                                                        Selected.institutions.filter(
                                                            (o) =>
                                                                parseInt(
                                                                    o.id
                                                                ) !==
                                                                parseInt(obj.id)
                                                        ),
                                                });
                                            }
                                            setIsLoading(false);
                                        });
                                    }}
                                    className="mx-3 text-red-600 cursor-pointer"
                                />
                            </div>
                        ))}
                    </div>

                    <label>
                        Institución
                        <select
                            {...resgisterAsignar("institution_id", {
                                required: true,
                            })}
                        >
                            <option disabled>Seleccionar</option>
                            {instituciones?.map((obj, i) => (
                                <option key={obj.id} value={obj.id}>
                                    {obj.name}
                                </option>
                            ))}
                        </select>
                    </label>

                    <label>
                        Convocatoria
                        <select
                            {...resgisterAsignar("convocatoria_id", {
                                required: true,
                            })}
                        >
                            <option disabled>Seleccionar</option>
                            {convocatorias?.map((obj, i) => (
                                <option key={i} value={obj.id}>
                                    {obj.name}
                                </option>
                            ))}
                        </select>
                    </label>

                    <div className="flex justify-center">
                        <Button type="submit" isLoading={isLoading}>
                            Guardar
                        </Button>
                    </div>
                </form>
            </Modal>

            <div className="py-8 px-5 md:flex md:justify-between items-center gap-4">
                <div className="flex md:items-center w-1/2">
                    <div className="flex text-base pr-4">Filtrar :</div>
                    <div className="px-2 ">
                        <InputField
                            className={"w-full mr-10 "}
                            onChange={({ target: { value } }) =>
                                setSearch(value)
                            }
                        />
                    </div>
                    <Button onClick={filterData}> Buscar </Button>
                </div>

                <div>
                    <Button
                        onClick={() =>
                            dispatch(setModal({ name: "modal1", state: true }))
                        }
                    >
                        Agregar
                    </Button>
                </div>
            </div>

            {status === "loading" ? (
                <Spinner />
            ) : (
                <>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 p-4">
                        {filtered?.map((evaluador, i) => (
                            <div
                                key={i}
                                className="bg-white relative group flex relative py-[30px] flex-col  cursor-pointer rounded-lg transition shadow-sm hover:shadow-xl"
                            >
                                <button
                                    onClick={() => {
                                        alertify
                                            .confirm(
                                                "Eliminar evaluador",
                                                "Esta seguro que deseas eliminar este evaluador",
                                                function () {
                                                    const res = Fetch({
                                                        data: {
                                                            evaluator_id: 1000,
                                                        },
                                                        uri: "/evaluators/delete",
                                                    })
                                                        .then((res) => {
                                                            toast.success(
                                                                "Evaluador eliminado correctamente"
                                                            );
                                                        })
                                                        .catch((err) => {
                                                            toast.error(
                                                                "Evaluador no pudo ser eliminado"
                                                            );
                                                        });
                                                }
                                            )
                                            .set({ title: "Eliminar" });
                                    }}
                                >
                                    <Icon.XCircle
                                        className="absolute -right-2 -top-2 rounded-full text-red-500"
                                        title="eliminar evaluador"
                                    />
                                </button>

                                <div
                                    onClick={() => {
                                        history.push(
                                            `/evaluadores/detalles_evaluadores/${evaluador?.id}`
                                        );
                                    }}
                                >
                                    <img
                                        className="w-[100px] h-[100px] border-2 border-[#F0F0F0] border-solid rounded-full object-contain m-auto"
                                        src={evaluador?.image_url || noImage}
                                        alt={evaluador?.name}
                                    />

                                    <div className="my-5 px-5 flex justify-center">
                                        <h2 className="block text-center text-xl line-clamp-2 text-[#231F20]">
                                            {evaluador?.name}
                                        </h2>
                                    </div>

                                    <div className="flex justify-center p-5">
                                        {/* <div className="flex px-4 flex-col items-center">
                                        <Icon.User
                                            className="text-[#7BB31A]"
                                            size={20}
                                        />
                                        <span className="text-xs mt-1">0</span>
                                    </div> */}
                                        <div className="flex px-4 flex-col items-center">
                                            <Icon.Home
                                                className="text-[#00A2FF]"
                                                size={20}
                                            />
                                            <span className="text-xs mt-1">
                                                {evaluador?.institutions.length}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mx-[20px]">
                                    <Button
                                        isLoading={loadinModal}
                                        onClick={() => {
                                            setSelected({
                                                id: evaluador.id,
                                                name: evaluador.name,
                                                image_url: evaluador.image_url,
                                                institutions:
                                                    evaluador.institutions,
                                            });
                                            setValueAsigmen(
                                                "evaluator_id",
                                                evaluador.id
                                            );
                                            dispatch(
                                                setModal({
                                                    name: "modal2",
                                                    state: true,
                                                })
                                            );
                                        }}
                                        className="w-full"
                                    >
                                        Asignar evaluación
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </div>

                    <Pagination
                        meta={status?.data?.meta ?? {}}
                        links={status?.data?.links ?? {}}
                        linkCaller={whereTo}
                    />
                </>
            )}
        </>
    );
}
