import { useState } from "react";
import { useFieldArray } from "react-hook-form";
import { InputField, SelectField, TextAreaField } from ".";
import { Button } from "../Button";
import NestedOptions from "./NestedOptions";

export default function NestedFieldArray({
    parentIndex,
    control,
    register,
    formState,
    watch,
}) {
    const { fields, remove, append } = useFieldArray({
        control,
        name: `sections[${parentIndex}].detalles`,
    });

    const typesDefinitions = [
        {
            label: "Párrafo corto",
            value: "text",
        },
        {
            label: "Párrafo largo",
            value: "textarea",
        },
        {
            label: "Selección única (radio)",
            value: "radio",
        },
        {
            label: "Selección múltipe (checkbox)",
            value: "checkbox",
        },
        {
            label: "Selecione",
            value: "select",
        },
        {
            label: "Archivo",
            value: "file",
        },
    ];

    const checkErrors = (fieldName, index) => {
        return (
            formState?.errors?.sections &&
            formState?.errors?.sections[parentIndex] &&
            formState?.errors?.sections[parentIndex]?.detalles &&
            formState?.errors?.sections[parentIndex]?.detalles[index] &&
            formState?.errors?.sections[parentIndex]?.detalles[index][fieldName]
        );
    };

    return (
        <div className="">
            {fields.map((item, index) => (
                <div key={item.id} className="grid gap-4 mt-4 border-b-2 pb-4">
                    <div className="hidden">
                        <InputField
                            label="sección"
                            value={watch(`sections[${parentIndex}]`).name}
                            registration={register(
                                `sections[${parentIndex}].detalles[${index}].seccion`,
                                {
                                    value: watch(`sections[${parentIndex}]`)
                                        .name,
                                }
                            )}
                        />
                    </div>

                    <div className="flex items-start gap-4 ">
                        <div className="flex-grow">
                            <InputField
                                label="Título de la pregunta"
                                error={checkErrors("name", index)}
                                registration={register(
                                    `sections[${parentIndex}].detalles[${index}].name`
                                )}
                            />
                        </div>
                        <div>
                            <Button
                                onClick={() => remove(index)}
                                variant="danger"
                                className="mt-5"
                            >
                                Eliminar pregunta
                            </Button>
                        </div>
                    </div>

                    <TextAreaField
                        label="Descripción"
                        error={checkErrors("description", index)}
                        registration={register(
                            `sections[${parentIndex}].detalles[${index}].description`
                        )}
                    />

                    <div className="flex items-start gap-4 ">
                        <div className="flex-grow">
                            <SelectField
                                label="Tipo de pregunta"
                                error={checkErrors("type", index)}
                                registration={register(
                                    `sections[${parentIndex}].detalles[${index}].type`
                                )}
                                options={typesDefinitions?.map(
                                    ({ label, value }) => ({
                                        label,
                                        value,
                                    })
                                )}
                            />
                        </div>
                        <div>
                            <InputField
                                type="checkbox"
                                label="Requerida"
                                error={checkErrors("required", index)}
                                registration={register(
                                    `sections[${parentIndex}].detalles[${index}].required`
                                )}
                                className="py-4 h-10 w-full pb-0"
                            />
                        </div>
                    </div>

                    {watch(
                        `sections[${parentIndex}].detalles[${index}].type`
                    ) === "select" && (
                        <NestedOptions
                            index1={parentIndex}
                            index2={index}
                            {...{ control, register, formState, watch }}
                        />
                    )}

                    {watch(
                        `sections[${parentIndex}].detalles[${index}].type`
                    ) === "checkbox" && (
                        <NestedOptions
                            index1={parentIndex}
                            index2={index}
                            {...{ control, register, formState, watch }}
                        />
                    )}

                    {watch(
                        `sections[${parentIndex}].detalles[${index}].type`
                    ) === "radio" && (
                        <NestedOptions
                            index1={parentIndex}
                            index2={index}
                            {...{ control, register, formState, watch }}
                        />
                    )}
                </div>
            ))}

            <Button
                onClick={() =>
                    append({
                        seccion: "Nombre de la sección",
                        type: "text",
                        required: false,
                        name: "Nombre de la pregunta",
                        description: "Descripción o detalle de la pregunta",
                    })
                }
                className="w-full mt-4"
            >
                Agregar pregunta
            </Button>
        </div>
    );
}
