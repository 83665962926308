import React, { useState } from "react";
import { Fetch } from "../../Services/Fetch";
import { Button } from "../Button";
import renderErrors from "../../helpers/renderErrors";
import Modal from "../modal";
import { toast } from "react-toastify";
import { Form, InputField, TextAreaField } from "../Form";
import * as yup from "yup";

const schema = yup.object().shape({
    subject: yup.string().required("Requerido"),
    body: yup.string().required("Requerido"),
});

export default function SendEmail({
    setModal,
    state,
    destino,
    subject,
    refresh,
}) {
    const [loading, setloading] = useState(false);
    const send = async (data) => {
        if (!destino) {
            toast.error("No existe destino para este mensaje");
            return;
        }
        setloading(true);
        try {
            data.user_id = destino;
            await Fetch({
                data: data,
                uri: "solicitudes/becado-email",
            });
            toast.success("Mensaje enviado exitosamente");
            refresh && refresh();
            setTimeout(() => {
                setModal(false);
            }, 300);
        } catch (error) {
            renderErrors(error.response.data);
        }
        setloading(false);
    };

    return (
        <Modal
            isVisible={state}
            title={"Enviar Correo al solicitante"}
            action={() => setModal(false)}
        >
            <Form
                onSubmit={send}
                options={{
                    defaultValues: {
                        subject: subject && `Respuesta a: ${subject}`,
                    },
                }}
                schema={schema}
            >
                {({ register, formState }) => (
                    <>
                        <InputField
                            label="Asunto"
                            disabled={subject}
                            error={formState.errors.subject}
                            registration={register("subject")}
                        />

                        <TextAreaField
                            label="Mensaje"
                            error={formState.errors.message}
                            registration={register("body")}
                            placeholder="Escriba su mensaje aquí..."
                            rows="3"
                        />

                        <div className="flex justify-end">
                            <Button
                                type="submit"
                                variant="inverse"
                                isLoading={loading}
                            >
                                Enviar
                            </Button>
                        </div>
                    </>
                )}
            </Form>
        </Modal>
    );
}
