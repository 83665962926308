import React from "react";
import * as Icon from "react-feather";
import Authorized from "../../helpers/authorized";
import MenuItem from "./MenuItem";

export default function Navigation(props) {
    return (
        <div>
            {true && (
                <ul>
                    <Authorized
                        hasAuthorized={[
                            "admin",
                            "coordinador",
                            "institucion",
                            "lectura",
                        ]}
                    >
                        <MenuItem
                            current={props.active}
                            to="/inicio"
                            title="Dashboard"
                        >
                            <Icon.PieChart size="21" />
                        </MenuItem>
                    </Authorized>
                    <Authorized
                        hasAuthorized={[
                            "admin",
                            "coordinador",
                            "evaluador",
                            ,
                            "institucion",
                            "oferente",
                            "lectura",
                        ]}
                    >
                        <MenuItem
                            current={props.active}
                            to="/convocatorias"
                            title="Convocatorias"
                        >
                            <Icon.File size="21" />
                        </MenuItem>
                    </Authorized>
                    <Authorized hasAuthorized={["evaluador"]}>
                        <MenuItem
                            current={props.active}
                            to="/evaluaciones"
                            title="Evaluaciones"
                        >
                            <Icon.Folder size="21" />
                        </MenuItem>
                    </Authorized>
                    <Authorized
                        hasAuthorized={[
                            "admin",
                            "coordinador",
                            "institucion",
                            "oferente",
                            "lectura",
                        ]}
                    >
                        <MenuItem
                            current={props.active}
                            to="/becados"
                            title="Becados"
                        >
                            <Icon.Users size="21" />
                        </MenuItem>
                    </Authorized>
                    {/* <Authorized
                        hasAuthorized={["admin", "coordinador", "oferente"]}
                    >
                        <MenuItem
                            current={props.active}
                            to="/desembolsos"
                            title="Desembolsos"
                        >
                            <Icon.DollarSign size="21" />
                        </MenuItem>
                    </Authorized> */}
                    <Authorized
                        hasAuthorized={["admin", "coordinador", "lectura"]}
                    >
                        <MenuItem
                            current={props.active}
                            to="/oferentes"
                            title="Oferentes"
                        >
                            <Icon.Briefcase size="21" />
                        </MenuItem>
                    </Authorized>
                    <Authorized
                        hasAuthorized={[
                            "admin",
                            "coordinador",
                            "evaluador",
                            ,
                            "oferente",
                            "lectura",
                        ]}
                    >
                        <MenuItem
                            current={props.active}
                            to="/instituciones"
                            title="Instituciones"
                        >
                            <Icon.Home size="21" />
                        </MenuItem>
                    </Authorized>
                    <Authorized
                        hasAuthorized={[
                            "admin",
                            "coordinador",
                            "oferente",
                            "lectura",
                        ]}
                    >
                        <MenuItem
                            current={props.active}
                            to="/coordinadores"
                            title="Coordinadores"
                        >
                            <Icon.UserCheck size="21" />
                        </MenuItem>
                    </Authorized>

                    <Authorized
                        hasAuthorized={[
                            "admin",
                            "coordinador",
                            "oferente",
                            "lectura",
                        ]}
                    >
                        <MenuItem
                            current={props.active}
                            to="/evaluadores"
                            title="Evaluadores"
                        >
                            <Icon.CheckSquare size="21" />
                        </MenuItem>
                    </Authorized>
                    <Authorized
                        hasAuthorized={[
                            "admin",
                            "coordinador",
                            "oferente",
                            "lectura",
                        ]}
                    >
                        <MenuItem
                            current={props.active}
                            to="/formularios"
                            title="Formularios"
                        >
                            <Icon.Clipboard size="21" />
                        </MenuItem>
                    </Authorized>

                    <Authorized hasAuthorized={["institucion", "lectura"]}>
                        <MenuItem
                            current={props.active}
                            to="/estatus_becados"
                            title="Estatus becados"
                        >
                            <Icon.BarChart2 size="21" />
                        </MenuItem>
                    </Authorized>
                    <Authorized hasAuthorized={["institucion"]}>
                        <MenuItem
                            current={props.active}
                            to="/Carreras"
                            title="Carreras"
                        >
                            <Icon.Archive size="21" />
                        </MenuItem>
                    </Authorized>

                    <Authorized hasAuthorized={["institucion", "lectura"]}>
                        <MenuItem
                            current={props.active}
                            to="/ofertas_academicas"
                            title="Oferta Académica"
                        >
                            <Icon.Layers size="21" />
                        </MenuItem>
                    </Authorized>

                    <Authorized hasAuthorized={["admin", "lectura"]}>
                        <MenuItem
                            current={props.active}
                            to="/reportes"
                            title="Reportes"
                        >
                            <Icon.Book size="21" />
                        </MenuItem>
                    </Authorized>
                    <Authorized hasAuthorized={["admin", "lectura"]}>
                        <MenuItem
                            current={props.active}
                            to="/reporte_becas"
                            title="Reporte de becas"
                        >
                            <Icon.Book size="21" />
                        </MenuItem>
                    </Authorized>

                    <Authorized hasAuthorized={["admin", "lectura"]}>
                        <MenuItem
                            current={props.active}
                            to="/usuarios"
                            title="Usuarios"
                        >
                            <Icon.User size="21" />
                        </MenuItem>
                    </Authorized>
                </ul>
            )}
        </div>
    );
}
