import AuthAxios from "../../config/AuthAxios";

export async function GetOne(id) {
    const Axios = AuthAxios();

    const form = new FormData();
    form.append("evaluator_id", id);

    try {
        const res = await Axios.post(`/evaluators/show`, form);

        return res;
    } catch (err) {
        console.error(err);
    }
}
