import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetAll } from "../../../Services/Convocatorias/GetAll";
import { Create } from "../../../Services/Convocatorias/Create";
import { Delete } from "../../../Services/Convocatorias/Delete";
import generateActions from "../generateActions";
import { Fetch } from "../../../Services/Fetch";

const name = "convocatorias";

const initialState = {
    all: [],
    one: {},
    create: {},
    status: null,
    estadisticas: {},
    mensajes: [],
    pagination: {},
    extraStatus: [],
    stadistics: {},
};

export const getAll = createAsyncThunk(
    `${name}/getall`,
    async (data) => await GetAll(data)
);

export const getAllFromFilter = createAsyncThunk(
    `${name}/getall`,
    async (link = "/convocatorias/filtrar") =>
        await Fetch({ uri: link, method: "get" })
);

export const getFilteredByName = createAsyncThunk(
    `${name}/getall`,
    async (data) =>
        await Fetch({
            uri: `/convocatorias/filtrar?busqueda=${data}`,
            method: "get",
        })
);

export const getPaginatedName = createAsyncThunk(
    `${name}/getpaginated`,
    async (data) =>
        await Fetch({
            uri: `/convocatorias/filtrar`,
            method: "get",
        })
);

export const getPaginatedPerIndex = createAsyncThunk(
    `${name}/getpaginatedindex`,
    async (link) => {
        return await Fetch({ uri: link, method: "get" });
    }
);

export const getOne = createAsyncThunk(
    `${name}/getone`,
    async (data) => await Fetch({ data, uri: "/convocatorias/show" })
);

export const getOneStadistics = createAsyncThunk(
    `${name}/getstadistics`,
    async ({ id }) => {
        return Promise.all([
            Fetch({
                uri: `/estadisticas/convocatoria?convocatoria_id=${id}`,
                method: "get",
            }),
            Fetch({
                uri: `/estadisticas/areas/solicitadas/convocatoria?convocatoria=${id}`,
                method: "get",
            }),
            Fetch({
                uri: `/estadisticas/provincias/solicitadas/convocatoria?convocatoria=${id}`,
                method: "get",
            }),
        ]).then(([estadisticas, areas, provincia]) => {
            const {
                data: {
                    data: { solicitudes, becados },
                },
            } = estadisticas;
            const {
                data: { data: areasData },
            } = areas;
            const {
                data: { data: provinciaData },
            } = provincia;

            return {
                estadisticas: { ...(solicitudes ?? {}), ...(becados ?? {}) },
                areas: areasData ?? [],
                totalStadisticas: solicitudes ?? {},
                totalState: provinciaData ?? [],
            };
        });
    }
);

export const create = createAsyncThunk(
    `${name}/create`,
    async (data) => await Create(data)
);

export const remove = createAsyncThunk(
    `${name}/delete`,
    async (data) => await Delete(data)
);

export const getAllMensajes = createAsyncThunk(
    `${name}/getall/mensajes`,
    async (data) =>
        await Fetch({ uri: "/convocatorias/mensajes/getAll", method: "get" })
);

export const createMensajes = createAsyncThunk(
    `${name}/create/mensajes`,
    async (data, { rejectWithValue }) => {
        try {
            const res = await Fetch({
                data,
                uri: "/convocatorias/mensajes/create",
            });
            return res.data.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateMensajes = createAsyncThunk(
    `${name}/update/mensajes`,
    async (data, { rejectWithValue }) => {
        try {
            const res = await Fetch({
                data: { ...data, mensaje_id: data.id },
                uri: "/convocatorias/mensajes/update",
            });
            return res.data.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const removeMensajes = createAsyncThunk(
    `${name}/remove/mensajes`,
    async (data, { rejectWithValue }) => {
        try {
            await Fetch({
                data,
                uri: "/convocatorias/mensajes/delete",
            });
            return data.mensaje_id;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const stadisticas = createAsyncThunk(
    `${name}/estadisticas`,
    async (data) => {
        let res1 = await Fetch({
            uri: `/estadisticas/convocatoria?convocatoria_id=${data}`,
            method: "get",
        });

        let res2 = await Fetch({
            uri: `/estadisticas/areas/solicitadas/convocatoria?convocatoria=${data}`,
            method: "get",
        });

        return { solicitudes: res1?.data?.data, areas: res2?.data?.data };
    }
);

export const reducers = generateActions([
    { action: getAll, stateProp: "all" },
    { action: getOne, stateProp: "one" },
    { action: create, stateProp: "create", statePropAll: "all" },
    { action: remove, stateProp: "delete", statePropAll: "all" },
    { action: stadisticas, stateProp: "estadisticas" },
    { action: getAllMensajes, stateProp: "mensajes" },
    { action: getPaginatedPerIndex, stateProp: "pagination" },
    { action: getOneStadistics, stateProp: "stadistics" },
]);

const OferentesSlice = createSlice({
    name,
    initialState,
    reducers: {
        syncCreate(state, action) {
            state.all.push(action.payload);
            state.create = action.payload;
            state.one = action.payload;
        },
        addOfferta(state, action) {
            state.one.ofertas?.push(action.payload);
        },
        removeOfferta(state, action) {
            state.one.ofertas = state.one.ofertas?.filter(
                (obj) => obj.id !== action.payload
            );
        },
    },
    extraReducers: {
        ...reducers,
        [createMensajes.fulfilled]: (state, { payload }) => {
            state.mensajes = [...state.mensajes, payload];
        },
        [updateMensajes.fulfilled]: (state, { payload }) => {
            state.mensajes = [
                ...state.mensajes.filter((o) => {
                    return parseInt(o.id, 10) !== parseInt(payload.id, 10);
                }),
                payload,
            ];
        },
        [removeMensajes.fulfilled]: (state, { payload }) => {
            state.mensajes = state.mensajes.filter((o) => {
                return parseInt(o.id, 10) !== parseInt(payload, 10);
            });
        },
    },
});
export const { syncCreate, addOfferta, removeOfferta } = OferentesSlice.actions;
export default OferentesSlice.reducer;
