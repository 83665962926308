import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import { Fetch } from "../../Services/Fetch";

export default function Messages() {
    const [messages, setMessages] = useState([]);

    const getAll = async () => {
        const res = await Fetch({
            uri: "/mensajes/internos/getAll",
            method: "get",
        });
        setMessages(res.data.data);
    };

    useEffect(() => {
        getAll();
    }, []);

    return (
        <>
            <Menu as="div" className="relative inline-block text-left ">
                <Menu.Button className="relative cursor-pointer group">
                    <Icon.Mail className="text-sm" />
                    <div className="badge">
                        <span className="block w-full text-xs">
                            {messages.reduce((acc, curr) => {
                                if (curr.estado === "unread") {
                                    return acc + 1;
                                } else {
                                    return acc;
                                }
                            }, 0)}
                        </span>
                    </div>
                </Menu.Button>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="origin-top-right md:absolute fixed md:right-0 right-4 mt-2 w-80 max-h-96 md:w-96 overflow-y-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {messages?.map((msg) => (
                            <Link key={msg.id} to="/perfil/mensajes">
                                <Menu.Item>
                                    {({ active }) => (
                                        <div
                                            className={`${
                                                active && "bg-gray-200"
                                            }
                                      border-b-2 text-sm p-4 cursor-pointer
                                       `}
                                        >
                                            <div className="">
                                                <div className="flex items-center justify-between pb-2">
                                                    <div className="font-semibold ">
                                                        {msg.from}
                                                    </div>
                                                </div>

                                                <div className="text-gray-700">
                                                    {msg.message}
                                                </div>
                                                <div className="my-1">
                                                    <p className="text-xs text-gray-400">
                                                        {msg.received}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Menu.Item>
                            </Link>
                        ))}

                        <Link to="/perfil/mensajes">
                            <Menu.Item>
                                {({ active }) => (
                                    <div
                                        className={`${active && "bg-gray-200"}
                                      border-b-2 text-sm p-4 cursor-pointer 
                                       `}
                                    >
                                        <div className="flex items-center justify-center font-semibold">
                                            Ver más
                                        </div>
                                    </div>
                                )}
                            </Menu.Item>
                        </Link>
                    </Menu.Items>
                </Transition>
            </Menu>
        </>
    );
}
