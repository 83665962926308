import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Fetch } from "../../../Services/Fetch";
import generateActions from "../generateActions";

const name = "solicitudes";

const initialState = {
    all: [],
    filtered: [],
    one: {},
    create: [],
    stadistics: {},
    selectsData: {
        Instituciones: [],
        Carreras: [],
        Generos: [],
        Estados: [],
        Niveles: [],
        Tipos: [],
    },
    status: null,
};

export const getSolicitudes = createAsyncThunk(
    `${name}/getall`,
    async (data) =>
        await Fetch({ data, uri: "/solicitudes/getAll", method: "get" })
);

export const getOneSolicitud = createAsyncThunk(
    `${name}/getone`,
    async (data) => await Fetch({ data, uri: "/solicitudes/show" })
);

export const getFilteredSolicitudes = createAsyncThunk(
    `${name}/getfilltered`,
    async ({ id, search = "" }) => {
        return await Fetch({
            uri: `/solicitudes/filtros?convocatoria=${id}${search}`,
            method: "get",
        });
    }
);

export const getPaginationSolicitudes = createAsyncThunk(
    `${name}/getall`,
    async ({ array, convocatoria_id, nextLink }) => {
        let response = await Fetch({
            data: { convocatoria_id: convocatoria_id },
            uri: nextLink,
            method: "get",
        });
        response.data.data = [...array, response.data.data].flat();
        return response;
    }
);

export const getPaginationSolicitudesByDirectLink = createAsyncThunk(
    `${name}/getall`,
    async ({ link, convocatoria_id }) =>
        await Fetch({
            uri: `${link}&convocatoria=${convocatoria_id}`,
            method: "get",
        })
);

export const getCustomSearch = createAsyncThunk(
    `${name}/getselect`,
    async ({
        internalSearch,
        instituciones,
        genero,
        carreras,
        estado,
        nivel,
        tipo,
        modalidad,
        id,
    }) =>
        await Fetch({
            uri: `/solicitudes/filtros?convocatoria=${id}&busqueda=${internalSearch}&institucion=${instituciones}&genero=${genero}&carrera=${carreras}&estado=${estado}&nivel=${nivel}&tipo=${tipo}&modalidad=${modalidad}`,
            method: "get",
        })
);

export const getSolicitudesBy = createAsyncThunk(
    `${name}/getby`,
    async (data) => await Fetch({ data, uri: "/solicitudes/getSolicitudes" })
);

export const getSolicitudesFilteredBy = createAsyncThunk(
    `${name}/getby`,
    async (data) => {
        let response = await Fetch({
            uri: `solicitudes/filtros?institucion=${data}`,
            method: "get",
        });
        return response;
    }
);

// Methods before Re-factoring

// Method for getting Institution Statdistics
export const getInstitucionStadistics = createAsyncThunk(
    `${name}/getallstadistics`,
    async ({ id, convoc }) =>
        await Fetch({
            uri: `estadisticas/institucion?convocatoria=${convoc}&institucion=${id}`,
            method: "get",
        })
);

// Method for getting SOlicitudes availables
export const getInstitucionSolicitudesData = createAsyncThunk(
    `${name}/getall`,
    async ({ id, convoc }) =>
        await Fetch({
            uri: `solicitudes/filtros?institucion=${id}&convocatoria=${convoc}`,
            method: "get",
        })
);

// Method for getting Solicitudes availables (pagination)
export const getInstitucionSolicitudesDataPaginated = createAsyncThunk(
    `${name}/getall`,
    async (uri) =>
        await Fetch({
            uri: uri,
            method: "get",
        })
);

// Method for getting Solicitudes filtered availables (pagination)
export const getInstitucionSolicitudesDataPaginatedSearch = createAsyncThunk(
    `${name}/getall`,
    async (id) =>
        await Fetch({
            uri: `solicitudes/filtros?${id}`,
            method: "get",
        })
);

export const getSelectsDataDestructured = createAsyncThunk(
    `${name}/getselectsdata`,
    async ({ convoc = 0, insti = 0 }) =>
        Promise.all([
            Fetch({
                uri: `selects/solicitudes/instituciones?convocatoria=${convoc}`,
                method: "get",
            }),
            Fetch({
                uri: `selects/solicitudes/carreras?convocatoria=${convoc}&institucion=${insti}`,
                method: "get",
            }),
            Fetch({ uri: "selects/generos", method: "get" }),
            Fetch({ uri: `selects/solicitudes/estados`, method: "get" }),
            Fetch({
                uri: `selects/solicitudes/niveles?convocatoria=${convoc}&institucion=${insti}`,
                method: "get",
            }),
            Fetch({
                uri: `selects/solicitudes/tipos?convocatoria=${convoc}&institucion=${insti}`,
                method: "get",
            }),
        ]).then(
            ([instituciones, carreras, generos, estados, niveles, tipos]) => ({
                instituciones: instituciones.data.data ?? [],
                carreras: carreras.data.data ?? [],
                generos: generos.data.data ?? [],
                estados: estados.data.data ?? [],
                niveles: niveles.data.data ?? [],
                tipos: tipos.data.data ?? [],
            })
        )
);

export const getSelectsData = createAsyncThunk(
    `${name}/getselectsdata`,
    async (data = 0) =>
        Promise.all([
            Fetch({
                uri: `selects/solicitudes/instituciones?convocatoria=${data}`,
                method: "get",
            }),
            Fetch({
                uri: `selects/solicitudes/carreras?convocatoria=${data}`,
                method: "get",
            }),
            Fetch({ uri: "selects/generos", method: "get" }),
            Fetch({ uri: `selects/solicitudes/estados`, method: "get" }),
            Fetch({
                uri: `selects/solicitudes/niveles?convocatoria=${data}`,
                method: "get",
            }),
            Fetch({
                uri: `selects/solicitudes/tipos?convocatoria=${data}`,
                method: "get",
            }),
            Fetch({
                uri: `selects/modalidades`,
                method: "get",
            }),
        ]).then(
            ([
                instituciones,
                carreras,
                generos,
                estados,
                niveles,
                tipos,
                modalidad,
            ]) => ({
                instituciones: instituciones.data.data ?? [],
                carreras: carreras.data.data ?? [],
                generos: generos.data.data ?? [],
                estados: estados.data.data ?? [],
                niveles: niveles.data.data ?? [],
                tipos: tipos.data.data ?? [],
                modalidad: modalidad.data.data ?? [],
            })
        )
);

export const reducers = generateActions([
    { action: getSolicitudes, stateProp: "all" },
    { action: getSolicitudesBy, stateProp: "all" },
    { action: getFilteredSolicitudes, stateProp: "all" },
    { action: getOneSolicitud, stateProp: "one" },
    { action: getSolicitudesFilteredBy, stateProp: "filterd" },
    { action: getInstitucionStadistics, stateProp: "stadistics" },
    { action: getSelectsData, stateProp: "selectsData" },
    { action: getSelectsDataDestructured, stateProp: "selectsData" },
    { action: getCustomSearch, stateProp: "all" },
    { action: getPaginationSolicitudesByDirectLink, stateProp: "all" },
]);

const SolicitudesSlice = createSlice({
    name,
    initialState,
    reducers: {
        setRequerimiento: (state, { payload }) => {
            state.one.evaluacion = state.one.evaluacion.reduce((acc, curr) => {
                if (payload.id === curr.id)
                    curr = { ...curr, score: payload.score, loading: false };
                return [...acc, curr];
            }, []);
        },
        setLoading: (state, { payload }) => {
            state.one.evaluacion = state.one.evaluacion.reduce((acc, curr) => {
                if (payload.id === curr.id)
                    curr = { ...curr, loading: payload.state };
                return [...acc, curr];
            }, []);
        },
    },
    extraReducers: {
        ...reducers,
    },
});
export const { setRequerimiento, setLoading } = SolicitudesSlice.actions;
export default SolicitudesSlice.reducer;
