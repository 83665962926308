import AuthAxios from '../../config/AuthAxios'

export async function GetAll(setLoading) {
  const Axios = AuthAxios()
  try {
    return Axios.get(`/oferentes/getAll`)
  } catch (err) {
    console.error(err)
  }
}
