import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    addEvaluationFilter,
    clearEvaluationFilter,
} from "../../../Store/slices/evaluationFilters/index.js";
import { Button } from "../../Button.js";
import Spinner from "../Spinner.jsx";

// Filterable-Becados-Component
export function BecadosFilterable({
    selects = {},
    setSearch = (p) => console.log(p),
    exportable = () => console.log("aaa"),
    hide = false,
    hasconvocatoria = null,
    isSpinning = false,
}) {
    const [internalSearch, setInternalSearch] = useState("");
    const [estado, setEstado] = useState("");
    const [areas, setAreas] = useState("");
    const [sede, setSede] = useState("");
    const [Niveles, setNiveles] = useState("");
    const [instituciones, setInstituciones] = useState("");
    const [carreras, setCarreras] = useState("");
    const [Generos, setGeneros] = useState("");
    const [convocatoria, setConvocatoria] = useState("");
    const [modalidad, setModalidad] = useState("");
    const [hidden, setHidden] = useState(hide);

    function makeExportable() {
        exportable({
            busqueda: internalSearch,
            estado: estado,
            area: areas,
            sede: sede,
            nivel: Niveles,
            institucion: instituciones,
            carrera: carreras,
            genero: Generos,
            convocatoria: convocatoria,
            modalidad: modalidad,
        });
    }

    function searchableDispatcher() {
        setSearch({
            busqueda: internalSearch,
            estado: estado,
            area: areas,
            sede: sede,
            nivel: Niveles,
            institucion: instituciones,
            carrera: carreras,
            genero: Generos,
            convocatoria: convocatoria,
            modalidad: modalidad,
        });
    }

    return (
        <div className="w-full p-5 bg-white rounded-lg shadow">
            {/* Upper bar Start */}
            <div className="flex items-center justify-between mt-4">
                <button
                    className="font-medium"
                    onClick={() => setHidden(!hidden)}
                >
                    <u>Filtros</u>
                </button>

                <button
                    className="px-4 py-2 text-sm font-medium text-gray-800 bg-gray-100 rounded-md hover:bg-gray-200"
                    onClick={() => makeExportable()}
                    disabled={isSpinning}
                >
                    {isSpinning ? <Spinner size="sm" /> : "Exportar Archivo"}
                </button>
            </div>
            {/* Upper bar End */}
            <div>
                <div
                    className={`grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 mt-4 ${
                        hidden ? "hidden" : ""
                    }`}
                >
                    {hasconvocatoria !== null ? (
                        <select
                            className="w-full px-4 py-3 text-sm bg-gray-100 border-transparent rounded-md focus:border-gray-500 focus:bg-white focus:ring-0"
                            value={convocatoria}
                            onChange={({ target: { value } }) =>
                                setConvocatoria(value)
                            }
                        >
                            <option value="">Convocatoria...</option>
                            {(selects["generalSelector"] ?? []).map(
                                ({ name, id }) => (
                                    <option value={id}>{name}</option>
                                )
                            )}
                        </select>
                    ) : null}

                    {/* Estado */}
                    <select
                        className="w-full px-4 py-3 text-sm bg-gray-100 border-transparent rounded-md focus:border-gray-500 focus:bg-white focus:ring-0"
                        value={estado}
                        onChange={({ target: { value } }) => setEstado(value)}
                    >
                        <option value="">Estado...</option>
                        {(selects["estados"] ?? []).map((x) => (
                            <option value={x}>{x}</option>
                        ))}
                    </select>

                    {/* Areas */}
                    <select
                        className="w-full px-4 py-3 text-sm bg-gray-100 border-transparent rounded-md focus:border-gray-500 focus:bg-white focus:ring-0"
                        value={areas}
                        onChange={({ target: { value } }) => setAreas(value)}
                    >
                        <option value="">Areas...</option>
                        {(selects["areas"] ?? []).map(({ name }) => (
                            <option value={name}>{name}</option>
                        ))}
                    </select>

                    {/* Sede */}
                    <select
                        className="w-full px-4 py-3 text-sm bg-gray-100 border-transparent rounded-md focus:border-gray-500 focus:bg-white focus:ring-0"
                        value={sede}
                        onChange={({ target: { value } }) => setSede(value)}
                    >
                        <option value="">Sede...</option>
                        {(selects["sedes"] ?? []).map(({ name }) => (
                            <option value={name}>{name}</option>
                        ))}
                    </select>

                    {/* Niveles */}
                    <select
                        className="w-full px-4 py-3 text-sm bg-gray-100 border-transparent rounded-md focus:border-gray-500 focus:bg-white focus:ring-0"
                        value={Niveles}
                        onChange={({ target: { value } }) => setNiveles(value)}
                    >
                        <option value="">Niveles...</option>
                        {(selects["niveles"] ?? []).map(({ name }) => (
                            <option value={name}>{name}</option>
                        ))}
                    </select>

                    {/* Carreras */}
                    <select
                        className="w-full px-4 py-3 text-sm bg-gray-100 border-transparent rounded-md focus:border-gray-500 focus:bg-white focus:ring-0"
                        value={carreras}
                        onChange={({ target: { value } }) => setCarreras(value)}
                    >
                        <option value="">Carreras...</option>
                        {(selects["carreras"] ?? []).map(({ career }) => {
                            return <option value={career}>{career}</option>;
                        })}
                    </select>

                    {/* Instituciones */}
                    <select
                        className="w-full px-4 py-3 text-sm bg-gray-100 border-transparent rounded-md focus:border-gray-500 focus:bg-white focus:ring-0"
                        value={instituciones}
                        onChange={({ target: { value } }) =>
                            setInstituciones(value)
                        }
                    >
                        <option value="">Instituciones...</option>
                        {(selects["instituciones"] ?? []).map(
                            ({ name, id }) => (
                                <option value={id}>{name}</option>
                            )
                        )}
                    </select>

                    {/* Generos */}
                    <select
                        className="w-full px-4 py-3 text-sm bg-gray-100 border-transparent rounded-md focus:border-gray-500 focus:bg-white focus:ring-0"
                        value={Generos}
                        onChange={({ target: { value } }) => setGeneros(value)}
                    >
                        <option value="">Generos...</option>
                        {(selects["generos"] ?? []).map(({ nombre }) => (
                            <option value={nombre}>{nombre}</option>
                        ))}
                    </select>

                    {/* Modalidad */}
                    <select
                        className="w-full px-4 py-3 text-sm bg-gray-100 border-transparent rounded-md focus:border-gray-500 focus:bg-white focus:ring-0"
                        value={modalidad}
                        onChange={({ target: { value } }) =>
                            setModalidad(value)
                        }
                    >
                        <option value="">Modalidad...</option>
                        {(selects["modalidades"] ?? []).map(({ modality }) => (
                            <option value={modality}>{modality}</option>
                        ))}
                    </select>

                    {/* Statically-Search */}
                    <input
                        className="w-full px-4 py-3 text-sm bg-gray-100 border-transparent rounded-md focus:border-gray-500 focus:bg-white focus:ring-0"
                        placeholder="Buscar..."
                        value={internalSearch}
                        onChange={({ target: { value } }) =>
                            setInternalSearch(value)
                        }
                    />

                    <div className="flex">
                        <Button
                            className="w-3/6"
                            onClick={() => searchableDispatcher()}
                        >
                            Buscar
                        </Button>
                        <Button
                            className="w-3/6"
                            onClick={() => {
                                setInternalSearch("");
                                setEstado("");
                                setAreas("");
                                setSede("");
                                setNiveles("");
                                setInstituciones("");
                                setCarreras("");
                                setGeneros("");
                                setConvocatoria("");

                                searchableDispatcher();
                            }}
                        >
                            Limpiar Filtros
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

// Filterable-Solicitudes-Component
export function SolicitudesFilterable({
    selects = {},
    setSearch = (p) => console.log(p),
    exportable = () => console.log("aaa"),
    isSpinning = false,
    notInst = false,
    instit = "",
}) {
    const queryState = useSelector((state) => state.evaluationFilters);
    const dispatch = useDispatch();

    const setQueryState = (name, value) => {
        dispatch(
            addEvaluationFilter({
                name: name,
                value: value,
            })
        );
    };

    useEffect(() => {
        setSearch({
            internalSearch: queryState.search,
            instituciones: instit || queryState.institution,
            genero: queryState.genre,
            carreras: queryState.career,
            estado: queryState.status,
            nivel: queryState.level,
            tipo: queryState.type,
            modalidad: queryState.modality,
        });
    }, []);

    const search = () => {
        setSearch({
            internalSearch: queryState.search,
            instituciones: instit || queryState.institution,
            genero: queryState.genre,
            carreras: queryState.career,
            estado: queryState.status,
            nivel: queryState.level,
            tipo: queryState.type,
            modalidad: queryState.modality,
        });
    };

    const clear = () => {
        setSearch({
            internalSearch: "",
            instituciones: instit,
            genero: "",
            carreras: "",
            estado: "",
            nivel: "",
            tipo: "",
            modalidad: "",
        });

        dispatch(clearEvaluationFilter());
    };

    return (
        <div className="w-full p-5 bg-white rounded-lg shadow">
            <div className="flex items-center justify-between mt-4">
                <p className="font-medium">Filtros</p>

                <button
                    className={`px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 text-sm font-medium rounded-md `}
                    onClick={exportable}
                    disabled={isSpinning}
                >
                    {isSpinning ? <Spinner size="sm" /> : "Exportar Archivo"}
                </button>
            </div>

            <div>
                <div className="grid grid-cols-2 gap-4 mt-4 md:grid-cols-3 xl:grid-cols-4">
                    {!notInst ? (
                        <select
                            className="w-full px-4 py-3 text-sm bg-gray-100 border-transparent rounded-md focus:border-gray-500 focus:bg-white focus:ring-0"
                            value={queryState.institution}
                            onChange={({ target: { value } }) => {
                                setQueryState("institution", value);
                            }}
                        >
                            <option value="">Instituciones...</option>
                            {(selects["instituciones"] ?? []).map(
                                ({ name, id }) => (
                                    <option
                                        value={id}
                                        key={id}
                                        selected={queryState.institution === id}
                                    >
                                        {name}
                                    </option>
                                )
                            )}
                        </select>
                    ) : null}

                    <select
                        className="w-full px-4 py-3 text-sm bg-gray-100 border-transparent rounded-md focus:border-gray-500 focus:bg-white focus:ring-0"
                        value={queryState.genre}
                        onChange={({ target: { value } }) => {
                            setQueryState("genre", value);
                        }}
                    >
                        <option value="">Genero...</option>
                        {(selects["generos"] ?? []).map(({ nombre }) => (
                            <option
                                key={nombre}
                                selected={queryState.genre === nombre}
                                value={nombre}
                            >
                                {nombre}
                            </option>
                        ))}
                    </select>

                    <select
                        className="w-full px-4 py-3 text-sm bg-gray-100 border-transparent rounded-md focus:border-gray-500 focus:bg-white focus:ring-0"
                        value={queryState.career}
                        onChange={({ target: { value } }) =>
                            setQueryState("career", value)
                        }
                    >
                        <option value="">Carreras...</option>
                        {(selects["carreras"] ?? []).map(({ career }) => (
                            <option
                                key={career}
                                selected={queryState.career === career}
                                value={career}
                            >
                                {career}
                            </option>
                        ))}
                    </select>

                    <select
                        className="w-full px-4 py-3 text-sm bg-gray-100 border-transparent rounded-md focus:border-gray-500 focus:bg-white focus:ring-0"
                        value={queryState.status}
                        onChange={({ target: { value } }) =>
                            setQueryState("status", value)
                        }
                    >
                        <option value="">Estado...</option>
                        {(selects["estados"] ?? []).map(({ name }) => (
                            <option
                                key={name}
                                selected={queryState.status === name}
                                value={name}
                            >
                                {name}
                            </option>
                        ))}
                    </select>

                    <select
                        className="w-full px-4 py-3 text-sm bg-gray-100 border-transparent rounded-md focus:border-gray-500 focus:bg-white focus:ring-0"
                        value={queryState.level}
                        onChange={({ target: { value } }) =>
                            setQueryState("level", value)
                        }
                    >
                        <option value="">Nivel...</option>
                        {(selects["niveles"] ?? []).map(({ name }) => (
                            <option
                                key={name}
                                selected={queryState.level === name}
                                value={name}
                            >
                                {name}
                            </option>
                        ))}
                    </select>

                    <select
                        className="w-full px-4 py-3 text-sm bg-gray-100 border-transparent rounded-md focus:border-gray-500 focus:bg-white focus:ring-0"
                        value={queryState.type}
                        onChange={({ target: { value } }) =>
                            setQueryState("type", value)
                        }
                    >
                        <option value="">Tipo...</option>
                        {(selects["tipos"] ?? []).map(({ name }) => (
                            <option
                                key={name}
                                selected={queryState.type === name}
                                value={name}
                            >
                                {name}
                            </option>
                        ))}
                    </select>

                    <select
                        className="w-full px-4 py-3 text-sm bg-gray-100 border-transparent rounded-md focus:border-gray-500 focus:bg-white focus:ring-0"
                        value={queryState.modality}
                        onChange={({ target: { value } }) =>
                            setQueryState("modality", value)
                        }
                    >
                        <option value="">Modalidad...</option>
                        {(selects["modalidad"] ?? []).map(({ modality }) => (
                            <option
                                key={modality}
                                selected={queryState.modality === modality}
                                value={modality}
                            >
                                {modality}
                            </option>
                        ))}
                    </select>

                    <input
                        className="w-full px-4 py-3 text-sm bg-gray-100 border-transparent rounded-md focus:border-gray-500 focus:bg-white focus:ring-0"
                        placeholder="Buscar..."
                        value={queryState.search}
                        onChange={({ target: { value } }) =>
                            setQueryState("search", value)
                        }
                    />
                    <div className="flex">
                        <Button className="w-3/6" onClick={() => search()}>
                            Buscar
                        </Button>
                        <Button className="w-3/6" onClick={() => clear()}>
                            Limpiar Filtros
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
