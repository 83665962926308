import { BACKOFFICE_URI } from "../../config/path_base";

export async function Loging(data) {
    const form = new FormData();
    Object.entries(data).forEach((obj) => {
        form.append(obj[0], obj[1]);
    });
    try {
        const res = await fetch(`${BACKOFFICE_URI}/profile/login`, {
            method: "post",
            headers: new Headers({
                Accept: "application/json",
            }),
            body: form,
        });
        const d = await res.json();
        sessionStorage.setItem("user", JSON.stringify(d));
        return d;
    } catch (err) {
        console.error(err);
        throw err;
    }
}
