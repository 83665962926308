import alertify from "alertifyjs";
import { useState } from "react";
import { UserCheck, X } from "react-feather";
import { toast } from "react-toastify";
import { Fetch } from "../../Services/Fetch";
import { Button } from "../Button";
import SendMessages from "../../components/messages/SendMessages";
import renderErrors from "../../helpers/renderErrors";

export const MessagesCard = ({ message, getAllMessages }) => {
    const [modal, setModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const deleteMessage = async () => {
        try {
            setIsLoading(true);
            await Fetch({
                data: {
                    mensaje_id: message.id,
                },
                uri: "/mensajes/internos/delete",
            });
            setIsLoading(false);
            toast.success("¡Actualizado!");
            getAllMessages();
        } catch (error) {
            setIsLoading(false);
            renderErrors(data.response.data);
        }
    };

    const markAsRead = async () => {
        try {
            setIsLoading(true);
            await Fetch({
                data: {
                    mensaje_id: message.id,
                },
                uri: "/mensajes/internos/setRead",
            });
            setIsLoading(false);
            toast.success("¡Actualizado!");
            getAllMessages();
        } catch (error) {
            setIsLoading(false);
            renderErrors(data.response.data);
        }
    };

    const markAsUnRead = async () => {
        try {
            setIsLoading(true);
            await Fetch({
                data: {
                    mensaje_id: message.id,
                },
                uri: "/mensajes/internos/setUnread",
            });
            setIsLoading(false);
            toast.success("¡Actualizado!");
            getAllMessages();
        } catch (error) {
            setIsLoading(false);
            renderErrors(data.response.data);
        }
    };

    return (
        <>
            <SendMessages
                setModal={setModal}
                state={modal}
                destino={message.id_candidato}
                subject={message.subject}
                refresh={getAllMessages}
            />

            <main className="flex w-full h-full shadow ">
                <section className="flex flex-col w-full px-4 bg-white ">
                    <div className="flex items-center justify-between py-1 mb-8 border-b-2">
                        <div>
                            <h1 className="py-2 text-2xl font-bold">
                                {message.subject}
                            </h1>

                            <div className="flex items-center gap-4">
                                <div className="items-center justify-center hidden md:flex">
                                    <div className="flex items-center justify-center w-8 h-8 bg-blue-200 rounded-full">
                                        <UserCheck className="w-4 h-4 text-blue-900" />
                                    </div>
                                </div>

                                <div className="flex flex-col">
                                    <h3 className="text-lg font-semibold"></h3>
                                    <p className="text-gray-600 text-light ">
                                        {message.tipo === "recibido"
                                            ? `De: ${message.from}`
                                            : `Para: ${message.to}`}
                                    </p>
                                    <p className="text-sm text-gray-400">
                                        {message.received}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <ul className="flex items-center gap-4 text-gray-400">
                                {/* {message.tipo === "enviado"
                                ? message.estado === "unread"
                                    ? "No ha sido leído"
                                    : "Leído"
                                : ""} */}

                                {message.tipo === "recibido" && (
                                    <Button
                                        onClick={
                                            message.tipo === "recibido" &&
                                            message.estado === "unread"
                                                ? markAsRead
                                                : markAsUnRead
                                        }
                                        variant={
                                            message.tipo === "recibido" &&
                                            message.estado === "unread"
                                                ? "primary"
                                                : "inverse"
                                        }
                                        isLoading={isLoading}
                                    >
                                        {message.tipo === "recibido" &&
                                        message.estado === "unread"
                                            ? "Marcar como leído"
                                            : "Marcar como no leído"}
                                    </Button>
                                )}

                                <Button
                                    onClick={deleteMessage}
                                    variant="dangerInversed"
                                    isLoading={isLoading}
                                >
                                    Borrar
                                </Button>
                            </ul>
                        </div>
                    </div>
                    <section>
                        <article className="leading-7 tracking-wider text-gray-500 ">
                            <p>{message.message}</p>
                            <footer className="pt-4">
                                <p>{message.recived}</p>
                            </footer>
                        </article>
                    </section>
                    {message.tipo === "recibido" && (
                        <section className="pt-4 pb-3">
                            <div className="flex items-center justify-end py-2">
                                <Button
                                    onClick={() => setModal(true)}
                                    isLoading={isLoading}
                                >
                                    Responder
                                </Button>
                            </div>
                        </section>
                    )}
                </section>
            </main>
        </>
    );
};
