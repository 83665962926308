import AuthAxios from '../../config/AuthAxios'

export async function Delete(setLoading, id) {
  setLoading(true)
  const Axios = AuthAxios()
  try {
    const res = await Axios.post(
      `/instituciones/delete?institution_offer_id=${id}`,
    )
    setLoading(false)
    return res
  } catch (err) {
    console.error(err)
    setLoading(false)
  }
}
