import * as Icon from "react-feather";
import noimage from "../images/no_image_available.png";

export default function DetallesBody({ data }) {
    return (
        <div className="grid grid-cols-12 mb-[22px] bg-white items-center justify-start">
            <div className="col-span-12 lg:col-span-3 grid xl:flex justify-center xl:justify-start items-center gap-4 p-4 border-solid border-[#F0F0F0] border-r-[1px]  ">
                <img
                    src={data?.candidate?.image_url || noimage}
                    alt="Perfil"
                    className="max-w-[150px] p-2 justify-self-center "
                />

                <div className="grid items-center justify-center ">
                    <h3 className="w-full text-2xl text-[#231F20] mb-[2px]">{`${data?.candidate?.name} ${data?.candidate?.last_name}`}</h3>
                    <div className="mt-[16px] flex justify-center lg:justify-start items-center ">
                        <span className="flex items-center mr-3">
                            <Icon.BarChart
                                size={16}
                                className="text-[#7BB31A]"
                            />
                            {/* TODO  experiencia laboral  */}
                            &nbsp;
                            {data?.candidate?.experiencia_laboral?.length || 0}
                        </span>
                        <span className="flex items-center">
                            <Icon.Award size={16} className="text-[#FF9800]" />
                            {/* TODO total de becas solicitadas */}
                            &nbsp;
                            {data?.candidate?.formacion_academica?.length || 0}
                        </span>
                    </div>
                </div>
            </div>

            <div className="col-span-12 lg:col-span-9 grid grid-rows-none lg:grid-rows-4 xl:grid-rows-3 lg:grid-flow-col items-center justify-between p-4 gap-4">
                <div className="flex items-center ">
                    <div className="pr-2">
                        <Icon.BarChart size={16} />
                    </div>
                    <div className="capitalize">{data?.convocatoria?.name}</div>
                </div>

                <div className="flex items-center ">
                    <div className="pr-2">
                        <Icon.Award size={16} />
                    </div>
                    <div className="capitalize">
                        {data?.oferta_academica?.oferta.academic_offer_name ||
                            data?.institution_offer?.academic_offer_name}
                    </div>
                </div>

                <div className="flex items-center ">
                    <div className="pr-2">
                        <Icon.BookOpen size={16} />
                    </div>
                    <div className="capitalize">
                        {data?.oferta_academica?.oferta.academic_offer_type ||
                            data?.institution_offer?.academic_offer_type}
                    </div>
                </div>

                <div className="flex items-center ">
                    <div className="pr-2">
                        <Icon.Home size={16} />
                    </div>
                    <div className="capitalize">
                        {data?.oferta_academica?.institucion?.name ||
                            data?.institution?.name}
                    </div>
                </div>
                <div className="flex items-center gap-1  lg:col-span-2 xl:col-span-3">
                    <div>
                        <Icon.Circle size={16} stroke={0} fill="green" />
                    </div>
                    <div>{`${data?.status || data?.solicitud?.status}`}</div>
                </div>

                {/* <div className="flex lg:justify-end  font-bold text-lg lg:col-span-2 xl:col-span-3">
                     TODO: en becados Solicitud score o coverage 
                    {`${data?.score || data?.coverage}/100`}
                </div> */}

                {/* TODO: Pendiente evaluador en becados */}
                <div className="flex items-center lg:justify-end lg:col-span-2 ">
                    <div>
                        {data?.evaluacion?.length
                            ? data?.evaluacion[0].evaluator_name
                            : "No encontrado"}
                    </div>
                </div>
            </div>
        </div>
    );
}
