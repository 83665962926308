import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function MenuItem(props) {
    const { to, title, children, current } = props;

    const location = useLocation();

    return (
        <>
            <li className="mb-2">
                <Link
                    className={
                        location.pathname === to ||
                        location.pathname.indexOf(to) !== -1
                            ? "bg-secondary flex items-center py-4 px-3 rounded-lg w-full text-white text-sm transition"
                            : "hover:bg-secondary flex items-center py-4 px-3 rounded-lg w-full hover:text-white text-black text-sm"
                    }
                    to={to}
                >
                    {children}&nbsp;&nbsp;{title}
                </Link>
            </li>
        </>
    );
}
