import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, Redirect, useParams } from "react-router-dom";
import * as Icon from "react-feather";
import Tabnavigator from "../../components/navigation/tabnavigator";
import Historial from "./Historial";
import Evaluaciones from "./Evaluaciones";
import {
    getFilteredSolicitudes,
    getSolicitudes,
    getSolicitudesBy,
} from "../../Store/slices/solicitudes";
import { getOne } from "../../Store/slices/evaluadores";
import noImage from "../../images/profile-placeholder.png";

export default function DetallesEvaluadores() {
    const { id } = useParams();

    const dispatch = useDispatch();

    const { one: evaluador } = useSelector((state) => state.evaluadores);

    useEffect(() => {
        dispatch(getOne(id));
        dispatch(getSolicitudesBy({ evaluator_id: id }));
    }, []);

    return (
        <div>
            <div className="m-5 pb-8">
                <div className="grid grid-cols-[164px,300px,1fr] mb-[22px] bg-white items-center">
                    <img
                        src={evaluador?.image_url || noImage}
                        alt="Perfil"
                        className="max-h-[150px] p-2"
                    />

                    <div className="px-8 border-solid border-[#F0F0F0] border-r-[1px]">
                        <h3 className="text-2xl text-[#231F20] ">
                            {evaluador?.name}
                        </h3>
                        <span className="text-xl text-[#AAAAAA]">
                            {evaluador?.type?.name}
                        </span>

                        <div className="mt-[16px] flex">
                            {evaluador?.status}
                        </div>
                    </div>

                    <div className="block px-11">
                        <span className="flex items-center mb-[14px]">
                            <Icon.Phone size={16} />
                            &nbsp;{evaluador.contact_phone}
                        </span>
                        <span className="flex items-center">
                            <Icon.Mail size={16} />
                            &nbsp;{evaluador.contact_email}
                        </span>
                    </div>
                </div>

                <Tabnavigator
                    routes={[
                        {
                            path: `/evaluadores/detalles_evaluadores/${id}/evaluaciones`,
                            name: "Evaluaciones",
                        },
                        // {
                        //     path: `/evaluadores/detalles_evaluadores/${id}/historial`,
                        //     name: "Historial",
                        // },
                    ]}
                />
                <div className="w-full h-auto bg-white p-[30px]">
                    <div>
                        <Switch>
                            <Route
                                exact
                                path="/evaluadores/detalles_evaluadores/:id/evaluaciones"
                            >
                                <Evaluaciones />
                            </Route>
                            {/* <Route
                                exact
                                path="/evaluadores/detalles_evaluadores/:id/historial"
                            >
                                <Historial />
                            </Route> */}

                            <Redirect to="/evaluadores/detalles_evaluadores/:id/evaluaciones" />
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    );
}
