import { configureStore } from "@reduxjs/toolkit";
import users from "./slices/user";
import evaluadores from "./slices/evaluadores";
import oferentes from "./slices/oferentes";
import coordinadores from "./slices/coordinadores";
import instituciones from "./slices/instituciones";
import convocatorias from "./slices/convocatorias";
import newConvocatoria from "./slices/convocatorias/newConvocatoria";
import solicitudes from "./slices/solicitudes/index";
import init from "./slices/init";
import formularios from "./slices/formularios";
import evaluations from "./slices/evaluaciones";
import horario from "./slices/horario";
import becados from "./slices/becados";
import ofertasAcademicas from "./slices/ofertaInstitucion";
import notificaciones from "./slices/notificaciones";
import usersAdmin from "./slices/usersAdmin";
import paises from "./slices/paises";
import horarios from "./slices/horarios";
import campus from "./slices/campus";
import evaluationFilters from "./slices/evaluationFilters";

export default configureStore({
    reducer: {
        campus,
        evaluationFilters,
        horarios,
        users,
        evaluadores,
        oferentes,
        coordinadores,
        instituciones,
        convocatorias,
        newConvocatoria,
        solicitudes,
        init,
        formularios,
        evaluations,
        horario,
        becados,
        ofertasAcademicas,
        notificaciones,
        usersAdmin,
        paises,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
