import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
};

const HorarioSlice = createSlice({
    name: "Horario",
    initialState,
    reducers: {
        setHorario: (state, { payload }) => {
            state.data = payload;
        },
        addHorario: (state, { payload }) => {
            state.data = [...state.data,payload];
        },
        editHorario: (state, { payload }) => {
            state.data = state.data.filter((o)=>parseInt(o.id)!==parseInt(payload.id))
            state.data = [...state.data,payload];
        },
        removeHorario: (state, { payload }) => {
            state.data = state.data.filter((o)=>parseInt(o.id)!==parseInt(payload.id))
        },
    },
});

export const { setHorario,addHorario,editHorario,removeHorario } = HorarioSlice.actions;

export default HorarioSlice.reducer;